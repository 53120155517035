import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';

const initialState = {
  newInventry: [],
  newLedger: [],
  newService: [],
  newTransaction: [],
  newTransHistory: [],
  newLedgerEdit: null,
  newInventoryEdit: null,
  newServiceEdit: null,
  newPending: [],
  taxCom: [],
  reconsillation: [],
  summary: null,
  category: [],
  location: [],
  isStatement: false,
  billWise: [],
  inventoryDetails: [],
  historyFinancial: [],
  subHistory: [],
  tab: 'inventory_itemname',
  overViewTab:'overview',
  gstData:null,
  inventoryData:[],
  inventoryLocationData:[],
  isLoading: true,
  inventorySetting:{},
  subCategory:[],
  transactionOfALocation:[],
};

const slice = createSlice({
  name: 'masterdata',
  initialState,
  reducers: {
    setTab(state, action) {
      state.tab = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setOverviewTab(state, action) {
      state.overViewTab = action.payload;
    },
    setStatement(state, action) {
      state.isStatement = action.payload;
    },
    setNewLedger(state, action) {
      const newLedger = action.payload;
      state.newLedger = newLedger;
    },

    setCategory(state, action) {
      state.category = action.payload;
    },

    setLocation(state, action) {
      state.location = action.payload;
    },

    setLedgerEdit(state, action) {
      const newLedgerEdit = action.payload;
      state.newLedgerEdit = newLedgerEdit;
    },

    setNewInventory(state, action) {
      const newInventry = action.payload;
      state.newInventry = newInventry;
    },

    setInventoryEdit(state, action) {
      const newInventoryEdit = action.payload;
      state.newInventoryEdit = newInventoryEdit;
    },

    setNewService(state, action) {
      const newService = action.payload;
      state.newService = newService;
    },

    setServiceEdit(state, action) {
      const newServiceEdit = action.payload;
      state.newServiceEdit = newServiceEdit;
    },

    setLedgerTransaction(state, action) {
      const newTransaction = action.payload;
      state.newTransaction = newTransaction;
    },

    setTransHistory(state, action) {
      const newTransHistory = action.payload;
      state.newTransHistory = newTransHistory;
    },

    setPending(state, action) {
      const newPending = action.payload;
      state.newPending = newPending;
    },

    setTaxComparision(state, action) {
      const taxCom = action.payload;
      state.taxCom = taxCom;
    },

    setInventorySummary(state, action) {
      const summary = action.payload;
      state.summary = summary;
    },

    setServiceSummary(state, action) {
      const summary = action.payload;
      state.summary = summary;
    },
    setReconsillation(state, action) {
      state.reconsillation = action.payload;
    },
    setbillWise(state, action) {
      state.billWise = action.payload;
    },
    setinventoryDetails(state, action) {
      state.inventoryDetails = action.payload;
    },
    sethistoryFinancial(state, action) {
      state.historyFinancial = action.payload;
    },
    setSubHistory(state, action) {
      state.subHistory = action.payload;
    },
    setGstData(state, action) {
      state.gstData = action.payload;
    },
    setInventoryData(state, action) {
      state.inventoryData = action.payload;
    },
    setInventoryLocationData(state, action) {
      state.inventoryLocationData = action.payload;
    },
    setInventorySetting(state, action) {
      state.inventorySetting = action.payload;
    },
    setSubCategory(state, action) {
      state.subCategory = action.payload;
    },
    setTransactionOfALocation(state, action) {
      state.transactionOfALocation = action.payload;
    },
    
  },
});

export default slice.reducer;

export const {
  setTab,
  setStatement,
  setLocation,
  setCategory,
  setNewInventory,
  setNewLedger,
  setInventoryEdit,
  setInventoryFilter,
  setLedgerEdit,
  setNewService,
  setServiceEdit,
  setLedgerTransaction,
  setPending,
  setTaxComparision,
  setInventorySummary,
  setTransHistory,
  setServiceSummary,
  setbillWise,
  setReconsillation,
  setOverviewTab,
  setinventoryDetails,
  sethistoryFinancial,
  setSubHistory,
  setGstData,
  setInventoryData,
  setIsLoading,
  setInventoryLocationData,
  setInventorySetting,
  setSubCategory,
  setTransactionOfALocation,
} = slice.actions;


export const getInventorySetting = () => async (dispatch) => {
  try {
    await axios.get(`/inventory-setting`).then((response) => dispatch(setInventorySetting(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getInventoryLocationWise = (sd,ed) => async (dispatch) => {
  try {
    await axios.get(`/locationWiseInventory?start=${sd}&end=${ed}`).then((response) => dispatch(setInventoryLocationData(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getInventory2 = (sd,ed,cat) => async (dispatch) => {
  try {
    await axios.get(`/get_category_wise_inventory?start=${sd}&end=${ed}&filter=${cat}`).then((response) => dispatch(setInventoryData(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getGstData = (gst) => async (dispatch) => {
  try {
    await axios.get(`validateGstNo?gst=${gst}`).then((response) => dispatch(setGstData(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
}; 

export const getLedger =
  (categoryId = '', startDate = '', endDate = '') =>
  async (dispatch) => {
    try {
      await axios 
        .get(`/ledger-partyAll?category_id=${categoryId}&start_date=${startDate || ''}&end_date=${endDate || ''}`)
        .then((response) => dispatch(setNewLedger(response.data)));
        dispatch(setIsLoading(false));

    } catch (error) {
      dispatch(setIsLoading(false));

      return console.error(error.message);
    }
    return true;
  };

export const getBillWise = (id, startDate, endDate, All) => async (dispatch) => {
  try {
    await axios
      .get(`ledger/BillWise/${id}?start=${startDate}&end=${endDate}&payment_status=[${All}]`)
      .then((response) => dispatch(setbillWise(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getInventoryDetails = (id, startDate, endDate) => async (dispatch) => {
  try {
    await axios
      .get(`inventory_details/${id}?start=${startDate}&end=${endDate}`)
      .then((response) => dispatch(setinventoryDetails(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getLedgerEdit = (id) => async (dispatch) => {
  try {
    await axios.get(`ledger-party?ID=${id}`).then((response) => dispatch(setLedgerEdit(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getCategory = (categoryType, subType) => async (dispatch) => {
  try {
    await axios
      .get(`/getCategory?categoryType=${categoryType}&subType=${subType}`)
      .then((response) => dispatch(setCategory(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getSubCategory = (categoryType, subType) => async (dispatch) => {
  try {
    await axios
      .get(`/getCategory?categoryType=${categoryType}&subType=${subType}`)
      .then((response) => dispatch(setSubCategory(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getLocation = (categoryType, subType) => async (dispatch) => {
  try {
    await axios
      .get(`/getCategory?categoryType=${categoryType}&subType=${subType}`)
      .then((response) => dispatch(setLocation(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getInventory = (startDate, endDate) => async (dispatch) => {
  try {
    await axios
      .get(`/inventoryAll?start=${startDate}&end=${endDate}`)
      .then((response) => dispatch(setNewInventory(response.data)));
      dispatch(setIsLoading(false));

  } catch (error) {
    dispatch(setIsLoading(false));

    return console.error(error.message);
  }
  return true;
};
// inventoryAll?start=2022-10-07&end=2023-10-07

export const getInventoryEdit = (id) => async (dispatch) => {
  try {
    await axios.get(`/inventory?ID=${id}`).then((response) => dispatch(setInventoryEdit(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getService = () => async (dispatch) => {
  try {
    await axios.get('/serviceAll').then((response) => dispatch(setNewService(response.data)));
    dispatch(setIsLoading(false));

  } catch (error) {
    dispatch(setIsLoading(false));

    return console.error(error.message);
  }
  return true;
};

export const getServiceEdit = (id) => async (dispatch) => {
  try {
    await axios.get(`/service?ID=${id}`).then((response) => dispatch(setServiceEdit(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getTaxComparision = (id, startDate, endDate) => async (dispatch) => {
  try {
    await axios
      .get(`/taxComparision/${id}?start=${startDate}&end=${endDate}`)
      .then((response) => dispatch(setTaxComparision(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getReconsillation = (id, startDate, endDate) => async (dispatch) => {
  try {
    await axios
      .get(`/reconsillation?ID=${id}&start=${startDate}&end=${endDate}`)
      .then((response) => dispatch(setReconsillation(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getLedgerPending = (id) => async (dispatch) => {
  try {
    await axios.get(`/ledger/ledgerPending/${id}`).then((response) => dispatch(setPending(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getLedgerTransaction = (id, startDate, endDate) => async (dispatch) => {
  try {
    await axios
      .get(`/ledger/ledgerTransactions/${id}?start=${startDate}&end=${endDate}`)
      .then((response) => dispatch(setLedgerTransaction(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getTransHistory = (id, startDate, endDate) => async (dispatch) => {
  try {
    await axios
      .get(`/ledger/ledgerTransHistory/${id}?start=${startDate}&end=${endDate}`)
      .then((response) => dispatch(setTransHistory(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getInventorySummary = () => async (dispatch) => {
  try {
    await axios.get('/inventory/summary').then((response) => dispatch(setInventorySummary(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getServiceSummary = () => async (dispatch) => {
  try {
    await axios.get('/service/summary').then((response) => dispatch(setServiceSummary(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const gethistoryFinancial = (id, startDate, endDate) => async (dispatch) => {
  try {
    await axios
      .get(`/financialStatement/${id}?start=${startDate}&end=${endDate}`)
      .then((response) => dispatch(sethistoryFinancial(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getSubHistory = (id) => async (dispatch) => {
  try {
    await axios.get(`/ledger/ledgerSubHistory/${id}`).then((response) => dispatch(setSubHistory(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getTransactionOfALocation= (sd, ed, location) => async (dispatch) => {
  try {
    await axios.get(`/transactionOfALocation?start=${sd}&end=${ed}&location=${location}`).then((response) => dispatch(setTransactionOfALocation(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
