import { useEffect, useState } from 'react';
import { MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { PATH_DASHBOARD } from '../../../routes/paths';
import { useDispatch } from '../../../redux/store';

const ImportButton = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isFalse = pathname.includes('import-data-form');

  const [state, setState] = useState('None');

  const option = [
    { id: 'None', label: 'None' },
    { id: 'tally', label: 'Tally' },
  ];

  const handleChange = (event) => {
    setState(event.target.value);
    if (event.target.value === 'tally') {
      navigate(PATH_DASHBOARD.importdata.importForm);
    }
    // switchBrach(event.target.value).then(() => {
    //   window.location.reload(false);
    // });
  };

  useEffect(() => {
    if (!isFalse) {
      setState('None');
    }
  }, [isFalse]);

  return (
    <>
      <FormControl sx={{ width: 120 }}>
        <InputLabel size={'small'} id="import">
          Import
        </InputLabel>
        <Select value={state} size={'small'} labelId="Import" id="import" label="Import" onChange={handleChange}>
          {option.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default ImportButton;
