import React, { useState } from 'react';
import Joyride from 'react-joyride';
import { Avatar, Badge, Box, Snackbar, Button, styled } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import ImageIcon from '../../../image/pic4.png';

const ToolbarShadowStyle = styled('div')(({ theme }) => ({
  left: 0,
  right: 0,
  bottom: 0,
  height: 24,
  zIndex: -1,
  margin: 'auto',
  borderRadius: '50%',
  position: 'absolute',
  boxShadow: theme.customShadows.z8,
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));

const ContactButton = () => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        console.log(`Copied ${text} to clipboard`);
        setSnackbarMessage(`Copied ${text} to clipboard`);
        setSnackbarOpen(true);
      })
      .catch((err) => {
        console.error('Failed to copy: ', err);
      });
  };

  const [isJoyrideOpen, setIsJoyrideOpen] = useState(false);

  const joyrideSteps = [
    {
      target: '#anjar-button',
      content: (
        <Box sx={{ textAlign: 'start' }}>
          <p>I've helped 200+ businesses streamline their operations in over 20 states.</p>
          <Box display="flex" alignItems="center" mt={3}>
            <MailOutlineIcon />
            <p style={{ marginLeft: '10px' }}>
              info@startupkhata.com{' '}
              <ContentCopyIcon
                style={{ marginLeft: '5px', cursor: 'pointer' }}
                onClick={() => copyToClipboard('info@startupkhata.com')}
                sx={{ width: '16px' }}
              />
            </p>
          </Box>
          <Box display="flex" alignItems="center" my={1}>
            <PhoneIcon />
            <p style={{ marginLeft: '10px' }}>
              (+91)8826081545{' '}
              <ContentCopyIcon
                style={{ marginLeft: '5px', cursor: 'pointer' }}
                onClick={() => copyToClipboard('(+91)8826081545')}
                sx={{ width: '16px' }}
              />
            </p>
          </Box>
          <Button
            variant="contained"
            sx={{ borderRadius: '50px', color: '#F9F9F9', mt: 2 }}
            href="tel:+918826081545"
            startIcon={<PhoneIcon />}
          >
            Call Now
          </Button>
        </Box>
      ),
      disableBeacon: true,
    },
    // Add more steps as needed
  ];

  const handleJoyrideButtonClick = () => {
    setIsJoyrideOpen((prevState) => !prevState);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      <Button
        id="anjar-button"
        onClick={handleJoyrideButtonClick}
        aria-label="image"
        variant="contained"
        sx={{
          display: { xs: 'none', md: 'block' },
          borderRadius: '50px',
          color: '#F9F9F9',
          background: '#2065D1',
          position: 'relative',
        }}
      >
        <Box sx={{ display: 'flex' }}>
          <StyledBadge overlap="circular" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} variant="dot">
            <Avatar alt="Anjar Ali" src={ImageIcon} width="10px" sx={{ ml: -1.2 }} />
          </StyledBadge>
          <Box sx={{ marginLeft: '10px', textAlign: 'left', mt: 1.2, fontSize: { md: '12px' } }}>
            <p style={{ marginTop: -9 }}>Virtual Accountant</p>
            <p style={{ marginBottom: -9, marginTop: -5 }}>Your Account Manager</p>
          </Box>
        </Box>
      </Button>

      <Joyride
        steps={joyrideSteps}
        run={isJoyrideOpen}
        disableOverlay
        disableCloseOnEsc
        disableOverlayClose
        // hideCloseButton
        hideFooter
        disableButtonClose
        styles={{
          options: {
            zIndex: 2000,
            showProgress: false,
            // backgroundColor:'black',
            showSkipButton: false,
            // arrowColor:"black",
            // backgroundColor:'black'
          },
          buttonNext: {
            display: 'none',
          },
          tooltip: {
            width: '500px',
            height: '264px',
            borderRadius: '20px',
            // color:'white'
          },
        }}
      />

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{
          '& .MuiSnackbarContent-root': {
            marginTop: '620px',
            width: '314px',
            marginRight: '960px',
            backgroundColor: 'gray',
            color: '#fff',
            borderRadius: '8px',
            boxShadow: '0px 0px 10px rgba(0,0,0,0.1)',
          },
          position: 'absolute',
          top: '60px',
          left: '50%',
          transform: 'translateX(-50%)',
        }}
      />
    </>
  );
};

export default ContactButton;
