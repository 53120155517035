export const hsngood =[
  '01',
  '0101',
  '01011010',
  '01011020',
  '01011090',
  '010121',
  '01012100',
  '010129',
  '01012910',
  '01012990',
  '010130',
  '01013010',
  '01013020',
  '01013090',
  '010190',
  '01019010',
  '01019020',
  '01019030',
  '01019090',
  '0102',
  '01021010',
  '01021020',
  '01021030',
  '01021090',
  '010221',
  '01022110',
  '01022120',
  '010229',
  '01022910',
  '01022990',
  '010231',
  '01023100',
  '010239',
  '01023900',
  '010290',
  '01029010',
  '01029020',
  '01029090',
  '0103',
  '010310',
  '01031000',
  '010391',
  '01039100',
  '010392',
  '01039200',
  '0104',
  '010410',
  '01041010',
  '01041090',
  '010420',
  '01042000',
  '0105',
  '010511',
  '01051100',
  '010512',
  '01051200',
  '010513',
  '01051300',
  '010514',
  '01051400',
  '010515',
  '01051500',
  '01051900',
  '01059200',
  '01059300',
  '010594',
  '01059400',
  '010599',
  '01059900',
  '0106',
  '010611',
  '01061100',
  '010612',
  '01061200',
  '010613',
  '01061300',
  '010614',
  '01061400',
  '010619',
  '01061900',
  '010620',
  '01062000',
  '010631',
  '01063100',
  '010632',
  '01063200',
  '010633',
  '01063300',
  '010639',
  '01063900',
  '010641',
  '01064110',
  '01064190',
  '010649',
  '01064910',
  '01064990',
  '010690',
  '01069000',
  '01069010',
  '01069020',
  '01069090',
  '02',
  '0201',
  '020110',
  '02011000',
  '020120',
  '02012000',
  '020130',
  '02013000',
  '0202',
  '020210',
  '02021000',
  '020220',
  '02022000',
  '020230',
  '02023000',
  '0203',
  '020310',
  '020311',
  '02031100',
  '020312',
  '02031200',
  '020319',
  '02031900',
  '020321',
  '02032100',
  '020322',
  '02032200',
  '020329',
  '02032900',
  '0204',
  '020410',
  '02041000',
  '020420',
  '020421',
  '02042100',
  '020422',
  '02042200',
  '020423',
  '02042300',
  '020430',
  '02043000',
  '020441',
  '02044100',
  '020442',
  '02044200',
  '020443',
  '02044300',
  '020450',
  '02045000',
  '0205',
  '020500',
  '02050000',
  '0206',
  '020610',
  '02061000',
  '020621',
  '02062100',
  '020622',
  '02062200',
  '020629',
  '02062900',
  '020630',
  '02063000',
  '020641',
  '02064100',
  '020649',
  '02064900',
  '020680',
  '02068010',
  '02068090',
  '020690',
  '02069010',
  '02069090',
  '0207',
  '020711',
  '02071100',
  '020712',
  '02071200',
  '020713',
  '02071300',
  '020714',
  '02071400',
  '020724',
  '02072400',
  '020725',
  '02072500',
  '020726',
  '02072600',
  '020727',
  '02072700',
  '020741',
  '02074100',
  '020742',
  '02074200',
  '020743',
  '02074300',
  '020744',
  '02074400',
  '020745',
  '02074500',
  '020751',
  '02075100',
  '020752',
  '02075200',
  '020753',
  '02075300',
  '020754',
  '02075400',
  '020755',
  '02075500',
  '020760',
  '02076000',
  '0208',
  '020810',
  '02081000',
  '02082000',
  '020830',
  '02083000',
  '020840',
  '02084000',
  '020850',
  '02085000',
  '020860',
  '02086000',
  '020890',
  '02089010',
  '02089090',
  '0209',
  '020900',
  '020910',
  '02091000',
  '020990',
  '02099000',
  '0210',
  '021011',
  '02101100',
  '021012',
  '02101200',
  '021019',
  '02101900',
  '021020',
  '02102000',
  '021091',
  '02109100',
  '021092',
  '02109200',
  '021093',
  '02109300',
  '021099',
  '02109900',
  '03',
  '0301',
  '030111',
  '03011100',
  '030119',
  '03011900',
  '030191',
  '03019100',
  '030192',
  '03019200',
  '030193',
  '03019300',
  '030194',
  '03019400',
  '030195',
  '03019500',
  '030199',
  '03019900',
  '0302',
  '030211',
  '03021100',
  '030213',
  '03021300',
  '030214',
  '03021400',
  '030219',
  '03021900',
  '030221',
  '03022100',
  '030222',
  '03022200',
  '030223',
  '03022300',
  '030224',
  '03022400',
  '030229',
  '03022900',
  '030231',
  '03023100',
  '030232',
  '03023200',
  '030233',
  '03023300',
  '030234',
  '03023400',
  '030235',
  '03023500',
  '030236',
  '03023600',
  '030239',
  '03023900',
  '03024000',
  '030241',
  '03024100',
  '030242',
  '03024200',
  '030243',
  '03024300',
  '030244',
  '03024400',
  '030245',
  '03024500',
  '030246',
  '03024600',
  '030247',
  '03024700',
  '030249',
  '03024900',
  '03025000',
  '030251',
  '03025100',
  '030252',
  '03025200',
  '030253',
  '03025300',
  '030254',
  '03025400',
  '030255',
  '03025500',
  '030256',
  '03025600',
  '030259',
  '03025900',
  '03026100',
  '03026200',
  '03026300',
  '03026400',
  '03026500',
  '03026600',
  '03026700',
  '03026800',
  '03026910',
  '03026920',
  '03026930',
  '03026990',
  '03027000',
  '030271',
  '03027100',
  '030272',
  '03027200',
  '030273',
  '03027300',
  '030274',
  '03027400',
  '030279',
  '03027900',
  '030281',
  '03028100',
  '030282',
  '03028200',
  '030283',
  '03028300',
  '030284',
  '03028400',
  '030285',
  '03028500',
  '030289',
  '03028910',
  '03028920',
  '03028930',
  '03028990',
  '03029000',
  '030291',
  '03029100',
  '03029110',
  '030292',
  '03029200',
  '03029210',
  '030299',
  '03029910',
  '03029990',
  '0303',
  '030311',
  '03031100',
  '030312',
  '03031200',
  '030313',
  '03031300',
  '030314',
  '03031400',
  '030319',
  '03031900',
  '03032100',
  '03032200',
  '030323',
  '03032300',
  '030324',
  '03032400',
  '030325',
  '03032500',
  '030326',
  '03032600',
  '030329',
  '03032900',
  '030331',
  '03033100',
  '030332',
  '03033200',
  '030333',
  '03033300',
  '030334',
  '03033400',
  '030339',
  '03033900',
  '030341',
  '03034100',
  '030342',
  '03034200',
  '030343',
  '03034300',
  '030344',
  '03034400',
  '030345',
  '03034500',
  '030346',
  '03034600',
  '030349',
  '03034900',
  '03035000',
  '030351',
  '03035100',
  '03035200',
  '030353',
  '03035300',
  '030354',
  '03035400',
  '030355',
  '03035500',
  '030356',
  '03035600',
  '030357',
  '03035700',
  '030359',
  '03035910',
  '03035990',
  '03036000',
  '03036100',
  '03036200',
  '030363',
  '03036300',
  '030364',
  '03036400',
  '030365',
  '03036500',
  '030366',
  '03036600',
  '030367',
  '03036700',
  '030368',
  '03036800',
  '030369',
  '03036900',
  '03037100',
  '03037200',
  '03037300',
  '03037400',
  '03037500',
  '03037600',
  '03037700',
  '03037800',
  '03037910',
  '03037920',
  '03037930',
  '03037940',
  '03037950',
  '03037960',
  '03037970',
  '03037980',
  '03037991',
  '03037992',
  '03037999',
  '03038010',
  '03038090',
  '030381',
  '03038110',
  '03038190',
  '030382',
  '03038200',
  '030383',
  '03038300',
  '030384',
  '03038400',
  '030389',
  '03038910',
  '03038920',
  '03038930',
  '03038940',
  '03038950',
  '03038960',
  '03038970',
  '03038980',
  '03038990',
  '03038991',
  '03038992',
  '03038999',
  '03039010',
  '03039090',
  '030391',
  '03039110',
  '03039190',
  '030392',
  '03039200',
  '03039210',
  '030399',
  '03039910',
  '03039990',
  '0304',
  '03041000',
  '03041100',
  '03041200',
  '03041900',
  '03042010',
  '03042020',
  '03042030',
  '03042040',
  '03042050',
  '03042090',
  '03042100',
  '03042200',
  '03042910',
  '03042920',
  '03042930',
  '03042940',
  '03042950',
  '03042990',
  '030431',
  '03043100',
  '030432',
  '03043200',
  '030433',
  '03043300',
  '030439',
  '03043900',
  '030441',
  '03044100',
  '030442',
  '03044200',
  '030443',
  '03044300',
  '030444',
  '03044400',
  '030445',
  '03044500',
  '030446',
  '03044600',
  '030447',
  '03044700',
  '030448',
  '03044800',
  '030449',
  '03044910',
  '03044920',
  '03044930',
  '03044940',
  '03044990',
  '030451',
  '03045100',
  '030452',
  '03045200',
  '030453',
  '03045300',
  '030454',
  '03045400',
  '030455',
  '03045500',
  '030456',
  '03045600',
  '030457',
  '03045700',
  '030459',
  '03045910',
  '03045920',
  '03045930',
  '03045940',
  '03045990',
  '030461',
  '03046100',
  '030462',
  '03046200',
  '030463',
  '03046300',
  '030469',
  '03046900',
  '030471',
  '03047100',
  '030472',
  '03047200',
  '030473',
  '03047300',
  '030474',
  '03047400',
  '030475',
  '03047500',
  '030479',
  '03047900',
  '030481',
  '03048100',
  '030482',
  '03048200',
  '030483',
  '03048300',
  '030484',
  '03048400',
  '030485',
  '03048500',
  '030486',
  '03048600',
  '030487',
  '03048700',
  '030488',
  '03048810',
  '03048820',
  '03048830',
  '030489',
  '03048910',
  '03048920',
  '03048930',
  '03048940',
  '03048990',
  '03049000',
  '030491',
  '03049100',
  '030492',
  '03049200',
  '030493',
  '03049300',
  '030494',
  '03049400',
  '030495',
  '03049500',
  '030496',
  '03049600',
  '030497',
  '03049700',
  '030499',
  '03049900',
  '0305',
  '03051000',
  '030520',
  '03052000',
  '03053000',
  '030531',
  '03053100',
  '030532',
  '03053200',
  '030539',
  '03053900',
  '030541',
  '03054100',
  '030542',
  '03054200',
  '030543',
  '03054300',
  '030544',
  '03054400',
  '030549',
  '03054900',
  '030551',
  '03055100',
  '030552',
  '03055200',
  '030553',
  '03055300',
  '030554',
  '03055400',
  '030559',
  '03055910',
  '03055920',
  '03055930',
  '03055990',
  '030561',
  '03056100',
  '030562',
  '03056200',
  '030563',
  '03056300',
  '030564',
  '03056400',
  '030569',
  '03056910',
  '03056920',
  '03056930',
  '03056990',
  '030571',
  '03057100',
  '030572',
  '03057200',
  '030579',
  '03057900',
  '0306',
  '030611',
  '03061100',
  '030612',
  '03061210',
  '03061290',
  '030614',
  '03061400',
  '030615',
  '03061500',
  '030616',
  '03061610',
  '03061690',
  '030617',
  '03061711',
  '03061719',
  '03061720',
  '03061730',
  '03061740',
  '03061750',
  '03061790',
  '030619',
  '03061900',
  '03062100',
  '03062200',
  '03062400',
  '03062500',
  '03062600',
  '03062710',
  '03062790',
  '03062900',
  '030631',
  '03063100',
  '030632',
  '03063200',
  '030633',
  '03063300',
  '030634',
  '03063400',
  '030635',
  '03063500',
  '030636',
  '03063600',
  '03063610',
  '03063620',
  '03063630',
  '03063640',
  '03063650',
  '03063660',
  '03063690',
  '030639',
  '03063900',
  '030691',
  '03069100',
  '030692',
  '03069200',
  '030693',
  '03069300',
  '030694',
  '03069400',
  '030695',
  '03069500',
  '030699',
  '03069900',
  '0307',
  '030711',
  '03071100',
  '030712',
  '03071200',
  '030719',
  '03071900',
  '030721',
  '03072100',
  '030722',
  '03072200',
  '030729',
  '03072900',
  '030731',
  '03073100',
  '030732',
  '03073200',
  '030739',
  '03073910',
  '03073990',
  '03074110',
  '03074120',
  '030742',
  '03074210',
  '03074220',
  '030743',
  '03074310',
  '03074320',
  '03074330',
  '03074390',
  '030749',
  '03074910',
  '03074920',
  '03074930',
  '03074940',
  '03074990',
  '030751',
  '03075100',
  '030752',
  '03075200',
  '030759',
  '03075900',
  '030760',
  '03076000',
  '030771',
  '03077100',
  '030772',
  '03077200',
  '030779',
  '03077900',
  '030781',
  '03078100',
  '030782',
  '03078200',
  '030783',
  '03078300',
  '030784',
  '03078400',
  '030787',
  '03078700',
  '030788',
  '03078800',
  '03078900',
  '030791',
  '03079100',
  '030792',
  '03079200',
  '030799',
  '03079900',
  '03079910',
  '03079920',
  '03079990',
  '0308',
  '030811',
  '03081100',
  '030812',
  '03081200',
  '030819',
  '03081900',
  '030821',
  '03082100',
  '030822',
  '03082200',
  '030829',
  '03082900',
  '030830',
  '03083010',
  '03083020',
  '03083090',
  '030890',
  '03089000',
  '0309',
  '030910',
  '03091010',
  '03091020',
  '03091030',
  '03091090',
  '030990',
  '03099011',
  '03099012',
  '03099013',
  '03099014',
  '03099019',
  '03099021',
  '03099022',
  '03099023',
  '03099024',
  '03099029',
  '03099031',
  '03099032',
  '03099033',
  '03099034',
  '03099039',
  '03099040',
  '03099050',
  '03099060',
  '03099070',
  '03099080',
  '03099090',
  '04',
  '0401',
  '040110',
  '04011000',
  '040120',
  '04012000',
  '040140',
  '04014000',
  '040150',
  '04015000',
  '0402',
  '040210',
  '04021010',
  '04021020',
  '04021090',
  '040221',
  '04022100',
  '040229',
  '04022910',
  '04022920',
  '04022990',
  '040291',
  '04029110',
  '04029120',
  '04029190',
  '040299',
  '04029910',
  '04029920',
  '04029990',
  '0403',
  '04031000',
  '040320',
  '04032000',
  '040390',
  '04039010',
  '04039090',
  '0404',
  '040410',
  '04041010',
  '04041020',
  '04041090',
  '040490',
  '04049000',
  '0405',
  '040510',
  '04051000',
  '040520',
  '04052000',
  '040590',
  '04059010',
  '04059020',
  '04059090',
  '0406',
  '040610',
  '04061000',
  '04061010',
  '04061090',
  '040620',
  '04062000',
  '040630',
  '04063000',
  '040640',
  '04064000',
  '040690',
  '04069000',
  '0407',
  '040700',
  '04070090',
  '040711',
  '04071100',
  '040719',
  '04071910',
  '04071990',
  '040721',
  '04072100',
  '040729',
  '04072900',
  '040790',
  '04079000',
  '0408',
  '040811',
  '04081100',
  '040819',
  '04081900',
  '040891',
  '04089100',
  '040899',
  '04089900',
  '0409',
  '040900',
  '04090000',
  '0410',
  '041000',
  '04100010',
  '04100020',
  '04100090',
  '041010',
  '04101010',
  '04101020',
  '04101090',
  '041090',
  '04109010',
  '04109020',
  '04109090',
  '05',
  '0501',
  '050100',
  '05010010',
  '05010020',
  '0502',
  '050210',
  '05021010',
  '05021020',
  '050290',
  '05029010',
  '05029020',
  '05029090',
  '0503',
  '05030000',
  '0504',
  '050400',
  '05040010',
  '05040020',
  '05040031',
  '05040039',
  '05040041',
  '05040049',
  '05040051',
  '05040059',
  '0505',
  '050510',
  '05051010',
  '05051090',
  '050590',
  '05059010',
  '05059021',
  '05059029',
  '05059031',
  '05059039',
  '05059091',
  '05059099',
  '0506',
  '050610',
  '05061011',
  '05061019',
  '05061021',
  '05061029',
  '05061031',
  '05061039',
  '05061041',
  '05061049',
  '050690',
  '05069011',
  '05069019',
  '05069091',
  '05069099',
  '0507',
  '050710',
  '05071010',
  '05071020',
  '050790',
  '05079010',
  '05079020',
  '05079030',
  '05079040',
  '05079050',
  '05079060',
  '05079070',
  '05079090',
  '0508',
  '050800',
  '05080010',
  '05080020',
  '05080030',
  '05080040',
  '05080050',
  '05080090',
  '0509',
  '05090010',
  '05090090',
  '0510',
  '051000',
  '05100010',
  '05100020',
  '05100030',
  '05100091',
  '05100099',
  '0511',
  '051110',
  '05111000',
  '051191',
  '05119110',
  '05119120',
  '05119130',
  '05119140',
  '05119190',
  '051199',
  '05119910',
  '05119911',
  '05119919',
  '05119921',
  '05119929',
  '05119991',
  '05119992',
  '05119999',
  '06',
  '0601',
  '060110',
  '06011000',
  '060120',
  '06012010',
  '06012021',
  '06012022',
  '06012090',
  '0602',
  '060210',
  '06021000',
  '060220',
  '06022010',
  '06022020',
  '06022090',
  '060230',
  '06023000',
  '060240',
  '06024000',
  '060290',
  '06029010',
  '06029020',
  '06029030',
  '06029090',
  '0603',
  '06031000',
  '060311',
  '06031100',
  '060312',
  '06031200',
  '060313',
  '06031300',
  '060314',
  '06031400',
  '060315',
  '06031500',
  '060319',
  '06031900',
  '060390',
  '06039000',
  '0604',
  '06041000',
  '060420',
  '06042000',
  '060490',
  '06049000',
  '06049100',
  '06049900',
  '063610',
  '07',
  '0701',
  '070110',
  '07011000',
  '070190',
  '07019000',
  '0702',
  '070200',
  '07020000',
  '0703',
  '070310',
  '07031010',
  '07031011',
  '07031019',
  '07031020',
  '07031090',
  '070320',
  '07032000',
  '070390',
  '07039000',
  '0704',
  '070410',
  '07041000',
  '070420',
  '07042000',
  '070490',
  '07049000',
  '0705',
  '070511',
  '07051100',
  '070519',
  '07051900',
  '070521',
  '07052100',
  '070529',
  '07052900',
  '0706',
  '070610',
  '07061000',
  '070690',
  '07069010',
  '07069020',
  '07069030',
  '07069090',
  '0707',
  '070700',
  '07070000',
  '0708',
  '070810',
  '07081000',
  '070820',
  '07082000',
  '070890',
  '07089000',
  '0709',
  '07091000',
  '070920',
  '07092000',
  '070930',
  '07093000',
  '070940',
  '07094000',
  '070951',
  '07095100',
  '070952',
  '07095200',
  '070953',
  '07095300',
  '070954',
  '07095400',
  '070955',
  '07095500',
  '070956',
  '07095600',
  '070959',
  '07095900',
  '070960',
  '07096010',
  '07096090',
  '070970',
  '07097000',
  '070990',
  '07099090',
  '070991',
  '07099100',
  '070992',
  '07099200',
  '070993',
  '07099300',
  '07099310',
  '07099320',
  '07099330',
  '07099340',
  '07099350',
  '07099360',
  '07099390',
  '070999',
  '07099910',
  '07099920',
  '07099930',
  '07099990',
  '0710',
  '071010',
  '07101000',
  '071021',
  '07102100',
  '071022',
  '07102200',
  '071029',
  '07102900',
  '071030',
  '07103000',
  '071040',
  '07104000',
  '071080',
  '07108010',
  '07108090',
  '071090',
  '07109000',
  '0711',
  '071120',
  '07112000',
  '07113000',
  '071140',
  '07114000',
  '071151',
  '07115100',
  '071159',
  '07115900',
  '071190',
  '07119010',
  '07119020',
  '07119090',
  '0712',
  '071220',
  '07122000',
  '071231',
  '07123100',
  '071232',
  '07123200',
  '071233',
  '07123300',
  '071234',
  '07123400',
  '071239',
  '07123900',
  '071290',
  '07129010',
  '07129020',
  '07129030',
  '07129040',
  '07129050',
  '07129060',
  '07129090',
  '0713',
  '071310',
  '07131000',
  '07131010',
  '07131020',
  '07131090',
  '071320',
  '07132000',
  '07132010',
  '07132020',
  '07132090',
  '071331',
  '07133100',
  '07133110',
  '07133190',
  '071332',
  '07133200',
  '071333',
  '07133300',
  '071334',
  '07133400',
  '071335',
  '07133500',
  '071339',
  '07133910',
  '07133990',
  '071340',
  '07134000',
  '071350',
  '07135000',
  '071360',
  '07136000',
  '071390',
  '07139010',
  '07139090',
  '0714',
  '071410',
  '07141000',
  '071420',
  '07142000',
  '071430',
  '07143000',
  '071440',
  '07144000',
  '071450',
  '07145000',
  '071490',
  '07149010',
  '07149090',
  '08',
  '0801',
  '080111',
  '08011100',
  '080112',
  '08011210',
  '08011220',
  '08011290',
  '080119',
  '08011910',
  '08011920',
  '08011990',
  '080121',
  '08012100',
  '080122',
  '08012200',
  '080131',
  '08013100',
  '080132',
  '08013210',
  '08013220',
  '08013290',
  '0802',
  '080211',
  '08021100',
  '080212',
  '08021200',
  '080221',
  '08022100',
  '080222',
  '08022200',
  '080231',
  '08023100',
  '080232',
  '08023200',
  '080241',
  '08024100',
  '080242',
  '08024200',
  '080251',
  '08025100',
  '080252',
  '08025200',
  '080261',
  '08026100',
  '080262',
  '08026200',
  '080270',
  '08027000',
  '080280',
  '08028010',
  '08028020',
  '08028030',
  '08028090',
  '080290',
  '08029000',
  '080291',
  '08029100',
  '080292',
  '08029200',
  '080299',
  '08029900',
  '0803',
  '080310',
  '08031010',
  '08031090',
  '080390',
  '08039010',
  '08039090',
  '0804',
  '080410',
  '08041010',
  '08041020',
  '08041030',
  '08041090',
  '080420',
  '08042010',
  '08042090',
  '080430',
  '08043000',
  '080440',
  '08044000',
  '080450',
  '08045010',
  '08045020',
  '08045021',
  '08045022',
  '08045023',
  '08045024',
  '08045025',
  '08045026',
  '08045027',
  '08045028',
  '08045029',
  '08045030',
  '08045040',
  '08045090',
  '0805',
  '080510',
  '08051000',
  '08052000',
  '080521',
  '08052100',
  '080522',
  '08052200',
  '080529',
  '08052900',
  '080540',
  '08054000',
  '080550',
  '08055000',
  '080590',
  '08059000',
  '0806',
  '080610',
  '08061000',
  '080620',
  '08062010',
  '08062090',
  '0807',
  '080711',
  '08071100',
  '080719',
  '08071900',
  '08071910',
  '08071990',
  '080720',
  '08072000',
  '0808',
  '080810',
  '08081000',
  '080821',
  '080829',
  '080830',
  '08083000',
  '080840',
  '08084000',
  '0809',
  '080910',
  '08091000',
  '080921',
  '08092100',
  '080929',
  '08092900',
  '080930',
  '08093000',
  '080940',
  '08094000',
  '0810',
  '081010',
  '08101000',
  '081020',
  '08102000',
  '081030',
  '08103000',
  '081040',
  '08104000',
  '081050',
  '08105000',
  '081060',
  '08106000',
  '081070',
  '08107000',
  '081090',
  '08109010',
  '08109020',
  '08109030',
  '08109040',
  '08109050',
  '08109060',
  '08109090',
  '0811',
  '081110',
  '08111010',
  '08111020',
  '08111090',
  '081120',
  '08112010',
  '08112020',
  '08112090',
  '081190',
  '08119010',
  '08119090',
  '0812',
  '081210',
  '08121000',
  '081290',
  '08129010',
  '08129090',
  '0813',
  '081310',
  '08131000',
  '081320',
  '08132000',
  '081330',
  '08133000',
  '081340',
  '08134010',
  '08134020',
  '08134090',
  '081350',
  '08135010',
  '08135020',
  '0814',
  '081400',
  '08140000',
  '09',
  '0901',
  '090111',
  '09011111',
  '09011112',
  '09011113',
  '09011119',
  '09011121',
  '09011122',
  '09011123',
  '09011124',
  '09011129',
  '09011131',
  '09011132',
  '09011133',
  '09011139',
  '09011141',
  '09011142',
  '09011143',
  '09011144',
  '09011145',
  '09011149',
  '09011190',
  '090112',
  '09011200',
  '090121',
  '09012110',
  '09012190',
  '090122',
  '09012210',
  '09012290',
  '090190',
  '09019010',
  '09019020',
  '09019090',
  '0902',
  '090210',
  '09021010',
  '09021020',
  '09021030',
  '09021090',
  '090220',
  '09022010',
  '09022020',
  '09022030',
  '09022040',
  '09022090',
  '090230',
  '09023010',
  '09023020',
  '09023030',
  '09023090',
  '090240',
  '09024010',
  '09024020',
  '09024030',
  '09024040',
  '09024050',
  '09024060',
  '09024090',
  '0903',
  '090300',
  '09030000',
  '0904',
  '090411',
  '09041110',
  '09041120',
  '09041130',
  '09041140',
  '09041150',
  '09041160',
  '09041170',
  '09041180',
  '09041190',
  '090412',
  '09041200',
  '090420',
  '090421',
  '09042110',
  '09042120',
  '090422',
  '09042211',
  '09042212',
  '09042219',
  '09042221',
  '09042229',
  '0905',
  '090500',
  '090510',
  '09051000',
  '090520',
  '09052000',
  '0906',
  '09061010',
  '09061020',
  '09061030',
  '09061090',
  '090611',
  '09061110',
  '09061120',
  '09061190',
  '090619',
  '09061910',
  '09061990',
  '090620',
  '09062000',
  '0907',
  '090710',
  '09071010',
  '09071020',
  '09071030',
  '09071090',
  '090720',
  '09072000',
  '0908',
  '090810',
  '090811',
  '09081110',
  '09081120',
  '090812',
  '09081200',
  '090821',
  '09082100',
  '090822',
  '09082200',
  '090830',
  '090831',
  '09083110',
  '09083120',
  '09083130',
  '09083140',
  '09083150',
  '09083190',
  '090832',
  '09083210',
  '09083220',
  '09083230',
  '09083290',
  '0909',
  '090910',
  '090920',
  '090921',
  '09092110',
  '09092190',
  '090922',
  '09092200',
  '090930',
  '090931',
  '09093111',
  '09093119',
  '09093121',
  '09093129',
  '090932',
  '09093200',
  '090940',
  '090950',
  '090961',
  '09096111',
  '09096119',
  '09096121',
  '09096129',
  '09096131',
  '09096139',
  '09096141',
  '09096149',
  '090962',
  '09096210',
  '09096220',
  '09096230',
  '09096240',
  '0910',
  '091010',
  '091011',
  '09101110',
  '09101120',
  '09101130',
  '09101190',
  '091012',
  '09101210',
  '09101290',
  '091020',
  '09102010',
  '09102020',
  '09102090',
  '091030',
  '09103010',
  '09103020',
  '09103030',
  '09103090',
  '09104010',
  '09104020',
  '09104030',
  '09105000',
  '091091',
  '09109100',
  '091099',
  '09109911',
  '09109912',
  '09109913',
  '09109914',
  '09109915',
  '09109919',
  '09109921',
  '09109923',
  '09109924',
  '09109925',
  '09109926',
  '09109927',
  '09109929',
  '09109930',
  '09109939',
  '09109990',
  '10',
  '1001',
  '100110',
  '10011010',
  '10011090',
  '100111',
  '10011100',
  '100119',
  '10011900',
  '100190',
  '10019010',
  '10019020',
  '10019031',
  '10019039',
  '100191',
  '10019100',
  '100199',
  '10019910',
  '10019920',
  '1002',
  '100200',
  '10020010',
  '10020090',
  '100210',
  '10021000',
  '100290',
  '10029000',
  '1003',
  '100300',
  '10030010',
  '10030090',
  '100310',
  '10031000',
  '100390',
  '10039000',
  '1004',
  '100400',
  '10040010',
  '10040090',
  '100410',
  '10041000',
  '100490',
  '10049000',
  '1005',
  '100510',
  '10051000',
  '100590',
  '10059000',
  '10059011',
  '10059019',
  '10059020',
  '10059030',
  '10059090',
  '1006',
  '100610',
  '10061010',
  '10061090',
  '100620',
  '10062000',
  '100630',
  '10063010',
  '10063020',
  '10063090',
  '100640',
  '10064000',
  '1007',
  '100700',
  '10070010',
  '10070090',
  '100710',
  '10071000',
  '100790',
  '10079000',
  '1008',
  '100810',
  '10081010',
  '10081090',
  '100820',
  '10082011',
  '10082019',
  '10082021',
  '10082029',
  '10082031',
  '10082039',
  '100821',
  '10082110',
  '10082120',
  '10082130',
  '10082140',
  '10082150',
  '10082160',
  '10082170',
  '10082180',
  '10082191',
  '10082199',
  '100829',
  '10082910',
  '10082920',
  '10082930',
  '10082940',
  '10082950',
  '10082960',
  '10082970',
  '10082980',
  '10082991',
  '10082999',
  '100830',
  '10083010',
  '10083090',
  '100840',
  '10084000',
  '100850',
  '10085000',
  '100860',
  '10086000',
  '100890',
  '10089010',
  '10089090',
  '1009',
  '10091090',
  '11',
  '1101',
  '110100',
  '11010000',
  '1102',
  '11021000',
  '110220',
  '11022000',
  '11023000',
  '110290',
  '11029000',
  '11029010',
  '11029021',
  '11029022',
  '11029029',
  '11029090',
  '1103',
  '110311',
  '11031110',
  '11031120',
  '110313',
  '11031300',
  '110319',
  '11031900',
  '110320',
  '11032000',
  '1104',
  '110412',
  '11041200',
  '110419',
  '11041900',
  '110422',
  '11042200',
  '110423',
  '11042300',
  '110429',
  '11042900',
  '110430',
  '11043000',
  '1105',
  '110510',
  '11051000',
  '110520',
  '11052000',
  '1106',
  '110610',
  '11061000',
  '11061010',
  '11061090',
  '110620',
  '11062010',
  '11062020',
  '11062090',
  '110630',
  '11063010',
  '11063020',
  '11063030',
  '11063090',
  '1107',
  '110710',
  '11071000',
  '110720',
  '11072000',
  '1108',
  '110811',
  '11081100',
  '110812',
  '11081200',
  '110813',
  '11081300',
  '110814',
  '11081400',
  '110819',
  '11081910',
  '11081990',
  '110820',
  '11082000',
  '1109',
  '110900',
  '11090000',
  '1111',
  '12',
  '1201',
  '120100',
  '12010010',
  '12010090',
  '120110',
  '12011000',
  '120190',
  '12019000',
  '1202',
  '120210',
  '12021011',
  '12021019',
  '12021091',
  '12021099',
  '120220',
  '12022010',
  '12022090',
  '120230',
  '12023010',
  '12023090',
  '120241',
  '12024110',
  '12024190',
  '120242',
  '12024210',
  '12024220',
  '12024290',
  '1203',
  '120300',
  '12030000',
  '1204',
  '120400',
  '12040010',
  '12040090',
  '1205',
  '120510',
  '12051000',
  '120590',
  '12059000',
  '1206',
  '120600',
  '12060010',
  '12060090',
  '1207',
  '120710',
  '12071010',
  '12071090',
  '120720',
  '12072010',
  '12072090',
  '120721',
  '12072100',
  '120729',
  '12072900',
  '120730',
  '12073010',
  '12073090',
  '120740',
  '12074010',
  '12074090',
  '120750',
  '12075010',
  '12075090',
  '120760',
  '12076010',
  '12076090',
  '120770',
  '12077010',
  '12077090',
  '120791',
  '12079100',
  '120799',
  '12079910',
  '12079920',
  '12079930',
  '12079940',
  '12079990',
  '1208',
  '120810',
  '12081000',
  '120890',
  '12089000',
  '1209',
  '120910',
  '12091000',
  '120921',
  '12092100',
  '120922',
  '12092200',
  '120923',
  '12092300',
  '120924',
  '12092400',
  '120925',
  '12092500',
  '12092600',
  '120929',
  '12092910',
  '12092990',
  '120930',
  '12093000',
  '120991',
  '12099110',
  '12099120',
  '12099130',
  '12099140',
  '12099150',
  '12099160',
  '12099170',
  '12099190',
  '120999',
  '12099910',
  '12099990',
  '1210',
  '121010',
  '12101000',
  '121020',
  '12102000',
  '1211',
  '12111000',
  '121120',
  '12112000',
  '121130',
  '12113000',
  '121140',
  '12114000',
  '121150',
  '12115000',
  '121160',
  '12116000',
  '121190',
  '12119011',
  '12119012',
  '12119013',
  '12119014',
  '12119015',
  '12119016',
  '12119019',
  '12119021',
  '12119022',
  '12119023',
  '12119024',
  '12119025',
  '12119026',
  '12119029',
  '12119031',
  '12119032',
  '12119033',
  '12119034',
  '12119035',
  '12119039',
  '12119041',
  '12119042',
  '12119043',
  '12119044',
  '12119045',
  '12119046',
  '12119047',
  '12119048',
  '12119049',
  '12119050',
  '12119051',
  '12119052',
  '12119053',
  '12119054',
  '12119055',
  '12119056',
  '12119057',
  '12119058',
  '12119059',
  '12119060',
  '12119070',
  '12119080',
  '12119090',
  '12119091',
  '12119092',
  '12119093',
  '12119094',
  '12119095',
  '12119096',
  '12119099',
  '1212',
  '12121010',
  '12121090',
  '121220',
  '12122010',
  '12122090',
  '121221',
  '12122110',
  '12122190',
  '121229',
  '12122910',
  '12122990',
  '12123010',
  '12123090',
  '121291',
  '12129100',
  '121292',
  '12129200',
  '121293',
  '12129300',
  '121294',
  '12129400',
  '121299',
  '12129910',
  '12129920',
  '12129990',
  '1213',
  '121300',
  '12130000',
  '1214',
  '121410',
  '12141000',
  '121490',
  '12149000',
  '13',
  '1301',
  '13011010',
  '13011020',
  '13011030',
  '13011040',
  '13011050',
  '13011060',
  '13011070',
  '13011080',
  '13011090',
  '130120',
  '13012000',
  '130190',
  '13019011',
  '13019012',
  '13019013',
  '13019014',
  '13019015',
  '13019016',
  '13019017',
  '13019018',
  '13019019',
  '13019021',
  '13019022',
  '13019029',
  '13019031',
  '13019032',
  '13019033',
  '13019034',
  '13019039',
  '13019041',
  '13019042',
  '13019043',
  '13019044',
  '13019045',
  '13019046',
  '13019049',
  '13019099',
  '1302',
  '130211',
  '13021100',
  '130212',
  '13021200',
  '130213',
  '13021300',
  '130214',
  '13021400',
  '130219',
  '13021911',
  '13021912',
  '13021913',
  '13021914',
  '13021915',
  '13021916',
  '13021917',
  '13021918',
  '13021919',
  '13021920',
  '13021930',
  '13021990',
  '130220',
  '13022000',
  '130231',
  '13023100',
  '130232',
  '13023210',
  '13023220',
  '13023230',
  '13023231',
  '13023239',
  '13023240',
  '13023290',
  '130239',
  '13023900',
  '13023910',
  '13023920',
  '13023990',
  '14',
  '1401',
  '140110',
  '14011000',
  '140120',
  '14012000',
  '140190',
  '14019010',
  '14019090',
  '1402',
  '14020000',
  '1403',
  '14030010',
  '14030090',
  '1404',
  '14041011',
  '14041019',
  '14041020',
  '14041030',
  '14041040',
  '14041050',
  '14041061',
  '14041069',
  '14041070',
  '14041090',
  '140420',
  '14042000',
  '140490',
  '14049010',
  '14049021',
  '14049029',
  '14049030',
  '14049040',
  '14049050',
  '14049060',
  '14049070',
  '14049090',
  '15',
  '1501',
  '150110',
  '15011000',
  '150120',
  '15012000',
  '150190',
  '15019000',
  '1502',
  '150200',
  '150210',
  '15021010',
  '15021090',
  '150290',
  '15029010',
  '15029020',
  '15029090',
  '1503',
  '150300',
  '15030000',
  '1504',
  '150410',
  '15041010',
  '15041091',
  '15041099',
  '150420',
  '15042010',
  '15042020',
  '15042030',
  '15042090',
  '150430',
  '15043000',
  '1505',
  '150500',
  '15050010',
  '15050020',
  '15050090',
  '1506',
  '150600',
  '15060010',
  '15060090',
  '1507',
  '150710',
  '15071000',
  '150790',
  '15079010',
  '15079090',
  '1508',
  '150810',
  '15081000',
  '150890',
  '15089010',
  '15089091',
  '15089099',
  '1509',
  '15091000',
  '150920',
  '15092000',
  '150930',
  '15093000',
  '150940',
  '15094000',
  '150990',
  '15099010',
  '15099090',
  '1510',
  '151000',
  '15100010',
  '15100091',
  '15100099',
  '151010',
  '15101000',
  '151090',
  '15109010',
  '15109090',
  '1511',
  '151110',
  '15111000',
  '151190',
  '15119010',
  '15119020',
  '15119030',
  '15119090',
  '1512',
  '151211',
  '15121110',
  '15121120',
  '151219',
  '15121910',
  '15121920',
  '15121930',
  '15121940',
  '15121990',
  '151221',
  '15122100',
  '151229',
  '15122910',
  '15122990',
  '1513',
  '151311',
  '15131100',
  '151319',
  '15131900',
  '151321',
  '15132110',
  '15132120',
  '151329',
  '15132910',
  '15132920',
  '15132930',
  '15132990',
  '1514',
  '151411',
  '15141110',
  '15141120',
  '15141190',
  '151419',
  '15141910',
  '15141920',
  '15141990',
  '151491',
  '15149110',
  '15149120',
  '15149190',
  '151499',
  '15149910',
  '15149920',
  '15149930',
  '15149990',
  '1515',
  '151511',
  '15151100',
  '151519',
  '15151910',
  '15151990',
  '151521',
  '15152100',
  '151529',
  '15152910',
  '15152990',
  '151530',
  '15153010',
  '15153090',
  '15154000',
  '151550',
  '15155010',
  '15155091',
  '15155099',
  '151560',
  '15156000',
  '151590',
  '15159010',
  '15159020',
  '15159030',
  '15159040',
  '15159091',
  '15159099',
  '1516',
  '151610',
  '15161000',
  '151620',
  '15162011',
  '15162019',
  '15162021',
  '15162029',
  '15162031',
  '15162039',
  '15162091',
  '15162099',
  '151630',
  '15163000',
  '1517',
  '151710',
  '15171010',
  '15171021',
  '15171022',
  '15171029',
  '151790',
  '15179010',
  '15179030',
  '15179040',
  '15179090',
  '1518',
  '151800',
  '15180011',
  '15180019',
  '15180021',
  '15180029',
  '15180031',
  '15180039',
  '15180040',
  '1519',
  '1520',
  '152000',
  '15200000',
  '1521',
  '152110',
  '15211011',
  '15211019',
  '15211090',
  '152190',
  '15219010',
  '15219020',
  '15219090',
  '1522',
  '152200',
  '15220010',
  '15220020',
  '15220090',
  '16',
  '1601',
  '160100',
  '16010000',
  '1602',
  '160210',
  '16021000',
  '160220',
  '16022000',
  '160231',
  '16023100',
  '160232',
  '16023200',
  '160239',
  '16023900',
  '160241',
  '16024100',
  '160242',
  '16024200',
  '160249',
  '16024900',
  '160250',
  '16025000',
  '160290',
  '16029000',
  '1603',
  '160300',
  '16030010',
  '16030020',
  '16030090',
  '1604',
  '160411',
  '16041100',
  '160412',
  '16041210',
  '16041290',
  '160413',
  '16041310',
  '16041320',
  '160414',
  '16041410',
  '16041490',
  '160415',
  '16041500',
  '160416',
  '16041600',
  '160417',
  '16041700',
  '160418',
  '16041800',
  '160419',
  '16041900',
  '160420',
  '16042000',
  '160431',
  '16043100',
  '160432',
  '16043200',
  '1605',
  '160510',
  '16051000',
  '160521',
  '16052100',
  '160529',
  '16052900',
  '160530',
  '16053000',
  '160540',
  '16054000',
  '160551',
  '16055100',
  '160552',
  '16055200',
  '160553',
  '16055300',
  '160554',
  '16055400',
  '160555',
  '16055500',
  '160556',
  '16055600',
  '160557',
  '16055700',
  '160558',
  '16055800',
  '160559',
  '16055900',
  '160561',
  '16056100',
  '160562',
  '16056200',
  '160563',
  '16056300',
  '160569',
  '16056900',
  '160590',
  '17',
  '1701',
  '170111',
  '170112',
  '17011200',
  '170113',
  '17011310',
  '17011320',
  '17011390',
  '170114',
  '17011410',
  '17011420',
  '17011490',
  '170191',
  '17019100',
  '170199',
  '17019910',
  '17019990',
  '1702',
  '170211',
  '17021110',
  '17021190',
  '170219',
  '17021910',
  '17021990',
  '170220',
  '17022010',
  '17022090',
  '170230',
  '17023010',
  '17023020',
  '17023031',
  '17023039',
  '170240',
  '17024010',
  '17024020',
  '17024031',
  '17024039',
  '170250',
  '17025000',
  '170260',
  '17026010',
  '17026090',
  '170290',
  '17029010',
  '17029020',
  '17029030',
  '17029040',
  '17029050',
  '17029090',
  '1703',
  '170310',
  '17031000',
  '170390',
  '17039010',
  '17039090',
  '1704',
  '170410',
  '17041000',
  '170490',
  '17049010',
  '17049020',
  '17049030',
  '17049090',
  '18',
  '1801',
  '180100',
  '18010000',
  '1802',
  '180200',
  '18020000',
  '1803',
  '180310',
  '18031000',
  '180320',
  '18032000',
  '1804',
  '180400',
  '18040000',
  '1805',
  '180500',
  '18050000',
  '1806',
  '180610',
  '18061000',
  '180620',
  '18062000',
  '180631',
  '18063100',
  '180632',
  '18063200',
  '180690',
  '18069010',
  '18069020',
  '18069030',
  '18069040',
  '18069090',
  '19',
  '1901',
  '190110',
  '19011010',
  '19011090',
  '190120',
  '19012000',
  '190190',
  '19019010',
  '19019090',
  '1902',
  '190211',
  '19021100',
  '190219',
  '19021900',
  '190220',
  '19022010',
  '19022090',
  '190230',
  '19023010',
  '19023090',
  '190240',
  '19024010',
  '19024090',
  '1903',
  '190300',
  '19030000',
  '1904',
  '190410',
  '19041010',
  '19041020',
  '19041030',
  '19041090',
  '190420',
  '19042000',
  '19042010',
  '19042090',
  '190430',
  '19043000',
  '190490',
  '19049000',
  '1905',
  '190510',
  '19051000',
  '190520',
  '19052000',
  '190531',
  '19053100',
  '190532',
  '19053211',
  '19053219',
  '19053290',
  '190540',
  '19054000',
  '190590',
  '19059010',
  '19059020',
  '19059030',
  '19059040',
  '19059090',
  '20',
  '2001',
  '200110',
  '20011000',
  '200190',
  '20019000',
  '2002',
  '200210',
  '20021000',
  '200290',
  '20029000',
  '2003',
  '200310',
  '20031000',
  '200390',
  '20039010',
  '20039090',
  '2004',
  '200410',
  '20041000',
  '200490',
  '20049000',
  '2005',
  '200510',
  '20051000',
  '200520',
  '20052000',
  '200540',
  '20054000',
  '200551',
  '20055100',
  '200559',
  '20055900',
  '200560',
  '20056000',
  '200570',
  '20057000',
  '200580',
  '20058000',
  '20059000',
  '200591',
  '20059100',
  '200599',
  '20059900',
  '2006',
  '200600',
  '20060000',
  '2007',
  '200710',
  '20071000',
  '200791',
  '20079100',
  '200799',
  '20079910',
  '20079920',
  '20079930',
  '20079940',
  '20079990',
  '2008',
  '200811',
  '20081100',
  '200819',
  '20081910',
  '20081920',
  '20081930',
  '20081940',
  '20081990',
  '200820',
  '20082000',
  '200830',
  '20083010',
  '20083090',
  '200840',
  '20084000',
  '200850',
  '20085000',
  '200860',
  '20086000',
  '200870',
  '20087000',
  '200880',
  '20088000',
  '200891',
  '20089100',
  '200893',
  '20089300',
  '200897',
  '20089700',
  '200899',
  '20089911',
  '20089912',
  '20089913',
  '20089914',
  '20089919',
  '20089991',
  '20089992',
  '20089993',
  '20089994',
  '20089999',
  '2009',
  '200911',
  '20091100',
  '200912',
  '20091200',
  '200919',
  '20091900',
  '200921',
  '20092100',
  '200929',
  '20092900',
  '200931',
  '20093100',
  '200939',
  '20093900',
  '200941',
  '20094100',
  '200949',
  '20094900',
  '200950',
  '20095000',
  '200961',
  '20096100',
  '200969',
  '20096900',
  '200971',
  '20097100',
  '200979',
  '20097900',
  '200980',
  '200981',
  '20098100',
  '200989',
  '20098910',
  '20098990',
  '200990',
  '20099000',
  '2031',
  '2032',
  '2042',
  '2044',
  '2062',
  '2064',
  '2071',
  '2072',
  '2073',
  '2075',
  '21',
  '2101',
  '210111',
  '21011110',
  '21011120',
  '21011130',
  '21011190',
  '210112',
  '21011200',
  '210120',
  '21012010',
  '21012020',
  '21012030',
  '21012090',
  '210130',
  '21013010',
  '21013020',
  '21013090',
  '2102',
  '210210',
  '21021010',
  '21021020',
  '21021090',
  '210220',
  '21022000',
  '210230',
  '21023000',
  '2103',
  '210310',
  '21031000',
  '210320',
  '21032000',
  '210330',
  '21033000',
  '210390',
  '21039010',
  '21039020',
  '21039030',
  '21039040',
  '21039090',
  '2104',
  '210410',
  '21041010',
  '21041090',
  '210420',
  '21042000',
  '2105',
  '210500',
  '21050000',
  '2106',
  '210610',
  '21061000',
  '210690',
  '21069011',
  '21069019',
  '21069020',
  '21069030',
  '21069040',
  '21069050',
  '21069060',
  '21069070',
  '21069080',
  '21069091',
  '21069092',
  '21069099',
  '2109',
  '22',
  '2201',
  '220110',
  '22011010',
  '22011020',
  '220190',
  '22019010',
  '22019090',
  '2202',
  '220210',
  '22021010',
  '22021020',
  '22021090',
  '220290',
  '22029010',
  '22029020',
  '22029030',
  '22029090',
  '220291',
  '22029100',
  '220299',
  '22029910',
  '22029920',
  '22029930',
  '22029990',
  '2203',
  '220300',
  '22030000',
  '2204',
  '220410',
  '22041000',
  '220421',
  '22042110',
  '22042120',
  '22042190',
  '220422',
  '22042210',
  '22042220',
  '22042290',
  '220429',
  '22042910',
  '22042920',
  '22042990',
  '220430',
  '22043000',
  '2205',
  '220510',
  '22051000',
  '220590',
  '22059000',
  '2206',
  '220600',
  '22060000',
  '2207',
  '220710',
  '22071011',
  '22071019',
  '22071090',
  '220720',
  '22072000',
  '2208',
  '220820',
  '22082010',
  '22082011',
  '22082012',
  '22082019',
  '22082020',
  '22082090',
  '22082091',
  '22082092',
  '22082099',
  '220830',
  '22083010',
  '22083011',
  '22083012',
  '22083013',
  '22083019',
  '22083020',
  '22083030',
  '22083090',
  '22083091',
  '22083092',
  '22083093',
  '22083099',
  '220840',
  '22084010',
  '22084011',
  '22084012',
  '22084020',
  '22084091',
  '22084092',
  '220850',
  '22085010',
  '22085011',
  '22085012',
  '22085013',
  '22085020',
  '22085091',
  '22085092',
  '22085093',
  '220860',
  '22086000',
  '220870',
  '22087010',
  '22087011',
  '22087012',
  '22087020',
  '22087091',
  '22087092',
  '220890',
  '22089010',
  '22089011',
  '22089012',
  '22089019',
  '22089020',
  '22089090',
  '22089091',
  '22089092',
  '22089099',
  '2209',
  '220900',
  '22090010',
  '22090020',
  '22090090',
  '23',
  '2301',
  '230110',
  '23011010',
  '23011090',
  '230120',
  '23012011',
  '23012019',
  '23012090',
  '2302',
  '230210',
  '23021010',
  '23021090',
  '23022010',
  '23022020',
  '23022090',
  '230230',
  '23023000',
  '230240',
  '23024000',
  '230250',
  '23025000',
  '2303',
  '230310',
  '23031000',
  '230320',
  '23032000',
  '230330',
  '23033000',
  '2304',
  '230400',
  '23040010',
  '23040020',
  '23040030',
  '23040090',
  '2305',
  '230500',
  '23050010',
  '23050020',
  '23050090',
  '2306',
  '230610',
  '23061010',
  '23061020',
  '23061030',
  '23061040',
  '23061090',
  '230620',
  '23062010',
  '23062020',
  '23062090',
  '230630',
  '23063010',
  '23063020',
  '23063090',
  '230641',
  '23064100',
  '230649',
  '23064900',
  '230650',
  '23065010',
  '23065020',
  '23065090',
  '230660',
  '23066000',
  '23067000',
  '230690',
  '23069011',
  '23069012',
  '23069013',
  '23069014',
  '23069015',
  '23069016',
  '23069017',
  '23069018',
  '23069019',
  '23069021',
  '23069022',
  '23069023',
  '23069024',
  '23069025',
  '23069026',
  '23069027',
  '23069028',
  '23069029',
  '23069030',
  '23069090',
  '2307',
  '230700',
  '230700',
  '23070000',
  '2308',
  '230800',
  '23080000',
  '2309',
  '230910',
  '23091000',
  '230990',
  '23099010',
  '23099020',
  '23099031',
  '23099032',
  '23099039',
  '23099090',
  '24',
  '2401',
  '240110',
  '24011010',
  '24011020',
  '24011030',
  '24011040',
  '24011050',
  '24011060',
  '24011070',
  '24011080',
  '24011090',
  '240120',
  '24012010',
  '24012020',
  '24012030',
  '24012040',
  '24012050',
  '24012060',
  '24012070',
  '24012080',
  '24012090',
  '240130',
  '24013000',
  '2402',
  '240210',
  '24021010',
  '24021020',
  '240220',
  '24022010',
  '24022020',
  '24022030',
  '24022040',
  '24022050',
  '24022090',
  '240290',
  '24029010',
  '24029020',
  '24029090',
  '2403',
  '240310',
  '240311',
  '24031110',
  '24031190',
  '240319',
  '24031910',
  '24031921',
  '24031929',
  '24031990',
  '240391',
  '24039100',
  '240399',
  '24039910',
  '24039920',
  '24039930',
  '24039940',
  '24039950',
  '24039960',
  '24039970',
  '24039990',
  '2404',
  '240411',
  '24041100',
  '240412',
  '24041200',
  '240419',
  '24041900',
  '240491',
  '24049100',
  '240492',
  '24049200',
  '240499',
  '24049900',
  '25',
  '2501',
  '250100',
  '25010010',
  '25010020',
  '25010090',
  '2502',
  '250200',
  '25020000',
  '2503',
  '250300',
  '25030010',
  '25030090',
  '2504',
  '250410',
  '25041010',
  '25041020',
  '25041090',
  '250490',
  '25049010',
  '25049090',
  '2505',
  '250510',
  '25051011',
  '25051012',
  '25051019',
  '25051020',
  '250590',
  '25059000',
  '2506',
  '250610',
  '25061010',
  '25061020',
  '250620',
  '25062010',
  '25062020',
  '25062090',
  '25062110',
  '25062120',
  '25062190',
  '25062900',
  '2507',
  '250700',
  '25070010',
  '25070021',
  '25070022',
  '25070029',
  '2508',
  '250810',
  '25081010',
  '25081090',
  '25082010',
  '25082090',
  '250830',
  '25083010',
  '25083020',
  '25083030',
  '25083090',
  '250840',
  '25084010',
  '25084020',
  '25084090',
  '250850',
  '25085010',
  '25085021',
  '25085022',
  '25085023',
  '25085031',
  '25085032',
  '25085039',
  '250860',
  '25086000',
  '250870',
  '25087000',
  '2509',
  '250900',
  '25090000',
  '2510',
  '251010',
  '25101010',
  '25101020',
  '25101030',
  '25101090',
  '251020',
  '25102010',
  '25102020',
  '25102030',
  '25102090',
  '2511',
  '251110',
  '25111010',
  '25111020',
  '25111090',
  '251120',
  '25112000',
  '2512',
  '251200',
  '25120010',
  '25120020',
  '25120030',
  '25120090',
  '2513',
  '251310',
  '25131000',
  '25131100',
  '25131900',
  '251320',
  '25132010',
  '25132020',
  '25132030',
  '25132090',
  '2514',
  '251400',
  '251400',
  '25140000',
  '2515',
  '251511',
  '25151100',
  '251512',
  '25151210',
  '25151220',
  '25151290',
  '251520',
  '25152010',
  '25152090',
  '2516',
  '251611',
  '25161100',
  '251612',
  '25161200',
  '251620',
  '25162000',
  '25162100',
  '25162200',
  '251690',
  '25169010',
  '25169020',
  '25169090',
  '2517',
  '251710',
  '25171010',
  '25171020',
  '25171090',
  '251720',
  '25172000',
  '251730',
  '25173000',
  '251741',
  '25174100',
  '251749',
  '25174900',
  '2518',
  '251810',
  '25181000',
  '251820',
  '25182000',
  '25183000',
  '2519',
  '251910',
  '25191000',
  '251990',
  '25199010',
  '25199020',
  '25199030',
  '25199040',
  '25199090',
  '2520',
  '252010',
  '25201010',
  '25201020',
  '25201090',
  '252020',
  '25202010',
  '25202090',
  '2521',
  '252100',
  '25210010',
  '25210090',
  '2522',
  '252210',
  '25221000',
  '252220',
  '25222000',
  '252230',
  '25223000',
  '2523',
  '252310',
  '25231000',
  '252321',
  '25232100',
  '252329',
  '25232910',
  '25232920',
  '25232930',
  '25232940',
  '25232990',
  '252330',
  '25233000',
  '252390',
  '25239010',
  '25239020',
  '25239090',
  '2524',
  '25240011',
  '25240012',
  '25240019',
  '25240021',
  '25240022',
  '25240029',
  '25240031',
  '25240039',
  '25240091',
  '25240099',
  '252410',
  '25241000',
  '25241010',
  '252490',
  '25249011',
  '25249012',
  '25249013',
  '25249014',
  '25249019',
  '25249021',
  '25249022',
  '25249023',
  '25249024',
  '25249029',
  '25249031',
  '25249032',
  '25249033',
  '25249034',
  '25249039',
  '25249091',
  '25249099',
  '2525',
  '252510',
  '25251010',
  '25251020',
  '25251030',
  '25251040',
  '25251090',
  '252520',
  '25252010',
  '25252020',
  '25252030',
  '25252040',
  '25252050',
  '25252090',
  '252530',
  '25253010',
  '25253020',
  '25253030',
  '25253090',
  '2526',
  '252610',
  '25261010',
  '25261020',
  '25261090',
  '252620',
  '25262000',
  '2528',
  '252800',
  '25280010',
  '25280020',
  '25280030',
  '25280090',
  '252890',
  '25289020',
  '2529',
  '252910',
  '25291010',
  '25291020',
  '252921',
  '25292100',
  '252922',
  '25292200',
  '252930',
  '25293000',
  '2530',
  '253010',
  '25301010',
  '25301020',
  '25301090',
  '253020',
  '25302000',
  '253090',
  '25309010',
  '25309020',
  '25309030',
  '25309040',
  '25309050',
  '25309060',
  '25309070',
  '25309091',
  '25309099',
  '26',
  '2601',
  '260111',
  '26011111',
  '26011112',
  '26011119',
  '26011121',
  '26011122',
  '26011129',
  '26011131',
  '26011139',
  '26011141',
  '26011142',
  '26011143',
  '26011149',
  '26011150',
  '26011190',
  '260112',
  '26011210',
  '26011290',
  '260120',
  '26012000',
  '2602',
  '260200',
  '26020010',
  '26020020',
  '26020030',
  '26020040',
  '26020050',
  '26020060',
  '26020070',
  '26020090',
  '2603',
  '260300',
  '26030000',
  '2604',
  '260400',
  '26040000',
  '2605',
  '260500',
  '26050000',
  '2606',
  '260600',
  '26060010',
  '26060020',
  '26060090',
  '2607',
  '260700',
  '26070000',
  '2608',
  '260800',
  '26080000',
  '2609',
  '260900',
  '26090000',
  '2610',
  '261000',
  '26100010',
  '26100020',
  '26100030',
  '26100040',
  '26100090',
  '2611',
  '261100',
  '26110000',
  '2612',
  '261210',
  '26121000',
  '261220',
  '26122000',
  '2613',
  '261310',
  '26131000',
  '261390',
  '26139000',
  '2614',
  '261400',
  '26140010',
  '26140020',
  '26140031',
  '26140039',
  '26140090',
  '2615',
  '261510',
  '26151000',
  '261590',
  '26159010',
  '26159020',
  '2616',
  '261610',
  '26161000',
  '261690',
  '26169010',
  '26169090',
  '2617',
  '261710',
  '26171000',
  '261790',
  '26179000',
  '2618',
  '261800',
  '26180000',
  '2619',
  '261900',
  '26190010',
  '26190090',
  '2620',
  '262011',
  '26201100',
  '262019',
  '26201900',
  '26201910',
  '26201990',
  '262021',
  '26202100',
  '262029',
  '26202900',
  '26202910',
  '26202990',
  '262030',
  '26203010',
  '26203090',
  '262040',
  '26204010',
  '26204090',
  '262060',
  '26206000',
  '262091',
  '26209100',
  '262099',
  '26209900',
  '2621',
  '262110',
  '26211000',
  '262190',
  '26219000',
  '27',
  '2701',
  '270111',
  '27011100',
  '270112',
  '27011200',
  '27011210',
  '27011290',
  '270119',
  '27011910',
  '27011920',
  '27011990',
  '270120',
  '27012010',
  '27012090',
  '2702',
  '270210',
  '27021000',
  '270220',
  '27022000',
  '2703',
  '270300',
  '27030010',
  '27030090',
  '2704',
  '270400',
  '27040010',
  '27040020',
  '27040030',
  '27040040',
  '27040090',
  '2705',
  '270500',
  '27050000',
  '2706',
  '270600',
  '27060010',
  '27060090',
  '2707',
  '270710',
  '27071000',
  '270720',
  '27072000',
  '270730',
  '27073000',
  '270740',
  '27074000',
  '270750',
  '27075000',
  '27076000',
  '270791',
  '27079100',
  '270799',
  '27079900',
  '2708',
  '270810',
  '27081010',
  '27081090',
  '270820',
  '27082000',
  '2709',
  '270900',
  '27090000',
  '27090010',
  '27090090',
  '27091000',
  '27092000',
  '2710',
  '271011',
  '271012',
  '27101211',
  '27101212',
  '27101213',
  '27101219',
  '27101220',
  '27101221',
  '27101222',
  '27101229',
  '27101231',
  '27101232',
  '27101239',
  '27101241',
  '27101242',
  '27101243',
  '27101244',
  '27101249',
  '27101250',
  '27101290',
  '271019',
  '27101910',
  '27101920',
  '27101930',
  '27101931',
  '27101932',
  '27101939',
  '27101940',
  '27101941',
  '27101942',
  '27101943',
  '27101944',
  '27101949',
  '27101950',
  '27101951',
  '27101952',
  '27101953',
  '27101959',
  '27101960',
  '27101961',
  '27101969',
  '27101970',
  '27101971',
  '27101972',
  '27101973',
  '27101974',
  '27101975',
  '27101976',
  '27101977',
  '27101978',
  '27101979',
  '27101980',
  '27101981',
  '27101982',
  '27101983',
  '27101984',
  '27101985',
  '27101986',
  '27101987',
  '27101988',
  '27101989',
  '27101990',
  '271020',
  '27102000',
  '27102010',
  '27102020',
  '27102090',
  '271091',
  '27109100',
  '271099',
  '27109900',
  '2711',
  '271111',
  '27111100',
  '271112',
  '27111200',
  '271113',
  '27111300',
  '271114',
  '27111400',
  '271119',
  '27111900',
  '27111910',
  '27111920',
  '27111990',
  '271121',
  '27112100',
  '271129',
  '27112900',
  '2712',
  '271210',
  '27121010',
  '27121090',
  '271220',
  '27122000',
  '27122010',
  '27122090',
  '271290',
  '27129010',
  '27129020',
  '27129030',
  '27129040',
  '27129090',
  '2713',
  '271311',
  '27131100',
  '27131110',
  '27131190',
  '271312',
  '27131200',
  '27131210',
  '27131290',
  '271320',
  '27132000',
  '271390',
  '27139000',
  '2714',
  '271410',
  '27141000',
  '271490',
  '27149010',
  '27149020',
  '27149030',
  '27149090',
  '2715',
  '271500',
  '27150010',
  '27150090',
  '2716',
  '271600',
  '27160000',
  '28',
  '2801',
  '280110',
  '28011000',
  '280120',
  '28012000',
  '280130',
  '28013010',
  '28013020',
  '2802',
  '280200',
  '28020010',
  '28020020',
  '28020030',
  '2803',
  '280300',
  '28030010',
  '28030020',
  '28030090',
  '2804',
  '280410',
  '28041000',
  '280421',
  '28042100',
  '280429',
  '28042910',
  '28042990',
  '280430',
  '28043000',
  '280440',
  '28044010',
  '28044090',
  '280450',
  '28045010',
  '28045020',
  '280461',
  '28046100',
  '280469',
  '28046900',
  '280470',
  '28047010',
  '28047020',
  '28047030',
  '280480',
  '28048000',
  '280490',
  '28049000',
  '2805',
  '280511',
  '28051100',
  '280512',
  '28051200',
  '280519',
  '28051900',
  '280530',
  '28053000',
  '280540',
  '28054000',
  '2806',
  '280610',
  '28061000',
  '280620',
  '28062000',
  '2807',
  '280700',
  '28070010',
  '28070020',
  '2808',
  '280800',
  '28080010',
  '28080020',
  '2809',
  '280910',
  '28091000',
  '280920',
  '28092010',
  '28092020',
  '2810',
  '281000',
  '28100010',
  '28100020',
  '2811',
  '281111',
  '28111100',
  '281112',
  '28111200',
  '281119',
  '28111910',
  '28111920',
  '28111930',
  '28111940',
  '28111990',
  '281121',
  '28112110',
  '28112190',
  '281122',
  '28112200',
  '28112300',
  '281129',
  '28112910',
  '28112920',
  '28112930',
  '28112940',
  '28112950',
  '28112990',
  '2812',
  '281210',
  '28121010',
  '28121020',
  '28121021',
  '28121022',
  '28121030',
  '28121040',
  '28121041',
  '28121042',
  '28121043',
  '28121047',
  '28121050',
  '28121060',
  '28121090',
  '281211',
  '28121100',
  '281212',
  '28121200',
  '281213',
  '28121300',
  '281214',
  '28121400',
  '281215',
  '28121500',
  '281216',
  '28121600',
  '281217',
  '28121700',
  '281219',
  '28121910',
  '28121920',
  '28121930',
  '28121990',
  '281290',
  '28129000',
  '2813',
  '281310',
  '28131000',
  '281390',
  '28139010',
  '28139020',
  '28139090',
  '2814',
  '281410',
  '28141000',
  '281420',
  '28142000',
  '2815',
  '281511',
  '28151110',
  '28151190',
  '281512',
  '28151200',
  '281520',
  '28152000',
  '281530',
  '28153000',
  '2816',
  '281610',
  '28161010',
  '28161020',
  '281640',
  '28164000',
  '2817',
  '281700',
  '28170010',
  '28170020',
  '2818',
  '281810',
  '28181000',
  '281820',
  '28182010',
  '28182011',
  '28182019',
  '28182090',
  '281830',
  '28183000',
  '2819',
  '281910',
  '28191000',
  '281990',
  '28199000',
  '2820',
  '282010',
  '28201000',
  '282090',
  '28209000',
  '2821',
  '282110',
  '28211010',
  '28211020',
  '282120',
  '28212000',
  '2822',
  '282200',
  '28220010',
  '28220020',
  '28220030',
  '2823',
  '282300',
  '28230010',
  '28230090',
  '2824',
  '282410',
  '28241010',
  '28241020',
  '28242000',
  '282490',
  '28249000',
  '2825',
  '282510',
  '28251010',
  '28251020',
  '28251030',
  '28251040',
  '28251090',
  '282520',
  '28252000',
  '282530',
  '28253010',
  '28253090',
  '282540',
  '28254000',
  '282550',
  '28255000',
  '282560',
  '28256010',
  '28256020',
  '282570',
  '28257010',
  '28257020',
  '28257090',
  '282580',
  '28258000',
  '282590',
  '28259010',
  '28259020',
  '28259030',
  '28259040',
  '28259050',
  '28259090',
  '2826',
  '28261110',
  '28261120',
  '282612',
  '28261200',
  '282619',
  '28261910',
  '28261990',
  '28262010',
  '28262020',
  '282630',
  '28263000',
  '282690',
  '28269000',
  '2827',
  '282710',
  '28271000',
  '282720',
  '28272000',
  '282731',
  '28273100',
  '282732',
  '28273200',
  '28273300',
  '28273400',
  '282735',
  '28273500',
  '28273600',
  '282739',
  '28273910',
  '28273920',
  '28273930',
  '28273940',
  '28273990',
  '282741',
  '28274110',
  '28274190',
  '282749',
  '28274900',
  '282751',
  '28275110',
  '28275120',
  '282759',
  '28275910',
  '28275990',
  '282760',
  '28276010',
  '28276020',
  '28276090',
  '2828',
  '282810',
  '28281010',
  '28281090',
  '282890',
  '28289011',
  '28289019',
  '28289020',
  '28289030',
  '28289040',
  '28289050',
  '28289060',
  '28289090',
  '2829',
  '282911',
  '28291100',
  '282919',
  '28291910',
  '28291920',
  '28291930',
  '28291990',
  '282990',
  '28299010',
  '28299020',
  '28299030',
  '2830',
  '283010',
  '28301000',
  '28302000',
  '28303000',
  '283090',
  '28309010',
  '28309020',
  '2831',
  '283110',
  '28311010',
  '28311020',
  '283190',
  '28319010',
  '28319020',
  '2832',
  '283210',
  '28321010',
  '28321020',
  '28321090',
  '283220',
  '28322010',
  '28322020',
  '28322090',
  '283230',
  '28323010',
  '28323020',
  '28323090',
  '2833',
  '283311',
  '28331100',
  '283319',
  '28331910',
  '28331920',
  '28331990',
  '283321',
  '28332100',
  '283322',
  '28332210',
  '28332290',
  '28332300',
  '283324',
  '28332400',
  '283325',
  '28332500',
  '28332610',
  '28332690',
  '283327',
  '28332700',
  '283329',
  '28332910',
  '28332920',
  '28332930',
  '28332940',
  '28332950',
  '28332990',
  '283330',
  '28333010',
  '28333020',
  '28333030',
  '28333090',
  '283340',
  '28334000',
  '2834',
  '283410',
  '28341010',
  '28341090',
  '283421',
  '28342100',
  '283429',
  '28342910',
  '28342920',
  '28342930',
  '28342990',
  '2835',
  '283510',
  '28351010',
  '28351020',
  '28351090',
  '283522',
  '28352200',
  '28352300',
  '283524',
  '28352400',
  '283525',
  '28352500',
  '283526',
  '28352610',
  '28352620',
  '28352690',
  '283529',
  '28352910',
  '28352920',
  '28352930',
  '28352940',
  '28352990',
  '283531',
  '28353100',
  '283539',
  '28353900',
  '2836',
  '28361000',
  '283620',
  '28362010',
  '28362020',
  '28362090',
  '283630',
  '28363000',
  '283640',
  '28364000',
  '283650',
  '28365000',
  '283660',
  '28366000',
  '28367000',
  '283691',
  '28369100',
  '283692',
  '28369200',
  '283699',
  '28369910',
  '28369920',
  '28369930',
  '28369990',
  '2837',
  '283711',
  '28371100',
  '283719',
  '28371910',
  '28371920',
  '28371990',
  '283720',
  '28372010',
  '28372020',
  '28372030',
  '28372040',
  '28372050',
  '28372090',
  '2838',
  '28380010',
  '28380020',
  '28380030',
  '2839',
  '283911',
  '28391100',
  '283919',
  '28391900',
  '28392000',
  '283990',
  '28399010',
  '28399090',
  '2840',
  '284011',
  '28401100',
  '284019',
  '28401900',
  '284020',
  '28402010',
  '28402090',
  '284030',
  '28403000',
  '2841',
  '28411010',
  '28411090',
  '28412010',
  '28412020',
  '284130',
  '28413000',
  '284150',
  '28415010',
  '28415090',
  '284161',
  '28416100',
  '284169',
  '28416900',
  '284170',
  '28417010',
  '28417020',
  '28417090',
  '284180',
  '28418010',
  '28418020',
  '28418090',
  '284190',
  '28419000',
  '2842',
  '284210',
  '28421000',
  '284290',
  '28429010',
  '28429020',
  '28429090',
  '2843',
  '284310',
  '28431010',
  '28431020',
  '28431090',
  '284321',
  '28432100',
  '284329',
  '28432900',
  '284330',
  '28433000',
  '284390',
  '28439011',
  '28439012',
  '28439019',
  '28439020',
  '2844',
  '284410',
  '28441000',
  '284420',
  '28442000',
  '284430',
  '28443010',
  '28443021',
  '28443022',
  '28443023',
  '28443029',
  '28443030',
  '28443090',
  '28444000',
  '284441',
  '28444100',
  '284442',
  '28444200',
  '284443',
  '28444300',
  '284444',
  '28444400',
  '284450',
  '28445000',
  '2845',
  '284510',
  '28451000',
  '284520',
  '28452000',
  '284530',
  '28453000',
  '284540',
  '28454000',
  '284590',
  '28459010',
  '28459090',
  '2846',
  '284610',
  '28461010',
  '28461090',
  '284690',
  '28469010',
  '28469020',
  '28469030',
  '28469090',
  '2847',
  '284700',
  '28470000',
  '2848',
  '284800',
  '28480010',
  '28480020',
  '28480090',
  '2849',
  '284910',
  '28491000',
  '284920',
  '28492010',
  '28492090',
  '284990',
  '28499010',
  '28499020',
  '28499090',
  '2850',
  '285000',
  '28500010',
  '28500020',
  '28500030',
  '28500041',
  '28500049',
  '28500050',
  '2851',
  '28510010',
  '28510020',
  '28510030',
  '28510040',
  '28510090',
  '28510091',
  '28510099',
  '2852',
  '285210',
  '28521000',
  '285290',
  '28529000',
  '2853',
  '285300',
  '28530010',
  '28530020',
  '28530030',
  '28530040',
  '28530091',
  '28530099',
  '285310',
  '28531000',
  '285390',
  '28539010',
  '28539020',
  '28539030',
  '28539040',
  '28539090',
  '29',
  '2901',
  '290110',
  '29011000',
  '290121',
  '29012100',
  '290122',
  '29012200',
  '290123',
  '29012300',
  '290124',
  '29012400',
  '290129',
  '29012910',
  '29012920',
  '29012930',
  '29012940',
  '29012990',
  '2902',
  '290211',
  '29021100',
  '290219',
  '29021900',
  '29021910',
  '29021990',
  '290220',
  '29022000',
  '290230',
  '29023000',
  '290241',
  '29024100',
  '290242',
  '29024200',
  '290243',
  '29024300',
  '290244',
  '29024400',
  '290250',
  '29025000',
  '290260',
  '29026000',
  '290270',
  '29027000',
  '290290',
  '29029010',
  '29029020',
  '29029030',
  '29029040',
  '29029050',
  '29029060',
  '29029090',
  '2903',
  '290311',
  '29031110',
  '29031120',
  '290312',
  '29031200',
  '290313',
  '29031300',
  '290314',
  '29031400',
  '290315',
  '29031500',
  '290319',
  '29031910',
  '29031920',
  '29031990',
  '290321',
  '29032100',
  '290322',
  '29032200',
  '290323',
  '29032300',
  '290329',
  '29032900',
  '29033010',
  '29033011',
  '29033019',
  '29033020',
  '29033030',
  '29033100',
  '290339',
  '29033911',
  '29033919',
  '29033920',
  '29033930',
  '29033990',
  '290341',
  '29034100',
  '290342',
  '29034200',
  '290343',
  '29034300',
  '290344',
  '29034400',
  '290345',
  '29034500',
  '290346',
  '29034600',
  '290347',
  '29034700',
  '290348',
  '29034800',
  '290349',
  '29034900',
  '290351',
  '29035100',
  '290359',
  '29035910',
  '29035990',
  '290361',
  '29036100',
  '290362',
  '29036200',
  '290369',
  '29036900',
  '290371',
  '29037100',
  '290372',
  '29037200',
  '290373',
  '29037300',
  '290374',
  '29037400',
  '290375',
  '29037500',
  '290376',
  '29037610',
  '29037620',
  '29037630',
  '290377',
  '29037711',
  '29037712',
  '29037713',
  '29037721',
  '29037722',
  '29037723',
  '29037724',
  '29037725',
  '29037731',
  '29037732',
  '29037733',
  '29037734',
  '29037735',
  '29037736',
  '29037737',
  '29037790',
  '290378',
  '29037800',
  '290379',
  '29037900',
  '290381',
  '29038100',
  '290382',
  '29038200',
  '290383',
  '29038300',
  '290389',
  '29038900',
  '290391',
  '29039110',
  '29039120',
  '29039130',
  '290392',
  '29039210',
  '29039221',
  '29039229',
  '290393',
  '29039300',
  '290394',
  '29039400',
  '290399',
  '29039910',
  '29039920',
  '29039930',
  '29039940',
  '29039950',
  '29039960',
  '29039970',
  '29039990',
  '2904',
  '290410',
  '29041010',
  '29041020',
  '29041030',
  '29041040',
  '29041090',
  '290420',
  '29042010',
  '29042020',
  '29042030',
  '29042040',
  '29042050',
  '29042060',
  '29042090',
  '290431',
  '29043100',
  '290432',
  '29043200',
  '290433',
  '29043300',
  '290434',
  '29043400',
  '290435',
  '29043500',
  '290436',
  '29043600',
  '290490',
  '29049010',
  '29049020',
  '29049030',
  '29049040',
  '29049050',
  '29049060',
  '29049070',
  '29049080',
  '29049090',
  '290491',
  '29049100',
  '290499',
  '29049910',
  '29049920',
  '29049930',
  '29049940',
  '29049950',
  '29049960',
  '29049970',
  '29049990',
  '2905',
  '290511',
  '29051100',
  '290512',
  '29051210',
  '29051220',
  '290513',
  '29051300',
  '290514',
  '29051410',
  '29051420',
  '29051430',
  '29051490',
  '29051500',
  '290516',
  '29051610',
  '29051620',
  '29051690',
  '290517',
  '29051700',
  '290519',
  '29051900',
  '29051910',
  '29051990',
  '290522',
  '29052210',
  '29052220',
  '29052230',
  '29052240',
  '29052250',
  '29052290',
  '290529',
  '29052900',
  '290531',
  '29053100',
  '290532',
  '29053200',
  '290539',
  '29053910',
  '29053920',
  '29053990',
  '290541',
  '29054100',
  '290542',
  '29054210',
  '29054290',
  '290543',
  '29054300',
  '290544',
  '29054400',
  '290545',
  '29054500',
  '290549',
  '29054900',
  '290551',
  '29055100',
  '290559',
  '29055900',
  '2906',
  '290611',
  '29061100',
  '290612',
  '29061200',
  '290613',
  '29061310',
  '29061390',
  '29061400',
  '290619',
  '29061910',
  '29061990',
  '290621',
  '29062100',
  '290629',
  '29062910',
  '29062920',
  '29062990',
  '2907',
  '290711',
  '29071110',
  '29071190',
  '290712',
  '29071210',
  '29071220',
  '29071290',
  '290713',
  '29071300',
  '29071400',
  '290715',
  '29071510',
  '29071520',
  '29071590',
  '290719',
  '29071910',
  '29071920',
  '29071930',
  '29071940',
  '29071950',
  '29071990',
  '290721',
  '29072100',
  '290722',
  '29072200',
  '290723',
  '29072300',
  '290729',
  '29072910',
  '29072920',
  '29072930',
  '29072990',
  '2908',
  '29081000',
  '290811',
  '29081100',
  '290819',
  '29081900',
  '29082010',
  '29082021',
  '29082022',
  '29082023',
  '29082024',
  '29082025',
  '29082026',
  '29082027',
  '29082029',
  '29089010',
  '29089020',
  '29089090',
  '290891',
  '29089100',
  '290892',
  '29089200',
  '290899',
  '29089910',
  '29089920',
  '29089990',
  '2909',
  '290911',
  '29091100',
  '290919',
  '29091900',
  '29091910',
  '29091920',
  '29091990',
  '290920',
  '29092000',
  '290930',
  '29093011',
  '29093012',
  '29093019',
  '29093020',
  '29093030',
  '29093090',
  '290941',
  '29094100',
  '29094200',
  '290943',
  '29094300',
  '290944',
  '29094400',
  '290949',
  '29094900',
  '29094910',
  '29094920',
  '29094930',
  '29094990',
  '290950',
  '29095010',
  '29095020',
  '29095030',
  '29095040',
  '29095050',
  '29095090',
  '290960',
  '29096000',
  '2910',
  '291010',
  '29101000',
  '291020',
  '29102000',
  '291030',
  '29103000',
  '291040',
  '29104000',
  '291050',
  '29105000',
  '291090',
  '29109000',
  '2911',
  '291100',
  '29110010',
  '29110090',
  '2912',
  '291211',
  '29121100',
  '291212',
  '29121200',
  '29121300',
  '291219',
  '29121910',
  '29121920',
  '29121930',
  '29121990',
  '291221',
  '29122100',
  '291229',
  '29122910',
  '29122920',
  '29122930',
  '29122990',
  '291241',
  '29124100',
  '291242',
  '29124200',
  '291249',
  '29124910',
  '29124920',
  '29124930',
  '29124940',
  '29124991',
  '29124999',
  '291250',
  '29125000',
  '291260',
  '29126000',
  '2913',
  '291300',
  '29130010',
  '29130090',
  '2914',
  '291411',
  '29141100',
  '291412',
  '29141200',
  '291413',
  '29141300',
  '291419',
  '29141910',
  '29141990',
  '291421',
  '291422',
  '29142200',
  '291423',
  '29142310',
  '29142320',
  '29142390',
  '291429',
  '29142910',
  '29142921',
  '29142922',
  '29142930',
  '29142940',
  '29142950',
  '29142990',
  '291431',
  '29143100',
  '291439',
  '29143910',
  '29143920',
  '29143930',
  '29143940',
  '29143990',
  '291440',
  '29144000',
  '291450',
  '29145000',
  '291461',
  '29146100',
  '291462',
  '29146200',
  '291469',
  '29146910',
  '29146920',
  '29146990',
  '291470',
  '29147010',
  '29147020',
  '29147090',
  '291471',
  '29147100',
  '291479',
  '29147910',
  '29147920',
  '29147930',
  '29147940',
  '29147950',
  '29147990',
  '2915',
  '291511',
  '29151100',
  '291512',
  '29151210',
  '29151290',
  '291513',
  '29151300',
  '291521',
  '29152100',
  '29152200',
  '29152300',
  '291524',
  '29152400',
  '291529',
  '29152910',
  '29152920',
  '29152930',
  '29152990',
  '291531',
  '29153100',
  '291532',
  '29153200',
  '291533',
  '29153300',
  '29153400',
  '29153500',
  '291536',
  '29153600',
  '291539',
  '29153910',
  '29153920',
  '29153930',
  '29153940',
  '29153950',
  '29153960',
  '29153970',
  '29153980',
  '29153990',
  '29153991',
  '29153992',
  '29153999',
  '291540',
  '29154010',
  '29154020',
  '29154030',
  '291550',
  '29155000',
  '291560',
  '29156010',
  '29156020',
  '291570',
  '29157010',
  '29157020',
  '29157030',
  '29157040',
  '29157050',
  '29157090',
  '291590',
  '29159010',
  '29159020',
  '29159030',
  '29159040',
  '29159050',
  '29159060',
  '29159070',
  '29159090',
  '29159091',
  '29159092',
  '29159093',
  '29159094',
  '29159095',
  '29159099',
  '2916',
  '291611',
  '29161100',
  '291612',
  '29161210',
  '29161290',
  '291613',
  '29161310',
  '29161320',
  '291614',
  '29161400',
  '291615',
  '29161510',
  '29161590',
  '291616',
  '29161600',
  '291619',
  '29161910',
  '29161920',
  '29161930',
  '29161940',
  '29161950',
  '29161960',
  '29161970',
  '29161990',
  '291620',
  '29162000',
  '29162010',
  '29162020',
  '29162090',
  '291631',
  '29163110',
  '29163120',
  '29163130',
  '29163140',
  '29163150',
  '29163160',
  '29163190',
  '291632',
  '29163200',
  '291634',
  '29163400',
  '291639',
  '29163910',
  '29163920',
  '29163930',
  '29163940',
  '29163950',
  '29163960',
  '29163990',
  '2917',
  '291711',
  '29171110',
  '29171120',
  '29171130',
  '29171140',
  '29171190',
  '291712',
  '29171200',
  '291713',
  '29171300',
  '29171310',
  '29171390',
  '291714',
  '29171400',
  '291719',
  '29171910',
  '29171920',
  '29171930',
  '29171940',
  '29171950',
  '29171960',
  '29171970',
  '29171990',
  '291720',
  '29172000',
  '29173100',
  '291732',
  '29173200',
  '291733',
  '29173300',
  '291734',
  '29173400',
  '291735',
  '29173500',
  '291736',
  '29173600',
  '291737',
  '29173700',
  '291739',
  '29173910',
  '29173920',
  '29173930',
  '29173940',
  '29173950',
  '29173960',
  '29173990',
  '2918',
  '291811',
  '29181110',
  '29181120',
  '29181190',
  '291812',
  '29181200',
  '291813',
  '29181310',
  '29181320',
  '29181390',
  '291814',
  '29181400',
  '291815',
  '29181510',
  '29181520',
  '29181530',
  '29181540',
  '29181550',
  '29181590',
  '291816',
  '29181610',
  '29181620',
  '29181690',
  '291817',
  '29181700',
  '291818',
  '29181800',
  '291819',
  '29181900',
  '29181910',
  '29181920',
  '29181930',
  '29181990',
  '291821',
  '29182110',
  '29182120',
  '29182190',
  '291822',
  '29182200',
  '291823',
  '29182310',
  '29182320',
  '29182330',
  '29182340',
  '29182390',
  '291829',
  '29182910',
  '29182920',
  '29182930',
  '29182990',
  '291830',
  '29183010',
  '29183020',
  '29183030',
  '29183040',
  '29183050',
  '29183090',
  '29189000',
  '291891',
  '29189100',
  '291899',
  '29189900',
  '29189910',
  '29189920',
  '29189990',
  '2919',
  '29190010',
  '29190020',
  '29190030',
  '29190040',
  '29190050',
  '29190090',
  '291910',
  '29191000',
  '291990',
  '29199010',
  '29199020',
  '29199030',
  '29199040',
  '29199050',
  '29199090',
  '2920',
  '29201000',
  '29201010',
  '29201020',
  '292011',
  '29201100',
  '292019',
  '29201910',
  '29201920',
  '29201990',
  '292021',
  '29202100',
  '292022',
  '29202200',
  '292023',
  '29202300',
  '292024',
  '29202400',
  '292029',
  '29202910',
  '29202920',
  '29202930',
  '29202990',
  '292030',
  '29203000',
  '292090',
  '29209000',
  '29209010',
  '29209020',
  '29209030',
  '29209041',
  '29209042',
  '29209043',
  '29209044',
  '29209090',
  '29209099',
  '2921',
  '292111',
  '29211110',
  '29211190',
  '292112',
  '29211200',
  '292113',
  '29211300',
  '292114',
  '29211400',
  '292119',
  '29211900',
  '29211910',
  '29211911',
  '29211912',
  '29211913',
  '29211914',
  '29211920',
  '29211990',
  '292121',
  '29212100',
  '292122',
  '29212200',
  '292129',
  '29212910',
  '29212920',
  '29212940',
  '29212990',
  '292130',
  '29213010',
  '29213090',
  '292141',
  '29214110',
  '29214120',
  '29214190',
  '292142',
  '29214211',
  '29214212',
  '29214213',
  '29214214',
  '29214215',
  '29214221',
  '29214222',
  '29214223',
  '29214224',
  '29214225',
  '29214226',
  '29214231',
  '29214232',
  '29214233',
  '29214234',
  '29214235',
  '29214236',
  '29214290',
  '29214310',
  '29214320',
  '29214330',
  '29214340',
  '29214350',
  '29214360',
  '29214370',
  '29214380',
  '29214390',
  '292144',
  '29214410',
  '29214490',
  '292145',
  '29214511',
  '29214512',
  '29214513',
  '29214514',
  '29214515',
  '29214516',
  '29214521',
  '29214522',
  '29214523',
  '29214524',
  '29214525',
  '29214526',
  '29214531',
  '29214532',
  '29214533',
  '29214590',
  '292146',
  '29214600',
  '292149',
  '29214910',
  '29214920',
  '29214990',
  '292151',
  '29215110',
  '29215120',
  '29215130',
  '29215140',
  '29215150',
  '29215160',
  '29215170',
  '29215180',
  '29215190',
  '292159',
  '29215910',
  '29215920',
  '29215930',
  '29215940',
  '29215990',
  '2922',
  '292211',
  '29221100',
  '29221110',
  '29221190',
  '292212',
  '29221200',
  '29221210',
  '29221290',
  '29221310',
  '29221390',
  '292214',
  '29221400',
  '292215',
  '29221500',
  '292216',
  '29221600',
  '292217',
  '29221710',
  '29221720',
  '292218',
  '29221800',
  '292219',
  '29221900',
  '29221910',
  '29221940',
  '29221950',
  '29221960',
  '29221990',
  '292221',
  '29222110',
  '29222120',
  '29222130',
  '29222140',
  '29222150',
  '29222160',
  '29222170',
  '29222180',
  '29222190',
  '29222210',
  '29222220',
  '29222230',
  '29222290',
  '292229',
  '29222911',
  '29222912',
  '29222913',
  '29222914',
  '29222921',
  '29222922',
  '29222923',
  '29222924',
  '29222925',
  '29222926',
  '29222931',
  '29222932',
  '29222933',
  '29222934',
  '29222935',
  '29222936',
  '29222990',
  '292231',
  '29223100',
  '292239',
  '29223900',
  '292241',
  '29224100',
  '292242',
  '29224210',
  '29224220',
  '29224290',
  '292243',
  '29224300',
  '292244',
  '29224400',
  '292249',
  '29224910',
  '29224920',
  '29224990',
  '292250',
  '29225011',
  '29225012',
  '29225013',
  '29225014',
  '29225015',
  '29225021',
  '29225022',
  '29225023',
  '29225024',
  '29225090',
  '2923',
  '292310',
  '29231000',
  '292320',
  '29232010',
  '29232090',
  '292330',
  '29233000',
  '292340',
  '29234000',
  '292390',
  '29239000',
  '2924',
  '292411',
  '29241100',
  '292412',
  '29241200',
  '292419',
  '29241900',
  '292421',
  '29242110',
  '29242120',
  '29242130',
  '29242190',
  '292423',
  '29242300',
  '292424',
  '29242400',
  '292425',
  '29242500',
  '292429',
  '29242910',
  '29242920',
  '29242930',
  '29242940',
  '29242950',
  '29242960',
  '29242970',
  '29242990',
  '2925',
  '292511',
  '29251100',
  '292512',
  '29251200',
  '292519',
  '29251900',
  '29252010',
  '29252090',
  '292521',
  '29252100',
  '292529',
  '29252910',
  '29252990',
  '2926',
  '292610',
  '29261000',
  '292620',
  '29262000',
  '292630',
  '29263000',
  '292640',
  '29264000',
  '292690',
  '29269000',
  '2927',
  '292700',
  '29270010',
  '29270090',
  '2928',
  '292800',
  '29280010',
  '29280090',
  '2929',
  '292910',
  '29291010',
  '29291020',
  '29291090',
  '292990',
  '29299010',
  '29299020',
  '29299030',
  '29299040',
  '29299050',
  '29299060',
  '29299090',
  '2930',
  '293010',
  '29301000',
  '293020',
  '29302000',
  '29302010',
  '29302090',
  '293030',
  '29303000',
  '293040',
  '29304000',
  '29305000',
  '293060',
  '29306000',
  '293070',
  '29307000',
  '293080',
  '29308000',
  '293090',
  '29309010',
  '29309020',
  '29309030',
  '29309040',
  '29309050',
  '29309060',
  '29309070',
  '29309080',
  '29309090',
  '29309091',
  '29309092',
  '29309093',
  '29309094',
  '29309095',
  '29309096',
  '29309097',
  '29309098',
  '29309099',
  '2931',
  '293100',
  '29310010',
  '29310041',
  '29310042',
  '29310043',
  '29310044',
  '29310051',
  '29310052',
  '29310053',
  '29310054',
  '29310055',
  '29310056',
  '29310057',
  '29310061',
  '29310062',
  '29310071',
  '29310072',
  '29310073',
  '293110',
  '29311010',
  '29311020',
  '29311090',
  '293120',
  '29312000',
  '29313100',
  '29313200',
  '29313300',
  '29313400',
  '29313500',
  '29313600',
  '29313700',
  '29313800',
  '29313900',
  '293141',
  '29314100',
  '293142',
  '29314200',
  '293143',
  '29314300',
  '293144',
  '29314400',
  '293145',
  '29314500',
  '293146',
  '29314600',
  '293147',
  '29314700',
  '293148',
  '29314800',
  '293149',
  '29314910',
  '29314920',
  '29314930',
  '29314990',
  '293151',
  '29315100',
  '293152',
  '29315200',
  '293153',
  '29315300',
  '293154',
  '29315400',
  '293159',
  '29315900',
  '293190',
  '29319010',
  '29319011',
  '29319012',
  '29319013',
  '29319014',
  '29319015',
  '29319019',
  '29319020',
  '29319030',
  '29319090',
  '2932',
  '293211',
  '29321100',
  '293212',
  '29321200',
  '293213',
  '29321300',
  '293214',
  '29321400',
  '293219',
  '29321910',
  '29321990',
  '293220',
  '29322010',
  '29322020',
  '29322090',
  '293229',
  '293291',
  '29329100',
  '293292',
  '29329200',
  '293293',
  '29329300',
  '293294',
  '29329400',
  '293295',
  '29329500',
  '293296',
  '29329600',
  '293299',
  '29329900',
  '29329910',
  '29329920',
  '29329990',
  '2933',
  '293311',
  '29331100',
  '293319',
  '29331910',
  '29331920',
  '29331930',
  '29331940',
  '29331950',
  '29331960',
  '29331970',
  '29331980',
  '29331990',
  '29331991',
  '29331999',
  '293321',
  '29332100',
  '293329',
  '29332910',
  '29332920',
  '29332930',
  '29332940',
  '29332950',
  '29332960',
  '29332990',
  '293331',
  '29333100',
  '293332',
  '29333200',
  '293333',
  '29333300',
  '29333311',
  '29333312',
  '29333313',
  '29333314',
  '29333315',
  '29333316',
  '29333317',
  '29333318',
  '29333321',
  '29333322',
  '29333323',
  '29333324',
  '29333325',
  '29333326',
  '29333327',
  '29333328',
  '29333331',
  '29333332',
  '29333333',
  '29333334',
  '29333335',
  '293334',
  '29333400',
  '293335',
  '29333500',
  '293336',
  '29333600',
  '293337',
  '29333700',
  '293339',
  '29333911',
  '29333912',
  '29333913',
  '29333914',
  '29333915',
  '29333916',
  '29333917',
  '29333918',
  '29333919',
  '29333920',
  '29333921',
  '29333922',
  '29333929',
  '29333930',
  '29333940',
  '29333990',
  '293341',
  '29334100',
  '293349',
  '29334900',
  '293352',
  '29335200',
  '293353',
  '29335300',
  '293354',
  '29335400',
  '293355',
  '29335500',
  '293359',
  '29335910',
  '29335920',
  '29335930',
  '29335940',
  '29335950',
  '29335990',
  '293361',
  '29336100',
  '293369',
  '29336910',
  '29336920',
  '29336930',
  '29336940',
  '29336950',
  '29336990',
  '293371',
  '29337100',
  '293372',
  '29337200',
  '293379',
  '29337900',
  '29337910',
  '29337920',
  '29337990',
  '293391',
  '29339100',
  '293392',
  '29339200',
  '293399',
  '29339900',
  '29339910',
  '29339920',
  '29339990',
  '2934',
  '293410',
  '29341000',
  '293420',
  '29342000',
  '293430',
  '29343000',
  '293491',
  '29349100',
  '293492',
  '29349200',
  '293499',
  '29349900',
  '29349910',
  '29349920',
  '29349930',
  '29349990',
  '2935',
  '293500',
  '29350011',
  '29350012',
  '29350013',
  '29350014',
  '29350015',
  '29350021',
  '29350022',
  '29350023',
  '29350024',
  '29350090',
  '293510',
  '29351000',
  '293520',
  '29352000',
  '293530',
  '29353000',
  '293540',
  '29354000',
  '293550',
  '29355000',
  '29355010',
  '29355090',
  '293590',
  '29359011',
  '29359012',
  '29359013',
  '29359014',
  '29359015',
  '29359021',
  '29359022',
  '29359023',
  '29359024',
  '29359090',
  '2936',
  '29361000',
  '293621',
  '29362100',
  '293622',
  '29362210',
  '29362290',
  '293623',
  '29362310',
  '29362390',
  '293624',
  '29362400',
  '293625',
  '29362500',
  '293626',
  '29362610',
  '29362690',
  '293627',
  '29362700',
  '293628',
  '29362800',
  '293629',
  '29362910',
  '29362920',
  '29362930',
  '29362940',
  '29362950',
  '29362990',
  '293690',
  '29369000',
  '2937',
  '293711',
  '29371100',
  '293712',
  '29371200',
  '293719',
  '29371900',
  '293721',
  '29372100',
  '293722',
  '29372200',
  '293723',
  '29372300',
  '293729',
  '29372900',
  '293731',
  '29373100',
  '293750',
  '29375000',
  '293790',
  '29379011',
  '29379019',
  '29379020',
  '29379090',
  '2938',
  '293810',
  '29381000',
  '293890',
  '29389010',
  '29389020',
  '29389090',
  '2939',
  '293911',
  '29391100',
  '293919',
  '29391900',
  '293920',
  '29392010',
  '29392020',
  '29392030',
  '29392040',
  '29392090',
  '29392110',
  '29392120',
  '29392130',
  '29392140',
  '29392190',
  '29392900',
  '29392910',
  '29392990',
  '293930',
  '29393000',
  '293941',
  '29394100',
  '29394110',
  '29394120',
  '29394190',
  '293942',
  '29394200',
  '293943',
  '29394300',
  '293944',
  '29394400',
  '293945',
  '29394500',
  '293949',
  '29394900',
  '293951',
  '29395100',
  '293959',
  '29395900',
  '293961',
  '29396110',
  '29396190',
  '293962',
  '29396210',
  '29396290',
  '293963',
  '29396300',
  '293969',
  '29396900',
  '29397100',
  '293972',
  '29397200',
  '293979',
  '29397900',
  '29397910',
  '29397990',
  '293980',
  '29398000',
  '29399100',
  '29399900',
  '2940',
  '294000',
  '29400000',
  '2941',
  '294110',
  '29411010',
  '29411020',
  '29411030',
  '29411040',
  '29411050',
  '29411090',
  '294120',
  '29412010',
  '29412090',
  '294130',
  '29413010',
  '29413020',
  '29413090',
  '294140',
  '29414000',
  '294150',
  '29415000',
  '294190',
  '29419011',
  '29419012',
  '29419013',
  '29419014',
  '29419019',
  '29419020',
  '29419030',
  '29419040',
  '29419050',
  '29419060',
  '29419090',
  '2942',
  '294200',
  '29420011',
  '29420012',
  '29420013',
  '29420014',
  '29420015',
  '29420016',
  '29420021',
  '29420022',
  '29420023',
  '29420024',
  '29420025',
  '29420026',
  '29420027',
  '29420031',
  '29420032',
  '29420033',
  '29420034',
  '29420090',
  '30',
  '3001',
  '30011010',
  '30011091',
  '30011099',
  '300120',
  '30012010',
  '30012020',
  '30012030',
  '30012090',
  '300190',
  '30019010',
  '30019091',
  '30019099',
  '3002',
  '300210',
  '30021011',
  '30021012',
  '30021013',
  '30021014',
  '30021019',
  '30021020',
  '30021091',
  '30021099',
  '30021100',
  '300212',
  '30021210',
  '30021220',
  '30021230',
  '30021240',
  '30021290',
  '300213',
  '30021300',
  '30021310',
  '300214',
  '30021400',
  '30021410',
  '300215',
  '30021500',
  '30021900',
  '300220',
  '30022011',
  '30022012',
  '30022013',
  '30022014',
  '30022015',
  '30022016',
  '30022017',
  '30022018',
  '30022019',
  '30022021',
  '30022022',
  '30022023',
  '30022024',
  '30022029',
  '30023000',
  '300241',
  '30024111',
  '30024112',
  '30024113',
  '30024114',
  '30024115',
  '30024116',
  '30024117',
  '30024118',
  '30024119',
  '30024121',
  '30024122',
  '30024123',
  '30024124',
  '30024125',
  '30024129',
  '300242',
  '30024200',
  '300249',
  '30024910',
  '30024920',
  '30024990',
  '300251',
  '30025100',
  '300259',
  '30025900',
  '300290',
  '30029010',
  '30029020',
  '30029030',
  '30029040',
  '30029090',
  '3003',
  '300310',
  '30031000',
  '300320',
  '30032000',
  '300331',
  '30033100',
  '300339',
  '30033900',
  '30034000',
  '300341',
  '30034100',
  '300342',
  '30034200',
  '300343',
  '30034300',
  '300349',
  '30034900',
  '300360',
  '30036000',
  '300390',
  '30039011',
  '30039012',
  '30039013',
  '30039014',
  '30039015',
  '30039021',
  '30039022',
  '30039031',
  '30039032',
  '30039033',
  '30039034',
  '30039035',
  '30039036',
  '30039090',
  '3004',
  '300410',
  '30041010',
  '30041020',
  '30041030',
  '30041040',
  '30041050',
  '30041060',
  '30041070',
  '30041090',
  '300420',
  '30042011',
  '30042012',
  '30042013',
  '30042014',
  '30042019',
  '30042020',
  '30042031',
  '30042032',
  '30042033',
  '30042034',
  '30042039',
  '30042041',
  '30042042',
  '30042049',
  '30042050',
  '30042061',
  '30042062',
  '30042063',
  '30042064',
  '30042069',
  '30042070',
  '30042091',
  '30042092',
  '30042093',
  '30042094',
  '30042095',
  '30042096',
  '30042097',
  '30042099',
  '300431',
  '30043110',
  '30043190',
  '300432',
  '30043200',
  '300439',
  '30043911',
  '30043912',
  '30043913',
  '30043914',
  '30043919',
  '30043921',
  '30043922',
  '30043990',
  '300440',
  '30044010',
  '30044020',
  '30044030',
  '30044040',
  '30044050',
  '30044060',
  '30044070',
  '30044090',
  '300441',
  '30044100',
  '300442',
  '30044200',
  '300443',
  '30044300',
  '300449',
  '30044910',
  '30044920',
  '30044930',
  '30044940',
  '30044950',
  '30044960',
  '30044970',
  '30044990',
  '300450',
  '30045010',
  '30045020',
  '30045031',
  '30045032',
  '30045033',
  '30045034',
  '30045035',
  '30045036',
  '30045037',
  '30045039',
  '30045090',
  '300460',
  '30046000',
  '300490',
  '30049011',
  '30049012',
  '30049013',
  '30049014',
  '30049015',
  '30049021',
  '30049022',
  '30049023',
  '30049024',
  '30049025',
  '30049026',
  '30049027',
  '30049029',
  '30049031',
  '30049032',
  '30049033',
  '30049034',
  '30049035',
  '30049036',
  '30049039',
  '30049041',
  '30049042',
  '30049043',
  '30049044',
  '30049045',
  '30049046',
  '30049047',
  '30049048',
  '30049049',
  '30049051',
  '30049052',
  '30049053',
  '30049054',
  '30049055',
  '30049056',
  '30049057',
  '30049058',
  '30049059',
  '30049061',
  '30049062',
  '30049063',
  '30049064',
  '30049065',
  '30049066',
  '30049067',
  '30049069',
  '30049071',
  '30049072',
  '30049073',
  '30049074',
  '30049075',
  '30049076',
  '30049077',
  '30049079',
  '30049081',
  '30049082',
  '30049083',
  '30049084',
  '30049085',
  '30049086',
  '30049087',
  '30049088',
  '30049089',
  '30049091',
  '30049092',
  '30049093',
  '30049094',
  '30049095',
  '30049096',
  '30049099',
  '3005',
  '300510',
  '30051010',
  '30051020',
  '30051090',
  '300590',
  '30059010',
  '30059020',
  '30059030',
  '30059040',
  '30059050',
  '30059060',
  '30059070',
  '30059090',
  '3006',
  '300610',
  '30061010',
  '30061020',
  '30062000',
  '300630',
  '30063000',
  '300640',
  '30064000',
  '300650',
  '30065000',
  '300660',
  '30066010',
  '30066020',
  '30066030',
  '300670',
  '30067000',
  '30068000',
  '300691',
  '30069100',
  '300692',
  '30069200',
  '300693',
  '30069300',
  '3011',
  '3019',
  '3021',
  '3022',
  '3023',
  '3024',
  '3025',
  '3026',
  '3027',
  '3028',
  '3029',
  '3031',
  '3032',
  '3033',
  '3034',
  '3035',
  '3036',
  '3037',
  '303799',
  '3038',
  '3039',
  '3041',
  '3042',
  '3045',
  '3046',
  '3047',
  '3048',
  '3049',
  '3054',
  '3055',
  '30559',
  '3056',
  '3061',
  '306131',
  '3062',
  '3069',
  '3072',
  '3073',
  '3074',
  '3074330',
  '3075',
  '3078',
  '3079',
  '3082',
  '31',
  '3101',
  '310100',
  '31010010',
  '31010091',
  '31010092',
  '31010099',
  '3102',
  '310210',
  '31021000',
  '31021010',
  '31021090',
  '310221',
  '31022100',
  '310229',
  '31022910',
  '31022990',
  '310230',
  '31023000',
  '310240',
  '31024000',
  '310250',
  '31025000',
  '310260',
  '31026000',
  '31027000',
  '310280',
  '31028000',
  '310290',
  '31029010',
  '31029090',
  '3103',
  '31031000',
  '310311',
  '31031100',
  '310319',
  '31031900',
  '31032000',
  '310390',
  '31039000',
  '3104',
  '31041000',
  '310420',
  '31042000',
  '310430',
  '31043000',
  '310490',
  '31049000',
  '3105',
  '310510',
  '31051000',
  '310520',
  '31052000',
  '310530',
  '31053000',
  '310540',
  '31054000',
  '310551',
  '31055100',
  '310559',
  '31055900',
  '310560',
  '31056000',
  '310590',
  '31059010',
  '31059090',
  '32',
  '3201',
  '320110',
  '32011000',
  '320120',
  '32012000',
  '320190',
  '32019010',
  '32019020',
  '32019030',
  '32019090',
  '3202',
  '320210',
  '32021000',
  '320290',
  '32029010',
  '32029020',
  '32029030',
  '32029090',
  '3203',
  '320300',
  '32030010',
  '32030020',
  '32030030',
  '32030040',
  '32030090',
  '3204',
  '320411',
  '32041111',
  '32041119',
  '32041121',
  '32041129',
  '32041131',
  '32041132',
  '32041133',
  '32041139',
  '32041141',
  '32041142',
  '32041143',
  '32041149',
  '32041151',
  '32041152',
  '32041153',
  '32041154',
  '32041155',
  '32041156',
  '32041159',
  '32041191',
  '32041192',
  '32041193',
  '32041194',
  '32041195',
  '32041196',
  '32041199',
  '320412',
  '32041211',
  '32041212',
  '32041213',
  '32041214',
  '32041215',
  '32041216',
  '32041217',
  '32041218',
  '32041219',
  '32041221',
  '32041222',
  '32041223',
  '32041224',
  '32041225',
  '32041229',
  '32041231',
  '32041232',
  '32041239',
  '32041241',
  '32041242',
  '32041243',
  '32041244',
  '32041245',
  '32041246',
  '32041247',
  '32041248',
  '32041251',
  '32041252',
  '32041253',
  '32041254',
  '32041255',
  '32041259',
  '32041261',
  '32041262',
  '32041263',
  '32041264',
  '32041265',
  '32041266',
  '32041267',
  '32041268',
  '32041269',
  '32041291',
  '32041292',
  '32041293',
  '32041294',
  '32041295',
  '32041299',
  '320413',
  '32041310',
  '32041321',
  '32041329',
  '32041331',
  '32041339',
  '32041341',
  '32041342',
  '32041343',
  '32041349',
  '32041351',
  '32041352',
  '32041359',
  '32041361',
  '32041369',
  '32041391',
  '32041392',
  '32041393',
  '32041399',
  '320414',
  '32041411',
  '32041419',
  '32041421',
  '32041429',
  '32041431',
  '32041439',
  '32041440',
  '32041450',
  '32041460',
  '32041470',
  '32041481',
  '32041482',
  '32041483',
  '32041484',
  '32041485',
  '32041486',
  '32041487',
  '32041488',
  '32041489',
  '32041490',
  '320415',
  '32041511',
  '32041512',
  '32041519',
  '32041521',
  '32041522',
  '32041529',
  '32041531',
  '32041539',
  '32041541',
  '32041542',
  '32041549',
  '32041551',
  '32041552',
  '32041553',
  '32041554',
  '32041555',
  '32041556',
  '32041557',
  '32041558',
  '32041559',
  '32041561',
  '32041562',
  '32041563',
  '32041564',
  '32041569',
  '32041571',
  '32041572',
  '32041573',
  '32041579',
  '32041581',
  '32041582',
  '32041583',
  '32041584',
  '32041589',
  '32041591',
  '32041592',
  '32041593',
  '32041594',
  '32041595',
  '32041596',
  '32041597',
  '32041599',
  '320416',
  '32041610',
  '32041620',
  '32041630',
  '32041640',
  '32041650',
  '32041660',
  '32041670',
  '32041680',
  '32041690',
  '320417',
  '32041711',
  '32041719',
  '32041720',
  '32041731',
  '32041739',
  '32041740',
  '32041751',
  '32041759',
  '32041761',
  '32041769',
  '32041770',
  '32041780',
  '32041790',
  '320418',
  '32041800',
  '320419',
  '32041911',
  '32041912',
  '32041913',
  '32041914',
  '32041915',
  '32041916',
  '32041921',
  '32041922',
  '32041923',
  '32041924',
  '32041925',
  '32041929',
  '32041931',
  '32041932',
  '32041933',
  '32041934',
  '32041935',
  '32041936',
  '32041937',
  '32041938',
  '32041941',
  '32041942',
  '32041943',
  '32041944',
  '32041945',
  '32041946',
  '32041947',
  '32041949',
  '32041951',
  '32041952',
  '32041953',
  '32041954',
  '32041955',
  '32041956',
  '32041957',
  '32041958',
  '32041959',
  '32041961',
  '32041962',
  '32041963',
  '32041964',
  '32041965',
  '32041966',
  '32041967',
  '32041969',
  '32041971',
  '32041972',
  '32041973',
  '32041974',
  '32041975',
  '32041976',
  '32041977',
  '32041978',
  '32041979',
  '32041981',
  '32041982',
  '32041983',
  '32041984',
  '32041985',
  '32041986',
  '32041987',
  '32041988',
  '32041989',
  '32041990',
  '320420',
  '32042010',
  '32042090',
  '320490',
  '32049000',
  '3205',
  '320500',
  '32050000',
  '3206',
  '320611',
  '32061110',
  '32061190',
  '320619',
  '32061900',
  '320620',
  '32062000',
  '32063000',
  '320641',
  '32064100',
  '320642',
  '32064200',
  '32064300',
  '320649',
  '32064910',
  '32064920',
  '32064930',
  '32064940',
  '32064990',
  '320650',
  '32065000',
  '3207',
  '320710',
  '32071010',
  '32071020',
  '32071030',
  '32071040',
  '32071090',
  '320720',
  '32072010',
  '32072020',
  '320730',
  '32073000',
  '320740',
  '32074000',
  '3208',
  '320810',
  '32081010',
  '32081020',
  '32081030',
  '32081090',
  '320820',
  '32082010',
  '32082020',
  '32082030',
  '32082090',
  '320890',
  '32089011',
  '32089019',
  '32089021',
  '32089022',
  '32089029',
  '32089030',
  '32089041',
  '32089049',
  '32089050',
  '32089090',
  '3209',
  '320910',
  '32091010',
  '32091090',
  '320990',
  '32099010',
  '32099020',
  '32099090',
  '3210',
  '321000',
  '32100011',
  '32100012',
  '32100019',
  '32100020',
  '32100030',
  '32100040',
  '32100090',
  '3211',
  '321100',
  '32110000',
  '3212',
  '321210',
  '32121000',
  '321290',
  '32129010',
  '32129020',
  '32129030',
  '32129090',
  '3213',
  '321310',
  '32131000',
  '321390',
  '32139000',
  '3214',
  '321410',
  '32141000',
  '321490',
  '32149010',
  '32149020',
  '32149090',
  '3215',
  '321511',
  '32151110',
  '32151120',
  '32151130',
  '32151140',
  '32151190',
  '321519',
  '32151910',
  '32151920',
  '32151930',
  '32151940',
  '32151990',
  '321590',
  '32159010',
  '32159020',
  '32159030',
  '32159040',
  '32159090',
  '33',
  '3301',
  '33011100',
  '330112',
  '33011200',
  '330113',
  '33011300',
  '33011400',
  '330119',
  '33011910',
  '33011990',
  '33012100',
  '33012210',
  '33012290',
  '33012300',
  '330124',
  '33012400',
  '330125',
  '33012510',
  '33012520',
  '33012530',
  '33012540',
  '33012590',
  '33012600',
  '330129',
  '33012911',
  '33012912',
  '33012913',
  '33012914',
  '33012915',
  '33012916',
  '33012917',
  '33012918',
  '33012921',
  '33012922',
  '33012923',
  '33012924',
  '33012925',
  '33012926',
  '33012927',
  '33012928',
  '33012931',
  '33012932',
  '33012933',
  '33012934',
  '33012935',
  '33012936',
  '33012937',
  '33012938',
  '33012941',
  '33012942',
  '33012943',
  '33012944',
  '33012945',
  '33012946',
  '33012947',
  '33012948',
  '33012949',
  '33012950',
  '33012990',
  '330130',
  '33013010',
  '33013091',
  '33013099',
  '330190',
  '33019011',
  '33019012',
  '33019013',
  '33019014',
  '33019015',
  '33019016',
  '33019017',
  '33019021',
  '33019022',
  '33019023',
  '33019024',
  '33019025',
  '33019029',
  '33019031',
  '33019032',
  '33019033',
  '33019041',
  '33019049',
  '33019051',
  '33019059',
  '33019060',
  '33019071',
  '33019079',
  '33019090',
  '3302',
  '330210',
  '33021010',
  '33021090',
  '330290',
  '33029011',
  '33029012',
  '33029019',
  '33029020',
  '33029090',
  '3303',
  '330300',
  '33030010',
  '33030020',
  '33030030',
  '33030040',
  '33030050',
  '33030060',
  '33030090',
  '3304',
  '330410',
  '33041000',
  '330420',
  '33042000',
  '330430',
  '33043000',
  '330491',
  '33049110',
  '33049120',
  '33049190',
  '330499',
  '33049910',
  '33049920',
  '33049930',
  '33049940',
  '33049950',
  '33049990',
  '3305',
  '330510',
  '33051010',
  '33051090',
  '330520',
  '33052000',
  '330530',
  '33053000',
  '330590',
  '33059011',
  '33059019',
  '33059020',
  '33059030',
  '33059040',
  '33059050',
  '33059090',
  '3306',
  '330610',
  '33061010',
  '33061020',
  '33061090',
  '330620',
  '33062000',
  '330690',
  '33069000',
  '3307',
  '330710',
  '33071010',
  '33071090',
  '330720',
  '33072000',
  '330730',
  '33073010',
  '33073090',
  '330741',
  '33074100',
  '330749',
  '33074900',
  '330790',
  '33079010',
  '33079020',
  '33079090',
  '34',
  '3401',
  '340111',
  '34011110',
  '34011120',
  '34011190',
  '340119',
  '34011911',
  '34011919',
  '34011920',
  '34011930',
  '34011941',
  '34011942',
  '34011990',
  '340120',
  '34012000',
  '340130',
  '34013011',
  '34013012',
  '34013019',
  '34013090',
  '3402',
  '340211',
  '34021110',
  '34021190',
  '34021200',
  '34021300',
  '34021900',
  '340220',
  '34022010',
  '34022020',
  '34022090',
  '340231',
  '34023100',
  '340239',
  '34023900',
  '340241',
  '34024100',
  '340242',
  '34024200',
  '340249',
  '34024900',
  '340250',
  '34025000',
  '340290',
  '34029011',
  '34029012',
  '34029019',
  '34029020',
  '34029030',
  '34029041',
  '34029042',
  '34029049',
  '34029051',
  '34029052',
  '34029059',
  '34029091',
  '34029092',
  '34029099',
  '3403',
  '340311',
  '34031100',
  '340319',
  '34031900',
  '340391',
  '34039100',
  '340399',
  '34039900',
  '3404',
  '34041000',
  '340420',
  '34042000',
  '340490',
  '34049010',
  '34049020',
  '34049031',
  '34049032',
  '34049033',
  '34049039',
  '34049090',
  '3405',
  '340510',
  '34051000',
  '340520',
  '34052000',
  '340530',
  '34053000',
  '340540',
  '34054000',
  '340590',
  '34059010',
  '34059090',
  '3406',
  '340600',
  '34060010',
  '34060090',
  '3407',
  '340700',
  '34070010',
  '34070090',
  '35',
  '3501',
  '350110',
  '35011000',
  '350190',
  '35019000',
  '3502',
  '350211',
  '35021100',
  '350219',
  '35021900',
  '350220',
  '35022000',
  '350290',
  '35029000',
  '3503',
  '350300',
  '35030010',
  '35030020',
  '35030030',
  '35030090',
  '3504',
  '350400',
  '35040010',
  '35040091',
  '35040099',
  '3505',
  '350510',
  '35051010',
  '35051090',
  '350520',
  '35052000',
  '3506',
  '350610',
  '35061000',
  '350691',
  '35069110',
  '35069190',
  '350699',
  '35069910',
  '35069991',
  '35069999',
  '3507',
  '350710',
  '35071011',
  '35071019',
  '35071091',
  '35071099',
  '350790',
  '35079010',
  '35079020',
  '35079030',
  '35079040',
  '35079050',
  '35079061',
  '35079062',
  '35079069',
  '35079071',
  '35079079',
  '35079091',
  '35079099',
  '36',
  '3601',
  '360100',
  '36010010',
  '36010020',
  '36010090',
  '3602',
  '360200',
  '36020010',
  '36020090',
  '3603',
  '360300',
  '36030011',
  '36030019',
  '36030020',
  '36030031',
  '36030039',
  '36030041',
  '36030049',
  '36030051',
  '36030059',
  '360310',
  '36031000',
  '360320',
  '36032000',
  '360330',
  '36033000',
  '360340',
  '36034000',
  '360350',
  '36035000',
  '360360',
  '36036000',
  '3604',
  '360410',
  '36041000',
  '360490',
  '36049010',
  '36049090',
  '3605',
  '360500',
  '36050010',
  '36050090',
  '3606',
  '360610',
  '36061000',
  '360690',
  '36069010',
  '36069091',
  '36069092',
  '36069093',
  '36069099',
  '37',
  '3701',
  '370110',
  '37011010',
  '37011090',
  '370120',
  '37012000',
  '370130',
  '37013000',
  '370191',
  '37019110',
  '37019190',
  '370199',
  '37019910',
  '37019990',
  '3702',
  '370210',
  '37021000',
  '37022000',
  '370231',
  '37023110',
  '37023190',
  '370232',
  '37023210',
  '37023290',
  '370239',
  '37023910',
  '37023990',
  '370241',
  '37024110',
  '37024190',
  '370242',
  '37024210',
  '37024220',
  '37024290',
  '370243',
  '37024310',
  '37024320',
  '37024390',
  '370244',
  '37024410',
  '37024420',
  '37024490',
  '370251',
  '370252',
  '37025210',
  '37025220',
  '37025290',
  '370253',
  '37025300',
  '370254',
  '37025410',
  '37025420',
  '37025490',
  '370255',
  '37025510',
  '37025520',
  '37025590',
  '370256',
  '37025610',
  '37025620',
  '37025690',
  '370293',
  '370294',
  '370295',
  '370296',
  '37029611',
  '37029619',
  '370297',
  '37029711',
  '37029719',
  '370298',
  '37029810',
  '37029890',
  '3703',
  '370310',
  '37031010',
  '37031020',
  '370320',
  '37032010',
  '37032020',
  '370390',
  '37039010',
  '37039020',
  '3704',
  '370400',
  '37040010',
  '37040020',
  '37040030',
  '37040090',
  '3705',
  '370500',
  '37050000',
  '37051000',
  '37052000',
  '37059010',
  '37059090',
  '3706',
  '370610',
  '37061011',
  '37061012',
  '37061013',
  '37061014',
  '37061015',
  '37061020',
  '37061030',
  '37061041',
  '37061042',
  '37061051',
  '37061052',
  '37061059',
  '37061061',
  '37061062',
  '37061063',
  '37061069',
  '37061070',
  '37061091',
  '37061092',
  '37061099',
  '370690',
  '37069011',
  '37069012',
  '37069013',
  '37069014',
  '37069015',
  '37069020',
  '37069030',
  '37069041',
  '37069042',
  '37069051',
  '37069052',
  '37069059',
  '37069061',
  '37069062',
  '37069063',
  '37069064',
  '37069069',
  '37069070',
  '37069091',
  '37069092',
  '37069099',
  '3707',
  '370710',
  '37071000',
  '370790',
  '37079010',
  '37079090',
  '38',
  '3801',
  '380110',
  '38011000',
  '380120',
  '38012000',
  '380130',
  '38013000',
  '380190',
  '38019000',
  '3802',
  '380210',
  '38021000',
  '380290',
  '38029011',
  '38029012',
  '38029019',
  '38029020',
  '3803',
  '380300',
  '38030000',
  '3804',
  '380400',
  '38040010',
  '38040020',
  '38040090',
  '3805',
  '380510',
  '38051010',
  '38051020',
  '38051030',
  '38052000',
  '380590',
  '38059010',
  '38059020',
  '38059030',
  '38059090',
  '3806',
  '380610',
  '38061010',
  '38061090',
  '380620',
  '38062000',
  '380630',
  '38063000',
  '380690',
  '38069010',
  '38069090',
  '3807',
  '380700',
  '38070010',
  '38070020',
  '38070030',
  '3808',
  '38081011',
  '38081012',
  '38081013',
  '38081014',
  '38081015',
  '38081016',
  '38081017',
  '38081021',
  '38081022',
  '38081023',
  '38081024',
  '38081025',
  '38081026',
  '38081027',
  '38081031',
  '38081032',
  '38081033',
  '38081034',
  '38081035',
  '38081036',
  '38081037',
  '38081091',
  '38081092',
  '38081099',
  '38082010',
  '38082020',
  '38082030',
  '38082040',
  '38082050',
  '38082090',
  '38083010',
  '38083020',
  '38083030',
  '38083040',
  '38083050',
  '38083090',
  '38084000',
  '380850',
  '38085000',
  '380852',
  '38085200',
  '380859',
  '38085900',
  '380861',
  '38086100',
  '380862',
  '38086200',
  '380869',
  '38086900',
  '38089010',
  '38089090',
  '380891',
  '38089111',
  '38089112',
  '38089113',
  '38089121',
  '38089122',
  '38089123',
  '38089124',
  '38089131',
  '38089132',
  '38089133',
  '38089134',
  '38089135',
  '38089136',
  '38089137',
  '38089141',
  '38089142',
  '38089151',
  '38089152',
  '38089191',
  '38089192',
  '38089199',
  '380892',
  '38089210',
  '38089220',
  '38089230',
  '38089240',
  '38089250',
  '38089260',
  '38089270',
  '38089290',
  '380893',
  '38089310',
  '38089320',
  '38089330',
  '38089340',
  '38089350',
  '38089361',
  '38089362',
  '38089371',
  '38089372',
  '38089390',
  '380894',
  '38089400',
  '380899',
  '38089910',
  '38089990',
  '3809',
  '380910',
  '38091000',
  '380991',
  '38099110',
  '38099120',
  '38099130',
  '38099140',
  '38099150',
  '38099160',
  '38099170',
  '38099180',
  '38099190',
  '380992',
  '38099200',
  '380993',
  '38099310',
  '38099390',
  '38099900',
  '3810',
  '381010',
  '38101010',
  '38101020',
  '38101090',
  '381090',
  '38109010',
  '38109090',
  '3811',
  '381111',
  '38111100',
  '381119',
  '38111900',
  '381121',
  '38112100',
  '381129',
  '38112900',
  '381190',
  '38119000',
  '3812',
  '381210',
  '38121000',
  '381220',
  '38122010',
  '38122090',
  '381230',
  '38123010',
  '38123020',
  '38123030',
  '38123090',
  '381231',
  '38123100',
  '381239',
  '38123910',
  '38123920',
  '38123930',
  '38123990',
  '3813',
  '381300',
  '38130000',
  '3814',
  '381400',
  '38140010',
  '38140020',
  '3815',
  '381511',
  '38151100',
  '381512',
  '38151210',
  '38151290',
  '381519',
  '38151900',
  '381590',
  '38159000',
  '3816',
  '381600',
  '38160000',
  '3817',
  '381700',
  '38170011',
  '38170019',
  '38170020',
  '3818',
  '381800',
  '38180010',
  '38180090',
  '3819',
  '381900',
  '38190010',
  '38190090',
  '3820',
  '382000',
  '38200000',
  '3821',
  '382100',
  '38210000',
  '3822',
  '382200',
  '38220011',
  '38220012',
  '38220019',
  '38220090',
  '382211',
  '38221100',
  '382212',
  '38221200',
  '382213',
  '38221300',
  '382219',
  '38221910',
  '38221990',
  '382290',
  '38229010',
  '38229090',
  '3823',
  '382311',
  '38231100',
  '38231111',
  '38231112',
  '38231119',
  '38231190',
  '382312',
  '38231200',
  '382313',
  '38231300',
  '382319',
  '38231900',
  '382370',
  '38237010',
  '38237020',
  '38237030',
  '38237040',
  '38237090',
  '3824',
  '382410',
  '38241000',
  '38242010',
  '38242020',
  '38242090',
  '382430',
  '38243000',
  '382440',
  '38244010',
  '38244090',
  '382450',
  '38245010',
  '38245090',
  '382460',
  '38246010',
  '38246090',
  '38247100',
  '38247110',
  '38247190',
  '38247200',
  '38247300',
  '38247400',
  '38247500',
  '38247600',
  '38247700',
  '38247800',
  '38247900',
  '38247910',
  '38247990',
  '382481',
  '38248100',
  '382482',
  '38248200',
  '382483',
  '38248300',
  '382484',
  '38248400',
  '382485',
  '38248500',
  '382486',
  '38248600',
  '382487',
  '38248700',
  '382488',
  '38248800',
  '382489',
  '38248900',
  '382490',
  '38249011',
  '38249012',
  '38249013',
  '38249014',
  '38249015',
  '38249016',
  '38249017',
  '38249021',
  '38249022',
  '38249023',
  '38249024',
  '38249025',
  '38249026',
  '38249031',
  '38249032',
  '38249033',
  '38249034',
  '38249035',
  '38249036',
  '38249037',
  '38249038',
  '38249090',
  '382491',
  '38249100',
  '382492',
  '38249200',
  '382499',
  '38249900',
  '3825',
  '382510',
  '38251000',
  '382520',
  '38252000',
  '382530',
  '38253000',
  '382541',
  '38254100',
  '382549',
  '38254900',
  '382550',
  '38255000',
  '382561',
  '38256100',
  '382569',
  '38256900',
  '382590',
  '38259000',
  '3826',
  '382600',
  '38260000',
  '3827',
  '382711',
  '38271100',
  '382712',
  '38271200',
  '382713',
  '38271300',
  '382714',
  '38271400',
  '382720',
  '38272000',
  '382731',
  '38273100',
  '382732',
  '38273200',
  '382739',
  '38273900',
  '382740',
  '38274000',
  '382751',
  '38275100',
  '382759',
  '38275900',
  '382761',
  '38276100',
  '382762',
  '38276200',
  '382763',
  '38276300',
  '382764',
  '38276400',
  '382765',
  '38276500',
  '382768',
  '38276800',
  '382769',
  '38276900',
  '382790',
  '38279000',
  '39',
  '3901',
  '390110',
  '39011010',
  '39011020',
  '39011090',
  '390120',
  '39012000',
  '390130',
  '39013000',
  '390140',
  '39014010',
  '39014090',
  '390190',
  '39019000',
  '39019010',
  '39019090',
  '3902',
  '390210',
  '39021000',
  '390220',
  '39022000',
  '390230',
  '39023000',
  '390290',
  '39029000',
  '3903',
  '390311',
  '39031100',
  '390319',
  '39031910',
  '39031990',
  '390320',
  '39032000',
  '390330',
  '39033000',
  '390390',
  '39039010',
  '39039020',
  '39039090',
  '3904',
  '390410',
  '39041010',
  '39041020',
  '39041090',
  '390421',
  '39042100',
  '39042110',
  '39042190',
  '390422',
  '39042200',
  '39042210',
  '39042290',
  '390430',
  '39043010',
  '39043090',
  '390440',
  '39044000',
  '390450',
  '39045010',
  '39045090',
  '390461',
  '39046100',
  '390469',
  '39046910',
  '39046990',
  '390490',
  '39049000',
  '39049010',
  '39049090',
  '3905',
  '390512',
  '39051210',
  '39051220',
  '39051290',
  '390519',
  '39051910',
  '39051920',
  '39051990',
  '390521',
  '39052100',
  '390529',
  '39052900',
  '390530',
  '39053000',
  '390591',
  '39059100',
  '390599',
  '39059910',
  '39059990',
  '3906',
  '390610',
  '39061010',
  '39061090',
  '390690',
  '39069010',
  '39069020',
  '39069030',
  '39069040',
  '39069050',
  '39069060',
  '39069070',
  '39069090',
  '3907',
  '390710',
  '39071000',
  '390720',
  '39072010',
  '39072090',
  '390721',
  '39072100',
  '390729',
  '39072910',
  '39072990',
  '390730',
  '39073010',
  '39073090',
  '390740',
  '39074000',
  '390750',
  '39075000',
  '390760',
  '39076010',
  '39076020',
  '39076090',
  '390761',
  '39076110',
  '39076190',
  '390769',
  '39076930',
  '39076990',
  '390770',
  '39077000',
  '390791',
  '39079110',
  '39079120',
  '39079130',
  '39079140',
  '39079150',
  '39079190',
  '390799',
  '39079900',
  '39079910',
  '39079920',
  '39079990',
  '3908',
  '390810',
  '39081010',
  '39081011',
  '39081019',
  '39081021',
  '39081029',
  '39081031',
  '39081039',
  '39081041',
  '39081049',
  '39081051',
  '39081059',
  '39081061',
  '39081069',
  '39081071',
  '39081079',
  '39081090',
  '390890',
  '39089000',
  '39089010',
  '39089020',
  '39089090',
  '3909',
  '390910',
  '39091010',
  '39091090',
  '390920',
  '39092010',
  '39092090',
  '390930',
  '39093010',
  '39093090',
  '390931',
  '39093100',
  '390939',
  '39093910',
  '39093990',
  '390940',
  '39094010',
  '39094020',
  '39094030',
  '39094040',
  '39094050',
  '39094060',
  '39094090',
  '390950',
  '39095000',
  '3910',
  '391000',
  '39100010',
  '39100020',
  '39100090',
  '3911',
  '391110',
  '39111010',
  '39111090',
  '391120',
  '39112000',
  '391190',
  '39119010',
  '39119090',
  '3912',
  '391211',
  '39121110',
  '39121120',
  '39121130',
  '39121140',
  '39121190',
  '391212',
  '39121210',
  '39121220',
  '39121230',
  '39121290',
  '391220',
  '39122011',
  '39122019',
  '39122021',
  '39122029',
  '391231',
  '39123100',
  '391239',
  '39123911',
  '39123912',
  '39123919',
  '39123921',
  '39123922',
  '39123929',
  '391290',
  '39129010',
  '39129020',
  '39129090',
  '3913',
  '391310',
  '39131010',
  '39131090',
  '391390',
  '39139011',
  '39139019',
  '39139020',
  '39139030',
  '39139090',
  '3914',
  '391400',
  '39140010',
  '39140020',
  '39140090',
  '3915',
  '391510',
  '39151000',
  '391520',
  '39152000',
  '391530',
  '39153010',
  '39153090',
  '391590',
  '39159010',
  '39159021',
  '39159029',
  '39159030',
  '39159041',
  '39159042',
  '39159049',
  '39159050',
  '39159061',
  '39159062',
  '39159063',
  '39159071',
  '39159072',
  '39159073',
  '39159074',
  '39159075',
  '39159079',
  '39159090',
  '3916',
  '391610',
  '39161010',
  '39161020',
  '39161090',
  '391620',
  '39162011',
  '39162019',
  '39162091',
  '39162099',
  '391690',
  '39169010',
  '39169021',
  '39169022',
  '39169023',
  '39169024',
  '39169025',
  '39169026',
  '39169027',
  '39169028',
  '39169031',
  '39169032',
  '39169040',
  '39169050',
  '39169060',
  '39169070',
  '39169080',
  '39169090',
  '3917',
  '391710',
  '39171010',
  '39171020',
  '391721',
  '39172110',
  '39172190',
  '391722',
  '39172200',
  '391723',
  '39172310',
  '39172390',
  '391729',
  '39172910',
  '39172920',
  '39172930',
  '39172940',
  '39172950',
  '39172990',
  '391731',
  '39173100',
  '391732',
  '39173210',
  '39173220',
  '39173290',
  '391733',
  '39173300',
  '391739',
  '39173910',
  '39173920',
  '39173990',
  '391740',
  '39174000',
  '3918',
  '391810',
  '39181010',
  '39181090',
  '391890',
  '39189010',
  '39189020',
  '39189090',
  '3919',
  '391910',
  '39191000',
  '391990',
  '39199010',
  '39199020',
  '39199090',
  '3920',
  '392010',
  '39201011',
  '39201012',
  '39201013',
  '39201019',
  '39201091',
  '39201092',
  '39201099',
  '392020',
  '39202010',
  '39202020',
  '39202090',
  '392030',
  '39203010',
  '39203020',
  '39203090',
  '392043',
  '39204300',
  '392049',
  '39204900',
  '392051',
  '39205111',
  '39205112',
  '39205119',
  '39205191',
  '39205192',
  '39205199',
  '392059',
  '39205911',
  '39205912',
  '39205919',
  '39205991',
  '39205992',
  '39205999',
  '392061',
  '39206110',
  '39206120',
  '39206190',
  '392062',
  '39206210',
  '39206220',
  '39206290',
  '392063',
  '39206310',
  '39206320',
  '39206390',
  '392069',
  '39206911',
  '39206912',
  '39206919',
  '39206921',
  '39206922',
  '39206929',
  '39206931',
  '39206932',
  '39206939',
  '39206991',
  '39206992',
  '39206999',
  '392071',
  '39207111',
  '39207119',
  '39207121',
  '39207129',
  '39207191',
  '39207192',
  '39207199',
  '39207210',
  '39207220',
  '39207290',
  '392073',
  '39207311',
  '39207312',
  '39207319',
  '39207321',
  '39207322',
  '39207329',
  '39207391',
  '39207392',
  '39207399',
  '392079',
  '39207911',
  '39207912',
  '39207919',
  '39207991',
  '39207992',
  '39207999',
  '392091',
  '39209110',
  '39209111',
  '39209112',
  '39209119',
  '39209120',
  '39209190',
  '392092',
  '39209211',
  '39209212',
  '39209219',
  '39209291',
  '39209292',
  '39209299',
  '392093',
  '39209310',
  '39209320',
  '39209390',
  '392094',
  '39209410',
  '39209420',
  '39209490',
  '392099',
  '39209911',
  '39209912',
  '39209919',
  '39209921',
  '39209922',
  '39209929',
  '39209931',
  '39209932',
  '39209939',
  '39209941',
  '39209942',
  '39209949',
  '39209951',
  '39209952',
  '39209959',
  '39209960',
  '39209991',
  '39209992',
  '39209999',
  '3921',
  '392111',
  '39211100',
  '392112',
  '39211200',
  '392113',
  '39211310',
  '39211390',
  '392114',
  '39211400',
  '392119',
  '39211900',
  '392190',
  '39219010',
  '39219021',
  '39219022',
  '39219023',
  '39219024',
  '39219025',
  '39219026',
  '39219029',
  '39219031',
  '39219032',
  '39219033',
  '39219034',
  '39219035',
  '39219036',
  '39219039',
  '39219091',
  '39219092',
  '39219093',
  '39219094',
  '39219095',
  '39219096',
  '39219099',
  '3922',
  '392210',
  '39221000',
  '392220',
  '39222000',
  '392290',
  '39229000',
  '3923',
  '392310',
  '39231010',
  '39231020',
  '39231030',
  '39231040',
  '39231090',
  '392321',
  '39232100',
  '392329',
  '39232910',
  '39232990',
  '392330',
  '39233010',
  '39233090',
  '392340',
  '39234000',
  '392350',
  '39235010',
  '39235090',
  '392390',
  '39239010',
  '39239020',
  '39239090',
  '3924',
  '392410',
  '39241010',
  '39241090',
  '392490',
  '39249010',
  '39249020',
  '39249090',
  '3925',
  '392510',
  '39251000',
  '392520',
  '39252000',
  '392530',
  '39253000',
  '392590',
  '39259010',
  '39259090',
  '3926',
  '392610',
  '39261011',
  '39261019',
  '39261091',
  '39261099',
  '392620',
  '39262011',
  '39262019',
  '39262021',
  '39262029',
  '39262031',
  '39262039',
  '39262041',
  '39262049',
  '39262091',
  '39262099',
  '392630',
  '39263010',
  '39263090',
  '392640',
  '39264011',
  '39264019',
  '39264021',
  '39264029',
  '39264031',
  '39264039',
  '39264041',
  '39264049',
  '39264051',
  '39264059',
  '39264060',
  '39264091',
  '39264099',
  '392690',
  '39269010',
  '39269021',
  '39269029',
  '39269031',
  '39269039',
  '39269041',
  '39269049',
  '39269051',
  '39269059',
  '39269061',
  '39269069',
  '39269071',
  '39269079',
  '39269080',
  '39269091',
  '39269099',
  '40',
  '4001',
  '400110',
  '40011010',
  '40011020',
  '400121',
  '40012100',
  '400122',
  '40012200',
  '400129',
  '40012910',
  '40012920',
  '40012930',
  '40012940',
  '40012990',
  '400130',
  '40013000',
  '4002',
  '400211',
  '40021100',
  '400219',
  '40021910',
  '40021920',
  '40021930',
  '40021990',
  '400220',
  '40022000',
  '400231',
  '40023100',
  '400239',
  '40023900',
  '400241',
  '40024100',
  '400249',
  '40024900',
  '400251',
  '40025100',
  '400259',
  '40025900',
  '400260',
  '40026000',
  '400270',
  '40027000',
  '400280',
  '40028010',
  '40028020',
  '40028090',
  '400291',
  '40029100',
  '400299',
  '40029910',
  '40029920',
  '40029990',
  '4003',
  '400300',
  '40030000',
  '4004',
  '400400',
  '40040000',
  '4005',
  '400510',
  '40051000',
  '400520',
  '40052010',
  '40052090',
  '400591',
  '40059110',
  '40059190',
  '400599',
  '40059910',
  '40059990',
  '4006',
  '400610',
  '40061000',
  '400690',
  '40069010',
  '40069090',
  '4007',
  '400700',
  '40070010',
  '40070020',
  '40070090',
  '4008',
  '400811',
  '40081110',
  '40081190',
  '400819',
  '40081910',
  '40081990',
  '400821',
  '40082110',
  '40082120',
  '40082190',
  '400829',
  '40082910',
  '40082920',
  '40082930',
  '40082940',
  '40082990',
  '4009',
  '400911',
  '40091100',
  '400912',
  '40091200',
  '400921',
  '40092100',
  '400922',
  '40092200',
  '400931',
  '40093100',
  '400932',
  '40093200',
  '400941',
  '40094100',
  '400942',
  '40094200',
  '4010',
  '401011',
  '40101110',
  '40101190',
  '401012',
  '40101210',
  '40101290',
  '40101310',
  '40101390',
  '401019',
  '40101910',
  '40101990',
  '401031',
  '40103110',
  '40103190',
  '401032',
  '40103210',
  '40103290',
  '401033',
  '40103310',
  '40103390',
  '401034',
  '40103410',
  '40103490',
  '401035',
  '40103510',
  '40103590',
  '401036',
  '40103610',
  '40103690',
  '401039',
  '40103911',
  '40103912',
  '40103919',
  '40103991',
  '40103992',
  '40103999',
  '4011',
  '401110',
  '40111010',
  '40111090',
  '401120',
  '40112010',
  '40112090',
  '401130',
  '40113000',
  '401140',
  '40114010',
  '40114020',
  '40114090',
  '401150',
  '40115010',
  '40115090',
  '40116100',
  '40116200',
  '40116300',
  '40116900',
  '401170',
  '40117000',
  '401180',
  '40118000',
  '401190',
  '40119000',
  '40119200',
  '40119300',
  '40119400',
  '40119900',
  '4012',
  '401211',
  '40121100',
  '401212',
  '40121200',
  '401213',
  '40121300',
  '401219',
  '40121910',
  '40121990',
  '401220',
  '40122010',
  '40122020',
  '40122090',
  '401290',
  '40129010',
  '40129020',
  '40129030',
  '40129041',
  '40129049',
  '40129050',
  '40129090',
  '4013',
  '401310',
  '40131010',
  '40131020',
  '401320',
  '40132000',
  '401390',
  '40139010',
  '40139020',
  '40139030',
  '40139041',
  '40139049',
  '40139050',
  '40139090',
  '4014',
  '401410',
  '40141010',
  '40141020',
  '401490',
  '40149010',
  '40149020',
  '40149030',
  '40149090',
  '4015',
  '40151100',
  '401512',
  '40151200',
  '401519',
  '40151900',
  '401590',
  '40159010',
  '40159020',
  '40159030',
  '40159091',
  '40159099',
  '4016',
  '401610',
  '40161000',
  '401691',
  '40169100',
  '401692',
  '40169200',
  '401693',
  '40169310',
  '40169320',
  '40169330',
  '40169340',
  '40169350',
  '40169360',
  '40169390',
  '401694',
  '40169400',
  '401695',
  '40169510',
  '40169590',
  '401699',
  '40169910',
  '40169920',
  '40169930',
  '40169940',
  '40169950',
  '40169960',
  '40169970',
  '40169980',
  '40169990',
  '4017',
  '401700',
  '40170010',
  '40170020',
  '40170030',
  '40170040',
  '40170050',
  '40170090',
  '40210',
  '4022',
  '4081',
  '4089',
  '41',
  '4101',
  '410120',
  '41012010',
  '41012020',
  '41012090',
  '410150',
  '41015010',
  '41015020',
  '41015090',
  '410190',
  '41019010',
  '41019020',
  '41019090',
  '4102',
  '410210',
  '41021010',
  '41021020',
  '41021030',
  '410221',
  '41022110',
  '41022120',
  '41022130',
  '410229',
  '41022910',
  '41022920',
  '4103',
  '41031010',
  '41031020',
  '41031030',
  '41031040',
  '41031090',
  '410320',
  '41032000',
  '410330',
  '41033000',
  '410390',
  '41039000',
  '4104',
  '410411',
  '41041100',
  '410419',
  '41041900',
  '410441',
  '41044100',
  '410449',
  '41044900',
  '4105',
  '410510',
  '41051000',
  '410530',
  '41053000',
  '4106',
  '410621',
  '41062100',
  '410622',
  '41062200',
  '410631',
  '41063100',
  '410632',
  '41063200',
  '410640',
  '41064000',
  '410691',
  '41069100',
  '410692',
  '41069200',
  '4107',
  '410711',
  '41071100',
  '410712',
  '41071200',
  '410719',
  '41071900',
  '410791',
  '41079100',
  '410792',
  '41079200',
  '410799',
  '41079900',
  '4112',
  '411200',
  '41120000',
  '4113',
  '411310',
  '41131000',
  '411320',
  '41132000',
  '411330',
  '41133000',
  '411390',
  '41139000',
  '4114',
  '411410',
  '41141000',
  '411420',
  '41142010',
  '41142020',
  '4115',
  '411510',
  '41151000',
  '411520',
  '41152010',
  '41152090',
  '42',
  '4201',
  '420100',
  '42010000',
  '4202',
  '420211',
  '42021110',
  '42021120',
  '42021130',
  '42021140',
  '42021150',
  '42021160',
  '42021170',
  '42021190',
  '420212',
  '42021210',
  '42021220',
  '42021230',
  '42021240',
  '42021250',
  '42021260',
  '42021270',
  '42021280',
  '42021290',
  '420219',
  '42021910',
  '42021920',
  '42021930',
  '42021940',
  '42021950',
  '42021960',
  '42021990',
  '420221',
  '42022110',
  '42022120',
  '42022190',
  '420222',
  '42022210',
  '42022220',
  '42022230',
  '42022240',
  '42022290',
  '420229',
  '42022910',
  '42022990',
  '420231',
  '42023110',
  '42023120',
  '42023190',
  '420232',
  '42023210',
  '42023290',
  '420239',
  '42023910',
  '42023990',
  '420291',
  '42029100',
  '420292',
  '42029200',
  '420299',
  '42029900',
  '4203',
  '420310',
  '42031010',
  '42031090',
  '420321',
  '42032110',
  '42032120',
  '420329',
  '42032910',
  '42032920',
  '42032930',
  '420330',
  '42033000',
  '420340',
  '42034010',
  '42034020',
  '42034090',
  '4204',
  '42040010',
  '42040020',
  '42040030',
  '42040040',
  '42040050',
  '42040060',
  '42040091',
  '42040099',
  '4205',
  '420500',
  '42050011',
  '42050019',
  '42050020',
  '42050090',
  '4206',
  '420600',
  '42060010',
  '42060090',
  '42061010',
  '42061090',
  '42069000',
  '43',
  '4301',
  '430110',
  '43011000',
  '430130',
  '43013000',
  '430160',
  '43016000',
  '43017000',
  '430180',
  '43018000',
  '430190',
  '43019000',
  '4302',
  '430211',
  '43021100',
  '43021300',
  '430219',
  '43021910',
  '43021920',
  '43021930',
  '43021940',
  '43021990',
  '430220',
  '43022000',
  '430230',
  '43023000',
  '4303',
  '430310',
  '43031010',
  '43031020',
  '43031090',
  '430390',
  '43039010',
  '43039020',
  '43039090',
  '4304',
  '430400',
  '43040011',
  '43040019',
  '43040020',
  '44',
  '4401',
  '440110',
  '44011010',
  '44011090',
  '440111',
  '44011110',
  '44011190',
  '440112',
  '44011210',
  '44011290',
  '440121',
  '44012100',
  '440122',
  '44012200',
  '440131',
  '44013100',
  '440132',
  '44013200',
  '440139',
  '44013900',
  '44014000',
  '440141',
  '44014100',
  '440149',
  '44014900',
  '4402',
  '44020010',
  '44020090',
  '440210',
  '44021000',
  '44021010',
  '440220',
  '44022010',
  '44022090',
  '440290',
  '44029000',
  '44029010',
  '44029090',
  '4403',
  '44031000',
  '440311',
  '44031100',
  '440312',
  '44031200',
  '440320',
  '44032010',
  '44032020',
  '44032090',
  '440321',
  '44032110',
  '44032120',
  '44032190',
  '440322',
  '44032210',
  '44032220',
  '44032290',
  '440323',
  '44032310',
  '44032320',
  '44032390',
  '440324',
  '44032410',
  '44032420',
  '44032490',
  '440325',
  '44032510',
  '44032520',
  '44032590',
  '440326',
  '44032610',
  '44032620',
  '44032690',
  '440341',
  '44034100',
  '440342',
  '44034200',
  '440349',
  '44034900',
  '44034910',
  '44034990',
  '440391',
  '44039100',
  '44039200',
  '440393',
  '44039300',
  '440394',
  '44039400',
  '440395',
  '44039500',
  '440396',
  '44039600',
  '440397',
  '44039700',
  '440398',
  '44039800',
  '440399',
  '44039911',
  '44039912',
  '44039913',
  '44039914',
  '44039915',
  '44039916',
  '44039917',
  '44039918',
  '44039919',
  '44039921',
  '44039922',
  '44039923',
  '44039924',
  '44039925',
  '44039926',
  '44039927',
  '44039928',
  '44039929',
  '44039990',
  '4404',
  '440410',
  '44041000',
  '440420',
  '44042010',
  '44042020',
  '44042090',
  '4405',
  '440500',
  '44050000',
  '4406',
  '44061000',
  '440611',
  '44061100',
  '440612',
  '44061200',
  '44069000',
  '440691',
  '44069100',
  '440692',
  '44069200',
  '4407',
  '440710',
  '44071010',
  '44071020',
  '44071090',
  '440711',
  '44071100',
  '440712',
  '44071200',
  '440713',
  '44071300',
  '440714',
  '44071400',
  '440719',
  '44071910',
  '44071990',
  '440721',
  '44072100',
  '440722',
  '44072200',
  '440723',
  '44072300',
  '44072400',
  '440725',
  '44072500',
  '440726',
  '44072600',
  '440727',
  '44072700',
  '440728',
  '44072800',
  '440729',
  '44072900',
  '44072910',
  '44072990',
  '440791',
  '44079100',
  '440792',
  '44079200',
  '440793',
  '44079300',
  '440794',
  '44079400',
  '440795',
  '44079500',
  '440796',
  '44079600',
  '440797',
  '44079700',
  '440799',
  '44079910',
  '44079920',
  '44079990',
  '4408',
  '440810',
  '44081010',
  '44081020',
  '44081030',
  '44081090',
  '440831',
  '44083110',
  '44083120',
  '44083130',
  '44083190',
  '440839',
  '44083910',
  '44083920',
  '44083930',
  '44083990',
  '440890',
  '44089010',
  '44089020',
  '44089090',
  '4409',
  '440910',
  '44091010',
  '44091020',
  '44091090',
  '44092010',
  '44092020',
  '44092090',
  '440921',
  '44092100',
  '440922',
  '44092200',
  '440929',
  '44092910',
  '44092920',
  '44092990',
  '4410',
  '441011',
  '44101110',
  '44101120',
  '44101130',
  '44101190',
  '441012',
  '44101210',
  '44101290',
  '441019',
  '44101900',
  '44102100',
  '44102900',
  '44103110',
  '44103120',
  '44103130',
  '44103190',
  '44103210',
  '44103220',
  '44103230',
  '44103290',
  '44103310',
  '44103320',
  '44103330',
  '44103390',
  '44103910',
  '44103920',
  '44103930',
  '44103990',
  '441090',
  '44109010',
  '44109011',
  '44109012',
  '44109019',
  '44109020',
  '44109030',
  '44109040',
  '44109050',
  '44109090',
  '44109091',
  '44109092',
  '44109093',
  '44109099',
  '4411',
  '44111110',
  '44111190',
  '441112',
  '44111200',
  '441113',
  '44111300',
  '441114',
  '44111400',
  '44111910',
  '44111990',
  '44112110',
  '44112190',
  '44112910',
  '44112990',
  '44113110',
  '44113190',
  '44113910',
  '44113990',
  '44119110',
  '44119120',
  '44119130',
  '44119190',
  '441192',
  '44119211',
  '44119219',
  '44119221',
  '44119229',
  '441193',
  '44119311',
  '44119319',
  '44119321',
  '44119329',
  '441194',
  '44119411',
  '44119419',
  '44119421',
  '44119422',
  '44119423',
  '44119429',
  '44119910',
  '44119920',
  '44119930',
  '44119990',
  '4412',
  '44121000',
  '44121310',
  '44121320',
  '44121330',
  '44121340',
  '44121350',
  '44121390',
  '44121410',
  '44121420',
  '44121430',
  '44121440',
  '44121490',
  '44121910',
  '44121920',
  '44121930',
  '44121940',
  '44121990',
  '44122210',
  '44122220',
  '44122230',
  '44122240',
  '44122290',
  '44122310',
  '44122320',
  '44122330',
  '44122340',
  '44122390',
  '44122910',
  '44122920',
  '44122930',
  '44122940',
  '44122950',
  '44122990',
  '441231',
  '44123110',
  '44123120',
  '44123130',
  '44123140',
  '44123150',
  '44123190',
  '441232',
  '44123210',
  '44123220',
  '44123230',
  '44123240',
  '44123290',
  '441233',
  '44123310',
  '44123320',
  '44123330',
  '44123340',
  '44123390',
  '441234',
  '44123410',
  '44123420',
  '44123430',
  '44123440',
  '44123490',
  '441239',
  '44123910',
  '44123920',
  '44123930',
  '44123940',
  '44123990',
  '441241',
  '44124100',
  '441242',
  '44124200',
  '441249',
  '44124900',
  '441251',
  '44125100',
  '441252',
  '44125200',
  '441259',
  '44125900',
  '441291',
  '44129110',
  '44129120',
  '44129130',
  '44129140',
  '44129190',
  '441292',
  '44129210',
  '44129220',
  '44129230',
  '44129240',
  '44129250',
  '44129290',
  '44129310',
  '44129320',
  '44129330',
  '44129340',
  '44129390',
  '44129400',
  '441299',
  '44129910',
  '44129920',
  '44129930',
  '44129940',
  '44129950',
  '44129990',
  '4413',
  '441300',
  '44130000',
  '4414',
  '44140000',
  '441410',
  '44141000',
  '441490',
  '44149000',
  '4415',
  '441510',
  '44151000',
  '441520',
  '44152000',
  '4416',
  '441600',
  '44160010',
  '44160020',
  '44160091',
  '44160099',
  '4417',
  '441700',
  '44170000',
  '4418',
  '44181000',
  '441811',
  '44181100',
  '441819',
  '44181900',
  '441820',
  '44182010',
  '44182020',
  '44182090',
  '441821',
  '44182110',
  '44182120',
  '44182190',
  '441829',
  '44182910',
  '44182920',
  '44182990',
  '441830',
  '44183000',
  '441840',
  '44184000',
  '441850',
  '44185000',
  '44186000',
  '44187100',
  '44187200',
  '441873',
  '44187300',
  '441874',
  '44187400',
  '441875',
  '44187500',
  '441879',
  '44187900',
  '441881',
  '44188100',
  '441882',
  '44188200',
  '441883',
  '44188300',
  '441889',
  '44188900',
  '44189000',
  '441891',
  '44189100',
  '441892',
  '44189200',
  '441899',
  '44189900',
  '4419',
  '441900',
  '44190010',
  '44190020',
  '441911',
  '44191100',
  '441912',
  '44191200',
  '441919',
  '44191900',
  '441920',
  '44192000',
  '441990',
  '44199010',
  '44199020',
  '44199090',
  '4420',
  '44201000',
  '442011',
  '44201100',
  '442019',
  '44201900',
  '442090',
  '44209010',
  '44209090',
  '4421',
  '442110',
  '44211000',
  '442120',
  '44212000',
  '442190',
  '44219011',
  '44219012',
  '44219013',
  '44219014',
  '44219019',
  '44219020',
  '44219030',
  '44219040',
  '44219050',
  '44219060',
  '44219070',
  '44219090',
  '442191',
  '44219111',
  '44219112',
  '44219113',
  '44219114',
  '44219119',
  '44219120',
  '44219130',
  '44219140',
  '44219150',
  '44219160',
  '44219170',
  '44219190',
  '442199',
  '44219911',
  '44219912',
  '44219913',
  '44219914',
  '44219919',
  '44219920',
  '44219930',
  '44219940',
  '44219950',
  '44219960',
  '44219970',
  '44219990',
  '44229112',
  '44239113',
  '44249114',
  '45',
  '4501',
  '450110',
  '45011000',
  '450190',
  '45019000',
  '4502',
  '450200',
  '45020000',
  '4503',
  '450310',
  '45031000',
  '450390',
  '45039010',
  '45039090',
  '4504',
  '450410',
  '45041010',
  '45041020',
  '45041090',
  '450490',
  '45049000',
  '46',
  '4601',
  '46012010',
  '46012020',
  '46012090',
  '460121',
  '46012100',
  '460122',
  '46012200',
  '460129',
  '46012900',
  '46019100',
  '460192',
  '46019200',
  '460193',
  '46019300',
  '460194',
  '46019400',
  '460199',
  '46019900',
  '4602',
  '46021011',
  '46021019',
  '46021090',
  '460211',
  '46021100',
  '460212',
  '46021200',
  '460219',
  '46021911',
  '46021919',
  '46021990',
  '460290',
  '46029000',
  '47',
  '4701',
  '470100',
  '47010000',
  '4702',
  '470200',
  '47020000',
  '4703',
  '470311',
  '47031100',
  '470319',
  '47031900',
  '470321',
  '47032100',
  '470329',
  '47032900',
  '4704',
  '470411',
  '47041100',
  '470419',
  '47041900',
  '470421',
  '47042100',
  '470429',
  '47042900',
  '4705',
  '470500',
  '47050000',
  '4706',
  '470610',
  '47061000',
  '470620',
  '47062000',
  '470630',
  '47063000',
  '470691',
  '47069100',
  '470692',
  '47069200',
  '470693',
  '47069300',
  '4707',
  '470710',
  '47071000',
  '470720',
  '47072000',
  '470730',
  '47073000',
  '470790',
  '47079000',
  '48',
  '4801',
  '480100',
  '48010010',
  '48010090',
  '4802',
  '480210',
  '48021010',
  '48021020',
  '480220',
  '48022010',
  '48022090',
  '48023000',
  '480240',
  '48024000',
  '480254',
  '48025410',
  '48025420',
  '48025430',
  '48025440',
  '48025450',
  '48025490',
  '480255',
  '48025510',
  '48025520',
  '48025530',
  '48025540',
  '48025550',
  '48025560',
  '48025570',
  '48025590',
  '480256',
  '48025610',
  '48025620',
  '48025630',
  '48025640',
  '48025650',
  '48025660',
  '48025670',
  '48025690',
  '480257',
  '48025710',
  '48025720',
  '48025730',
  '48025740',
  '48025750',
  '48025760',
  '48025770',
  '48025790',
  '480258',
  '48025810',
  '48025820',
  '48025830',
  '48025840',
  '48025850',
  '48025890',
  '480261',
  '48026110',
  '48026120',
  '48026130',
  '48026140',
  '48026150',
  '48026160',
  '48026190',
  '480262',
  '48026210',
  '48026220',
  '48026230',
  '48026240',
  '48026250',
  '48026260',
  '48026290',
  '480269',
  '48026910',
  '48026920',
  '48026930',
  '48026940',
  '48026950',
  '48026960',
  '48026990',
  '4803',
  '480300',
  '48030010',
  '48030090',
  '4804',
  '480411',
  '48041100',
  '480419',
  '48041900',
  '480421',
  '48042100',
  '480429',
  '48042900',
  '480431',
  '48043100',
  '480439',
  '48043900',
  '480441',
  '48044100',
  '480442',
  '48044200',
  '480449',
  '48044900',
  '480451',
  '48045100',
  '480452',
  '48045200',
  '480459',
  '48045900',
  '4805',
  '480511',
  '48051100',
  '480512',
  '48051200',
  '480519',
  '48051900',
  '480524',
  '48052400',
  '480525',
  '48052500',
  '480530',
  '48053000',
  '480540',
  '48054000',
  '480550',
  '48055000',
  '480591',
  '48059100',
  '480592',
  '48059200',
  '480593',
  '48059300',
  '4806',
  '480610',
  '48061000',
  '480620',
  '48062000',
  '480630',
  '48063000',
  '480640',
  '48064010',
  '48064090',
  '4807',
  '480700',
  '48070010',
  '48070090',
  '4808',
  '480810',
  '48081000',
  '480840',
  '48084010',
  '48084090',
  '480890',
  '48089000',
  '4809',
  '48091010',
  '48091090',
  '480920',
  '48092000',
  '480990',
  '48099000',
  '4810',
  '481013',
  '48101310',
  '48101320',
  '48101330',
  '48101390',
  '481014',
  '48101410',
  '48101420',
  '48101430',
  '48101490',
  '481019',
  '48101910',
  '48101920',
  '48101930',
  '48101990',
  '481022',
  '48102200',
  '481029',
  '48102900',
  '481031',
  '48103100',
  '481032',
  '48103200',
  '481039',
  '48103910',
  '48103920',
  '48103930',
  '48103990',
  '481092',
  '48109200',
  '481099',
  '48109900',
  '4811',
  '481110',
  '48111000',
  '481141',
  '48114100',
  '481149',
  '48114900',
  '481151',
  '48115110',
  '48115190',
  '481159',
  '48115910',
  '48115990',
  '481160',
  '48116000',
  '481190',
  '48119011',
  '48119012',
  '48119013',
  '48119014',
  '48119015',
  '48119016',
  '48119017',
  '48119018',
  '48119091',
  '48119093',
  '48119094',
  '48119095',
  '48119096',
  '48119099',
  '4812',
  '481200',
  '48120000',
  '4813',
  '481310',
  '48131000',
  '481320',
  '48132000',
  '481390',
  '48139010',
  '48139090',
  '4814',
  '481420',
  '48142000',
  '48143000',
  '481490',
  '48149000',
  '4815',
  '48150000',
  '4816',
  '48161000',
  '481620',
  '48162010',
  '48162020',
  '48162090',
  '48163000',
  '481690',
  '48169010',
  '48169020',
  '48169090',
  '4817',
  '481710',
  '48171000',
  '481720',
  '48172000',
  '481730',
  '48173010',
  '48173090',
  '4818',
  '481810',
  '48181000',
  '481820',
  '48182000',
  '481830',
  '48183000',
  '481840',
  '481850',
  '48185000',
  '481890',
  '48189000',
  '4819',
  '481910',
  '48191010',
  '48191090',
  '481920',
  '48192010',
  '48192020',
  '48192090',
  '481930',
  '48193000',
  '481940',
  '48194000',
  '481950',
  '48195010',
  '48195090',
  '481960',
  '48196000',
  '4820',
  '482010',
  '48201010',
  '48201020',
  '48201090',
  '482020',
  '48202000',
  '482030',
  '48203000',
  '482040',
  '48204000',
  '482050',
  '48205000',
  '482090',
  '48209010',
  '48209090',
  '4821',
  '482110',
  '48211010',
  '48211020',
  '48211090',
  '482190',
  '48219010',
  '48219090',
  '4822',
  '482210',
  '48221000',
  '482290',
  '48229010',
  '48229090',
  '4823',
  '48231200',
  '48231900',
  '482320',
  '48232000',
  '482340',
  '48234000',
  '48236000',
  '482361',
  '48236100',
  '482369',
  '48236900',
  '482370',
  '48237010',
  '48237020',
  '48237030',
  '48237090',
  '482390',
  '48239011',
  '48239012',
  '48239013',
  '48239014',
  '48239015',
  '48239016',
  '48239017',
  '48239018',
  '48239019',
  '48239021',
  '48239022',
  '48239023',
  '48239030',
  '48239090',
  '49',
  '4901',
  '490110',
  '49011010',
  '49011020',
  '490191',
  '49019100',
  '490199',
  '49019900',
  '4902',
  '490210',
  '49021010',
  '49021020',
  '490290',
  '49029010',
  '49029020',
  '4903',
  '490300',
  '49030010',
  '49030020',
  '4904',
  '490400',
  '49040000',
  '4905',
  '49051000',
  '490520',
  '49052000',
  '490590',
  '49059010',
  '49059020',
  '49059090',
  '49059100',
  '490599',
  '49059910',
  '49059990',
  '4906',
  '490600',
  '49060000',
  '4907',
  '490700',
  '49070010',
  '49070020',
  '49070030',
  '49070090',
  '4908',
  '490810',
  '49081000',
  '490890',
  '49089000',
  '4909',
  '490900',
  '49090010',
  '49090090',
  '4910',
  '491000',
  '49100010',
  '49100090',
  '4911',
  '491110',
  '49111010',
  '49111020',
  '49111030',
  '49111090',
  '491191',
  '49119100',
  '491199',
  '49119910',
  '49119920',
  '49119990',
  '50',
  '5001',
  '500100',
  '50010000',
  '5002',
  '500200',
  '50020010',
  '50020020',
  '50020030',
  '5003',
  '500300',
  '50030010',
  '50030020',
  '50030030',
  '50030040',
  '50030090',
  '50031010',
  '50031020',
  '50031090',
  '50039010',
  '50039020',
  '50039030',
  '50039040',
  '50039090',
  '5004',
  '500400',
  '50040010',
  '50040090',
  '5005',
  '500500',
  '50050011',
  '50050012',
  '50050021',
  '50050022',
  '5006',
  '500600',
  '50060011',
  '50060019',
  '50060021',
  '50060029',
  '50060031',
  '50060032',
  '50060033',
  '50060039',
  '50060090',
  '5007',
  '500710',
  '50071000',
  '500720',
  '50072010',
  '50072090',
  '500790',
  '50079010',
  '50079090',
  '504004',
  '504005',
  '505902',
  '505903',
  '505909',
  '506101',
  '506102',
  '506103',
  '506104',
  '506901',
  '506909',
  '51',
  '5100',
  '5101',
  '510111',
  '51011100',
  '510119',
  '51011900',
  '510121',
  '51012100',
  '510129',
  '51012900',
  '510130',
  '51013000',
  '5102',
  '510211',
  '51021110',
  '51021190',
  '510219',
  '51021910',
  '51021990',
  '510220',
  '51022010',
  '51022090',
  '5103',
  '510310',
  '51031010',
  '51031090',
  '510320',
  '51032010',
  '51032020',
  '51032090',
  '510330',
  '51033000',
  '5104',
  '510400',
  '51040010',
  '51040090',
  '5105',
  '510510',
  '51051000',
  '510521',
  '51052100',
  '510529',
  '51052910',
  '51052990',
  '510531',
  '51053100',
  '510539',
  '51053900',
  '510540',
  '51054000',
  '5106',
  '510610',
  '51061010',
  '51061020',
  '51061090',
  '510620',
  '51062010',
  '51062020',
  '51062090',
  '5107',
  '510710',
  '51071010',
  '51071020',
  '51071030',
  '51071040',
  '51071090',
  '510720',
  '51072010',
  '51072020',
  '51072030',
  '51072040',
  '51072090',
  '5108',
  '510810',
  '51081000',
  '510820',
  '51082000',
  '5109',
  '510910',
  '51091010',
  '51091090',
  '510990',
  '51099000',
  '5110',
  '511000',
  '51100010',
  '51100020',
  '5111',
  '511111',
  '51111110',
  '51111120',
  '51111130',
  '51111140',
  '51111190',
  '511119',
  '51111910',
  '51111920',
  '51111930',
  '51111940',
  '51111990',
  '511120',
  '51112010',
  '51112020',
  '51112030',
  '51112040',
  '51112090',
  '511130',
  '51113010',
  '51113020',
  '51113030',
  '51113040',
  '51113090',
  '511190',
  '51119010',
  '51119020',
  '51119030',
  '51119040',
  '51119090',
  '5112',
  '511211',
  '51121110',
  '51121120',
  '51121130',
  '51121140',
  '51121190',
  '511219',
  '51121910',
  '51121920',
  '51121930',
  '51121940',
  '51121990',
  '511220',
  '51122010',
  '51122020',
  '51122030',
  '51122040',
  '51122090',
  '511230',
  '51123010',
  '51123020',
  '51123030',
  '51123040',
  '51123090',
  '511290',
  '51129010',
  '51129020',
  '51129030',
  '51129040',
  '51129050',
  '51129090',
  '5113',
  '511300',
  '51130010',
  '51130020',
  '51130030',
  '51130040',
  '51130090',
  '5119110',
  '511991',
  '511992',
  '511999',
  '52',
  '5201',
  '520100',
  '52010011',
  '52010012',
  '52010013',
  '52010014',
  '52010015',
  '52010019',
  '52010020',
  '52010021',
  '52010022',
  '52010023',
  '52010024',
  '52010025',
  '5202',
  '520210',
  '52021000',
  '520291',
  '52029100',
  '520299',
  '52029900',
  '5203',
  '520300',
  '52030000',
  '5204',
  '520411',
  '52041110',
  '52041120',
  '52041130',
  '52041140',
  '52041190',
  '520419',
  '52041900',
  '520420',
  '52042010',
  '52042020',
  '52042030',
  '52042040',
  '52042090',
  '5205',
  '520511',
  '52051110',
  '52051120',
  '52051130',
  '52051190',
  '520512',
  '52051210',
  '52051220',
  '52051230',
  '52051290',
  '520513',
  '52051310',
  '52051320',
  '52051330',
  '52051390',
  '520514',
  '52051410',
  '52051420',
  '52051430',
  '52051490',
  '520515',
  '52051510',
  '52051520',
  '52051530',
  '52051590',
  '520521',
  '52052110',
  '52052120',
  '52052130',
  '52052190',
  '520522',
  '52052210',
  '52052220',
  '52052290',
  '520523',
  '52052310',
  '52052320',
  '52052390',
  '520524',
  '52052410',
  '52052420',
  '52052490',
  '520526',
  '52052610',
  '52052620',
  '52052690',
  '520527',
  '52052710',
  '52052720',
  '52052790',
  '520528',
  '52052810',
  '52052820',
  '52052890',
  '520531',
  '52053110',
  '52053120',
  '52053130',
  '52053190',
  '520532',
  '52053210',
  '52053220',
  '52053290',
  '520533',
  '52053310',
  '52053320',
  '52053330',
  '52053390',
  '520534',
  '52053410',
  '52053420',
  '52053430',
  '52053490',
  '520535',
  '52053510',
  '52053590',
  '520541',
  '52054110',
  '52054120',
  '52054130',
  '52054190',
  '520542',
  '52054210',
  '52054290',
  '520543',
  '52054310',
  '52054320',
  '52054390',
  '520544',
  '52054410',
  '52054420',
  '52054490',
  '520546',
  '52054610',
  '52054620',
  '52054630',
  '52054690',
  '520547',
  '52054710',
  '52054720',
  '52054730',
  '52054790',
  '520548',
  '52054810',
  '52054820',
  '52054830',
  '52054890',
  '5206',
  '520611',
  '52061100',
  '520612',
  '52061200',
  '520613',
  '52061300',
  '520614',
  '52061400',
  '520615',
  '52061500',
  '520621',
  '52062100',
  '520622',
  '52062200',
  '520623',
  '52062300',
  '520624',
  '52062400',
  '520625',
  '52062500',
  '520631',
  '52063100',
  '520632',
  '52063200',
  '520633',
  '52063300',
  '520634',
  '52063400',
  '520635',
  '52063500',
  '520641',
  '52064100',
  '520642',
  '52064200',
  '520643',
  '52064300',
  '520644',
  '52064400',
  '520645',
  '52064500',
  '5207',
  '520710',
  '52071000',
  '520790',
  '52079000',
  '5208',
  '520811',
  '52081110',
  '52081120',
  '52081130',
  '52081140',
  '52081190',
  '520812',
  '52081210',
  '52081220',
  '52081230',
  '52081240',
  '52081250',
  '52081260',
  '52081290',
  '520813',
  '52081310',
  '52081320',
  '52081390',
  '520819',
  '52081910',
  '52081990',
  '520821',
  '52082110',
  '52082120',
  '52082130',
  '52082140',
  '52082150',
  '52082160',
  '52082170',
  '52082180',
  '52082190',
  '520822',
  '52082210',
  '52082220',
  '52082230',
  '52082240',
  '52082250',
  '52082260',
  '52082270',
  '52082280',
  '52082290',
  '520823',
  '52082310',
  '52082320',
  '52082330',
  '52082390',
  '520829',
  '52082910',
  '52082920',
  '52082990',
  '520831',
  '52083110',
  '52083121',
  '52083129',
  '52083130',
  '52083140',
  '52083150',
  '52083160',
  '52083170',
  '52083180',
  '52083190',
  '520832',
  '52083210',
  '52083220',
  '52083230',
  '52083240',
  '52083250',
  '52083260',
  '52083270',
  '52083280',
  '52083290',
  '520833',
  '52083310',
  '52083320',
  '52083330',
  '52083390',
  '520839',
  '52083910',
  '52083990',
  '520841',
  '52084110',
  '52084120',
  '52084121',
  '52084129',
  '52084130',
  '52084140',
  '52084150',
  '52084190',
  '520842',
  '52084210',
  '52084220',
  '52084230',
  '52084240',
  '52084250',
  '52084260',
  '52084290',
  '520843',
  '52084310',
  '52084320',
  '52084330',
  '52084340',
  '52084390',
  '520849',
  '52084910',
  '52084921',
  '52084929',
  '52084990',
  '520851',
  '52085110',
  '52085120',
  '52085130',
  '52085140',
  '52085150',
  '52085160',
  '52085170',
  '52085180',
  '52085190',
  '520852',
  '52085210',
  '52085220',
  '52085230',
  '52085240',
  '52085250',
  '52085260',
  '52085270',
  '52085280',
  '52085290',
  '52085310',
  '52085320',
  '52085390',
  '520859',
  '52085910',
  '52085920',
  '52085990',
  '5209',
  '520911',
  '52091111',
  '52091112',
  '52091113',
  '52091114',
  '52091119',
  '52091190',
  '520912',
  '52091210',
  '52091220',
  '52091230',
  '52091240',
  '52091250',
  '52091260',
  '52091270',
  '52091290',
  '520919',
  '52091900',
  '520921',
  '52092110',
  '52092120',
  '52092130',
  '52092140',
  '52092150',
  '52092160',
  '52092170',
  '52092180',
  '52092190',
  '520922',
  '52092210',
  '52092220',
  '52092230',
  '52092290',
  '520929',
  '52092910',
  '52092920',
  '52092990',
  '520931',
  '52093110',
  '52093120',
  '52093130',
  '52093140',
  '52093150',
  '52093160',
  '52093170',
  '52093180',
  '52093190',
  '520932',
  '52093210',
  '52093220',
  '52093230',
  '52093290',
  '520939',
  '52093910',
  '52093990',
  '520941',
  '52094110',
  '52094120',
  '52094130',
  '52094140',
  '52094150',
  '52094160',
  '52094170',
  '52094190',
  '520942',
  '52094200',
  '520943',
  '52094310',
  '52094320',
  '52094330',
  '52094340',
  '52094390',
  '520949',
  '52094910',
  '52094990',
  '520951',
  '52095111',
  '52095119',
  '52095120',
  '52095130',
  '52095140',
  '52095150',
  '52095160',
  '52095170',
  '52095190',
  '520952',
  '52095210',
  '52095220',
  '52095290',
  '520959',
  '52095910',
  '52095990',
  '5210',
  '521011',
  '52101110',
  '52101120',
  '52101190',
  '52101210',
  '52101290',
  '521019',
  '52101900',
  '521021',
  '52102110',
  '52102120',
  '52102130',
  '52102140',
  '52102150',
  '52102190',
  '52102211',
  '52102212',
  '52102219',
  '52102221',
  '52102229',
  '521029',
  '52102910',
  '52102920',
  '52102990',
  '521031',
  '52103110',
  '52103120',
  '52103130',
  '52103140',
  '52103150',
  '52103160',
  '52103190',
  '521032',
  '52103210',
  '52103220',
  '52103230',
  '52103239',
  '52103290',
  '521039',
  '52103910',
  '52103990',
  '521041',
  '52104110',
  '52104120',
  '52104130',
  '52104140',
  '52104150',
  '52104160',
  '52104170',
  '52104190',
  '52104210',
  '52104220',
  '52104230',
  '52104240',
  '52104250',
  '52104260',
  '52104290',
  '521049',
  '52104910',
  '52104990',
  '521051',
  '52105110',
  '52105120',
  '52105130',
  '52105140',
  '52105150',
  '52105190',
  '52105210',
  '52105220',
  '52105290',
  '521059',
  '52105910',
  '52105990',
  '5211',
  '521111',
  '52111110',
  '52111120',
  '52111190',
  '521112',
  '52111210',
  '52111220',
  '52111230',
  '52111290',
  '521119',
  '52111900',
  '521120',
  '52112010',
  '52112020',
  '52112030',
  '52112040',
  '52112050',
  '52112060',
  '52112091',
  '52112092',
  '52112099',
  '52112110',
  '52112120',
  '52112130',
  '52112140',
  '52112150',
  '52112190',
  '52112210',
  '52112220',
  '52112230',
  '52112290',
  '52112910',
  '52112920',
  '52112990',
  '521131',
  '52113110',
  '52113120',
  '52113130',
  '52113140',
  '52113150',
  '52113190',
  '521132',
  '52113210',
  '52113220',
  '52113230',
  '52113240',
  '52113290',
  '521139',
  '52113910',
  '52113990',
  '521141',
  '52114110',
  '52114120',
  '52114130',
  '52114140',
  '52114150',
  '52114160',
  '52114170',
  '52114190',
  '52114200',
  '521143',
  '52114310',
  '52114320',
  '52114330',
  '52114340',
  '52114390',
  '521149',
  '52114910',
  '52114990',
  '521151',
  '52115110',
  '52115120',
  '52115130',
  '52115140',
  '52115150',
  '52115190',
  '521152',
  '52115210',
  '52115220',
  '52115230',
  '52115290',
  '521159',
  '52115910',
  '52115990',
  '5212',
  '521211',
  '52121100',
  '521212',
  '52121200',
  '521213',
  '52121300',
  '521214',
  '52121400',
  '521215',
  '52121500',
  '521221',
  '52122100',
  '521222',
  '52122200',
  '521223',
  '52122300',
  '521224',
  '52122400',
  '521225',
  '52122500',
  '52805120',
  '53',
  '5301',
  '530110',
  '53011000',
  '530121',
  '53012100',
  '530129',
  '53012900',
  '530130',
  '53013000',
  '5302',
  '530210',
  '53021000',
  '530290',
  '53029000',
  '5303',
  '530310',
  '53031010',
  '53031090',
  '530390',
  '53039010',
  '53039090',
  '5304',
  '53041010',
  '53041020',
  '53041090',
  '53049000',
  '5305',
  '530500',
  '53050010',
  '53050030',
  '53050040',
  '53050050',
  '53050090',
  '53051110',
  '53051120',
  '53051130',
  '53051140',
  '53051190',
  '53051900',
  '53052100',
  '53052900',
  '53059010',
  '53059090',
  '5306',
  '530610',
  '53061010',
  '53061090',
  '530620',
  '53062010',
  '53062090',
  '5307',
  '530710',
  '53071010',
  '53071090',
  '530720',
  '53072000',
  '5308',
  '530810',
  '53081010',
  '53081020',
  '53081090',
  '530820',
  '53082000',
  '530890',
  '53089010',
  '53089090',
  '5309',
  '530911',
  '53091110',
  '53091120',
  '530919',
  '53091910',
  '53091920',
  '53091990',
  '530921',
  '53092110',
  '53092120',
  '530929',
  '53092910',
  '53092920',
  '53092990',
  '5310',
  '531010',
  '53101011',
  '53101012',
  '53101013',
  '53101014',
  '53101019',
  '53101091',
  '53101092',
  '53101093',
  '53101099',
  '531090',
  '53109010',
  '53109020',
  '53109091',
  '53109092',
  '53109093',
  '53109099',
  '5311',
  '531100',
  '53110011',
  '53110012',
  '53110013',
  '53110014',
  '53110015',
  '53110019',
  '53110021',
  '53110022',
  '53110023',
  '53110024',
  '53110029',
  '54',
  '5401',
  '540110',
  '54011000',
  '540120',
  '54012000',
  '5402',
  '54021010',
  '54021090',
  '540211',
  '54021100',
  '54021110',
  '540219',
  '54021910',
  '54021920',
  '54021990',
  '540220',
  '54022010',
  '54022090',
  '540231',
  '54023100',
  '540232',
  '54023200',
  '540233',
  '54023300',
  '540234',
  '54023400',
  '540239',
  '54023910',
  '54023920',
  '54023990',
  '54024100',
  '54024200',
  '54024300',
  '540244',
  '54024400',
  '540245',
  '54024500',
  '540246',
  '54024600',
  '540247',
  '54024700',
  '540248',
  '54024800',
  '540249',
  '54024900',
  '540251',
  '54025100',
  '540252',
  '54025200',
  '54025210',
  '54025290',
  '540253',
  '54025300',
  '540259',
  '54025900',
  '54025910',
  '54025990',
  '540261',
  '54026100',
  '540262',
  '54026200',
  '540263',
  '54026300',
  '540269',
  '54026910',
  '54026920',
  '54026930',
  '54026940',
  '54026950',
  '54026960',
  '54026990',
  '5403',
  '540310',
  '54031010',
  '54031020',
  '54031090',
  '54032000',
  '540331',
  '54033100',
  '540332',
  '54033200',
  '540333',
  '54033300',
  '540339',
  '54033910',
  '54033990',
  '540341',
  '54034110',
  '54034120',
  '54034130',
  '54034140',
  '54034150',
  '54034160',
  '54034170',
  '54034180',
  '54034190',
  '540342',
  '54034210',
  '54034220',
  '54034230',
  '54034240',
  '54034250',
  '54034290',
  '540349',
  '54034911',
  '54034912',
  '54034913',
  '54034914',
  '54034915',
  '54034919',
  '54034990',
  '5404',
  '54041000',
  '540411',
  '54041100',
  '540412',
  '54041200',
  '540419',
  '54041910',
  '54041920',
  '54041990',
  '540490',
  '54049010',
  '54049020',
  '54049090',
  '5405',
  '540500',
  '54050000',
  '5406',
  '540600',
  '54060010',
  '54060020',
  '54061000',
  '54062000',
  '5407',
  '540710',
  '54071011',
  '54071012',
  '54071013',
  '54071014',
  '54071015',
  '54071016',
  '54071019',
  '54071021',
  '54071022',
  '54071023',
  '54071024',
  '54071025',
  '54071026',
  '54071029',
  '54071031',
  '54071032',
  '54071033',
  '54071034',
  '54071035',
  '54071036',
  '54071039',
  '54071041',
  '54071042',
  '54071043',
  '54071044',
  '54071045',
  '54071046',
  '54071049',
  '54071091',
  '54071092',
  '54071093',
  '54071094',
  '54071095',
  '54071096',
  '54071099',
  '540720',
  '54072010',
  '54072020',
  '54072030',
  '54072040',
  '54072090',
  '540730',
  '54073010',
  '54073020',
  '54073030',
  '54073040',
  '54073090',
  '540741',
  '54074111',
  '54074112',
  '54074113',
  '54074114',
  '54074119',
  '54074121',
  '54074122',
  '54074123',
  '54074124',
  '54074129',
  '540742',
  '54074210',
  '54074220',
  '54074230',
  '54074240',
  '54074290',
  '540743',
  '54074300',
  '540744',
  '54074410',
  '54074420',
  '54074430',
  '54074440',
  '54074490',
  '540751',
  '54075111',
  '54075119',
  '54075121',
  '54075129',
  '540752',
  '54075210',
  '54075220',
  '54075230',
  '54075240',
  '54075290',
  '540753',
  '54075300',
  '540754',
  '54075410',
  '54075420',
  '54075430',
  '54075490',
  '540761',
  '54076110',
  '54076120',
  '54076190',
  '540769',
  '54076900',
  '540771',
  '54077110',
  '54077120',
  '540772',
  '54077200',
  '540773',
  '54077300',
  '540774',
  '54077400',
  '540781',
  '54078111',
  '54078112',
  '54078113',
  '54078114',
  '54078115',
  '54078116',
  '54078119',
  '54078121',
  '54078122',
  '54078123',
  '54078124',
  '54078125',
  '54078126',
  '54078129',
  '540782',
  '54078210',
  '54078220',
  '54078230',
  '54078240',
  '54078250',
  '54078260',
  '54078290',
  '540783',
  '54078300',
  '540784',
  '54078410',
  '54078420',
  '54078430',
  '54078440',
  '54078450',
  '54078460',
  '54078470',
  '54078490',
  '540791',
  '54079110',
  '54079120',
  '540792',
  '54079200',
  '540793',
  '54079300',
  '540794',
  '54079400',
  '5408',
  '540810',
  '54081000',
  '540821',
  '54082110',
  '54082120',
  '540822',
  '54082211',
  '54082212',
  '54082213',
  '54082214',
  '54082215',
  '54082216',
  '54082217',
  '54082218',
  '54082219',
  '54082220',
  '54082290',
  '540823',
  '54082300',
  '540824',
  '54082411',
  '54082412',
  '54082413',
  '54082414',
  '54082415',
  '54082416',
  '54082417',
  '54082418',
  '54082419',
  '54082490',
  '540831',
  '54083110',
  '54083120',
  '540832',
  '54083211',
  '54083212',
  '54083213',
  '54083214',
  '54083215',
  '54083219',
  '54083290',
  '540833',
  '54083300',
  '540834',
  '54083411',
  '54083412',
  '54083413',
  '54083414',
  '54083415',
  '54083416',
  '54083417',
  '54083418',
  '54083419',
  '54083420',
  '54083490',
  '55',
  '5501',
  '55011000',
  '550111',
  '55011100',
  '550119',
  '55011900',
  '550120',
  '55012000',
  '550130',
  '55013000',
  '550140',
  '55014000',
  '550190',
  '55019010',
  '55019090',
  '5502',
  '550200',
  '55020010',
  '55020020',
  '55020090',
  '550210',
  '55021010',
  '55021090',
  '550290',
  '55029010',
  '55029090',
  '5503',
  '55031000',
  '550311',
  '55031100',
  '55031110',
  '55031120',
  '55031190',
  '550319',
  '55031900',
  '55031910',
  '55031920',
  '55031930',
  '55031990',
  '550320',
  '55032000',
  '550330',
  '55033000',
  '55033010',
  '55033090',
  '550340',
  '55034000',
  '550390',
  '55039010',
  '55039020',
  '55039030',
  '55039090',
  '5504',
  '550410',
  '55041000',
  '55041010',
  '55041011',
  '55041019',
  '55041020',
  '55041021',
  '55041029',
  '55041090',
  '550490',
  '55049010',
  '55049020',
  '55049030',
  '55049090',
  '5505',
  '550510',
  '55051010',
  '55051090',
  '550520',
  '55052000',
  '5506',
  '550610',
  '55061000',
  '550620',
  '55062000',
  '550630',
  '55063000',
  '550640',
  '55064000',
  '550690',
  '55069010',
  '55069090',
  '5507',
  '550700',
  '55070010',
  '55070020',
  '55070030',
  '55070040',
  '55070090',
  '5508',
  '550810',
  '55081000',
  '550820',
  '55082000',
  '5509',
  '550911',
  '55091100',
  '550912',
  '55091200',
  '550921',
  '55092100',
  '550922',
  '55092200',
  '550931',
  '55093100',
  '550932',
  '55093200',
  '550941',
  '55094110',
  '55094120',
  '55094130',
  '55094190',
  '550942',
  '55094210',
  '55094220',
  '55094230',
  '55094290',
  '550951',
  '55095100',
  '550952',
  '55095200',
  '550953',
  '55095300',
  '550959',
  '55095900',
  '550961',
  '55096100',
  '550962',
  '55096200',
  '550969',
  '55096900',
  '550991',
  '55099100',
  '550992',
  '55099200',
  '550999',
  '55099900',
  '55099910',
  '55099990',
  '5510',
  '551011',
  '55101110',
  '55101120',
  '55101190',
  '551012',
  '55101210',
  '55101220',
  '55101290',
  '551020',
  '55102010',
  '55102020',
  '55102090',
  '551030',
  '55103010',
  '55103020',
  '55103090',
  '551090',
  '55109010',
  '55109020',
  '55109030',
  '55109090',
  '5511',
  '551110',
  '55111000',
  '551120',
  '55112000',
  '551130',
  '55113010',
  '55113090',
  '5512',
  '551211',
  '55121110',
  '55121120',
  '551219',
  '55121910',
  '55121920',
  '55121990',
  '551221',
  '55122110',
  '55122120',
  '551229',
  '55122910',
  '55122920',
  '55122990',
  '551291',
  '55129110',
  '55129120',
  '551299',
  '55129910',
  '55129920',
  '55129990',
  '5513',
  '551311',
  '55131110',
  '55131120',
  '551312',
  '55131210',
  '55131220',
  '551313',
  '55131310',
  '55131320',
  '551319',
  '55131910',
  '55131920',
  '551321',
  '55132100',
  '55132200',
  '551323',
  '55132300',
  '551329',
  '55132900',
  '551331',
  '55133100',
  '55133200',
  '55133300',
  '551339',
  '55133900',
  '551341',
  '55134100',
  '55134200',
  '55134300',
  '551349',
  '55134900',
  '5514',
  '551411',
  '55141110',
  '55141120',
  '551412',
  '55141210',
  '55141220',
  '55141310',
  '55141320',
  '551419',
  '55141910',
  '55141920',
  '551421',
  '55142100',
  '551422',
  '55142200',
  '551423',
  '55142300',
  '551429',
  '55142900',
  '551430',
  '55143011',
  '55143012',
  '55143013',
  '55143019',
  '55143100',
  '55143200',
  '55143300',
  '55143900',
  '551441',
  '55144100',
  '551442',
  '55144200',
  '551443',
  '55144300',
  '551449',
  '55144900',
  '5515',
  '551511',
  '55151110',
  '55151120',
  '55151130',
  '55151140',
  '55151190',
  '551512',
  '55151210',
  '55151220',
  '55151230',
  '55151240',
  '55151290',
  '551513',
  '55151310',
  '55151320',
  '55151330',
  '55151340',
  '55151390',
  '551519',
  '55151910',
  '55151920',
  '55151930',
  '55151940',
  '55151990',
  '551521',
  '55152110',
  '55152120',
  '55152130',
  '55152140',
  '55152190',
  '551522',
  '55152210',
  '55152220',
  '55152230',
  '55152240',
  '55152290',
  '551529',
  '55152910',
  '55152920',
  '55152930',
  '55152940',
  '55152990',
  '551591',
  '55159110',
  '55159120',
  '55159130',
  '55159140',
  '55159190',
  '55159210',
  '55159220',
  '55159230',
  '55159240',
  '55159290',
  '551599',
  '55159910',
  '55159920',
  '55159930',
  '55159940',
  '55159950',
  '55159990',
  '5516',
  '551611',
  '55161110',
  '55161120',
  '551612',
  '55161200',
  '551613',
  '55161300',
  '551614',
  '55161410',
  '55161420',
  '55161490',
  '551621',
  '55162110',
  '55162120',
  '551622',
  '55162200',
  '551623',
  '55162300',
  '551624',
  '55162400',
  '551631',
  '55163110',
  '55163120',
  '551632',
  '55163200',
  '551633',
  '55163300',
  '551634',
  '55163400',
  '551641',
  '55164110',
  '55164120',
  '551642',
  '55164200',
  '551643',
  '55164300',
  '551644',
  '55164400',
  '551691',
  '55169110',
  '55169120',
  '551692',
  '55169200',
  '551693',
  '55169300',
  '551694',
  '55169400',
  '56',
  '5601',
  '56011000',
  '560121',
  '56012110',
  '56012190',
  '560122',
  '56012200',
  '560129',
  '56012900',
  '560130',
  '56013000',
  '5602',
  '560210',
  '56021000',
  '560221',
  '56022100',
  '560229',
  '56022910',
  '56022920',
  '56022990',
  '560290',
  '56029010',
  '56029090',
  '5603',
  '560311',
  '56031100',
  '56031110',
  '56031190',
  '560312',
  '56031200',
  '560313',
  '56031300',
  '560314',
  '56031400',
  '560391',
  '56039100',
  '560392',
  '56039200',
  '560393',
  '56039300',
  '56039310',
  '56039390',
  '560394',
  '56039400',
  '56039410',
  '56039420',
  '56039490',
  '5604',
  '560410',
  '56041000',
  '56042010',
  '56042090',
  '560490',
  '56049000',
  '5605',
  '560500',
  '56050010',
  '56050020',
  '56050090',
  '5606',
  '560600',
  '56060010',
  '56060020',
  '56060030',
  '56060090',
  '5607',
  '56071010',
  '56071090',
  '560721',
  '56072100',
  '560729',
  '56072900',
  '56073000',
  '560741',
  '56074100',
  '560749',
  '56074900',
  '560750',
  '56075010',
  '56075020',
  '56075030',
  '56075040',
  '56075090',
  '560790',
  '56079010',
  '56079020',
  '56079090',
  '5608',
  '560811',
  '56081110',
  '56081190',
  '560819',
  '56081900',
  '560890',
  '56089010',
  '56089020',
  '56089090',
  '5609',
  '560900',
  '56090010',
  '56090020',
  '56090030',
  '56090090',
  '57',
  '5701',
  '570110',
  '57011000',
  '57011010',
  '57011090',
  '570190',
  '57019010',
  '57019011',
  '57019019',
  '57019020',
  '57019031',
  '57019039',
  '57019090',
  '5702',
  '570210',
  '57021000',
  '570220',
  '57022010',
  '57022020',
  '57022090',
  '570231',
  '57023110',
  '57023120',
  '57023130',
  '57023140',
  '57023190',
  '570232',
  '57023210',
  '57023220',
  '57023290',
  '570239',
  '57023910',
  '57023920',
  '57023990',
  '570241',
  '57024110',
  '57024120',
  '57024130',
  '57024140',
  '57024190',
  '570242',
  '57024210',
  '57024220',
  '57024230',
  '57024290',
  '570249',
  '57024910',
  '57024920',
  '57024990',
  '570250',
  '57025021',
  '57025022',
  '57025029',
  '57025031',
  '57025032',
  '57025033',
  '57025039',
  '57025110',
  '57025120',
  '57025130',
  '57025140',
  '57025210',
  '57025220',
  '57025290',
  '57025910',
  '57025920',
  '57025930',
  '57025990',
  '570291',
  '57029110',
  '57029120',
  '57029130',
  '57029140',
  '57029190',
  '570292',
  '57029210',
  '57029220',
  '57029290',
  '570299',
  '57029910',
  '57029920',
  '57029990',
  '5703',
  '570310',
  '57031010',
  '57031020',
  '57031090',
  '570320',
  '57032010',
  '57032020',
  '57032090',
  '570321',
  '57032100',
  '570329',
  '57032910',
  '57032920',
  '57032990',
  '570330',
  '57033010',
  '57033020',
  '57033090',
  '570331',
  '57033100',
  '570339',
  '57033910',
  '57033920',
  '57033990',
  '570390',
  '57039010',
  '57039020',
  '57039090',
  '5704',
  '570410',
  '57041000',
  '570420',
  '57042010',
  '57042020',
  '57042090',
  '570490',
  '57049010',
  '57049020',
  '57049090',
  '5705',
  '570500',
  '57050011',
  '57050019',
  '57050021',
  '57050022',
  '57050023',
  '57050024',
  '57050029',
  '57050031',
  '57050032',
  '57050039',
  '57050041',
  '57050042',
  '57050049',
  '57050090',
  '58',
  '5801',
  '580110',
  '58011000',
  '580121',
  '58012100',
  '580122',
  '58012210',
  '58012290',
  '580123',
  '58012300',
  '580126',
  '58012600',
  '580127',
  '58012710',
  '58012720',
  '58012790',
  '580131',
  '58013100',
  '580132',
  '58013200',
  '580133',
  '58013300',
  '580134',
  '580136',
  '58013610',
  '58013690',
  '580137',
  '58013710',
  '58013720',
  '58013790',
  '580190',
  '58019010',
  '58019090',
  '5802',
  '580210',
  '58021010',
  '58021020',
  '58021030',
  '58021040',
  '58021050',
  '58021060',
  '58021090',
  '58021100',
  '580219',
  '58021910',
  '58021920',
  '58021930',
  '58021940',
  '58021950',
  '58021990',
  '580220',
  '58022000',
  '580230',
  '58023000',
  '5803',
  '580300',
  '58030011',
  '58030012',
  '58030013',
  '58030014',
  '58030015',
  '58030019',
  '58030091',
  '58030092',
  '58030093',
  '58030099',
  '58031010',
  '58031020',
  '58031030',
  '58031040',
  '58031050',
  '58031090',
  '58039010',
  '58039020',
  '58039030',
  '58039090',
  '5804',
  '580410',
  '58041010',
  '58041090',
  '580421',
  '58042100',
  '580429',
  '58042910',
  '58042990',
  '580430',
  '58043000',
  '5805',
  '580500',
  '58050010',
  '58050020',
  '58050090',
  '5806',
  '580610',
  '58061000',
  '580620',
  '58062000',
  '580631',
  '58063110',
  '58063120',
  '58063190',
  '580632',
  '58063200',
  '580639',
  '58063910',
  '58063920',
  '58063930',
  '58063990',
  '580640',
  '58064000',
  '5807',
  '580710',
  '58071010',
  '58071020',
  '58071090',
  '580790',
  '58079010',
  '58079090',
  '5808',
  '580810',
  '58081010',
  '58081090',
  '580890',
  '58089010',
  '58089020',
  '58089030',
  '58089040',
  '58089050',
  '58089060',
  '58089090',
  '5809',
  '580900',
  '58090010',
  '58090090',
  '5810',
  '581010',
  '58101000',
  '581091',
  '58109100',
  '58109110',
  '58109190',
  '581092',
  '58109210',
  '58109220',
  '58109290',
  '581099',
  '58109900',
  '58109910',
  '58109990',
  '5811',
  '581100',
  '58110010',
  '58110020',
  '58110090',
  '59',
  '5901',
  '590110',
  '59011010',
  '59011020',
  '59011090',
  '590190',
  '59019010',
  '59019020',
  '59019090',
  '5902',
  '590210',
  '59021010',
  '59021090',
  '590220',
  '59022010',
  '59022090',
  '59023010',
  '59023090',
  '590290',
  '59029010',
  '59029090',
  '5903',
  '590310',
  '59031010',
  '59031090',
  '590320',
  '59032010',
  '59032090',
  '590390',
  '59039010',
  '59039020',
  '59039090',
  '5904',
  '590410',
  '59041000',
  '590490',
  '59049010',
  '59049090',
  '5905',
  '590500',
  '59050010',
  '59050090',
  '59051010',
  '59051090',
  '5906',
  '590610',
  '59061000',
  '590691',
  '59069110',
  '59069190',
  '590699',
  '59069910',
  '59069920',
  '59069990',
  '5907',
  '590700',
  '59070011',
  '59070012',
  '59070019',
  '59070091',
  '59070092',
  '59070093',
  '59070099',
  '5908',
  '590800',
  '59080010',
  '59080020',
  '59080090',
  '5909',
  '590900',
  '59090010',
  '59090020',
  '59090090',
  '5910',
  '591000',
  '59100010',
  '59100020',
  '59100030',
  '59100040',
  '59100050',
  '59100060',
  '59100090',
  '5911',
  '591110',
  '59111000',
  '591120',
  '59112000',
  '591131',
  '59113110',
  '59113120',
  '59113130',
  '59113140',
  '59113150',
  '59113190',
  '591132',
  '59113210',
  '59113220',
  '59113230',
  '59113240',
  '59113250',
  '59113290',
  '591140',
  '59114000',
  '591190',
  '59119010',
  '59119020',
  '59119031',
  '59119032',
  '59119039',
  '59119040',
  '59119090',
  '60',
  '6001',
  '600110',
  '60011010',
  '60011020',
  '60011090',
  '600121',
  '60012100',
  '600122',
  '60012200',
  '600129',
  '60012900',
  '600191',
  '60019100',
  '600192',
  '60019200',
  '600199',
  '60019910',
  '60019990',
  '6002',
  '600240',
  '60024000',
  '600290',
  '60029000',
  '6003',
  '600310',
  '60031000',
  '600320',
  '60032000',
  '600330',
  '60033000',
  '600340',
  '60034000',
  '600390',
  '60039000',
  '6004',
  '600410',
  '60041000',
  '600490',
  '60049000',
  '6005',
  '60051000',
  '600521',
  '60052100',
  '600522',
  '60052200',
  '600523',
  '60052300',
  '600524',
  '60052400',
  '60053100',
  '60053200',
  '60053300',
  '60053400',
  '600535',
  '60053500',
  '600536',
  '60053600',
  '600537',
  '60053700',
  '60053710',
  '60053790',
  '600538',
  '60053800',
  '600539',
  '60053900',
  '600541',
  '60054100',
  '600542',
  '60054200',
  '600543',
  '60054300',
  '600544',
  '60054400',
  '600590',
  '60059000',
  '6006',
  '600610',
  '60061000',
  '600621',
  '60062100',
  '600622',
  '60062200',
  '600623',
  '60062300',
  '600624',
  '60062400',
  '600631',
  '60063100',
  '600632',
  '60063200',
  '600633',
  '60063300',
  '600634',
  '60063400',
  '600641',
  '60064100',
  '600642',
  '60064200',
  '600643',
  '60064300',
  '600644',
  '60064400',
  '600690',
  '60069000',
  '601202',
  '6031',
  '6049',
  '61',
  '6101',
  '61011010',
  '61011020',
  '61011090',
  '610120',
  '61012000',
  '610130',
  '61013010',
  '61013020',
  '610190',
  '61019010',
  '61019090',
  '6102',
  '610210',
  '61021000',
  '610220',
  '61022000',
  '610230',
  '61023010',
  '61023020',
  '610290',
  '61029010',
  '61029090',
  '6103',
  '610310',
  '61031010',
  '61031020',
  '61031030',
  '61031090',
  '61031100',
  '61031200',
  '61031910',
  '61031920',
  '61031930',
  '61031990',
  '61032100',
  '610322',
  '61032200',
  '610323',
  '61032300',
  '610329',
  '61032910',
  '61032920',
  '61032990',
  '610331',
  '61033100',
  '610332',
  '61033200',
  '610333',
  '61033300',
  '610339',
  '61033910',
  '61033920',
  '61033990',
  '610341',
  '61034100',
  '610342',
  '61034200',
  '610343',
  '61034300',
  '610349',
  '61034910',
  '61034920',
  '61034990',
  '6104',
  '61041100',
  '61041200',
  '610413',
  '61041300',
  '610419',
  '61041910',
  '61041920',
  '61041990',
  '61042100',
  '610422',
  '61042200',
  '610423',
  '61042300',
  '610429',
  '61042910',
  '61042920',
  '61042990',
  '610431',
  '61043100',
  '610432',
  '61043200',
  '610433',
  '61043300',
  '610439',
  '61043910',
  '61043920',
  '61043990',
  '610441',
  '61044100',
  '610442',
  '61044200',
  '610443',
  '61044300',
  '610444',
  '61044400',
  '610449',
  '61044910',
  '61044990',
  '610451',
  '61045100',
  '610452',
  '61045200',
  '610453',
  '61045300',
  '610459',
  '61045910',
  '61045920',
  '61045990',
  '610461',
  '61046100',
  '610462',
  '61046200',
  '610463',
  '61046300',
  '610469',
  '61046910',
  '61046920',
  '61046990',
  '6105',
  '610510',
  '61051010',
  '61051020',
  '61051090',
  '610520',
  '61052010',
  '61052020',
  '610590',
  '61059010',
  '61059090',
  '6106',
  '610610',
  '61061000',
  '610620',
  '61062010',
  '61062020',
  '610690',
  '61069010',
  '61069020',
  '61069090',
  '6107',
  '610711',
  '61071100',
  '610712',
  '61071210',
  '61071220',
  '610719',
  '61071910',
  '61071990',
  '610721',
  '61072100',
  '610722',
  '61072210',
  '61072220',
  '610729',
  '61072910',
  '61072920',
  '61072990',
  '610791',
  '61079110',
  '61079190',
  '61079210',
  '61079220',
  '610799',
  '61079910',
  '61079920',
  '61079990',
  '6108',
  '610811',
  '61081110',
  '61081120',
  '610819',
  '61081910',
  '61081920',
  '61081990',
  '610821',
  '61082100',
  '610822',
  '61082210',
  '61082220',
  '610829',
  '61082910',
  '61082990',
  '610831',
  '61083100',
  '610832',
  '61083210',
  '61083220',
  '610839',
  '61083910',
  '61083990',
  '610891',
  '61089100',
  '610892',
  '61089210',
  '61089220',
  '610899',
  '61089910',
  '61089920',
  '61089990',
  '6109',
  '610910',
  '61091000',
  '610990',
  '61099010',
  '61099020',
  '61099030',
  '61099040',
  '61099090',
  '6110',
  '611011',
  '61101110',
  '61101120',
  '61101190',
  '611012',
  '61101200',
  '611019',
  '61101900',
  '611020',
  '61102000',
  '611030',
  '61103010',
  '61103020',
  '611090',
  '61109000',
  '6111',
  '61111000',
  '611120',
  '61112000',
  '611130',
  '61113000',
  '611190',
  '61119010',
  '61119020',
  '61119090',
  '6112',
  '611211',
  '61121100',
  '611212',
  '61121200',
  '611219',
  '61121910',
  '61121920',
  '61121930',
  '61121990',
  '611220',
  '61122010',
  '61122020',
  '61122030',
  '61122040',
  '61122050',
  '61122090',
  '611231',
  '61123100',
  '611239',
  '61123910',
  '61123920',
  '61123990',
  '611241',
  '61124100',
  '611249',
  '61124910',
  '61124920',
  '61124990',
  '6113',
  '611300',
  '61130000',
  '6114',
  '61141000',
  '611420',
  '61142000',
  '611430',
  '61143010',
  '61143020',
  '611490',
  '61149010',
  '61149090',
  '6115',
  '611510',
  '61151000',
  '61151100',
  '61151200',
  '61151910',
  '61151920',
  '61151930',
  '61151990',
  '61152010',
  '61152090',
  '611521',
  '61152100',
  '611522',
  '61152200',
  '611529',
  '61152910',
  '61152920',
  '61152930',
  '61152990',
  '611530',
  '61153000',
  '61159100',
  '61159200',
  '61159300',
  '611594',
  '61159400',
  '611595',
  '61159500',
  '611596',
  '61159600',
  '611599',
  '61159910',
  '61159990',
  '6116',
  '611610',
  '61161000',
  '611691',
  '61169100',
  '611692',
  '61169200',
  '611693',
  '61169300',
  '611699',
  '61169910',
  '61169990',
  '6117',
  '611710',
  '61171010',
  '61171020',
  '61171030',
  '61171040',
  '61171090',
  '61172010',
  '61172020',
  '61172030',
  '61172040',
  '61172090',
  '611780',
  '61178010',
  '61178020',
  '61178030',
  '61178040',
  '61178090',
  '611790',
  '61179000',
  '62',
  '6201',
  '62011100',
  '620112',
  '62011210',
  '62011290',
  '620113',
  '62011310',
  '62011390',
  '620119',
  '62011910',
  '62011990',
  '620120',
  '62012010',
  '62012090',
  '620130',
  '62013010',
  '62013090',
  '620140',
  '62014010',
  '62014090',
  '620190',
  '62019010',
  '62019090',
  '62019100',
  '62019200',
  '62019300',
  '620199',
  '62019910',
  '62019990',
  '6202',
  '620211',
  '62021110',
  '62021190',
  '62021200',
  '62021300',
  '620219',
  '62021910',
  '62021920',
  '62021990',
  '620220',
  '62022010',
  '62022090',
  '620230',
  '62023010',
  '62023090',
  '620240',
  '62024010',
  '62024090',
  '620290',
  '62029010',
  '62029090',
  '620291',
  '62029110',
  '62029190',
  '620292',
  '62029210',
  '62029290',
  '620293',
  '62029310',
  '62029390',
  '620299',
  '62029911',
  '62029919',
  '62029990',
  '6203',
  '620311',
  '62031100',
  '620312',
  '62031200',
  '620319',
  '62031910',
  '62031990',
  '62032100',
  '620322',
  '62032200',
  '620323',
  '62032300',
  '620329',
  '62032900',
  '62032911',
  '62032919',
  '62032990',
  '620331',
  '62033100',
  '62033110',
  '62033190',
  '620332',
  '62033200',
  '620333',
  '62033300',
  '620339',
  '62033910',
  '62033911',
  '62033919',
  '62033990',
  '620341',
  '62034100',
  '620342',
  '62034200',
  '62034210',
  '62034290',
  '620343',
  '62034300',
  '620349',
  '62034910',
  '62034990',
  '6204',
  '620411',
  '62041100',
  '620412',
  '62041200',
  '62041210',
  '62041290',
  '620413',
  '62041300',
  '62041310',
  '62041390',
  '620419',
  '62041911',
  '62041912',
  '62041919',
  '62041990',
  '62041991',
  '62041999',
  '620421',
  '62042100',
  '620422',
  '62042210',
  '62042220',
  '62042290',
  '620423',
  '62042300',
  '62042310',
  '62042390',
  '620429',
  '62042911',
  '62042912',
  '62042913',
  '62042919',
  '62042990',
  '62042991',
  '62042999',
  '620431',
  '62043100',
  '62043110',
  '62043190',
  '620432',
  '62043200',
  '62043210',
  '62043290',
  '620433',
  '62043300',
  '62043310',
  '62043390',
  '620439',
  '62043911',
  '62043912',
  '62043913',
  '62043919',
  '62043990',
  '62043991',
  '62043999',
  '620441',
  '62044110',
  '62044120',
  '62044190',
  '620442',
  '62044210',
  '62044220',
  '62044230',
  '62044290',
  '620443',
  '62044310',
  '62044320',
  '62044390',
  '620444',
  '62044400',
  '62044410',
  '62044490',
  '620449',
  '62044911',
  '62044912',
  '62044919',
  '62044990',
  '62044991',
  '62044999',
  '620451',
  '62045100',
  '620452',
  '62045200',
  '62045210',
  '62045290',
  '620453',
  '62045300',
  '62045310',
  '62045390',
  '620459',
  '62045910',
  '62045911',
  '62045919',
  '62045990',
  '62045991',
  '62045999',
  '620461',
  '62046110',
  '62046190',
  '620462',
  '62046200',
  '62046210',
  '62046290',
  '620463',
  '62046300',
  '620469',
  '62046911',
  '62046919',
  '62046990',
  '620499',
  '6205',
  '62051000',
  '620520',
  '62052000',
  '62052010',
  '62052020',
  '62052090',
  '620530',
  '62053000',
  '62053010',
  '62053090',
  '620590',
  '62059010',
  '62059011',
  '62059012',
  '62059019',
  '62059090',
  '6206',
  '620610',
  '62061010',
  '62061090',
  '620620',
  '62062000',
  '620630',
  '62063000',
  '62063010',
  '62063090',
  '620640',
  '62064000',
  '620690',
  '62069000',
  '62069012',
  '6207',
  '620711',
  '62071100',
  '620719',
  '62071910',
  '62071920',
  '62071930',
  '62071990',
  '620721',
  '62072110',
  '62072190',
  '620722',
  '62072200',
  '620729',
  '62072900',
  '620791',
  '62079110',
  '62079120',
  '62079190',
  '62079200',
  '620799',
  '62079911',
  '62079919',
  '62079921',
  '62079929',
  '62079990',
  '6208',
  '620811',
  '62081100',
  '620819',
  '62081910',
  '62081990',
  '620821',
  '62082100',
  '62082110',
  '62082190',
  '620822',
  '62082200',
  '620829',
  '62082910',
  '62082920',
  '62082990',
  '620891',
  '62089110',
  '62089190',
  '620892',
  '62089210',
  '62089290',
  '620899',
  '62089910',
  '62089920',
  '62089990',
  '6209',
  '62091000',
  '620920',
  '62092000',
  '62092010',
  '62092090',
  '620930',
  '62093000',
  '620990',
  '62099010',
  '62099090',
  '6210',
  '621010',
  '62101000',
  '62101010',
  '62101020',
  '62101090',
  '621020',
  '62102010',
  '62102020',
  '62102030',
  '62102090',
  '621030',
  '62103010',
  '62103020',
  '62103030',
  '62103090',
  '621040',
  '62104010',
  '62104020',
  '62104030',
  '62104040',
  '62104050',
  '62104060',
  '62104070',
  '62104080',
  '62104090',
  '621050',
  '62105000',
  '6211',
  '621111',
  '62111100',
  '621112',
  '62111200',
  '621120',
  '62112000',
  '62113100',
  '621132',
  '62113200',
  '621133',
  '62113300',
  '621139',
  '62113900',
  '62113911',
  '62113919',
  '62113990',
  '621142',
  '62114210',
  '62114211',
  '62114219',
  '62114290',
  '62114291',
  '62114299',
  '621143',
  '62114300',
  '62114310',
  '62114390',
  '621149',
  '62114910',
  '62114921',
  '62114922',
  '62114929',
  '62114990',
  '62114991',
  '62114999',
  '6212',
  '621210',
  '62121000',
  '621220',
  '62122000',
  '621230',
  '62123000',
  '621290',
  '62129010',
  '62129090',
  '6213',
  '62131000',
  '621320',
  '62132000',
  '621390',
  '62139010',
  '62139090',
  '6214',
  '621410',
  '62141010',
  '62141020',
  '62141030',
  '62141040',
  '62141090',
  '621420',
  '62142010',
  '62142020',
  '62142021',
  '62142029',
  '62142030',
  '62142031',
  '62142039',
  '62142090',
  '621430',
  '62143000',
  '62143010',
  '62143090',
  '621440',
  '62144000',
  '62144010',
  '62144090',
  '621490',
  '62149010',
  '62149021',
  '62149022',
  '62149029',
  '62149031',
  '62149032',
  '62149039',
  '62149040',
  '62149041',
  '62149049',
  '62149050',
  '62149051',
  '62149059',
  '62149060',
  '62149061',
  '62149069',
  '62149090',
  '62149091',
  '62149099',
  '6215',
  '621510',
  '62151000',
  '62151010',
  '62151090',
  '621520',
  '62152000',
  '621590',
  '62159010',
  '62159090',
  '6216',
  '621600',
  '62160010',
  '62160020',
  '62160090',
  '6217',
  '621710',
  '62171010',
  '62171020',
  '62171030',
  '62171040',
  '62171050',
  '62171060',
  '62171070',
  '62171090',
  '621790',
  '62179010',
  '62179020',
  '62179030',
  '62179040',
  '62179090',
  '63',
  '6301',
  '630110',
  '63011000',
  '630120',
  '63012000',
  '630130',
  '63013000',
  '630140',
  '63014000',
  '630190',
  '63019010',
  '63019090',
  '6302',
  '630210',
  '63021010',
  '63021090',
  '630221',
  '63022110',
  '63022190',
  '630222',
  '63022200',
  '630229',
  '63022900',
  '630231',
  '63023100',
  '630232',
  '63023200',
  '630239',
  '63023900',
  '630240',
  '63024010',
  '63024020',
  '63024030',
  '63024040',
  '63024090',
  '630251',
  '63025110',
  '63025190',
  '63025200',
  '63025300',
  '630259',
  '63025900',
  '630260',
  '63026010',
  '63026090',
  '630291',
  '63029110',
  '63029190',
  '63029200',
  '630293',
  '63029300',
  '630299',
  '63029900',
  '6303',
  '63031100',
  '630312',
  '63031200',
  '630319',
  '63031900',
  '630391',
  '63039100',
  '630392',
  '63039200',
  '630399',
  '63039910',
  '63039990',
  '6304',
  '630411',
  '63041100',
  '630419',
  '63041910',
  '63041920',
  '63041930',
  '63041940',
  '63041990',
  '630420',
  '63042000',
  '630491',
  '63049110',
  '63049120',
  '63049190',
  '630492',
  '63049211',
  '63049219',
  '63049221',
  '63049229',
  '63049231',
  '63049239',
  '63049241',
  '63049249',
  '63049250',
  '63049260',
  '63049270',
  '63049281',
  '63049289',
  '63049291',
  '63049299',
  '630493',
  '63049300',
  '630499',
  '63049910',
  '63049991',
  '63049992',
  '63049999',
  '6305',
  '630510',
  '63051010',
  '63051020',
  '63051030',
  '63051040',
  '63051050',
  '63051060',
  '63051070',
  '63051080',
  '63051090',
  '630520',
  '63052000',
  '630532',
  '63053200',
  '630533',
  '63053300',
  '630539',
  '63053900',
  '630590',
  '63059000',
  '6306',
  '63061100',
  '630612',
  '63061200',
  '630619',
  '63061910',
  '63061920',
  '63061930',
  '63061990',
  '63062100',
  '630622',
  '63062200',
  '630629',
  '63062910',
  '63062990',
  '630630',
  '63063000',
  '63063100',
  '63063910',
  '63063990',
  '630640',
  '63064000',
  '63064100',
  '63064900',
  '630690',
  '63069010',
  '63069090',
  '6307',
  '630710',
  '63071010',
  '63071020',
  '63071030',
  '63071090',
  '630720',
  '63072010',
  '63072090',
  '630790',
  '63079011',
  '63079012',
  '63079013',
  '63079019',
  '63079020',
  '63079090',
  '63079091',
  '63079099',
  '6308',
  '630800',
  '63080000',
  '6309',
  '630900',
  '63090000',
  '6310',
  '631010',
  '63101010',
  '63101020',
  '63101030',
  '63101090',
  '631090',
  '63109010',
  '63109020',
  '63109030',
  '63109040',
  '63109090',
  '64',
  '6401',
  '640110',
  '64011010',
  '64011090',
  '64019110',
  '64019190',
  '640192',
  '64019210',
  '64019290',
  '640199',
  '64019910',
  '64019990',
  '6402',
  '640212',
  '64021210',
  '64021290',
  '640219',
  '64021910',
  '64021990',
  '640220',
  '64022010',
  '64022090',
  '64023010',
  '64023090',
  '640291',
  '64029110',
  '64029190',
  '640299',
  '64029910',
  '64029990',
  '6403',
  '640312',
  '64031200',
  '640319',
  '64031910',
  '64031920',
  '64031990',
  '640320',
  '64032011',
  '64032012',
  '64032013',
  '64032019',
  '64032021',
  '64032022',
  '64032023',
  '64032029',
  '64032030',
  '64032040',
  '64032090',
  '64033000',
  '640340',
  '64034000',
  '640351',
  '64035111',
  '64035112',
  '64035113',
  '64035119',
  '64035190',
  '640359',
  '64035910',
  '64035920',
  '64035930',
  '64035990',
  '640391',
  '64039110',
  '64039120',
  '64039190',
  '640399',
  '64039910',
  '64039920',
  '64039990',
  '6404',
  '640411',
  '64041110',
  '64041120',
  '64041190',
  '640419',
  '64041910',
  '64041920',
  '64041990',
  '640420',
  '64042000',
  '6405',
  '640510',
  '64051000',
  '640520',
  '64052000',
  '640590',
  '64059000',
  '6406',
  '640610',
  '64061010',
  '64061020',
  '64061030',
  '64061040',
  '64061090',
  '640620',
  '64062000',
  '640690',
  '64069010',
  '64069020',
  '64069030',
  '64069040',
  '64069050',
  '64069090',
  '640699',
  '65',
  '6501',
  '650100',
  '65010010',
  '65010020',
  '65010090',
  '6502',
  '650200',
  '65020010',
  '65020020',
  '65020090',
  '6503',
  '65030000',
  '6504',
  '650400',
  '65040000',
  '6505',
  '650500',
  '65050010',
  '65050090',
  '6506',
  '650610',
  '65061010',
  '65061090',
  '650691',
  '65069100',
  '65069200',
  '650699',
  '65069900',
  '6507',
  '650700',
  '65070000',
  '66',
  '6601',
  '660110',
  '66011000',
  '660191',
  '66019100',
  '660199',
  '66019900',
  '6602',
  '660200',
  '66020000',
  '6603',
  '66031010',
  '66031090',
  '660320',
  '66032000',
  '660390',
  '66039010',
  '66039090',
  '67',
  '6701',
  '670100',
  '67010010',
  '67010090',
  '6702',
  '670210',
  '67021010',
  '67021090',
  '670290',
  '67029010',
  '67029090',
  '6703',
  '670300',
  '67030010',
  '67030020',
  '6704',
  '670411',
  '67041100',
  '670419',
  '67041910',
  '67041990',
  '670420',
  '67042010',
  '67042020',
  '67042090',
  '670490',
  '67049000',
  '6705',
  '6706',
  '6707',
  '6708',
  '6709',
  '6710',
  '6711',
  '6712',
  '6713',
  '68',
  '6801',
  '680100',
  '68010000',
  '6802',
  '680210',
  '68021000',
  '680221',
  '68022110',
  '68022120',
  '68022190',
  '68022200',
  '680223',
  '68022310',
  '68022390',
  '680229',
  '68022900',
  '680291',
  '68029100',
  '680292',
  '68029200',
  '680293',
  '68029300',
  '680299',
  '68029900',
  '6803',
  '680300',
  '68030000',
  '6804',
  '680410',
  '68041000',
  '680421',
  '68042110',
  '68042190',
  '680422',
  '68042210',
  '68042220',
  '68042290',
  '680423',
  '68042310',
  '68042390',
  '680430',
  '68043010',
  '68043020',
  '6805',
  '680510',
  '68051010',
  '68051090',
  '680520',
  '68052010',
  '68052020',
  '68052030',
  '68052040',
  '68052090',
  '680530',
  '68053000',
  '6806',
  '680610',
  '68061000',
  '680620',
  '68062000',
  '680690',
  '68069000',
  '6807',
  '680710',
  '68071010',
  '68071090',
  '680790',
  '68079010',
  '68079090',
  '6808',
  '680800',
  '68080000',
  '6809',
  '680911',
  '68091100',
  '680919',
  '68091900',
  '680990',
  '68099000',
  '6810',
  '681011',
  '68101110',
  '68101190',
  '681019',
  '68101910',
  '68101990',
  '681091',
  '68109100',
  '681099',
  '68109910',
  '68109990',
  '6811',
  '68111000',
  '68112010',
  '68112020',
  '68112090',
  '68113010',
  '68113090',
  '681140',
  '68114010',
  '68114020',
  '68114090',
  '681181',
  '68118100',
  '681182',
  '68118200',
  '681189',
  '68118910',
  '68118990',
  '68119000',
  '6812',
  '68125000',
  '68126011',
  '68126019',
  '68126090',
  '68127000',
  '681280',
  '68128000',
  '68129011',
  '68129019',
  '68129021',
  '68129022',
  '68129090',
  '681291',
  '68129100',
  '681292',
  '68129211',
  '68129219',
  '68129290',
  '68129300',
  '681299',
  '68129911',
  '68129919',
  '68129921',
  '68129922',
  '68129990',
  '6813',
  '68131000',
  '681320',
  '68132010',
  '68132090',
  '681381',
  '68138100',
  '681389',
  '68138900',
  '68139010',
  '68139090',
  '68139100',
  '6814',
  '681410',
  '68141010',
  '68141020',
  '68141030',
  '68141090',
  '681490',
  '68149010',
  '68149020',
  '68149030',
  '68149040',
  '68149050',
  '68149060',
  '68149090',
  '6815',
  '681510',
  '68151010',
  '68151020',
  '68151090',
  '681511',
  '68151100',
  '681512',
  '68151200',
  '681513',
  '68151300',
  '681519',
  '68151900',
  '681520',
  '68152000',
  '681591',
  '68159100',
  '681599',
  '68159910',
  '68159920',
  '68159930',
  '68159990',
  '69',
  '6901',
  '690100',
  '69010010',
  '69010020',
  '69010030',
  '69010090',
  '6902',
  '690210',
  '69021010',
  '69021020',
  '69021030',
  '69021040',
  '69021050',
  '69021090',
  '690220',
  '69022010',
  '69022020',
  '69022030',
  '69022040',
  '69022050',
  '69022090',
  '690290',
  '69029010',
  '69029020',
  '69029030',
  '69029040',
  '69029090',
  '6903',
  '690310',
  '69031000',
  '69031010',
  '69031090',
  '690320',
  '69032010',
  '69032090',
  '690390',
  '69039010',
  '69039020',
  '69039030',
  '69039040',
  '69039090',
  '6904',
  '690410',
  '69041000',
  '690490',
  '69049000',
  '6905',
  '690510',
  '69051000',
  '690590',
  '69059000',
  '6906',
  '690600',
  '69060000',
  '6907',
  '690710',
  '69071010',
  '69071090',
  '690721',
  '69072100',
  '690722',
  '69072200',
  '690723',
  '69072300',
  '690730',
  '69073000',
  '69073010',
  '690740',
  '69074000',
  '69074010',
  '690790',
  '69079010',
  '69079090',
  '6908',
  '69081010',
  '69081020',
  '69081090',
  '69089010',
  '69089020',
  '69089090',
  '6909',
  '690911',
  '69091100',
  '690912',
  '69091200',
  '690919',
  '69091910',
  '69091990',
  '690990',
  '69099000',
  '6910',
  '691010',
  '69101000',
  '691090',
  '69109000',
  '6911',
  '691110',
  '69111011',
  '69111019',
  '69111021',
  '69111029',
  '691190',
  '69119010',
  '69119020',
  '69119090',
  '6912',
  '691200',
  '69120010',
  '69120020',
  '69120030',
  '69120040',
  '69120090',
  '6913',
  '691310',
  '69131000',
  '691390',
  '69139000',
  '6914',
  '691410',
  '69141000',
  '691490',
  '69149000',
  '70',
  '7001',
  '700100',
  '70010010',
  '70010020',
  '70010090',
  '7002',
  '700210',
  '70021000',
  '700220',
  '70022010',
  '70022090',
  '700231',
  '70023100',
  '700232',
  '70023200',
  '700239',
  '70023900',
  '7003',
  '700312',
  '70031210',
  '70031290',
  '700319',
  '70031910',
  '70031990',
  '700320',
  '70032010',
  '70032090',
  '700330',
  '70033010',
  '70033090',
  '7004',
  '700420',
  '70042011',
  '70042019',
  '70042091',
  '70042099',
  '700490',
  '70049011',
  '70049019',
  '70049091',
  '70049099',
  '7005',
  '700510',
  '70051010',
  '70051090',
  '700521',
  '70052110',
  '70052190',
  '700529',
  '70052910',
  '70052990',
  '700530',
  '70053010',
  '70053090',
  '7006',
  '700600',
  '70060000',
  '7007',
  '700711',
  '70071100',
  '700719',
  '70071900',
  '700721',
  '70072110',
  '70072190',
  '700729',
  '70072900',
  '7008',
  '700800',
  '70080010',
  '70080020',
  '70080090',
  '7009',
  '700910',
  '70091010',
  '70091090',
  '700991',
  '70099100',
  '700992',
  '70099200',
  '7010',
  '701010',
  '70101000',
  '701020',
  '70102000',
  '701090',
  '70109000',
  '7011',
  '701110',
  '70111010',
  '70111020',
  '70111090',
  '701120',
  '70112000',
  '701190',
  '70119010',
  '70119090',
  '7012',
  '70120000',
  '7013',
  '701310',
  '70131000',
  '70132100',
  '701322',
  '70132200',
  '701328',
  '70132800',
  '70132900',
  '70133100',
  '70133200',
  '701333',
  '70133300',
  '701337',
  '70133700',
  '70133900',
  '701341',
  '70134100',
  '701342',
  '70134200',
  '701349',
  '70134900',
  '701391',
  '70139100',
  '70139110',
  '70139190',
  '701399',
  '70139900',
  '70139910',
  '70139990',
  '7014',
  '701400',
  '70140010',
  '70140020',
  '7015',
  '701510',
  '70151010',
  '70151020',
  '70151090',
  '701590',
  '70159010',
  '70159020',
  '70159090',
  '7016',
  '701610',
  '70161000',
  '701690',
  '70169000',
  '7017',
  '701710',
  '70171000',
  '701720',
  '70172000',
  '701790',
  '70179010',
  '70179020',
  '70179030',
  '70179090',
  '7018',
  '701810',
  '70181010',
  '70181020',
  '70181090',
  '701820',
  '70182000',
  '701890',
  '70189010',
  '70189090',
  '7019',
  '701911',
  '70191100',
  '701912',
  '70191200',
  '701913',
  '70191300',
  '701914',
  '70191400',
  '701915',
  '70191500',
  '701919',
  '70191900',
  '70193100',
  '70193200',
  '70193900',
  '70194000',
  '70195100',
  '70195200',
  '70195900',
  '701961',
  '70196100',
  '701962',
  '70196200',
  '701963',
  '70196300',
  '701964',
  '70196400',
  '701965',
  '70196500',
  '701966',
  '70196600',
  '701969',
  '70196900',
  '701971',
  '70197100',
  '701972',
  '70197200',
  '701973',
  '70197300',
  '701980',
  '70198000',
  '701990',
  '70199000',
  '70199010',
  '70199090',
  '7020',
  '702000',
  '70200011',
  '70200012',
  '70200019',
  '70200021',
  '70200029',
  '70200090',
  '7051',
  '7052',
  '7095',
  '71',
  '7101',
  '710110',
  '71011010',
  '71011020',
  '710121',
  '71012100',
  '710122',
  '71012200',
  '7102',
  '710210',
  '71021000',
  '710221',
  '71022110',
  '71022120',
  '710229',
  '71022910',
  '71022990',
  '710231',
  '71023100',
  '710239',
  '71023910',
  '71023990',
  '7103',
  '710310',
  '71031011',
  '71031012',
  '71031019',
  '71031021',
  '71031022',
  '71031023',
  '71031024',
  '71031029',
  '71031031',
  '71031032',
  '71031033',
  '71031034',
  '71031039',
  '71031041',
  '71031042',
  '71031043',
  '71031049',
  '71031051',
  '71031052',
  '71031059',
  '71031061',
  '71031062',
  '71031063',
  '71031064',
  '71031069',
  '71031071',
  '71031072',
  '71031079',
  '71031090',
  '710391',
  '71039100',
  '71039110',
  '71039120',
  '71039130',
  '710399',
  '71039910',
  '71039911',
  '71039912',
  '71039913',
  '71039919',
  '71039920',
  '71039921',
  '71039929',
  '71039930',
  '71039931',
  '71039932',
  '71039939',
  '71039940',
  '71039941',
  '71039942',
  '71039943',
  '71039944',
  '71039949',
  '71039951',
  '71039952',
  '71039959',
  '71039990',
  '7104',
  '710410',
  '71041000',
  '710420',
  '71042000',
  '71042010',
  '71042090',
  '710421',
  '71042100',
  '71042110',
  '71042120',
  '710429',
  '71042900',
  '71049010',
  '71049090',
  '710491',
  '71049100',
  '71049110',
  '71049120',
  '710499',
  '71049900',
  '7105',
  '710510',
  '71051000',
  '71051010',
  '71051020',
  '710590',
  '71059000',
  '7106',
  '710610',
  '71061000',
  '710691',
  '71069100',
  '71069110',
  '71069190',
  '710692',
  '71069210',
  '71069220',
  '71069290',
  '7107',
  '710700',
  '71070000',
  '7108',
  '710811',
  '71081100',
  '710812',
  '71081200',
  '710813',
  '71081300',
  '710820',
  '71082000',
  '7109',
  '710900',
  '71090000',
  '7110',
  '711011',
  '71101110',
  '71101120',
  '711019',
  '71101900',
  '711021',
  '71102100',
  '711029',
  '71102900',
  '711031',
  '71103100',
  '711039',
  '71103900',
  '711041',
  '71104100',
  '711049',
  '71104900',
  '7111',
  '711100',
  '71110000',
  '7112',
  '711230',
  '71123000',
  '711291',
  '71129100',
  '711292',
  '71129200',
  '711299',
  '71129910',
  '71129920',
  '71129990',
  '7113',
  '711311',
  '71131110',
  '71131120',
  '71131130',
  '71131141',
  '71131142',
  '71131143',
  '71131144',
  '71131145',
  '71131149',
  '71131190',
  '711319',
  '71131910',
  '71131911',
  '71131912',
  '71131913',
  '71131914',
  '71131915',
  '71131919',
  '71131920',
  '71131921',
  '71131922',
  '71131923',
  '71131924',
  '71131925',
  '71131929',
  '71131930',
  '71131940',
  '71131950',
  '71131960',
  '71131990',
  '711320',
  '71132000',
  '7114',
  '711411',
  '71141110',
  '71141120',
  '711419',
  '71141910',
  '71141920',
  '71141930',
  '711420',
  '71142010',
  '71142020',
  '71142030',
  '7115',
  '711510',
  '71151000',
  '711590',
  '71159010',
  '71159020',
  '71159090',
  '7116',
  '711610',
  '71161000',
  '711620',
  '71162000',
  '7117',
  '711711',
  '71171100',
  '711719',
  '71171910',
  '71171920',
  '71171990',
  '711790',
  '71179010',
  '71179090',
  '7118',
  '711810',
  '71181000',
  '711890',
  '71189000',
  '7123',
  '7133',
  '713909',
  '72',
  '7201',
  '720110',
  '72011000',
  '720120',
  '72012000',
  '720150',
  '72015010',
  '72015090',
  '7202',
  '720211',
  '72021100',
  '720219',
  '72021900',
  '720221',
  '72022100',
  '720229',
  '72022900',
  '720230',
  '72023000',
  '720241',
  '72024100',
  '720249',
  '72024900',
  '720250',
  '72025000',
  '720260',
  '72026000',
  '720270',
  '72027000',
  '720280',
  '72028000',
  '720291',
  '72029100',
  '720292',
  '72029200',
  '720293',
  '72029300',
  '720299',
  '72029911',
  '72029912',
  '72029913',
  '72029914',
  '72029915',
  '72029916',
  '72029921',
  '72029922',
  '72029931',
  '72029932',
  '72029990',
  '7203',
  '720310',
  '72031000',
  '720390',
  '72039000',
  '7204',
  '720410',
  '72041000',
  '720421',
  '72042110',
  '72042190',
  '720429',
  '72042910',
  '72042920',
  '72042990',
  '720430',
  '72043000',
  '720441',
  '72044100',
  '720449',
  '72044900',
  '720450',
  '72045000',
  '7205',
  '720510',
  '72051011',
  '72051012',
  '72051019',
  '72051021',
  '72051022',
  '72051029',
  '72051090',
  '720521',
  '72052100',
  '720529',
  '72052910',
  '72052990',
  '7206',
  '720610',
  '72061010',
  '72061020',
  '72061090',
  '720690',
  '72069011',
  '72069012',
  '72069019',
  '72069091',
  '72069092',
  '72069099',
  '7207',
  '720711',
  '72071110',
  '72071120',
  '72071130',
  '72071190',
  '720712',
  '72071210',
  '72071220',
  '72071230',
  '72071290',
  '720719',
  '72071910',
  '72071920',
  '72071990',
  '720720',
  '72072010',
  '72072020',
  '72072030',
  '72072090',
  '7208',
  '720810',
  '72081000',
  '720825',
  '72082510',
  '72082520',
  '72082530',
  '72082540',
  '72082590',
  '720826',
  '72082610',
  '72082620',
  '72082630',
  '72082640',
  '72082690',
  '720827',
  '72082710',
  '72082720',
  '72082730',
  '72082740',
  '72082790',
  '720836',
  '72083610',
  '72083620',
  '72083630',
  '72083640',
  '72083690',
  '720837',
  '72083710',
  '72083720',
  '72083730',
  '72083740',
  '72083790',
  '720838',
  '72083810',
  '72083820',
  '72083830',
  '72083840',
  '72083890',
  '720839',
  '72083910',
  '72083920',
  '72083930',
  '72083940',
  '72083990',
  '720840',
  '72084010',
  '72084020',
  '72084030',
  '72084040',
  '72084090',
  '720851',
  '72085110',
  '72085120',
  '72085130',
  '72085140',
  '72085190',
  '720852',
  '72085210',
  '72085220',
  '72085230',
  '72085240',
  '72085290',
  '720853',
  '72085310',
  '72085320',
  '72085330',
  '72085340',
  '72085390',
  '720854',
  '72085410',
  '72085420',
  '72085430',
  '72085440',
  '72085490',
  '720890',
  '72089000',
  '7209',
  '720915',
  '72091510',
  '72091520',
  '72091530',
  '72091590',
  '720916',
  '72091610',
  '72091620',
  '72091630',
  '72091690',
  '720917',
  '72091710',
  '72091720',
  '72091730',
  '72091790',
  '720918',
  '72091810',
  '72091820',
  '72091830',
  '72091890',
  '720925',
  '72092510',
  '72092520',
  '72092530',
  '72092590',
  '720926',
  '72092610',
  '72092620',
  '72092630',
  '72092690',
  '720927',
  '72092710',
  '72092720',
  '72092730',
  '72092790',
  '720928',
  '72092810',
  '72092820',
  '72092830',
  '72092890',
  '720990',
  '72099000',
  '7210',
  '721011',
  '72101110',
  '72101190',
  '721012',
  '72101210',
  '72101290',
  '721020',
  '72102000',
  '721030',
  '72103010',
  '72103090',
  '72103091',
  '72103099',
  '721041',
  '72104100',
  '721049',
  '72104900',
  '72104910',
  '72104990',
  '721050',
  '72105000',
  '721061',
  '72106100',
  '721069',
  '72106900',
  '721070',
  '72107000',
  '721090',
  '72109010',
  '72109090',
  '7211',
  '721113',
  '72111300',
  '721114',
  '72111410',
  '72111420',
  '72111430',
  '72111440',
  '72111450',
  '72111460',
  '72111490',
  '721119',
  '72111910',
  '72111920',
  '72111930',
  '72111940',
  '72111950',
  '72111960',
  '72111990',
  '721123',
  '72112310',
  '72112320',
  '72112330',
  '72112340',
  '72112350',
  '72112390',
  '721129',
  '72112910',
  '72112920',
  '72112930',
  '72112940',
  '72112950',
  '72112960',
  '72112990',
  '721190',
  '72119011',
  '72119012',
  '72119013',
  '72119090',
  '7212',
  '721210',
  '72121010',
  '72121090',
  '721220',
  '72122010',
  '72122090',
  '72122091',
  '721230',
  '72123010',
  '72123090',
  '72123091',
  '72123099',
  '721240',
  '72124000',
  '721250',
  '72125010',
  '72125020',
  '72125030',
  '72125040',
  '72125090',
  '721260',
  '72126000',
  '7213',
  '721310',
  '72131010',
  '72131090',
  '721320',
  '72132010',
  '72132020',
  '72132090',
  '721391',
  '72139110',
  '72139120',
  '72139190',
  '721399',
  '72139910',
  '72139920',
  '72139990',
  '7214',
  '721410',
  '72141010',
  '72141090',
  '721420',
  '72142010',
  '72142090',
  '721430',
  '72143000',
  '721491',
  '72149110',
  '72149190',
  '721499',
  '72149910',
  '72149990',
  '7215',
  '721510',
  '72151000',
  '721550',
  '72155010',
  '72155090',
  '721590',
  '72159010',
  '72159020',
  '72159090',
  '7216',
  '721610',
  '72161000',
  '721621',
  '72162100',
  '721622',
  '72162200',
  '721631',
  '72163100',
  '721632',
  '72163200',
  '721633',
  '72163300',
  '721640',
  '72164000',
  '721650',
  '72165000',
  '721661',
  '72166100',
  '721669',
  '72166900',
  '721691',
  '72169100',
  '721699',
  '72169910',
  '72169920',
  '72169930',
  '72169940',
  '72169990',
  '7217',
  '721710',
  '72171010',
  '72171020',
  '72171030',
  '721720',
  '72172010',
  '72172020',
  '72172030',
  '721730',
  '72173010',
  '72173020',
  '72173030',
  '721790',
  '72179011',
  '72179012',
  '72179013',
  '72179019',
  '72179091',
  '72179092',
  '72179093',
  '72179099',
  '7218',
  '721810',
  '72181000',
  '721891',
  '72189100',
  '721899',
  '72189910',
  '72189990',
  '7219',
  '721911',
  '72191111',
  '72191112',
  '72191190',
  '721912',
  '72191200',
  '721913',
  '72191300',
  '721914',
  '72191400',
  '721921',
  '72192111',
  '72192112',
  '72192121',
  '72192122',
  '72192131',
  '72192132',
  '72192141',
  '72192142',
  '72192190',
  '721922',
  '72192211',
  '72192212',
  '72192219',
  '72192291',
  '72192292',
  '72192299',
  '721923',
  '72192310',
  '72192320',
  '72192390',
  '721924',
  '72192411',
  '72192412',
  '72192413',
  '72192419',
  '72192421',
  '72192422',
  '72192423',
  '72192429',
  '72192490',
  '721931',
  '72193111',
  '72193112',
  '72193121',
  '72193122',
  '72193190',
  '721932',
  '72193210',
  '72193220',
  '72193290',
  '721933',
  '72193310',
  '72193320',
  '72193390',
  '721934',
  '72193410',
  '72193420',
  '72193490',
  '721935',
  '72193510',
  '72193520',
  '72193590',
  '721990',
  '72199011',
  '72199012',
  '72199013',
  '72199090',
  '7220',
  '722011',
  '72201110',
  '72201121',
  '72201122',
  '72201129',
  '72201190',
  '722012',
  '72201210',
  '72201221',
  '72201222',
  '72201229',
  '72201290',
  '722020',
  '72202010',
  '72202021',
  '72202022',
  '72202029',
  '72202090',
  '722090',
  '72209010',
  '72209021',
  '72209022',
  '72209029',
  '72209090',
  '7221',
  '722100',
  '72210011',
  '72210012',
  '72210019',
  '72210090',
  '7222',
  '722211',
  '72221111',
  '72221112',
  '72221119',
  '72221191',
  '72221192',
  '72221199',
  '722219',
  '72221911',
  '72221912',
  '72221919',
  '72221991',
  '72221992',
  '72221999',
  '722220',
  '72222011',
  '72222012',
  '72222019',
  '72222091',
  '72222092',
  '72222099',
  '722230',
  '72223011',
  '72223012',
  '72223019',
  '72223091',
  '72223092',
  '72223099',
  '722240',
  '72224010',
  '72224020',
  '7223',
  '722300',
  '72230010',
  '72230091',
  '72230092',
  '72230099',
  '7224',
  '722410',
  '72241000',
  '722490',
  '72249010',
  '72249020',
  '72249030',
  '72249040',
  '72249091',
  '72249099',
  '7225',
  '722511',
  '72251100',
  '722519',
  '72251910',
  '72251920',
  '72251990',
  '72252011',
  '72252019',
  '72252021',
  '72252029',
  '722530',
  '72253010',
  '72253090',
  '722540',
  '72254011',
  '72254012',
  '72254013',
  '72254019',
  '72254020',
  '72254030',
  '722550',
  '72255010',
  '72255020',
  '72255030',
  '722591',
  '72259100',
  '72259110',
  '72259190',
  '722592',
  '72259200',
  '72259210',
  '72259290',
  '722599',
  '72259900',
  '72259910',
  '72259920',
  '72259930',
  '72259990',
  '7226',
  '722611',
  '72261100',
  '722619',
  '72261910',
  '72261920',
  '72261990',
  '722620',
  '72262011',
  '72262012',
  '72262021',
  '72262022',
  '72262030',
  '722691',
  '72269110',
  '72269120',
  '72269130',
  '72269190',
  '722692',
  '72269210',
  '72269220',
  '72269230',
  '72269300',
  '72269400',
  '722699',
  '72269910',
  '72269920',
  '72269930',
  '72269940',
  '72269951',
  '72269952',
  '72269953',
  '72269960',
  '72269971',
  '72269972',
  '72269973',
  '72269979',
  '72269981',
  '72269982',
  '72269983',
  '72269989',
  '72269990',
  '7227',
  '722710',
  '72271000',
  '722720',
  '72272000',
  '722790',
  '72279010',
  '72279020',
  '72279030',
  '72279040',
  '72279050',
  '72279060',
  '72279090',
  '7228',
  '722810',
  '72281010',
  '72281090',
  '722820',
  '72282000',
  '722830',
  '72283011',
  '72283019',
  '72283021',
  '72283022',
  '72283023',
  '72283024',
  '72283029',
  '722840',
  '72284000',
  '722850',
  '72285010',
  '72285090',
  '722860',
  '72286011',
  '72286012',
  '72286091',
  '72286092',
  '72286093',
  '72286094',
  '72286099',
  '722870',
  '72287011',
  '72287012',
  '72287021',
  '72287022',
  '722880',
  '72288010',
  '72288020',
  '72288090',
  '7229',
  '72291000',
  '722920',
  '72292000',
  '722990',
  '72299011',
  '72299012',
  '72299013',
  '72299014',
  '72299015',
  '72299016',
  '72299021',
  '72299022',
  '72299023',
  '72299031',
  '72299032',
  '72299033',
  '72299034',
  '72299040',
  '72299051',
  '72299052',
  '72299053',
  '72299054',
  '72299059',
  '72299060',
  '72299070',
  '72299090',
  '73',
  '7301',
  '730110',
  '73011000',
  '730120',
  '73012010',
  '73012090',
  '7302',
  '730210',
  '73021010',
  '73021011',
  '73021012',
  '73021013',
  '73021014',
  '73021019',
  '73021020',
  '73021090',
  '730230',
  '73023000',
  '730240',
  '73024000',
  '730290',
  '73029010',
  '73029090',
  '7303',
  '730300',
  '73030010',
  '73030020',
  '73030030',
  '73030090',
  '7304',
  '73041011',
  '73041019',
  '73041021',
  '73041029',
  '730411',
  '73041110',
  '73041120',
  '73041190',
  '730419',
  '73041910',
  '73041920',
  '73041990',
  '73042110',
  '73042190',
  '730422',
  '73042200',
  '730423',
  '73042310',
  '73042390',
  '730424',
  '73042400',
  '730429',
  '73042910',
  '73042990',
  '730431',
  '73043111',
  '73043119',
  '73043121',
  '73043129',
  '73043131',
  '73043139',
  '730439',
  '73043911',
  '73043919',
  '73043921',
  '73043929',
  '73043931',
  '73043939',
  '730441',
  '73044100',
  '730449',
  '73044900',
  '730451',
  '73045110',
  '73045120',
  '73045130',
  '730459',
  '73045910',
  '73045920',
  '73045930',
  '730490',
  '73049000',
  '7305',
  '730511',
  '73051111',
  '73051119',
  '73051121',
  '73051129',
  '730512',
  '73051211',
  '73051219',
  '73051221',
  '73051229',
  '730519',
  '73051911',
  '73051919',
  '73051921',
  '73051929',
  '730520',
  '73052010',
  '73052090',
  '730531',
  '73053110',
  '73053190',
  '730539',
  '73053910',
  '73053990',
  '730590',
  '73059010',
  '73059021',
  '73059029',
  '73059091',
  '73059099',
  '7306',
  '73061011',
  '73061019',
  '73061021',
  '73061029',
  '730611',
  '73061100',
  '730619',
  '73061911',
  '73061919',
  '73061921',
  '73061929',
  '73062010',
  '73062090',
  '730621',
  '73062100',
  '730629',
  '73062911',
  '73062919',
  '730630',
  '73063010',
  '73063090',
  '730640',
  '73064000',
  '730650',
  '73065000',
  '73066010',
  '73066090',
  '730661',
  '73066100',
  '730669',
  '73066900',
  '730690',
  '73069011',
  '73069019',
  '73069090',
  '7307',
  '730711',
  '73071110',
  '73071120',
  '73071190',
  '730719',
  '73071900',
  '730721',
  '73072100',
  '730722',
  '73072200',
  '730723',
  '73072300',
  '730729',
  '73072900',
  '730791',
  '73079110',
  '73079190',
  '730792',
  '73079210',
  '73079290',
  '730793',
  '73079310',
  '73079390',
  '730799',
  '73079910',
  '73079990',
  '7308',
  '730810',
  '73081000',
  '730820',
  '73082011',
  '73082019',
  '73082020',
  '730830',
  '73083000',
  '730840',
  '73084000',
  '730890',
  '73089010',
  '73089020',
  '73089030',
  '73089040',
  '73089050',
  '73089060',
  '73089070',
  '73089090',
  '7309',
  '730900',
  '73090010',
  '73090020',
  '73090030',
  '73090040',
  '73090090',
  '7310',
  '731010',
  '73101010',
  '73101020',
  '73101090',
  '731021',
  '73102110',
  '73102190',
  '731029',
  '73102910',
  '73102920',
  '73102990',
  '7311',
  '731100',
  '73110010',
  '73110020',
  '73110030',
  '73110090',
  '7312',
  '731210',
  '73121010',
  '73121020',
  '73121030',
  '73121090',
  '731290',
  '73129000',
  '7313',
  '731300',
  '73130010',
  '73130020',
  '7314',
  '731412',
  '73141200',
  '73141300',
  '731414',
  '73141410',
  '73141490',
  '731419',
  '73141910',
  '73141990',
  '731420',
  '73142010',
  '73142090',
  '731431',
  '73143100',
  '731439',
  '73143900',
  '731441',
  '73144110',
  '73144190',
  '731442',
  '73144210',
  '73144290',
  '731449',
  '73144910',
  '73144990',
  '731450',
  '73145000',
  '7315',
  '731511',
  '73151100',
  '731512',
  '73151210',
  '73151220',
  '73151290',
  '731519',
  '73151900',
  '731520',
  '73152000',
  '731581',
  '73158100',
  '731582',
  '73158200',
  '731589',
  '73158900',
  '731590',
  '73159000',
  '7316',
  '731600',
  '73160010',
  '73160090',
  '7317',
  '731700',
  '73170011',
  '73170012',
  '73170013',
  '73170019',
  '73170021',
  '73170029',
  '73170030',
  '73170091',
  '73170099',
  '7318',
  '731811',
  '73181110',
  '73181190',
  '731812',
  '73181200',
  '731813',
  '73181300',
  '731814',
  '73181400',
  '731815',
  '73181500',
  '731816',
  '73181600',
  '731819',
  '73181900',
  '731821',
  '73182100',
  '731822',
  '73182200',
  '731823',
  '73182300',
  '731824',
  '73182400',
  '731829',
  '73182910',
  '73182990',
  '7319',
  '73191010',
  '73191020',
  '73191090',
  '731940',
  '73194010',
  '73194090',
  '731990',
  '73199000',
  '7320',
  '732010',
  '73201011',
  '73201012',
  '73201019',
  '73201020',
  '732020',
  '73202000',
  '732090',
  '73209010',
  '73209020',
  '73209090',
  '7321',
  '732111',
  '73211110',
  '73211120',
  '73211190',
  '732112',
  '73211210',
  '73211220',
  '73211290',
  '73211310',
  '73211320',
  '73211390',
  '732119',
  '73211910',
  '73211990',
  '732181',
  '73218100',
  '732182',
  '73218200',
  '73218310',
  '73218390',
  '732189',
  '73218910',
  '73218990',
  '732190',
  '73219000',
  '7322',
  '732211',
  '73221100',
  '732219',
  '73221900',
  '732290',
  '73229010',
  '73229090',
  '7323',
  '732310',
  '73231000',
  '732391',
  '73239110',
  '73239190',
  '732392',
  '73239200',
  '732393',
  '73239310',
  '73239390',
  '732394',
  '73239410',
  '73239420',
  '73239490',
  '732399',
  '73239910',
  '73239920',
  '73239990',
  '7324',
  '732410',
  '73241000',
  '732421',
  '73242100',
  '732429',
  '73242900',
  '732490',
  '73249000',
  '7325',
  '732510',
  '73251000',
  '732591',
  '73259100',
  '732599',
  '73259910',
  '73259920',
  '73259930',
  '73259991',
  '73259992',
  '73259993',
  '73259999',
  '7326',
  '732611',
  '73261100',
  '732619',
  '73261910',
  '73261990',
  '732620',
  '73262010',
  '73262090',
  '732690',
  '73269010',
  '73269020',
  '73269030',
  '73269040',
  '73269050',
  '73269060',
  '73269070',
  '73269080',
  '73269091',
  '73269099',
  '74',
  '7401',
  '740100',
  '74010010',
  '74010090',
  '74011000',
  '74012000',
  '7402',
  '740200',
  '74020010',
  '74020090',
  '7403',
  '740311',
  '74031100',
  '740312',
  '74031200',
  '740313',
  '74031300',
  '740319',
  '74031900',
  '740321',
  '74032100',
  '740322',
  '74032210',
  '74032290',
  '74032310',
  '74032320',
  '740329',
  '74032900',
  '7404',
  '740400',
  '74040011',
  '74040012',
  '74040019',
  '74040021',
  '74040022',
  '74040023',
  '74040024',
  '74040025',
  '74040029',
  '7405',
  '740500',
  '74050000',
  '7406',
  '740610',
  '74061000',
  '740620',
  '74062000',
  '7407',
  '740710',
  '74071010',
  '74071020',
  '74071030',
  '74071040',
  '74071051',
  '74071059',
  '74071090',
  '740721',
  '74072110',
  '74072120',
  '74072130',
  '74072190',
  '74072210',
  '74072220',
  '740729',
  '74072910',
  '74072921',
  '74072929',
  '74072990',
  '7408',
  '740811',
  '74081110',
  '74081190',
  '740819',
  '74081910',
  '74081920',
  '74081990',
  '740821',
  '74082110',
  '74082190',
  '740822',
  '74082210',
  '74082220',
  '74082290',
  '740829',
  '74082910',
  '74082990',
  '7409',
  '740911',
  '74091100',
  '740919',
  '74091900',
  '740921',
  '74092100',
  '740929',
  '74092900',
  '740931',
  '74093100',
  '740939',
  '74093900',
  '740940',
  '74094000',
  '740990',
  '74099000',
  '7410',
  '741011',
  '74101100',
  '741012',
  '74101200',
  '741021',
  '74102100',
  '741022',
  '74102200',
  '7411',
  '741110',
  '74111000',
  '741121',
  '74112100',
  '741122',
  '74112200',
  '741129',
  '74112900',
  '7412',
  '741210',
  '74121000',
  '741220',
  '74122011',
  '74122012',
  '74122019',
  '74122090',
  '7413',
  '741300',
  '74130000',
  '7414',
  '74142010',
  '74142090',
  '74149010',
  '74149020',
  '74149090',
  '7415',
  '741510',
  '74151000',
  '741521',
  '74152100',
  '741529',
  '74152900',
  '741533',
  '74153310',
  '74153390',
  '741539',
  '74153910',
  '74153990',
  '7416',
  '74160000',
  '7417',
  '74170011',
  '74170019',
  '74170020',
  '74170091',
  '74170092',
  '74170099',
  '7418',
  '741810',
  '74181010',
  '74181021',
  '74181022',
  '74181023',
  '74181024',
  '74181031',
  '74181039',
  '74181090',
  '74181100',
  '741819',
  '741820',
  '74182010',
  '74182020',
  '7419',
  '741910',
  '74191010',
  '74191021',
  '74191029',
  '741920',
  '74192000',
  '741980',
  '74198010',
  '74198020',
  '74198030',
  '74198040',
  '74198050',
  '74198090',
  '74199100',
  '741999',
  '74199910',
  '74199920',
  '74199930',
  '74199940',
  '74199990',
  '75',
  '7501',
  '750110',
  '75011000',
  '750120',
  '75012000',
  '7502',
  '750210',
  '75021000',
  '750220',
  '75022010',
  '75022020',
  '75022030',
  '75022040',
  '75022090',
  '7503',
  '750300',
  '75030010',
  '75030090',
  '7504',
  '750400',
  '75040000',
  '7505',
  '750511',
  '75051110',
  '75051120',
  '750512',
  '75051210',
  '75051220',
  '750521',
  '75052100',
  '750522',
  '75052200',
  '7506',
  '750610',
  '75061000',
  '750620',
  '75062000',
  '7507',
  '750711',
  '75071100',
  '750712',
  '75071200',
  '750720',
  '75072000',
  '7508',
  '750810',
  '75081000',
  '750890',
  '75089010',
  '75089020',
  '75089030',
  '75089090',
  '75122099',
  '76',
  '7601',
  '760110',
  '76011010',
  '76011020',
  '76011030',
  '76011040',
  '76011090',
  '760120',
  '76012010',
  '76012020',
  '76012030',
  '76012040',
  '76012090',
  '7602',
  '760200',
  '76020010',
  '76020090',
  '7603',
  '760310',
  '76031010',
  '76031090',
  '760320',
  '76032000',
  '7604',
  '760410',
  '76041010',
  '76041020',
  '76041031',
  '76041039',
  '760421',
  '76042100',
  '760429',
  '76042910',
  '76042920',
  '76042930',
  '76042990',
  '7605',
  '760511',
  '76051100',
  '760519',
  '76051910',
  '76051991',
  '76051999',
  '760521',
  '76052100',
  '760529',
  '76052910',
  '76052990',
  '7606',
  '760611',
  '76061110',
  '76061190',
  '760612',
  '76061200',
  '760691',
  '76069110',
  '76069120',
  '76069190',
  '760692',
  '76069210',
  '76069290',
  '7607',
  '760711',
  '76071110',
  '76071190',
  '760719',
  '76071910',
  '76071991',
  '76071992',
  '76071993',
  '76071994',
  '76071995',
  '76071999',
  '760720',
  '76072010',
  '76072090',
  '7608',
  '760810',
  '76081000',
  '760820',
  '76082000',
  '7609',
  '760900',
  '76090000',
  '7610',
  '761010',
  '76101000',
  '761090',
  '76109010',
  '76109020',
  '76109030',
  '76109090',
  '7611',
  '761100',
  '76110000',
  '7612',
  '761210',
  '76121010',
  '76121020',
  '76121030',
  '76121090',
  '761290',
  '76129010',
  '76129020',
  '76129030',
  '76129090',
  '7613',
  '761300',
  '76130011',
  '76130012',
  '76130013',
  '76130019',
  '76130021',
  '76130022',
  '76130023',
  '76130029',
  '76130091',
  '76130092',
  '76130093',
  '76130099',
  '7614',
  '761410',
  '76141000',
  '761490',
  '76149000',
  '7615',
  '761510',
  '76151011',
  '76151012',
  '76151021',
  '76151029',
  '76151030',
  '76151040',
  '76151090',
  '76151100',
  '761519',
  '761520',
  '76152010',
  '76152020',
  '76152090',
  '7616',
  '761610',
  '76161000',
  '761691',
  '76169100',
  '761699',
  '76169910',
  '76169920',
  '76169930',
  '76169990',
  '77',
  '78',
  '7801',
  '780110',
  '78011000',
  '780191',
  '78019100',
  '780199',
  '78019910',
  '78019920',
  '78019930',
  '78019990',
  '7802',
  '780200',
  '78020010',
  '78020090',
  '7803',
  '78030011',
  '78030019',
  '78030021',
  '78030029',
  '78030030',
  '7804',
  '780411',
  '78041110',
  '78041120',
  '780419',
  '78041910',
  '78041990',
  '780420',
  '78042000',
  '7805',
  '78050010',
  '78050020',
  '7806',
  '780600',
  '78060010',
  '78060020',
  '78060030',
  '78060090',
  '79',
  '7901',
  '790111',
  '79011100',
  '790112',
  '79011200',
  '790120',
  '79012010',
  '79012090',
  '7902',
  '790200',
  '79020010',
  '79020090',
  '7903',
  '790310',
  '79031000',
  '790390',
  '79039000',
  '7904',
  '790400',
  '79040011',
  '79040012',
  '79040019',
  '79040021',
  '79040022',
  '79040029',
  '79040030',
  '7905',
  '790500',
  '79050010',
  '79050020',
  '79050030',
  '79050040',
  '7906',
  '79060010',
  '79060020',
  '7907',
  '790700',
  '79070010',
  '79070090',
  '80',
  '8001',
  '800110',
  '80011010',
  '80011090',
  '80012000',
  '8002',
  '800200',
  '80020010',
  '80020090',
  '8003',
  '800300',
  '80030010',
  '80030020',
  '80030030',
  '80030040',
  '8004',
  '80040010',
  '80040090',
  '8005',
  '80050000',
  '8006',
  '80060010',
  '80060020',
  '8007',
  '800700',
  '80070010',
  '80070090',
  '8011',
  '8012',
  '8013',
  '8021',
  '8022',
  '8023',
  '802901',
  '8071',
  '81',
  '8101',
  '810110',
  '81011000',
  '810194',
  '81019400',
  '81019510',
  '81019590',
  '810196',
  '81019600',
  '810197',
  '81019700',
  '810199',
  '81019910',
  '81019990',
  '8102',
  '810210',
  '81021000',
  '810294',
  '81029400',
  '810295',
  '81029510',
  '81029590',
  '810296',
  '81029600',
  '810297',
  '81029700',
  '810299',
  '81029900',
  '8103',
  '810320',
  '81032010',
  '81032090',
  '810330',
  '81033000',
  '81039000',
  '810391',
  '81039100',
  '810399',
  '81039900',
  '8104',
  '810411',
  '81041100',
  '810419',
  '81041900',
  '810420',
  '81042010',
  '81042090',
  '810430',
  '81043010',
  '81043020',
  '810490',
  '81049010',
  '81049020',
  '81049030',
  '81049090',
  '8105',
  '810520',
  '81052010',
  '81052020',
  '81052030',
  '810530',
  '81053000',
  '810590',
  '81059000',
  '8106',
  '810600',
  '81060010',
  '81060020',
  '81060030',
  '81060090',
  '810610',
  '81061010',
  '81061020',
  '81061090',
  '810690',
  '81069010',
  '81069090',
  '8107',
  '81072000',
  '81073000',
  '810790',
  '81079010',
  '81079090',
  '8108',
  '810820',
  '81082000',
  '810830',
  '81083000',
  '810890',
  '81089010',
  '81089090',
  '8109',
  '81092000',
  '810921',
  '81092100',
  '810929',
  '81092900',
  '81093000',
  '810931',
  '81093100',
  '810939',
  '81093900',
  '81099000',
  '810991',
  '81099100',
  '810999',
  '81099900',
  '8110',
  '811010',
  '81101000',
  '811020',
  '81102000',
  '811090',
  '81109000',
  '8111',
  '811100',
  '81110010',
  '81110020',
  '81110030',
  '81110090',
  '8112',
  '811212',
  '81121200',
  '811213',
  '81121300',
  '811219',
  '81121900',
  '811221',
  '81122100',
  '811222',
  '81122200',
  '811229',
  '81122900',
  '81123010',
  '81123020',
  '81123030',
  '81123090',
  '811231',
  '81123110',
  '81123120',
  '81123130',
  '811239',
  '81123900',
  '81124010',
  '81124020',
  '81124030',
  '81124090',
  '811241',
  '81124110',
  '81124120',
  '81124130',
  '811249',
  '81124900',
  '811251',
  '81125100',
  '811252',
  '81125200',
  '811259',
  '81125900',
  '811261',
  '81126100',
  '811269',
  '81126910',
  '81126920',
  '81126990',
  '811292',
  '81129200',
  '811299',
  '81129900',
  '8113',
  '811300',
  '81130010',
  '81130020',
  '81130030',
  '81130090',
  '82',
  '8201',
  '820110',
  '82011000',
  '820130',
  '82013000',
  '820140',
  '82014000',
  '820150',
  '82015000',
  '820160',
  '82016000',
  '820190',
  '82019000',
  '8202',
  '820210',
  '82021010',
  '82021020',
  '82021090',
  '820220',
  '82022000',
  '820231',
  '82023100',
  '820239',
  '82023900',
  '820240',
  '82024000',
  '820291',
  '82029110',
  '82029120',
  '820299',
  '82029910',
  '82029990',
  '8203',
  '820310',
  '82031000',
  '820320',
  '82032000',
  '820330',
  '82033000',
  '820340',
  '82034010',
  '82034090',
  '8204',
  '820411',
  '82041110',
  '82041120',
  '820412',
  '82041210',
  '82041220',
  '820420',
  '82042000',
  '8205',
  '820510',
  '82051000',
  '820520',
  '82052000',
  '820530',
  '82053000',
  '820540',
  '82054000',
  '820551',
  '82055110',
  '82055190',
  '820559',
  '82055910',
  '82055920',
  '82055930',
  '82055940',
  '82055990',
  '820560',
  '82056000',
  '820570',
  '82057000',
  '820580',
  '820590',
  '82059010',
  '82059020',
  '82059030',
  '82059090',
  '8206',
  '820600',
  '82060010',
  '82060090',
  '8207',
  '820713',
  '82071300',
  '820719',
  '82071900',
  '820720',
  '82072000',
  '820730',
  '82073000',
  '820740',
  '82074010',
  '82074090',
  '820750',
  '82075000',
  '820760',
  '82076010',
  '82076090',
  '820770',
  '82077010',
  '82077090',
  '820780',
  '82078000',
  '820790',
  '82079010',
  '82079020',
  '82079030',
  '82079090',
  '8208',
  '820810',
  '82081000',
  '820820',
  '82082000',
  '820830',
  '82083000',
  '820840',
  '82084000',
  '820890',
  '82089010',
  '82089020',
  '82089030',
  '82089040',
  '82089090',
  '8209',
  '820900',
  '82090010',
  '82090090',
  '8210',
  '821000',
  '82100000',
  '8211',
  '821110',
  '82111000',
  '821191',
  '82119100',
  '821192',
  '82119200',
  '821193',
  '82119310',
  '82119390',
  '821194',
  '82119400',
  '821195',
  '82119500',
  '8212',
  '821210',
  '82121010',
  '82121090',
  '821220',
  '82122011',
  '82122019',
  '82122020',
  '821290',
  '82129000',
  '8213',
  '821300',
  '82130000',
  '8214',
  '821410',
  '82141010',
  '82141090',
  '821420',
  '82142010',
  '82142090',
  '821490',
  '82149010',
  '82149090',
  '8215',
  '821510',
  '82151000',
  '821520',
  '82152000',
  '821591',
  '82159100',
  '821599',
  '82159900',
  '83',
  '8301',
  '830110',
  '83011000',
  '830120',
  '83012000',
  '830130',
  '83013000',
  '830140',
  '83014010',
  '83014090',
  '830150',
  '83015000',
  '830160',
  '83016000',
  '830170',
  '83017000',
  '8302',
  '830210',
  '83021010',
  '83021020',
  '83021090',
  '830220',
  '83022000',
  '830230',
  '83023010',
  '83023090',
  '830241',
  '83024110',
  '83024120',
  '83024190',
  '830242',
  '83024200',
  '830249',
  '83024900',
  '830250',
  '83025000',
  '830260',
  '83026000',
  '8303',
  '830300',
  '83030000',
  '8304',
  '830400',
  '83040000',
  '8305',
  '830510',
  '83051000',
  '830520',
  '83052000',
  '830590',
  '83059010',
  '83059020',
  '83059090',
  '8306',
  '830610',
  '83061000',
  '830621',
  '83062110',
  '83062120',
  '83062190',
  '830629',
  '83062910',
  '83062920',
  '83062990',
  '830630',
  '83063000',
  '8307',
  '830710',
  '83071000',
  '830790',
  '83079000',
  '8308',
  '830810',
  '83081010',
  '83081021',
  '83081029',
  '830820',
  '83082000',
  '830890',
  '83089011',
  '83089019',
  '83089020',
  '83089031',
  '83089039',
  '83089040',
  '83089091',
  '83089099',
  '8309',
  '830910',
  '83091000',
  '830990',
  '83099010',
  '83099020',
  '83099030',
  '83099090',
  '8310',
  '831000',
  '83100010',
  '83100090',
  '8311',
  '831110',
  '83111000',
  '831120',
  '83112000',
  '831130',
  '83113010',
  '83113090',
  '831190',
  '83119000',
  '8352',
  '84',
  '8401',
  '840110',
  '84011000',
  '840120',
  '84012000',
  '840130',
  '84013000',
  '840140',
  '84014000',
  '8402',
  '840211',
  '84021100',
  '840212',
  '84021200',
  '840219',
  '84021910',
  '84021920',
  '84021990',
  '840220',
  '84022000',
  '840290',
  '84029010',
  '84029020',
  '84029090',
  '8403',
  '840310',
  '84031000',
  '840390',
  '84039000',
  '8404',
  '840410',
  '84041000',
  '840420',
  '84042000',
  '840490',
  '84049000',
  '8405',
  '840510',
  '84051010',
  '84051020',
  '84051090',
  '840590',
  '84059000',
  '8406',
  '840610',
  '84061000',
  '840681',
  '84068100',
  '840682',
  '84068200',
  '840690',
  '84069000',
  '8407',
  '840710',
  '84071000',
  '840721',
  '84072100',
  '840729',
  '84072900',
  '840731',
  '84073110',
  '84073190',
  '840732',
  '84073210',
  '84073290',
  '840733',
  '84073310',
  '84073320',
  '84073390',
  '840734',
  '84073410',
  '84073490',
  '840790',
  '84079010',
  '84079020',
  '84079090',
  '8408',
  '840810',
  '84081010',
  '84081091',
  '84081092',
  '84081093',
  '840820',
  '84082010',
  '84082020',
  '840890',
  '84089010',
  '84089090',
  '8409',
  '840910',
  '84091000',
  '840991',
  '84099111',
  '84099112',
  '84099113',
  '84099114',
  '84099120',
  '84099191',
  '84099192',
  '84099193',
  '84099194',
  '84099199',
  '840999',
  '84099911',
  '84099912',
  '84099913',
  '84099914',
  '84099920',
  '84099930',
  '84099941',
  '84099942',
  '84099949',
  '84099990',
  '8410',
  '841011',
  '84101100',
  '841012',
  '84101210',
  '84101220',
  '841013',
  '84101310',
  '84101320',
  '84101390',
  '841090',
  '84109000',
  '8411',
  '841111',
  '84111100',
  '841112',
  '84111200',
  '841121',
  '84112100',
  '841122',
  '84112200',
  '841181',
  '84118100',
  '841182',
  '84118210',
  '84118220',
  '84118230',
  '84118240',
  '84118250',
  '84118260',
  '841191',
  '84119100',
  '841199',
  '84119900',
  '8412',
  '841210',
  '84121000',
  '841221',
  '84122100',
  '841229',
  '84122910',
  '84122990',
  '841231',
  '84123100',
  '841239',
  '84123900',
  '841280',
  '84128011',
  '84128019',
  '84128020',
  '84128030',
  '84128090',
  '841290',
  '84129010',
  '84129020',
  '84129030',
  '84129090',
  '8413',
  '841311',
  '84131110',
  '84131191',
  '84131199',
  '841319',
  '84131910',
  '84131990',
  '841320',
  '84132000',
  '841330',
  '84133010',
  '84133020',
  '84133030',
  '84133090',
  '841340',
  '84134000',
  '841350',
  '84135010',
  '84135021',
  '84135029',
  '84135090',
  '841360',
  '84136010',
  '84136020',
  '84136090',
  '841370',
  '84137010',
  '84137091',
  '84137092',
  '84137093',
  '84137094',
  '84137095',
  '84137096',
  '84137097',
  '84137099',
  '841381',
  '84138110',
  '84138120',
  '84138130',
  '84138190',
  '841382',
  '84138200',
  '841391',
  '84139110',
  '84139120',
  '84139130',
  '84139140',
  '84139190',
  '841392',
  '84139200',
  '8414',
  '841410',
  '84141000',
  '84141010',
  '84141090',
  '841420',
  '84142010',
  '84142020',
  '84142090',
  '841430',
  '84143000',
  '841440',
  '84144010',
  '84144020',
  '84144030',
  '84144090',
  '841451',
  '84145110',
  '84145120',
  '84145130',
  '84145140',
  '84145150',
  '84145190',
  '841459',
  '84145910',
  '84145920',
  '84145930',
  '84145990',
  '841460',
  '84146000',
  '841470',
  '84147000',
  '841480',
  '84148011',
  '84148019',
  '84148020',
  '84148030',
  '84148090',
  '841490',
  '84149011',
  '84149012',
  '84149019',
  '84149020',
  '84149030',
  '84149040',
  '84149090',
  '8415',
  '841510',
  '84151010',
  '84151090',
  '841520',
  '84152010',
  '84152090',
  '841581',
  '84158110',
  '84158190',
  '841582',
  '84158210',
  '84158290',
  '841583',
  '84158310',
  '84158390',
  '841590',
  '84159000',
  '8416',
  '841610',
  '84161000',
  '841620',
  '84162000',
  '841630',
  '84163000',
  '841690',
  '84169000',
  '8417',
  '841710',
  '84171000',
  '841720',
  '84172000',
  '841780',
  '84178010',
  '84178090',
  '841790',
  '84179000',
  '8418',
  '841810',
  '84181010',
  '84181090',
  '841821',
  '84182100',
  '84182200',
  '841829',
  '84182900',
  '841830',
  '84183010',
  '84183090',
  '841840',
  '84184010',
  '84184090',
  '841850',
  '84185000',
  '841861',
  '84186100',
  '841869',
  '84186910',
  '84186920',
  '84186930',
  '84186940',
  '84186950',
  '84186990',
  '841891',
  '84189100',
  '841899',
  '84189900',
  '8419',
  '841911',
  '84191110',
  '84191190',
  '841912',
  '84191200',
  '841919',
  '84191910',
  '84191920',
  '841920',
  '84192010',
  '84192090',
  '84193100',
  '84193200',
  '841933',
  '84193300',
  '841934',
  '84193400',
  '841935',
  '84193500',
  '841939',
  '84193900',
  '841940',
  '84194010',
  '84194020',
  '84194090',
  '841950',
  '84195010',
  '84195011',
  '84195012',
  '84195013',
  '84195019',
  '84195020',
  '84195030',
  '84195090',
  '84195091',
  '84195092',
  '84195093',
  '84195099',
  '841960',
  '84196000',
  '841981',
  '84198110',
  '84198120',
  '84198190',
  '841989',
  '84198910',
  '84198911',
  '84198912',
  '84198913',
  '84198914',
  '84198915',
  '84198916',
  '84198917',
  '84198919',
  '84198920',
  '84198930',
  '84198940',
  '84198950',
  '84198960',
  '84198970',
  '84198980',
  '84198990',
  '841990',
  '84199010',
  '84199090',
  '8420',
  '842010',
  '84201000',
  '842091',
  '84209100',
  '842099',
  '84209900',
  '8421',
  '842111',
  '84211100',
  '842112',
  '84211200',
  '842119',
  '84211910',
  '84211920',
  '84211930',
  '84211940',
  '84211950',
  '84211960',
  '84211991',
  '84211999',
  '842121',
  '84212110',
  '84212120',
  '84212190',
  '842122',
  '84212200',
  '842123',
  '84212300',
  '842129',
  '84212900',
  '842131',
  '84213100',
  '842132',
  '84213200',
  '842139',
  '84213910',
  '84213920',
  '84213990',
  '842191',
  '84219100',
  '842199',
  '84219900',
  '8422',
  '842211',
  '84221100',
  '842219',
  '84221900',
  '842220',
  '84222000',
  '842230',
  '84223000',
  '842240',
  '84224000',
  '842290',
  '84229010',
  '84229020',
  '84229090',
  '8423',
  '842310',
  '84231000',
  '842320',
  '84232000',
  '842330',
  '84233000',
  '842381',
  '84238110',
  '84238190',
  '842382',
  '84238210',
  '84238290',
  '842389',
  '84238900',
  '842390',
  '84239010',
  '84239020',
  '8424',
  '842410',
  '84241000',
  '842420',
  '84242000',
  '842430',
  '84243000',
  '842441',
  '84244100',
  '842449',
  '84244900',
  '84248100',
  '842482',
  '84248200',
  '842489',
  '84248910',
  '84248920',
  '84248990',
  '842490',
  '84249000',
  '8425',
  '842511',
  '84251110',
  '84251120',
  '842519',
  '84251910',
  '84251920',
  '84252000',
  '842531',
  '84253100',
  '842539',
  '84253900',
  '842541',
  '84254100',
  '842542',
  '84254200',
  '842549',
  '84254900',
  '8426',
  '842611',
  '84261100',
  '842612',
  '84261200',
  '842619',
  '84261900',
  '842620',
  '84262000',
  '842630',
  '84263000',
  '842641',
  '84264100',
  '842649',
  '84264900',
  '842691',
  '84269100',
  '842699',
  '84269910',
  '84269990',
  '8427',
  '842710',
  '84271000',
  '842720',
  '84272000',
  '842790',
  '84279000',
  '8428',
  '842810',
  '84281011',
  '84281019',
  '84281020',
  '842820',
  '84282011',
  '84282019',
  '84282020',
  '842831',
  '84283100',
  '842832',
  '84283200',
  '842833',
  '84283300',
  '842839',
  '84283900',
  '842840',
  '84284000',
  '84285010',
  '84285020',
  '84285090',
  '842860',
  '84286000',
  '842870',
  '84287000',
  '842890',
  '84289010',
  '84289020',
  '84289090',
  '8429',
  '842911',
  '84291110',
  '84291120',
  '842919',
  '84291910',
  '84291920',
  '842920',
  '84292000',
  '842930',
  '84293000',
  '842940',
  '84294010',
  '84294020',
  '84294030',
  '842951',
  '84295100',
  '842952',
  '84295200',
  '842959',
  '84295900',
  '8430',
  '843010',
  '84301010',
  '84301020',
  '843020',
  '84302000',
  '843031',
  '84303110',
  '84303120',
  '84303190',
  '843039',
  '84303900',
  '843041',
  '84304110',
  '84304120',
  '84304130',
  '84304190',
  '843049',
  '84304900',
  '843050',
  '84305010',
  '84305090',
  '84306100',
  '84306900',
  '8431',
  '843110',
  '84311010',
  '84311090',
  '843120',
  '84312010',
  '84312090',
  '843131',
  '84313100',
  '843139',
  '84313910',
  '84313990',
  '843141',
  '84314100',
  '843142',
  '84314200',
  '843143',
  '84314310',
  '84314390',
  '843149',
  '84314910',
  '84314920',
  '84314930',
  '84314940',
  '84314990',
  '8432',
  '843210',
  '84321010',
  '84321020',
  '84321090',
  '843221',
  '84322100',
  '843229',
  '84322910',
  '84322990',
  '84323000',
  '843231',
  '84323100',
  '843239',
  '84323900',
  '84324000',
  '843241',
  '84324100',
  '843242',
  '84324200',
  '843280',
  '84328010',
  '84328020',
  '84328090',
  '843290',
  '84329010',
  '84329090',
  '8433',
  '843311',
  '84331110',
  '84331190',
  '843319',
  '84331910',
  '84331990',
  '843320',
  '84332000',
  '843330',
  '84333000',
  '843340',
  '84334000',
  '843351',
  '84335100',
  '843352',
  '84335200',
  '843353',
  '84335300',
  '843359',
  '84335900',
  '843360',
  '84336010',
  '84336020',
  '843390',
  '84339000',
  '8434',
  '843410',
  '84341000',
  '843420',
  '84342000',
  '843490',
  '84349010',
  '84349020',
  '8435',
  '843510',
  '84351000',
  '843590',
  '84359000',
  '8436',
  '843610',
  '84361000',
  '843621',
  '84362100',
  '843629',
  '84362900',
  '843680',
  '84368010',
  '84368090',
  '843691',
  '84369100',
  '843699',
  '84369900',
  '8437',
  '843710',
  '84371000',
  '843780',
  '84378010',
  '84378020',
  '84378090',
  '843790',
  '84379010',
  '84379020',
  '84379090',
  '8438',
  '843810',
  '84381010',
  '84381020',
  '843820',
  '84382000',
  '843830',
  '84383010',
  '84383090',
  '843840',
  '84384000',
  '84385000',
  '843860',
  '84386000',
  '843880',
  '84388010',
  '84388020',
  '84388030',
  '84388040',
  '84388090',
  '843890',
  '84389010',
  '84389090',
  '8439',
  '843910',
  '84391000',
  '843920',
  '84392000',
  '843930',
  '84393010',
  '84393090',
  '843991',
  '84399100',
  '843999',
  '84399900',
  '8440',
  '844010',
  '84401010',
  '84401090',
  '844090',
  '84409000',
  '8441',
  '844110',
  '84411010',
  '84411090',
  '844120',
  '84412000',
  '844130',
  '84413000',
  '844140',
  '84414000',
  '844180',
  '84418000',
  '844190',
  '84419000',
  '8442',
  '84421000',
  '84422010',
  '84422020',
  '84422030',
  '84422090',
  '844230',
  '84423010',
  '84423020',
  '84423090',
  '844240',
  '84424000',
  '844250',
  '84425010',
  '84425020',
  '84425031',
  '84425032',
  '84425039',
  '84425040',
  '84425050',
  '84425060',
  '84425090',
  '8443',
  '844311',
  '84431100',
  '844312',
  '84431200',
  '844313',
  '84431300',
  '844314',
  '84431400',
  '844315',
  '84431500',
  '844316',
  '84431600',
  '844317',
  '84431700',
  '844319',
  '84431900',
  '84431910',
  '84431920',
  '84431930',
  '84431941',
  '84431949',
  '84431990',
  '84432100',
  '84432900',
  '84433000',
  '844331',
  '84433100',
  '844332',
  '84433210',
  '84433220',
  '84433230',
  '84433240',
  '84433250',
  '84433260',
  '84433290',
  '844339',
  '84433910',
  '84433920',
  '84433930',
  '84433940',
  '84433950',
  '84433960',
  '84433970',
  '84433990',
  '84434000',
  '84435100',
  '84435910',
  '84435920',
  '84435930',
  '84435941',
  '84435949',
  '84435990',
  '84436010',
  '84436020',
  '84436030',
  '84436090',
  '84439010',
  '84439090',
  '844391',
  '84439100',
  '844399',
  '84439910',
  '84439920',
  '84439930',
  '84439940',
  '84439950',
  '84439951',
  '84439952',
  '84439953',
  '84439959',
  '84439960',
  '84439970',
  '84439990',
  '8444',
  '844400',
  '84440010',
  '84440090',
  '8445',
  '844511',
  '84451110',
  '84451190',
  '844512',
  '84451210',
  '84451290',
  '844513',
  '84451300',
  '844519',
  '84451910',
  '84451920',
  '84451930',
  '84451940',
  '84451950',
  '84451960',
  '84451990',
  '844520',
  '84452011',
  '84452012',
  '84452013',
  '84452014',
  '84452019',
  '84452020',
  '84452030',
  '84452040',
  '84452050',
  '84452090',
  '844530',
  '84453011',
  '84453019',
  '84453020',
  '84453030',
  '84453040',
  '84453050',
  '84453090',
  '844540',
  '84454010',
  '84454020',
  '84454030',
  '84454040',
  '84454050',
  '84454090',
  '844590',
  '84459000',
  '8446',
  '844610',
  '84461011',
  '84461012',
  '84461019',
  '84461090',
  '844621',
  '84462110',
  '84462190',
  '844629',
  '84462910',
  '84462990',
  '844630',
  '84463011',
  '84463012',
  '84463019',
  '84463090',
  '8447',
  '844711',
  '84471111',
  '84471119',
  '84471120',
  '84471190',
  '844712',
  '84471211',
  '84471219',
  '84471220',
  '84471290',
  '844720',
  '84472010',
  '84472020',
  '84472030',
  '84472090',
  '844790',
  '84479010',
  '84479020',
  '84479030',
  '8448',
  '844811',
  '84481110',
  '84481190',
  '844819',
  '84481900',
  '844820',
  '84482000',
  '844831',
  '84483100',
  '844832',
  '84483210',
  '84483220',
  '84483230',
  '84483240',
  '84483290',
  '844833',
  '84483310',
  '84483320',
  '84483330',
  '84483340',
  '84483390',
  '844839',
  '84483910',
  '84483920',
  '84483990',
  '84484110',
  '84484120',
  '84484130',
  '84484140',
  '84484150',
  '84484160',
  '84484190',
  '844842',
  '84484210',
  '84484220',
  '84484290',
  '844849',
  '84484910',
  '84484920',
  '84484930',
  '84484940',
  '84484950',
  '84484990',
  '844851',
  '84485110',
  '84485120',
  '84485130',
  '84485190',
  '844859',
  '84485900',
  '8449',
  '844900',
  '84490010',
  '84490090',
  '8450',
  '845011',
  '84501100',
  '845012',
  '84501200',
  '845019',
  '84501900',
  '845020',
  '84502000',
  '845090',
  '84509010',
  '84509090',
  '8451',
  '845110',
  '84511010',
  '84511090',
  '845121',
  '84512100',
  '845129',
  '84512900',
  '845130',
  '84513010',
  '84513090',
  '845140',
  '84514011',
  '84514019',
  '84514021',
  '84514029',
  '84514091',
  '84514099',
  '845150',
  '84515000',
  '845180',
  '84518011',
  '84518019',
  '84518021',
  '84518022',
  '84518029',
  '84518090',
  '845190',
  '84519000',
  '8452',
  '845210',
  '84521011',
  '84521012',
  '84521019',
  '84521021',
  '84521022',
  '84521029',
  '845221',
  '84522110',
  '84522120',
  '84522190',
  '845229',
  '84522900',
  '845230',
  '84523010',
  '84523090',
  '845240',
  '845290',
  '84529011',
  '84529019',
  '84529091',
  '84529099',
  '8453',
  '845310',
  '84531000',
  '845320',
  '84532000',
  '845380',
  '84538000',
  '845390',
  '84539010',
  '84539090',
  '8454',
  '845410',
  '84541000',
  '845420',
  '84542010',
  '84542020',
  '845430',
  '84543010',
  '84543020',
  '84543090',
  '845490',
  '84549000',
  '8455',
  '845510',
  '84551000',
  '845521',
  '84552110',
  '84552120',
  '845522',
  '84552200',
  '845530',
  '84553000',
  '845590',
  '84559000',
  '8456',
  '84561000',
  '845611',
  '84561100',
  '845612',
  '84561200',
  '845620',
  '84562000',
  '845630',
  '84563000',
  '845640',
  '84564000',
  '845650',
  '84565000',
  '845690',
  '84569010',
  '84569020',
  '84569090',
  '84569100',
  '84569910',
  '84569990',
  '8457',
  '845710',
  '84571010',
  '84571020',
  '845720',
  '84572010',
  '84572020',
  '84572090',
  '845730',
  '84573010',
  '84573020',
  '84573090',
  '8458',
  '845811',
  '84581100',
  '845819',
  '84581911',
  '84581912',
  '84581913',
  '84581919',
  '84581990',
  '845891',
  '84589100',
  '845899',
  '84589910',
  '84589920',
  '84589931',
  '84589932',
  '84589933',
  '84589934',
  '84589935',
  '84589941',
  '84589942',
  '84589943',
  '84589951',
  '84589959',
  '84589990',
  '8459',
  '845910',
  '84591000',
  '845921',
  '84592100',
  '845929',
  '84592910',
  '84592920',
  '84592930',
  '84592940',
  '84592950',
  '84592990',
  '845931',
  '84593100',
  '845939',
  '84593910',
  '84593990',
  '845940',
  '84594010',
  '84594020',
  '84594030',
  '84594090',
  '845941',
  '84594110',
  '84594120',
  '84594130',
  '84594190',
  '845949',
  '84594910',
  '84594920',
  '84594930',
  '84594990',
  '845951',
  '84595110',
  '84595120',
  '84595130',
  '84595190',
  '845959',
  '84595910',
  '84595920',
  '84595930',
  '84595940',
  '84595950',
  '84595990',
  '845961',
  '84596110',
  '84596190',
  '845969',
  '84596910',
  '84596920',
  '84596930',
  '84596940',
  '84596990',
  '845970',
  '84597010',
  '84597020',
  '8460',
  '84601100',
  '846012',
  '84601200',
  '846019',
  '84601900',
  '84602100',
  '846022',
  '84602200',
  '846023',
  '84602300',
  '846024',
  '84602400',
  '846029',
  '84602910',
  '84602920',
  '84602930',
  '84602940',
  '84602990',
  '846031',
  '84603100',
  '846039',
  '84603910',
  '84603990',
  '846040',
  '84604011',
  '84604012',
  '84604013',
  '84604019',
  '84604020',
  '846090',
  '84609010',
  '84609090',
  '8461',
  '846120',
  '84612011',
  '84612019',
  '84612020',
  '846130',
  '84613010',
  '84613020',
  '84613090',
  '846140',
  '84614011',
  '84614012',
  '84614013',
  '84614014',
  '84614019',
  '84614021',
  '84614022',
  '84614023',
  '84614024',
  '84614025',
  '84614026',
  '84614029',
  '846150',
  '84615011',
  '84615012',
  '84615013',
  '84615014',
  '84615015',
  '84615019',
  '84615021',
  '84615029',
  '846190',
  '84619000',
  '8462',
  '846210',
  '84621011',
  '84621012',
  '84621013',
  '84621014',
  '84621019',
  '84621020',
  '84621030',
  '846211',
  '84621100',
  '846219',
  '84621900',
  '84622100',
  '846222',
  '84622200',
  '846223',
  '84622300',
  '846224',
  '84622400',
  '846225',
  '84622500',
  '846226',
  '84622600',
  '846229',
  '84622900',
  '84622910',
  '84622920',
  '84622930',
  '84622990',
  '84623100',
  '846232',
  '84623200',
  '846233',
  '84623300',
  '846239',
  '84623900',
  '84623910',
  '84623920',
  '84623990',
  '84624100',
  '846242',
  '84624200',
  '846249',
  '84624900',
  '84624910',
  '84624920',
  '84624930',
  '84624990',
  '846251',
  '84625100',
  '846259',
  '84625900',
  '846261',
  '84626100',
  '846262',
  '84626200',
  '846263',
  '84626300',
  '846269',
  '84626900',
  '846290',
  '84629000',
  '846291',
  '84629110',
  '84629190',
  '846299',
  '84629911',
  '84629912',
  '84629913',
  '84629914',
  '84629915',
  '84629919',
  '84629920',
  '84629930',
  '84629940',
  '84629950',
  '84629960',
  '84629970',
  '84629990',
  '8463',
  '846310',
  '84631010',
  '84631020',
  '84631030',
  '84631090',
  '846320',
  '84632000',
  '846330',
  '84633010',
  '84633020',
  '84633030',
  '84633040',
  '846390',
  '84639010',
  '84639020',
  '84639030',
  '84639090',
  '8464',
  '846410',
  '84641010',
  '84641090',
  '846420',
  '84642000',
  '846490',
  '84649000',
  '8465',
  '846510',
  '84651000',
  '846520',
  '84652000',
  '846591',
  '84659100',
  '846592',
  '84659200',
  '846593',
  '84659300',
  '846594',
  '84659400',
  '846595',
  '84659500',
  '846596',
  '84659600',
  '846599',
  '84659910',
  '84659990',
  '8466',
  '846610',
  '84661010',
  '84661020',
  '846620',
  '84662000',
  '846630',
  '84663010',
  '84663020',
  '84663090',
  '846691',
  '84669100',
  '846692',
  '84669200',
  '846693',
  '84669310',
  '84669390',
  '846694',
  '84669400',
  '8467',
  '846711',
  '84671110',
  '84671120',
  '84671190',
  '846719',
  '84671900',
  '846721',
  '84672100',
  '846722',
  '84672200',
  '846729',
  '84672900',
  '846781',
  '84678100',
  '846789',
  '84678910',
  '84678920',
  '84678990',
  '846791',
  '84679100',
  '846792',
  '84679200',
  '846799',
  '84679900',
  '8468',
  '846810',
  '84681000',
  '846820',
  '84682010',
  '84682090',
  '846880',
  '84688000',
  '846890',
  '84689000',
  '8469',
  '846900',
  '84690010',
  '84690020',
  '84690030',
  '84690040',
  '84690090',
  '84691100',
  '84691200',
  '84692010',
  '84692090',
  '84693010',
  '84693090',
  '8470',
  '847010',
  '84701000',
  '847021',
  '84702100',
  '847029',
  '84702900',
  '847030',
  '84703000',
  '84704010',
  '84704020',
  '847050',
  '84705010',
  '84705020',
  '847090',
  '84709010',
  '84709020',
  '8471',
  '84711000',
  '847130',
  '84713010',
  '84713090',
  '847141',
  '84714110',
  '84714120',
  '84714190',
  '847149',
  '84714900',
  '847150',
  '84715000',
  '847160',
  '84716010',
  '84716021',
  '84716022',
  '84716023',
  '84716024',
  '84716025',
  '84716026',
  '84716027',
  '84716029',
  '84716030',
  '84716040',
  '84716050',
  '84716060',
  '84716090',
  '847170',
  '84717010',
  '84717020',
  '84717030',
  '84717040',
  '84717050',
  '84717060',
  '84717070',
  '84717090',
  '847180',
  '84718000',
  '847190',
  '84719000',
  '8472',
  '847210',
  '84721000',
  '84722000',
  '847230',
  '84723000',
  '847290',
  '84729010',
  '84729020',
  '84729030',
  '84729040',
  '84729090',
  '84729091',
  '84729092',
  '84729093',
  '84729094',
  '84729095',
  '84729099',
  '8473',
  '84731000',
  '847321',
  '84732100',
  '847329',
  '84732900',
  '847330',
  '84733010',
  '84733020',
  '84733030',
  '84733040',
  '84733050',
  '84733060',
  '84733091',
  '84733092',
  '84733099',
  '847340',
  '84734010',
  '84734090',
  '847350',
  '84735000',
  '8474',
  '847410',
  '84741010',
  '84741090',
  '847420',
  '84742010',
  '84742020',
  '84742090',
  '847431',
  '84743110',
  '84743120',
  '847432',
  '84743200',
  '847439',
  '84743900',
  '847480',
  '84748010',
  '84748020',
  '84748030',
  '84748090',
  '847490',
  '84749000',
  '8475',
  '847510',
  '84751000',
  '847521',
  '84752100',
  '847529',
  '84752900',
  '847590',
  '84759000',
  '8476',
  '847621',
  '84762110',
  '84762120',
  '847629',
  '84762900',
  '847681',
  '84768110',
  '84768120',
  '847689',
  '84768910',
  '84768920',
  '84768930',
  '84768990',
  '847690',
  '84769010',
  '84769090',
  '8477',
  '847710',
  '84771000',
  '847720',
  '84772000',
  '847730',
  '84773000',
  '847740',
  '84774000',
  '847751',
  '84775100',
  '847759',
  '84775900',
  '847780',
  '84778010',
  '84778090',
  '847790',
  '84779000',
  '8478',
  '847810',
  '84781010',
  '84781020',
  '84781090',
  '847890',
  '84789000',
  '8479',
  '847910',
  '84791000',
  '847920',
  '84792010',
  '84792090',
  '847930',
  '84793000',
  '847940',
  '84794000',
  '847950',
  '84795000',
  '847960',
  '84796000',
  '847971',
  '84797100',
  '847979',
  '84797900',
  '847981',
  '84798100',
  '847982',
  '84798200',
  '847983',
  '84798300',
  '847989',
  '84798910',
  '84798920',
  '84798930',
  '84798940',
  '84798950',
  '84798960',
  '84798970',
  '84798991',
  '84798992',
  '84798999',
  '847990',
  '84799010',
  '84799020',
  '84799030',
  '84799040',
  '84799090',
  '8480',
  '848010',
  '84801000',
  '848020',
  '84802000',
  '848030',
  '84803000',
  '848041',
  '84804100',
  '848049',
  '84804900',
  '848050',
  '84805000',
  '848060',
  '84806000',
  '848071',
  '84807100',
  '848079',
  '84807900',
  '8481',
  '848110',
  '84811000',
  '848120',
  '84812000',
  '848130',
  '84813000',
  '848140',
  '84814000',
  '848180',
  '84818010',
  '84818020',
  '84818030',
  '84818041',
  '84818049',
  '84818050',
  '84818090',
  '848190',
  '84819010',
  '84819090',
  '8482',
  '848210',
  '84821011',
  '84821012',
  '84821013',
  '84821020',
  '84821030',
  '84821040',
  '84821051',
  '84821052',
  '84821053',
  '84821090',
  '848220',
  '84822011',
  '84822012',
  '84822013',
  '84822090',
  '848230',
  '84823000',
  '848240',
  '84824000',
  '848250',
  '84825000',
  '84825011',
  '84825012',
  '84825013',
  '84825021',
  '84825022',
  '84825023',
  '848280',
  '84828000',
  '848291',
  '84829111',
  '84829112',
  '84829113',
  '84829114',
  '84829119',
  '84829120',
  '84829130',
  '848299',
  '84829900',
  '8483',
  '848310',
  '84831010',
  '84831091',
  '84831092',
  '84831099',
  '848320',
  '84832000',
  '848330',
  '84833000',
  '848340',
  '84834000',
  '848350',
  '84835010',
  '84835090',
  '848360',
  '84836010',
  '84836020',
  '84836090',
  '848390',
  '84839000',
  '8484',
  '848410',
  '84841010',
  '84841090',
  '848420',
  '84842000',
  '848490',
  '84849000',
  '8485',
  '848510',
  '84851000',
  '848520',
  '84852000',
  '848530',
  '84853000',
  '848580',
  '84858000',
  '848590',
  '84859000',
  '8486',
  '848610',
  '84861000',
  '848620',
  '84862000',
  '848630',
  '84863000',
  '848640',
  '84864000',
  '848690',
  '84869000',
  '8487',
  '848710',
  '84871000',
  '848790',
  '84879000',
  '85',
  '8501',
  '850110',
  '85011011',
  '85011012',
  '85011013',
  '85011019',
  '85011020',
  '850120',
  '85012000',
  '850131',
  '85013111',
  '85013112',
  '85013113',
  '85013119',
  '85013120',
  '850132',
  '85013210',
  '85013220',
  '850133',
  '85013310',
  '85013320',
  '850134',
  '85013410',
  '85013420',
  '85013430',
  '85013440',
  '85013450',
  '850140',
  '85014010',
  '85014090',
  '850151',
  '85015110',
  '85015120',
  '85015190',
  '850152',
  '85015210',
  '85015220',
  '85015290',
  '850153',
  '85015310',
  '85015320',
  '85015330',
  '85015390',
  '850161',
  '85016100',
  '850162',
  '85016200',
  '850163',
  '85016300',
  '850164',
  '85016410',
  '85016420',
  '85016430',
  '85016440',
  '85016450',
  '85016460',
  '85016470',
  '85016480',
  '850171',
  '85017100',
  '850172',
  '85017200',
  '850180',
  '85018000',
  '8502',
  '850211',
  '85021100',
  '850212',
  '85021200',
  '850213',
  '85021310',
  '85021320',
  '85021330',
  '85021340',
  '85021350',
  '85021360',
  '850220',
  '85022010',
  '85022090',
  '850231',
  '85023100',
  '850239',
  '85023910',
  '85023920',
  '85023990',
  '850240',
  '85024000',
  '8503',
  '850300',
  '85030010',
  '85030021',
  '85030029',
  '85030090',
  '8504',
  '850410',
  '85041010',
  '85041020',
  '85041090',
  '850421',
  '85042100',
  '850422',
  '85042200',
  '850423',
  '85042310',
  '85042320',
  '85042330',
  '85042340',
  '850431',
  '85043100',
  '850432',
  '85043200',
  '850433',
  '85043300',
  '850434',
  '85043400',
  '850440',
  '85044010',
  '85044021',
  '85044029',
  '85044030',
  '85044040',
  '85044090',
  '850450',
  '85045010',
  '85045090',
  '850490',
  '85049010',
  '85049090',
  '8505',
  '850511',
  '85051110',
  '85051190',
  '850519',
  '85051900',
  '850520',
  '85052000',
  '85053000',
  '850590',
  '85059000',
  '8506',
  '850610',
  '85061000',
  '850630',
  '85063000',
  '850640',
  '85064000',
  '850650',
  '85065000',
  '850660',
  '85066000',
  '850680',
  '85068010',
  '85068090',
  '850690',
  '85069000',
  '8507',
  '850710',
  '85071000',
  '850720',
  '85072000',
  '850730',
  '85073000',
  '85074000',
  '850750',
  '85075000',
  '850760',
  '85076000',
  '850780',
  '85078000',
  '850790',
  '85079010',
  '85079090',
  '8508',
  '850811',
  '85081100',
  '850819',
  '85081900',
  '850860',
  '85086000',
  '850870',
  '85087000',
  '8509',
  '85091000',
  '85092000',
  '85093000',
  '850940',
  '85094010',
  '85094090',
  '850980',
  '85098000',
  '850990',
  '85099000',
  '8510',
  '851010',
  '85101000',
  '851020',
  '85102000',
  '851030',
  '85103000',
  '851090',
  '85109000',
  '8511',
  '851110',
  '85111000',
  '851120',
  '85112010',
  '85112090',
  '851130',
  '85113010',
  '85113020',
  '851140',
  '85114000',
  '851150',
  '85115000',
  '851180',
  '85118000',
  '851190',
  '85119000',
  '8512',
  '851210',
  '85121000',
  '851220',
  '85122010',
  '85122020',
  '85122090',
  '851230',
  '85123010',
  '85123090',
  '851240',
  '85124000',
  '851290',
  '85129000',
  '8513',
  '851310',
  '85131010',
  '85131020',
  '85131030',
  '85131040',
  '85131090',
  '851390',
  '85139000',
  '8514',
  '85141000',
  '851411',
  '85141100',
  '851419',
  '85141900',
  '851420',
  '85142000',
  '851430',
  '85143010',
  '85143090',
  '851431',
  '85143100',
  '851432',
  '85143200',
  '851439',
  '85143900',
  '851440',
  '85144000',
  '851490',
  '85149000',
  '8515',
  '851511',
  '85151100',
  '851519',
  '85151900',
  '851521',
  '85152110',
  '85152120',
  '85152190',
  '851529',
  '85152900',
  '851531',
  '85153100',
  '851539',
  '85153910',
  '85153920',
  '85153990',
  '851580',
  '85158010',
  '85158090',
  '851590',
  '85159000',
  '8516',
  '851610',
  '85161000',
  '851621',
  '85162100',
  '851629',
  '85162900',
  '851631',
  '85163100',
  '851632',
  '85163200',
  '851633',
  '85163300',
  '851640',
  '85164000',
  '851650',
  '85165000',
  '851660',
  '85166000',
  '851671',
  '85167100',
  '851672',
  '85167200',
  '851679',
  '85167910',
  '85167920',
  '85167990',
  '851680',
  '85168000',
  '851690',
  '85169000',
  '8517',
  '851711',
  '85171110',
  '85171190',
  '851712',
  '85171210',
  '85171211',
  '85171219',
  '85171290',
  '851713',
  '85171300',
  '851714',
  '85171400',
  '851718',
  '85171810',
  '85171890',
  '85171911',
  '85171912',
  '85171919',
  '85171920',
  '85172100',
  '85172200',
  '85173000',
  '85175010',
  '85175020',
  '85175030',
  '85175040',
  '85175050',
  '85175060',
  '85175070',
  '85175091',
  '85175092',
  '85175093',
  '85175094',
  '85175099',
  '851761',
  '85176100',
  '851762',
  '85176210',
  '85176220',
  '85176230',
  '85176240',
  '85176250',
  '85176260',
  '85176270',
  '85176290',
  '851769',
  '85176910',
  '85176920',
  '85176930',
  '85176940',
  '85176950',
  '85176960',
  '85176970',
  '85176990',
  '851770',
  '85177010',
  '85177090',
  '851771',
  '85177100',
  '851779',
  '85177910',
  '85177990',
  '85178010',
  '85178020',
  '85178030',
  '85178090',
  '85179010',
  '85179090',
  '8518',
  '851810',
  '85181000',
  '851821',
  '85182100',
  '85182110',
  '85182190',
  '851822',
  '85182200',
  '85182210',
  '85182290',
  '851829',
  '85182900',
  '85182910',
  '85182990',
  '851830',
  '85183000',
  '85183011',
  '85183019',
  '85183020',
  '85183090',
  '851840',
  '85184000',
  '851850',
  '85185000',
  '851890',
  '85189000',
  '8519',
  '85191000',
  '851920',
  '85192000',
  '85192100',
  '85192900',
  '851930',
  '85193000',
  '85193100',
  '85193900',
  '85194000',
  '85195000',
  '851981',
  '85198100',
  '851989',
  '85198910',
  '85198920',
  '85198930',
  '85198940',
  '85198990',
  '85199200',
  '85199300',
  '85199910',
  '85199920',
  '85199930',
  '85199940',
  '85199990',
  '8520',
  '85201000',
  '85202000',
  '85203200',
  '85203310',
  '85203390',
  '85203910',
  '85203990',
  '85209010',
  '85209090',
  '8521',
  '852110',
  '85211011',
  '85211012',
  '85211019',
  '85211021',
  '85211022',
  '85211029',
  '85211091',
  '85211092',
  '85211099',
  '852190',
  '85219010',
  '85219020',
  '85219090',
  '8522',
  '852210',
  '85221000',
  '852290',
  '85229000',
  '8523',
  '85231111',
  '85231119',
  '85231121',
  '85231122',
  '85231129',
  '85231211',
  '85231219',
  '85231221',
  '85231222',
  '85231229',
  '85231311',
  '85231312',
  '85231313',
  '85231319',
  '85231321',
  '85231322',
  '85231323',
  '85231324',
  '85231325',
  '85231329',
  '85232010',
  '85232020',
  '85232090',
  '852321',
  '85232100',
  '852329',
  '85232910',
  '85232920',
  '85232930',
  '85232940',
  '85232950',
  '85232960',
  '85232970',
  '85232980',
  '85232990',
  '85233000',
  '852340',
  '852341',
  '85234110',
  '85234120',
  '85234130',
  '85234140',
  '85234150',
  '85234160',
  '85234190',
  '852349',
  '85234910',
  '85234920',
  '85234930',
  '85234940',
  '85234950',
  '85234960',
  '85234970',
  '85234990',
  '852351',
  '85235100',
  '852352',
  '85235210',
  '85235220',
  '85235290',
  '852359',
  '85235910',
  '85235990',
  '852380',
  '85238010',
  '85238020',
  '85238030',
  '85238040',
  '85238050',
  '85238060',
  '85238090',
  '85239010',
  '85239020',
  '85239030',
  '85239040',
  '85239050',
  '85239060',
  '85239090',
  '8524',
  '85241010',
  '85241090',
  '852411',
  '85241100',
  '85241110',
  '85241120',
  '85241130',
  '85241190',
  '852412',
  '85241200',
  '85241210',
  '85241220',
  '85241230',
  '85241290',
  '852419',
  '85241900',
  '85241910',
  '85241920',
  '85241930',
  '85241990',
  '85243111',
  '85243119',
  '85243190',
  '85243210',
  '85243290',
  '85243910',
  '85243920',
  '85243930',
  '85243990',
  '85244011',
  '85244019',
  '85244090',
  '85245111',
  '85245112',
  '85245119',
  '85245120',
  '85245130',
  '85245140',
  '85245190',
  '85245211',
  '85245212',
  '85245219',
  '85245220',
  '85245230',
  '85245240',
  '85245290',
  '85245311',
  '85245312',
  '85245319',
  '85245320',
  '85245330',
  '85245340',
  '85245390',
  '85246000',
  '852491',
  '85249100',
  '85249110',
  '85249111',
  '85249112',
  '85249113',
  '85249119',
  '85249120',
  '85249130',
  '85249190',
  '852492',
  '85249200',
  '85249210',
  '85249220',
  '85249230',
  '85249290',
  '852499',
  '85249900',
  '85249910',
  '85249920',
  '85249930',
  '85249990',
  '85249991',
  '85249999',
  '8525',
  '85251010',
  '85251020',
  '85251030',
  '85251040',
  '85251050',
  '85251090',
  '85252011',
  '85252012',
  '85252013',
  '85252014',
  '85252015',
  '85252016',
  '85252017',
  '85252019',
  '85252091',
  '85252092',
  '85252099',
  '85253000',
  '85254000',
  '852550',
  '85255010',
  '85255020',
  '85255030',
  '85255040',
  '85255050',
  '85255090',
  '852560',
  '85256000',
  '85256011',
  '85256012',
  '85256013',
  '85256019',
  '85256091',
  '85256092',
  '85256099',
  '852580',
  '85258010',
  '85258020',
  '85258030',
  '85258090',
  '852581',
  '85258100',
  '852582',
  '85258200',
  '852583',
  '85258300',
  '852589',
  '85258900',
  '8526',
  '852610',
  '85261000',
  '852691',
  '85269110',
  '85269120',
  '85269130',
  '85269140',
  '85269150',
  '85269190',
  '852692',
  '85269200',
  '8527',
  '852712',
  '85271200',
  '852713',
  '85271300',
  '852719',
  '85271900',
  '852721',
  '85272100',
  '852729',
  '85272900',
  '85273100',
  '85273200',
  '85273900',
  '85279011',
  '85279012',
  '85279019',
  '85279090',
  '852791',
  '85279100',
  '852792',
  '85279200',
  '852799',
  '85279900',
  '85279911',
  '85279912',
  '85279919',
  '85279990',
  '8528',
  '85281211',
  '85281212',
  '85281213',
  '85281214',
  '85281215',
  '85281216',
  '85281217',
  '85281218',
  '85281219',
  '85281291',
  '85281299',
  '85281310',
  '85281390',
  '85282110',
  '85282190',
  '85282200',
  '85283010',
  '85283020',
  '85283030',
  '85284100',
  '852842',
  '85284200',
  '852849',
  '85284900',
  '85285100',
  '852852',
  '85285200',
  '852859',
  '85285900',
  '85286100',
  '852862',
  '85286200',
  '852869',
  '85286900',
  '852871',
  '85287100',
  '852872',
  '85287211',
  '85287212',
  '85287213',
  '85287214',
  '85287215',
  '85287216',
  '85287217',
  '85287218',
  '85287219',
  '852873',
  '85287310',
  '85287390',
  '8529',
  '852910',
  '85291011',
  '85291012',
  '85291019',
  '85291021',
  '85291022',
  '85291029',
  '85291091',
  '85291092',
  '85291099',
  '852990',
  '85299010',
  '85299020',
  '85299030',
  '85299090',
  '8530',
  '853010',
  '85301010',
  '85301020',
  '853080',
  '85308000',
  '853090',
  '85309000',
  '8531',
  '853110',
  '85311010',
  '85311020',
  '85311090',
  '853120',
  '85312000',
  '853180',
  '85318000',
  '853190',
  '85319000',
  '8532',
  '853210',
  '85321000',
  '853221',
  '85322100',
  '853222',
  '85322200',
  '853223',
  '85322300',
  '853224',
  '85322400',
  '853225',
  '85322500',
  '853229',
  '85322910',
  '85322990',
  '853230',
  '85323000',
  '853290',
  '85329000',
  '8533',
  '853310',
  '85331000',
  '853321',
  '85332111',
  '85332119',
  '85332121',
  '85332129',
  '853329',
  '85332911',
  '85332919',
  '85332921',
  '85332929',
  '853331',
  '85333110',
  '85333120',
  '85333190',
  '853339',
  '85333910',
  '85333920',
  '85333990',
  '853340',
  '85334010',
  '85334020',
  '85334030',
  '85334090',
  '853390',
  '85339000',
  '8534',
  '853400',
  '85340000',
  '8535',
  '853510',
  '85351010',
  '85351020',
  '85351030',
  '85351040',
  '85351050',
  '85351090',
  '853521',
  '85352111',
  '85352112',
  '85352113',
  '85352119',
  '85352121',
  '85352122',
  '85352123',
  '85352129',
  '85352190',
  '853529',
  '85352911',
  '85352912',
  '85352913',
  '85352919',
  '85352921',
  '85352922',
  '85352923',
  '85352929',
  '85352990',
  '853530',
  '85353010',
  '85353090',
  '853540',
  '85354010',
  '85354020',
  '85354030',
  '853590',
  '85359010',
  '85359020',
  '85359030',
  '85359040',
  '85359090',
  '8536',
  '853610',
  '85361010',
  '85361020',
  '85361030',
  '85361040',
  '85361050',
  '85361060',
  '85361090',
  '853620',
  '85362010',
  '85362020',
  '85362030',
  '85362040',
  '85362090',
  '853630',
  '85363000',
  '853641',
  '85364100',
  '853649',
  '85364900',
  '853650',
  '85365010',
  '85365020',
  '85365090',
  '853661',
  '85366110',
  '85366190',
  '853669',
  '85366910',
  '85366990',
  '853670',
  '85367000',
  '853690',
  '85369010',
  '85369020',
  '85369030',
  '85369090',
  '8537',
  '853710',
  '85371000',
  '853720',
  '85372000',
  '8538',
  '853810',
  '85381010',
  '85381090',
  '85389000',
  '8539',
  '853910',
  '85391000',
  '853921',
  '85392110',
  '85392120',
  '85392190',
  '853922',
  '85392200',
  '853929',
  '85392910',
  '85392920',
  '85392930',
  '85392940',
  '85392990',
  '853931',
  '85393110',
  '85393190',
  '853932',
  '85393210',
  '85393220',
  '85393230',
  '853939',
  '85393910',
  '85393990',
  '853941',
  '85394100',
  '853949',
  '85394900',
  '85395000',
  '853951',
  '85395100',
  '853952',
  '85395200',
  '853990',
  '85399010',
  '85399020',
  '85399090',
  '8540',
  '854011',
  '85401110',
  '85401120',
  '85401190',
  '854012',
  '85401200',
  '854020',
  '85402000',
  '854040',
  '85404010',
  '85404020',
  '854060',
  '85406000',
  '854071',
  '85407100',
  '854079',
  '85407900',
  '854081',
  '85408100',
  '854089',
  '85408900',
  '854091',
  '85409100',
  '854099',
  '85409900',
  '8541',
  '854110',
  '85411000',
  '854121',
  '85412100',
  '854129',
  '85412900',
  '854130',
  '85413010',
  '85413090',
  '854140',
  '85414011',
  '85414012',
  '85414019',
  '85414020',
  '85414090',
  '854141',
  '85414100',
  '854142',
  '85414200',
  '854143',
  '85414300',
  '854149',
  '85414900',
  '85415000',
  '854151',
  '85415100',
  '854159',
  '85415900',
  '854160',
  '85416000',
  '854190',
  '85419000',
  '8542',
  '85421010',
  '85421020',
  '85421090',
  '85422100',
  '85422910',
  '85422990',
  '854231',
  '85423100',
  '854232',
  '85423200',
  '854233',
  '85423300',
  '854239',
  '85423900',
  '85426000',
  '85427000',
  '854290',
  '85429000',
  '8543',
  '854310',
  '85431010',
  '85431020',
  '85431030',
  '85431090',
  '85431100',
  '85431910',
  '85431920',
  '85431990',
  '854320',
  '85432010',
  '85432020',
  '85432030',
  '85432090',
  '854330',
  '85433000',
  '854340',
  '85434000',
  '854370',
  '85437011',
  '85437012',
  '85437013',
  '85437019',
  '85437021',
  '85437022',
  '85437029',
  '85437031',
  '85437032',
  '85437033',
  '85437034',
  '85437035',
  '85437036',
  '85437039',
  '85437041',
  '85437042',
  '85437049',
  '85437050',
  '85437061',
  '85437062',
  '85437069',
  '85437071',
  '85437072',
  '85437091',
  '85437092',
  '85437093',
  '85437094',
  '85437095',
  '85437099',
  '85438100',
  '85438910',
  '85438920',
  '85438931',
  '85438932',
  '85438939',
  '85438941',
  '85438942',
  '85438943',
  '85438944',
  '85438945',
  '85438946',
  '85438949',
  '85438951',
  '85438952',
  '85438959',
  '85438960',
  '85438971',
  '85438972',
  '85438979',
  '85438981',
  '85438982',
  '85438991',
  '85438992',
  '85438993',
  '85438994',
  '85438995',
  '85438999',
  '854390',
  '85439000',
  '8544',
  '854411',
  '85441110',
  '85441190',
  '854419',
  '85441910',
  '85441920',
  '85441930',
  '85441990',
  '854420',
  '85442010',
  '85442090',
  '854430',
  '85443000',
  '85444111',
  '85444119',
  '85444120',
  '85444130',
  '85444140',
  '85444190',
  '854442',
  '85444210',
  '85444220',
  '85444230',
  '85444290',
  '85444291',
  '85444292',
  '85444293',
  '85444299',
  '854449',
  '85444910',
  '85444911',
  '85444919',
  '85444920',
  '85444930',
  '85444990',
  '85444991',
  '85444992',
  '85444993',
  '85444999',
  '85445110',
  '85445120',
  '85445130',
  '85445140',
  '85445150',
  '85445190',
  '85445910',
  '85445920',
  '85445930',
  '85445940',
  '85445950',
  '85445990',
  '854460',
  '85446010',
  '85446020',
  '85446030',
  '85446090',
  '854470',
  '85447010',
  '85447090',
  '8545',
  '854511',
  '85451100',
  '854519',
  '85451900',
  '854520',
  '85452000',
  '854590',
  '85459010',
  '85459020',
  '85459090',
  '8546',
  '854610',
  '85461000',
  '854620',
  '85462011',
  '85462019',
  '85462021',
  '85462022',
  '85462023',
  '85462024',
  '85462029',
  '85462031',
  '85462032',
  '85462033',
  '85462039',
  '85462040',
  '85462050',
  '85462090',
  '854690',
  '85469010',
  '85469090',
  '8547',
  '854710',
  '85471010',
  '85471020',
  '85471030',
  '85471040',
  '85471090',
  '85472000',
  '854790',
  '85479010',
  '85479020',
  '85479090',
  '8548',
  '854800',
  '85480000',
  '854810',
  '85481010',
  '85481020',
  '85481090',
  '85489000',
  '8549',
  '854911',
  '85491100',
  '854912',
  '85491200',
  '854913',
  '85491300',
  '854914',
  '85491400',
  '854919',
  '85491900',
  '854921',
  '85492100',
  '854929',
  '85492900',
  '854931',
  '85493100',
  '854939',
  '85493900',
  '854991',
  '85499100',
  '854999',
  '85499900',
  '86',
  '8601',
  '860110',
  '86011000',
  '860120',
  '86012000',
  '8602',
  '860210',
  '86021000',
  '860290',
  '86029010',
  '86029090',
  '8603',
  '860310',
  '86031000',
  '860390',
  '86039000',
  '8604',
  '860400',
  '86040000',
  '8605',
  '860500',
  '86050000',
  '8606',
  '860610',
  '86061010',
  '86061020',
  '86061090',
  '86062000',
  '860630',
  '86063000',
  '860691',
  '86069110',
  '86069120',
  '86069190',
  '860692',
  '86069210',
  '86069220',
  '86069290',
  '860699',
  '86069900',
  '8607',
  '860711',
  '86071100',
  '860712',
  '86071200',
  '860719',
  '86071910',
  '86071920',
  '86071930',
  '86071990',
  '860721',
  '86072100',
  '860729',
  '86072900',
  '860730',
  '86073010',
  '86073090',
  '860791',
  '86079100',
  '860799',
  '86079910',
  '86079920',
  '86079930',
  '86079990',
  '8608',
  '860800',
  '86080010',
  '86080020',
  '86080030',
  '86080040',
  '86080090',
  '8609',
  '860900',
  '86090000',
  '87',
  '8701',
  '870110',
  '87011000',
  '870120',
  '87012010',
  '87012090',
  '870121',
  '87012100',
  '870122',
  '87012200',
  '870123',
  '87012300',
  '870124',
  '87012400',
  '870129',
  '87012900',
  '870130',
  '87013011',
  '87013019',
  '87013091',
  '87013099',
  '870190',
  '87019010',
  '87019090',
  '870191',
  '87019100',
  '870192',
  '87019200',
  '870193',
  '87019300',
  '870194',
  '87019400',
  '870195',
  '87019500',
  '8702',
  '870210',
  '87021011',
  '87021012',
  '87021018',
  '87021019',
  '87021021',
  '87021022',
  '87021028',
  '87021029',
  '87021091',
  '87021092',
  '87021099',
  '870220',
  '87022011',
  '87022012',
  '87022018',
  '87022019',
  '87022021',
  '87022022',
  '87022028',
  '87022029',
  '870230',
  '87023011',
  '87023012',
  '87023018',
  '87023019',
  '87023021',
  '87023022',
  '87023028',
  '87023029',
  '870240',
  '87024011',
  '87024012',
  '87024018',
  '87024019',
  '87024021',
  '87024022',
  '87024028',
  '87024029',
  '870290',
  '87029011',
  '87029012',
  '87029013',
  '87029018',
  '87029019',
  '87029020',
  '87029021',
  '87029022',
  '87029028',
  '87029029',
  '87029091',
  '87029092',
  '87029099',
  '8703',
  '870310',
  '87031010',
  '87031090',
  '870321',
  '87032110',
  '87032120',
  '87032191',
  '87032192',
  '87032199',
  '870322',
  '87032210',
  '87032220',
  '87032230',
  '87032291',
  '87032299',
  '870323',
  '87032310',
  '87032320',
  '87032391',
  '87032392',
  '87032399',
  '870324',
  '87032410',
  '87032420',
  '87032491',
  '87032492',
  '87032499',
  '870331',
  '87033110',
  '87033120',
  '87033191',
  '87033192',
  '87033199',
  '870332',
  '87033210',
  '87033220',
  '87033291',
  '87033292',
  '87033299',
  '870333',
  '87033310',
  '87033320',
  '87033391',
  '87033392',
  '87033399',
  '870340',
  '87034010',
  '87034020',
  '87034030',
  '87034040',
  '87034090',
  '870350',
  '87035010',
  '87035020',
  '87035030',
  '87035040',
  '87035090',
  '870360',
  '87036010',
  '87036020',
  '87036030',
  '87036040',
  '87036090',
  '870370',
  '87037010',
  '87037020',
  '87037030',
  '87037040',
  '87037090',
  '870380',
  '87038010',
  '87038020',
  '87038030',
  '87038040',
  '87038090',
  '870390',
  '87039000',
  '87039010',
  '87039090',
  '8704',
  '870410',
  '87041010',
  '87041090',
  '870421',
  '87042100',
  '87042110',
  '87042120',
  '87042190',
  '870422',
  '87042200',
  '87042211',
  '87042219',
  '87042290',
  '870423',
  '87042300',
  '87042311',
  '87042319',
  '87042390',
  '870431',
  '87043100',
  '87043110',
  '87043120',
  '87043190',
  '870432',
  '87043200',
  '87043211',
  '87043219',
  '87043290',
  '870441',
  '87044100',
  '870442',
  '87044200',
  '870443',
  '87044300',
  '870451',
  '87045100',
  '870452',
  '87045200',
  '870460',
  '87046000',
  '870490',
  '87049011',
  '87049012',
  '87049019',
  '87049090',
  '8705',
  '870510',
  '87051000',
  '870520',
  '87052000',
  '870530',
  '87053000',
  '870540',
  '87054000',
  '870590',
  '87059000',
  '8706',
  '870600',
  '87060011',
  '87060019',
  '87060021',
  '87060029',
  '87060031',
  '87060039',
  '87060041',
  '87060042',
  '87060043',
  '87060049',
  '87060050',
  '8707',
  '87071000',
  '87079000',
  '8708',
  '870810',
  '87081010',
  '87081090',
  '870821',
  '87082100',
  '870822',
  '87082200',
  '870829',
  '87082900',
  '870830',
  '87083000',
  '87083100',
  '87083900',
  '870840',
  '87084000',
  '870850',
  '87085000',
  '87086000',
  '870870',
  '87087000',
  '870880',
  '87088000',
  '870891',
  '87089100',
  '870892',
  '87089200',
  '870893',
  '87089300',
  '870894',
  '87089400',
  '870895',
  '87089500',
  '870899',
  '87089900',
  '8709',
  '870911',
  '87091100',
  '870919',
  '87091900',
  '870990',
  '87099000',
  '8710',
  '871000',
  '87100000',
  '8711',
  '871110',
  '87111010',
  '87111020',
  '87111090',
  '871120',
  '87112011',
  '87112019',
  '87112021',
  '87112029',
  '87112031',
  '87112039',
  '87112091',
  '87112099',
  '871130',
  '87113010',
  '87113020',
  '87113090',
  '871140',
  '87114010',
  '87114090',
  '871150',
  '87115000',
  '871160',
  '87116010',
  '87116020',
  '87116030',
  '87116090',
  '871190',
  '87119010',
  '87119090',
  '87119091',
  '87119099',
  '8712',
  '871200',
  '87120010',
  '87120090',
  '8713',
  '871310',
  '87131010',
  '87131090',
  '871390',
  '87139010',
  '87139090',
  '8714',
  '871410',
  '87141010',
  '87141090',
  '871420',
  '87142010',
  '87142020',
  '87142090',
  '871491',
  '87149100',
  '871492',
  '87149210',
  '87149220',
  '87149290',
  '871493',
  '87149310',
  '87149320',
  '87149390',
  '871494',
  '87149400',
  '871495',
  '87149510',
  '87149590',
  '871496',
  '87149600',
  '871499',
  '87149910',
  '87149920',
  '87149990',
  '8715',
  '871500',
  '87150010',
  '87150020',
  '8716',
  '871610',
  '87161000',
  '871620',
  '87162000',
  '871631',
  '87163100',
  '871639',
  '87163900',
  '871640',
  '87164000',
  '871680',
  '87168010',
  '87168020',
  '87168090',
  '871690',
  '87169010',
  '87169090',
  '88',
  '8801',
  '880100',
  '88010010',
  '88010020',
  '88010090',
  '88011000',
  '88019010',
  '88019090',
  '8802',
  '880211',
  '88021100',
  '880212',
  '88021200',
  '880220',
  '88022000',
  '880230',
  '88023000',
  '880240',
  '88024000',
  '880260',
  '88026000',
  '8803',
  '88031000',
  '88032000',
  '88033000',
  '88039000',
  '8804',
  '880400',
  '88040010',
  '88040020',
  '8805',
  '880510',
  '88051010',
  '88051020',
  '88051030',
  '880521',
  '88052100',
  '880529',
  '88052900',
  '8806',
  '880610',
  '88061000',
  '880621',
  '88062100',
  '880622',
  '88062200',
  '880623',
  '88062300',
  '880624',
  '88062400',
  '880629',
  '88062900',
  '880691',
  '88069100',
  '880692',
  '88069200',
  '880693',
  '88069300',
  '880694',
  '88069400',
  '880699',
  '88069900',
  '8807',
  '880710',
  '88071000',
  '880720',
  '88072000',
  '880730',
  '88073000',
  '880790',
  '88079000',
  '89',
  '8901',
  '890110',
  '89011010',
  '89011020',
  '89011030',
  '89011040',
  '89011090',
  '890120',
  '89012000',
  '890130',
  '89013000',
  '890190',
  '89019000',
  '8902',
  '890200',
  '89020010',
  '89020090',
  '8903',
  '89031000',
  '890311',
  '89031100',
  '890312',
  '89031200',
  '890319',
  '89031900',
  '890321',
  '89032100',
  '890322',
  '89032200',
  '890323',
  '89032300',
  '890331',
  '89033100',
  '890332',
  '89033200',
  '890333',
  '89033300',
  '89039100',
  '89039200',
  '890393',
  '89039300',
  '890399',
  '89039900',
  '89039910',
  '89039990',
  '8904',
  '890400',
  '89040000',
  '8905',
  '890510',
  '89051000',
  '890520',
  '89052000',
  '890590',
  '89059010',
  '89059090',
  '8906',
  '890610',
  '89061000',
  '890690',
  '89069000',
  '8907',
  '890710',
  '89071000',
  '890790',
  '89079000',
  '8908',
  '890800',
  '89080000',
  '90',
  '9001',
  '900110',
  '90011000',
  '900120',
  '90012000',
  '900130',
  '90013000',
  '900140',
  '90014010',
  '90014090',
  '900150',
  '90015000',
  '900190',
  '90019010',
  '90019090',
  '9002',
  '900211',
  '90021100',
  '900219',
  '90021900',
  '900220',
  '90022000',
  '900290',
  '90029000',
  '9003',
  '900311',
  '90031100',
  '900319',
  '90031900',
  '900390',
  '90039000',
  '9004',
  '900410',
  '90041000',
  '900490',
  '90049010',
  '90049020',
  '90049090',
  '9005',
  '900510',
  '90051000',
  '900580',
  '90058010',
  '90058020',
  '90058090',
  '900590',
  '90059010',
  '90059020',
  '90059090',
  '9006',
  '90061000',
  '90062000',
  '900630',
  '90063000',
  '900640',
  '90064000',
  '90065100',
  '90065200',
  '900653',
  '90065310',
  '90065390',
  '900659',
  '90065910',
  '90065990',
  '900661',
  '90066100',
  '90066200',
  '900669',
  '90066900',
  '900691',
  '90069100',
  '900699',
  '90069900',
  '9007',
  '900710',
  '90071010',
  '90071090',
  '900720',
  '90072010',
  '90072090',
  '900791',
  '90079100',
  '900792',
  '90079200',
  '9008',
  '900850',
  '90085010',
  '90085020',
  '90085030',
  '90085040',
  '900890',
  '90089000',
  '9009',
  '90091100',
  '90091200',
  '90092100',
  '90092200',
  '90093000',
  '90099100',
  '90099200',
  '90099300',
  '90099900',
  '9010',
  '901010',
  '90101000',
  '90104100',
  '90104200',
  '90104900',
  '901050',
  '90105000',
  '901060',
  '90106000',
  '901090',
  '90109000',
  '9011',
  '901110',
  '90111000',
  '901111',
  '901112',
  '901113',
  '901114',
  '901120',
  '90112000',
  '901180',
  '90118000',
  '901190',
  '90119000',
  '9012',
  '90121',
  '901210',
  '90121010',
  '90121090',
  '901290',
  '90129000',
  '9013',
  '901310',
  '90131010',
  '90131020',
  '90131090',
  '901320',
  '90132000',
  '901380',
  '90138000',
  '90138010',
  '90138090',
  '901390',
  '90139000',
  '90139010',
  '90139090',
  '9014',
  '901410',
  '90141000',
  '901420',
  '90142000',
  '901480',
  '90148010',
  '90148020',
  '90148090',
  '901490',
  '90149000',
  '9015',
  '901510',
  '90151000',
  '901520',
  '90152000',
  '901530',
  '90153010',
  '90153090',
  '901540',
  '90154000',
  '901580',
  '90158010',
  '90158020',
  '90158030',
  '90158090',
  '901590',
  '90159000',
  '9016',
  '901600',
  '90160010',
  '90160020',
  '90160090',
  '9017',
  '90171000',
  '901720',
  '90172010',
  '90172020',
  '90172030',
  '90172090',
  '901730',
  '90173010',
  '90173021',
  '90173022',
  '90173023',
  '90173029',
  '901780',
  '90178010',
  '90178090',
  '901790',
  '90179000',
  '9018',
  '901811',
  '90181100',
  '901812',
  '90181210',
  '90181290',
  '901813',
  '90181300',
  '901814',
  '90181400',
  '901819',
  '90181910',
  '90181920',
  '90181990',
  '901820',
  '90182000',
  '901831',
  '90183100',
  '901832',
  '90183210',
  '90183220',
  '90183230',
  '90183290',
  '901839',
  '90183910',
  '90183920',
  '90183930',
  '90183990',
  '901841',
  '90184100',
  '901849',
  '90184900',
  '901850',
  '90185010',
  '90185020',
  '90185030',
  '90185090',
  '901890',
  '90189011',
  '90189012',
  '90189019',
  '90189021',
  '90189022',
  '90189023',
  '90189024',
  '90189025',
  '90189029',
  '90189031',
  '90189032',
  '90189033',
  '90189041',
  '90189042',
  '90189043',
  '90189044',
  '90189091',
  '90189092',
  '90189093',
  '90189094',
  '90189095',
  '90189096',
  '90189097',
  '90189098',
  '90189099',
  '9019',
  '901910',
  '90191010',
  '90191020',
  '90191090',
  '901920',
  '90192010',
  '90192090',
  '9020',
  '902000',
  '90200000',
  '9021',
  '902110',
  '90211000',
  '902121',
  '90212100',
  '902129',
  '90212900',
  '902131',
  '90213100',
  '902139',
  '90213900',
  '902140',
  '90214010',
  '90214090',
  '90215000',
  '902190',
  '90219010',
  '90219090',
  '9022',
  '902212',
  '90221200',
  '902213',
  '90221300',
  '902214',
  '90221410',
  '90221420',
  '90221490',
  '902219',
  '90221900',
  '902221',
  '90222100',
  '902229',
  '90222900',
  '902230',
  '90223000',
  '902290',
  '90229010',
  '90229020',
  '90229030',
  '90229040',
  '90229090',
  '9023',
  '902300',
  '90230010',
  '90230090',
  '9024',
  '902410',
  '90241000',
  '902480',
  '90248010',
  '90248091',
  '90248099',
  '902490',
  '90249000',
  '9025',
  '902511',
  '90251110',
  '90251190',
  '902519',
  '90251910',
  '90251920',
  '90251990',
  '902580',
  '90258010',
  '90258020',
  '90258030',
  '90258090',
  '90259000',
  '9026',
  '902610',
  '90261010',
  '90261020',
  '90261090',
  '902620',
  '90262000',
  '902680',
  '90268010',
  '90268090',
  '902690',
  '90269000',
  '9027',
  '902710',
  '90271000',
  '902720',
  '90272000',
  '902730',
  '90273010',
  '90273020',
  '90273090',
  '90274000',
  '902750',
  '90275010',
  '90275020',
  '90275030',
  '90275090',
  '902780',
  '90278010',
  '90278020',
  '90278030',
  '90278040',
  '90278090',
  '902781',
  '90278100',
  '902789',
  '90278910',
  '90278920',
  '90278930',
  '90278990',
  '902790',
  '90279010',
  '90279020',
  '90279090',
  '9028',
  '902810',
  '90281000',
  '902820',
  '90282000',
  '902830',
  '90283010',
  '90283090',
  '902890',
  '90289010',
  '90289090',
  '9029',
  '902910',
  '90291010',
  '90291090',
  '902920',
  '90292010',
  '90292020',
  '90292030',
  '90292090',
  '902990',
  '90299000',
  '9030',
  '903010',
  '90301000',
  '903020',
  '90302000',
  '903031',
  '90303100',
  '903032',
  '90303200',
  '903033',
  '90303310',
  '90303320',
  '90303330',
  '90303340',
  '90303350',
  '90303390',
  '903039',
  '90303900',
  '90303910',
  '90303920',
  '90303930',
  '90303940',
  '90303950',
  '90303990',
  '903040',
  '90304000',
  '903082',
  '90308200',
  '90308300',
  '903084',
  '90308400',
  '903089',
  '90308910',
  '90308920',
  '90308990',
  '903090',
  '90309010',
  '90309090',
  '9031',
  '903110',
  '90311000',
  '903120',
  '90312000',
  '90313000',
  '903141',
  '90314100',
  '903149',
  '90314900',
  '903180',
  '90318000',
  '903190',
  '90319000',
  '9032',
  '903210',
  '90321010',
  '90321090',
  '903220',
  '90322010',
  '90322090',
  '903281',
  '90328100',
  '903289',
  '90328910',
  '90328990',
  '903290',
  '90329000',
  '9033',
  '903300',
  '90330000',
  '9070',
  '909101',
  '909102',
  '909301',
  '909302',
  '909501',
  '909502',
  '91',
  '9101',
  '910111',
  '91011100',
  '91011200',
  '910119',
  '91011900',
  '910121',
  '91012100',
  '910129',
  '91012900',
  '910191',
  '91019110',
  '91019120',
  '91019190',
  '910199',
  '91019910',
  '91019920',
  '91019990',
  '9102',
  '910211',
  '91021100',
  '910212',
  '91021200',
  '910219',
  '91021900',
  '910221',
  '91022100',
  '910229',
  '91022900',
  '910291',
  '91029110',
  '91029120',
  '91029190',
  '910299',
  '91029910',
  '91029920',
  '91029990',
  '9103',
  '910310',
  '91031000',
  '910390',
  '91039000',
  '9104',
  '910400',
  '91040000',
  '9105',
  '91051090',
  '910511',
  '91051100',
  '910519',
  '91051900',
  '910521',
  '91052100',
  '910529',
  '91052900',
  '910591',
  '91059100',
  '910599',
  '91059910',
  '91059990',
  '9106',
  '910610',
  '91061000',
  '91062000',
  '910690',
  '91069000',
  '9107',
  '910700',
  '91070000',
  '9108',
  '910811',
  '91081100',
  '910812',
  '91081200',
  '910819',
  '91081900',
  '910820',
  '91082000',
  '910890',
  '91089000',
  '9109',
  '910910',
  '91091010',
  '91091090',
  '910990',
  '91099000',
  '910991',
  '910992',
  '910993',
  '9110',
  '911011',
  '91101100',
  '911012',
  '91101200',
  '911019',
  '91101900',
  '911090',
  '91109000',
  '9111',
  '911110',
  '91111000',
  '911120',
  '91112000',
  '911180',
  '91118000',
  '911190',
  '91119000',
  '9112',
  '911220',
  '91122000',
  '911290',
  '91129000',
  '9113',
  '911310',
  '91131000',
  '911320',
  '91132010',
  '91132090',
  '911390',
  '91139010',
  '91139090',
  '9114',
  '911410',
  '91141010',
  '91141020',
  '911430',
  '91143010',
  '91143020',
  '911440',
  '91144010',
  '91144020',
  '911490',
  '91149030',
  '91149040',
  '91149091',
  '91149092',
  '92',
  '9201',
  '920110',
  '92011000',
  '920120',
  '92012000',
  '920190',
  '92019000',
  '9202',
  '920210',
  '92021000',
  '920290',
  '92029000',
  '9203',
  '92030010',
  '92030090',
  '9204',
  '920400',
  '92040000',
  '92041000',
  '92042000',
  '9205',
  '920510',
  '92051000',
  '920590',
  '92059010',
  '92059020',
  '92059090',
  '9206',
  '92060000',
  '9207',
  '920710',
  '92071000',
  '920790',
  '92079000',
  '9208',
  '920810',
  '92081000',
  '92082000',
  '920890',
  '92089000',
  '9209',
  '92091000',
  '92092000',
  '920930',
  '92093000',
  '920991',
  '92099100',
  '920992',
  '92099200',
  '92099300',
  '920994',
  '92099400',
  '920999',
  '92099900',
  '93',
  '9301',
  '930110',
  '93011010',
  '93011090',
  '930120',
  '93012000',
  '930190',
  '93019000',
  '9302',
  '930200',
  '93020000',
  '9303',
  '930310',
  '93031000',
  '930320',
  '93032000',
  '930330',
  '93033000',
  '930390',
  '93039000',
  '9304',
  '930400',
  '93040000',
  '9305',
  '930510',
  '93051000',
  '930520',
  '93052010',
  '93052090',
  '930591',
  '93059100',
  '930599',
  '93059900',
  '9306',
  '93061000',
  '930621',
  '93062100',
  '930629',
  '93062900',
  '930630',
  '93063000',
  '930690',
  '93069000',
  '9307',
  '930700',
  '93070000',
  '94',
  '9401',
  '940110',
  '94011000',
  '940120',
  '94012000',
  '94013000',
  '940131',
  '94013100',
  '940139',
  '94013900',
  '94014000',
  '940141',
  '94014100',
  '940149',
  '94014900',
  '94015000',
  '94015100',
  '940152',
  '94015200',
  '940153',
  '94015300',
  '940159',
  '94015900',
  '940161',
  '94016100',
  '940169',
  '94016900',
  '940171',
  '94017100',
  '940179',
  '94017900',
  '940180',
  '94018000',
  '94019000',
  '940191',
  '94019100',
  '940199',
  '94019900',
  '9402',
  '940210',
  '94021010',
  '94021090',
  '940290',
  '94029010',
  '94029020',
  '94029090',
  '9403',
  '940310',
  '94031010',
  '94031090',
  '940320',
  '94032010',
  '94032090',
  '940330',
  '94033010',
  '94033090',
  '940340',
  '94034000',
  '940350',
  '94035010',
  '94035090',
  '940360',
  '94036000',
  '940370',
  '94037000',
  '94038010',
  '94038090',
  '94038100',
  '940382',
  '94038200',
  '940383',
  '94038300',
  '940389',
  '94038900',
  '94039000',
  '940391',
  '94039100',
  '940399',
  '94039900',
  '9404',
  '940410',
  '94041000',
  '940421',
  '94042110',
  '94042190',
  '940429',
  '94042910',
  '94042920',
  '94042990',
  '940430',
  '94043010',
  '94043090',
  '940440',
  '94044010',
  '94044020',
  '94044030',
  '94044040',
  '940490',
  '94049000',
  '94049011',
  '94049019',
  '94049091',
  '94049099',
  '9405',
  '940510',
  '94051010',
  '94051020',
  '94051090',
  '940511',
  '94051100',
  '940519',
  '94051900',
  '940520',
  '94052010',
  '94052090',
  '940521',
  '94052100',
  '940529',
  '94052900',
  '94053000',
  '940531',
  '94053100',
  '940539',
  '94053900',
  '940540',
  '94054010',
  '94054090',
  '940541',
  '94054100',
  '940542',
  '94054200',
  '940549',
  '94054900',
  '940550',
  '94055000',
  '94055010',
  '94055020',
  '94055031',
  '94055039',
  '94055040',
  '94055051',
  '94055059',
  '940560',
  '94056010',
  '94056090',
  '940561',
  '94056100',
  '940569',
  '94056900',
  '940591',
  '94059100',
  '940592',
  '94059200',
  '940599',
  '94059900',
  '9406',
  '940600',
  '94060011',
  '94060019',
  '94060091',
  '94060092',
  '94060093',
  '94060099',
  '940610',
  '94061010',
  '94061020',
  '94061030',
  '94061090',
  '940620',
  '94062000',
  '940690',
  '94069010',
  '94069020',
  '94069030',
  '94069090',
  '95',
  '9501',
  '95010010',
  '95010090',
  '9502',
  '95021010',
  '95021020',
  '95021030',
  '95021090',
  '95029100',
  '95029900',
  '9503',
  '950300',
  '95030010',
  '95030020',
  '95030030',
  '95030090',
  '95030091',
  '95030099',
  '95031000',
  '95032000',
  '95033000',
  '95034100',
  '95034910',
  '95034920',
  '95034930',
  '95034990',
  '95035010',
  '95035090',
  '95036010',
  '95036090',
  '95037010',
  '95037090',
  '95038010',
  '95038090',
  '95039010',
  '95039020',
  '95039090',
  '9504',
  '950420',
  '95042000',
  '950430',
  '95043000',
  '950440',
  '95044000',
  '950450',
  '95045000',
  '950490',
  '95049010',
  '95049020',
  '95049090',
  '9505',
  '950510',
  '95051000',
  '950590',
  '95059010',
  '95059090',
  '9506',
  '950611',
  '95061100',
  '950612',
  '95061200',
  '950619',
  '95061900',
  '950621',
  '95062100',
  '950629',
  '95062900',
  '950631',
  '95063100',
  '950632',
  '95063200',
  '950639',
  '95063900',
  '950640',
  '95064000',
  '950651',
  '95065100',
  '950659',
  '95065910',
  '95065990',
  '950661',
  '95066100',
  '950662',
  '95066210',
  '95066220',
  '95066230',
  '95066290',
  '950669',
  '95066910',
  '95066920',
  '95066930',
  '95066940',
  '95066990',
  '950670',
  '95067000',
  '950691',
  '95069110',
  '95069190',
  '950699',
  '95069910',
  '95069920',
  '95069930',
  '95069940',
  '95069950',
  '95069960',
  '95069970',
  '95069980',
  '95069990',
  '9507',
  '950710',
  '95071000',
  '950720',
  '95072000',
  '950730',
  '95073000',
  '950790',
  '95079010',
  '95079090',
  '9508',
  '950810',
  '95081000',
  '950821',
  '95082100',
  '950822',
  '95082200',
  '950823',
  '95082300',
  '950824',
  '95082400',
  '950825',
  '95082500',
  '950826',
  '95082600',
  '950829',
  '95082900',
  '950830',
  '95083000',
  '950840',
  '95084000',
  '95089000',
  '96',
  '9601',
  '960110',
  '96011000',
  '960190',
  '96019010',
  '96019020',
  '96019030',
  '96019040',
  '96019090',
  '9602',
  '960200',
  '96020010',
  '96020020',
  '96020030',
  '96020040',
  '96020090',
  '9603',
  '960310',
  '96031000',
  '960321',
  '96032100',
  '960329',
  '96032900',
  '960330',
  '96033010',
  '96033020',
  '96033090',
  '960340',
  '96034010',
  '96034020',
  '960350',
  '96035000',
  '960390',
  '96039000',
  '9604',
  '960400',
  '96040000',
  '9605',
  '960500',
  '96050010',
  '96050090',
  '9606',
  '960610',
  '96061010',
  '96061020',
  '960621',
  '96062100',
  '960622',
  '96062200',
  '960629',
  '96062910',
  '96062990',
  '960630',
  '96063010',
  '96063090',
  '9607',
  '960711',
  '96071110',
  '96071190',
  '960719',
  '96071910',
  '96071990',
  '960720',
  '96072000',
  '9608',
  '960810',
  '96081011',
  '96081012',
  '96081019',
  '96081091',
  '96081092',
  '96081099',
  '960820',
  '96082000',
  '960830',
  '96083011',
  '96083012',
  '96083019',
  '96083021',
  '96083022',
  '96083029',
  '96083091',
  '96083092',
  '96083099',
  '960831',
  '960839',
  '960840',
  '96084000',
  '960850',
  '96085000',
  '960860',
  '96086010',
  '96086090',
  '960891',
  '96089110',
  '96089120',
  '96089130',
  '96089191',
  '96089199',
  '960899',
  '96089910',
  '96089990',
  '9609',
  '960910',
  '96091000',
  '960920',
  '96092000',
  '960990',
  '96099010',
  '96099020',
  '96099030',
  '96099090',
  '9610',
  '961000',
  '96100000',
  '9611',
  '961100',
  '96110000',
  '9612',
  '961210',
  '96121010',
  '96121020',
  '96121030',
  '96121090',
  '961220',
  '96122000',
  '9613',
  '961310',
  '96131000',
  '961320',
  '96132000',
  '961380',
  '96138010',
  '96138090',
  '961390',
  '96139000',
  '9614',
  '961400',
  '96140000',
  '96142000',
  '96149010',
  '96149020',
  '96149090',
  '9615',
  '961511',
  '96151100',
  '961519',
  '96151900',
  '961590',
  '96159000',
  '9616',
  '961610',
  '96161010',
  '96161020',
  '961620',
  '96162000',
  '9617',
  '961700',
  '96170011',
  '96170012',
  '96170013',
  '96170019',
  '96170090',
  '9618',
  '961800',
  '96180000',
  '9619',
  '961900',
  '96190010',
  '96190020',
  '96190030',
  '96190040',
  '96190090',
  '9620',
  '962000',
  '96200000',
  '97',
  '9701',
  '970110',
  '97011010',
  '97011020',
  '97011030',
  '97011090',
  '970121',
  '970122',
  '97012200',
  '970129',
  '97012900',
  '970190',
  '97019091',
  '97019092',
  '97019099',
  '970191',
  '97019100',
  '970192',
  '97019200',
  '970199',
  '97019900',
  '9702',
  '97020000',
  '970210',
  '97021000',
  '970290',
  '97029000',
  '9703',
  '970300',
  '97030010',
  '97030020',
  '97030090',
  '970310',
  '97031010',
  '97031020',
  '97031090',
  '970390',
  '97039010',
  '97039020',
  '97039090',
  '9704',
  '970400',
  '97040010',
  '97040020',
  '97040090',
  '9705',
  '970500',
  '97050010',
  '97050090',
  '970510',
  '97051000',
  '970521',
  '97052100',
  '970522',
  '97052200',
  '970529',
  '97052900',
  '970531',
  '97053100',
  '970539',
  '97053900',
  '9706',
  '97060000',
  '970610',
  '97061000',
  '970690',
  '97069000',
  '98',
  '9801',
  '980100',
  '98010011',
  '98010012',
  '98010013',
  '98010014',
  '98010015',
  '98010019',
  '98010020',
  '98010030',
  '9802',
  '980200',
  '98020000',
  '9803',
  '980300',
  '98030000',
  '9804',
  '980410',
  '98041000',
  '980490',
  '98049000',
  '9805',
  '980510',
  '98051000',
  '980590',
  '98059000',
  '9806',
  '980600',
  '98060000',
];
