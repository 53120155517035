import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';

const initialState = {
  // purchase setting work
  purchaseSetting: {
    prefixInvoice: '',
    poNo: false,
    ebn: false,
    mot: false,
    col1: false,
    col2: false,
    col3: false,
    col1Name: '',
    col2Name: '',
    col3Name: '',
    job: false,
    disc: false,
    dis: false,
    transId: false,
    vehicleNo: false,
    convertPurchase:null,
  },
  tab: 'purchase',
  purchase: [],
  voucher: null,
  inventory: [],
  ledger: [],
  bill: [],
  import: [],
  order: [],
  total: 0,
  purchaseEdit: null,
  billEdit: null,
  importEdit: null,
  orderEdit: null,
  purchaseSummary:null,
  isLoading: true,
  convertDeliveryTopurchase:{},

  deliveryChallan:[],
  deliveryChallanSummary:[],
  deliveryChallanEdit:[],
  // deliveryChallanNo:[],
  convertPurchaseToDeliveryChallan:{},
  convertDeliveryChallanToPurchase:null
};

const slice = createSlice({
  name: 'purchase',
  initialState,
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setImport(state, action) {
      state.import = action.payload;
    },
    setPurchaseSummary(state, action) {
      state.purchaseSummary = action.payload;
    },

    setTab(state, action) {
      state.tab = action.payload;
    },
    setBill(state, action) {
      state.bill = action.payload;
    },
    setOrder(state, action) {
      state.order = action.payload;
    },
    setLedger(state, action) {
      state.ledger = action.payload;
    },
    setPurchaseSetting(state, action) {
      state.purchaseSetting = action.payload;
    },
    setPurchase(state, action) {
      state.purchase = action.payload;
    },
    setConvertPurchase(state, action){
      state.convertpurchase= action.payload;
    },
    setVoucher(state, action) {
      state.voucher = action.payload;
    },
    setInventory(state, action) {
      state.inventory = action.payload;
    },
    setTotal(state, action) {
      state.total = action.payload;
    },
    setPurchaseEdit(state, action) {
      state.purchaseEdit = action.payload;
    },
    setBillEdit(state, action) {
      state.billEdit = action.payload;
    },
    setImportEdit(state, action) {
      state.importEdit = action.payload;
    },
    setOrderEdit(state, action) {
      state.orderEdit = action.payload;
    },
    setConvertDeliveryTopurchase(state, action) {
      state.convertDeliveryTopurchase = action.payload;
    },
    setDeliveryChallan(state, action) {
      state.deliveryChallan = action.payload;
    },
    setDeliveryChallanSummary(state, action) {
      state.deliveryChallanSummary = action.payload;
    },
    setDeliveryChallanEdit(state, action) {
      state.deliveryChallanEdit = action.payload;
    },
    setConvertPurchaseToDeliveryChallan(state, action) {
      state.convertPurchaseToDeliveryChallan = action.payload;
    },

    setConvertDeliveryChallanToPurchase(state, action) {
      state.convertDeliveryChallanToPurchase = action.payload;
    },
    // 
    // setDeliveryChallanNo(state, action) {
    //   state.deliveryChallanNo = action.payload;
    // },
  },
});

export default slice.reducer;

export const {
  setConvertDeliveryChallanToPurchase,
  setConvertPurchaseToDeliveryChallan,
  setConvertDeliveryTopurchase,
  setPurchaseSetting,
  setConvertPurchase,
  setPurchase,
  setVoucher,
  setInventory,
  setLedger,
  setBill,
  setOrder,
  setTab,
  setImport,
  setTotal,
  setPurchaseEdit,
  setBillEdit,
  setImportEdit,
  setOrderEdit,
  setPurchaseSummary,
  setIsLoading,

  setDeliveryChallan,
  setDeliveryChallanSummary,
  setDeliveryChallanEdit,
  // setDeliveryChallanNo,
} = slice.actions;

export const getPurchaseSummary = (sd, ed) => async (dispatch) => {
  try {
    await axios
      .get(`/purchase/summary/PCE?start_date=${sd}&end_date=${ed}`)
      .then((response) => dispatch(setPurchaseSummary(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getBillSummary = (sd, ed) => async (dispatch) => {
  try {
    await axios
      .get(`/purchase/summary/PBS?start_date=${sd}&end_date=${ed}`)
      .then((response) => dispatch(setPurchaseSummary(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getImportSummary = (sd, ed) => async (dispatch) => {
  try {
    await axios
      .get(`/purchase/summary/PIT?start_date=${sd}&end_date=${ed}`)
      .then((response) => dispatch(setPurchaseSummary(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getPurchaseOrderSummary = (sd, ed) => async (dispatch) => {
  try {
    await axios
      .get(`/purchase/summary/POD?start_date=${sd}&end_date=${ed}`)
      .then((response) => dispatch(setPurchaseSummary(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getImport = (sd, ed, n, r, s,t) => async (dispatch) => {
  try {
    await axios
      .get(`/purchase/importAll?start_date=${sd}&end_date=${ed}&pagination=${n}&records=${r}&search=${s}&type=${t}`)
      .then((response) => dispatch(setImport(response.data)));
      dispatch(setIsLoading(false));

  } catch (error) {
    dispatch(setIsLoading(false));

    return console.error(error.message);
  }
  return true;
};

export const getBill = (sd, ed, n, r, s,t) => async (dispatch) => {
  try {
    await axios
      .get(`/purchase/billofsupplyAll?start_date=${sd}&end_date=${ed}&pagination=${n}&records=${r}&search=${s}&type=${t}`)
      .then((response) => dispatch(setBill(response.data)));
      dispatch(setIsLoading(false));
  } catch (error) {
    dispatch(setIsLoading(false));
    return console.error(error.message);
  }
  return true;
};
export const getOrder = (sd, ed, n, r, s,t) => async (dispatch) => {
  try {
    await axios
      .get(`/purchase/orderAll?start_date=${sd}&end_date=${ed}&pagination=${n}&records=${r}&search=${s}&type=${t}`)
      .then((response) => dispatch(setOrder(response.data)));
      dispatch(setIsLoading(false));

  } catch (error) {
    dispatch(setIsLoading(false));

    return console.error(error.message);
  }
  return true;
};

export const getLedger = () => async (dispatch) => {
  try {
    await axios.get('/purchase/ledger').then((response) => dispatch(setLedger(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getSetting = () => async (dispatch) => {
  try {
    await axios.get('/purchase/settings').then((response) => dispatch(setPurchaseSetting(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getVoucher = (no) => async (dispatch) => {
  try {
    await axios.get(`/purchase/voucherNumber/${no}`).then((response) => dispatch(setVoucher(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getInventory = () => async (dispatch) => {
  try {
    await axios.get('/purchase/inventory').then((response) => dispatch(setInventory(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getPurchase = (sd, ed, n, r, s,t) => async (dispatch) => {
  try {
    await axios
      .get(`/purchase/purchaseAll?start_date=${sd}&end_date=${ed}&pagination=${n}&records=${r}&search=${s}&type=${t}`)
      .then((response) => dispatch(setPurchase(response.data)));
      dispatch(setIsLoading(false));
  } catch (error) {
    dispatch(setIsLoading(false));
    return console.error(error.message);
  }
  return true;
};

export const getPurchaseEdit = (id) => async (dispatch) => {
  try {
    await axios.get(`/purchase/purchase?ID=${id}`).then((response) => dispatch(setPurchaseEdit(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getBillEdit = (id) => async (dispatch) => {
  try {
    await axios.get(`/purchase/billofsupply?ID=${id}`).then((response) => dispatch(setBillEdit(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getImportEdit = (id) => async (dispatch) => {
  try {
    await axios.get(`/purchase/import?ID=${id}`).then((response) => dispatch(setImportEdit(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getOrderEdit = (id) => async (dispatch) => {
  try {
    await axios.get(`/purchase/order?ID=${id}`).then((response) => dispatch(setOrderEdit(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getDeliveryChallan = (sd, ed, n, r, s,t) => async (dispatch) => {
  try {
    await axios
      .get(`/purchase/deliveryAll?start_date=${sd}&end_date=${ed}&pagination=${n}&records=${r}&search=${s}&type=${t}`)
      .then((response) => dispatch(setDeliveryChallan(response.data)));
      dispatch(setIsLoading(false));
  } catch (error) {
    dispatch(setIsLoading(false));
    return console.error(error.message);
  }
  return true;
};

export const getDeliveryChallanSummary = (sd, ed) => async (dispatch) => {
  try {
    await axios
      .get(`/purchase/summary/DCL?start_date=${sd}&end_date=${ed}`)
      .then((response) => dispatch(setDeliveryChallanSummary(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getDeliveryChallanEdit = (id) => async (dispatch) => {
  if (id && typeof id === 'number') {
    try {
      await axios.get(`/purchase/delivery?ID=${id}`).then((response) => dispatch(setDeliveryChallanEdit(response.data)));
    } catch (error) {
      return console.error(error.message);
    }
  }
  return true;
};

// export const getDeliveryChallanNo = () => async (dispatch) => {
//   try {
//     await axios.get('/purchase/invoiceNumber/DCL').then((response) => dispatch(setDeliveryChallanNo(response.data)));
//   } catch (error) {
//     return console.error(error.message);
//   }
//   return true;
// };

// export const getDeliveryChallanTerms = () => async (dispatch) => {
//   try{
//     await axios.get
//   }
// }