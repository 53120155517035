import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';

const initialState = {
  gstr1ViewInvoice: [],
  filterGstr1ViewInvoice: [],
  gstr1PrepareData: null,
  hsnSummary: [],
  gst2AData: [],
  tab: 'Input as per GST2A',
  notInbook: [],
  notIn2a: [],
  asPerbook: [],
  gst2BData: [],
  gst2aSummary: null,
  gst2bSummary: null,
  asperbooksSummary: null,
  isLoading: true,

  Gstr1Tab: 'B2B sales as per Portal',

  Gstr1B2Bsales: [],
  Gstr1B2BsalesSummary: [],
  Gstr1Exp: [],
  Gstr1expSummary: [],
  Gstr1credit: [],
  Gstr1creditSummary: [],
  Gstr1BillofSupply: [],
  Gstr1BillofSupplySummary: [],
  Gstr1RetailCredit: [],
  Gstr1RetailCreditSummary: [],
  Gstr1RetailSales: [],
  Gstr1RetailSalesSummary: [],
  // gstr1 recon as per books
  Gstr1HeaderTab: 'As Per Portal',
  Gstr1BooksTab: 'B2B sales as per Books',
  Gstr1B2BsalesBooks: [],
  Gstr1B2BsalesBooksSummary: [],
  Gstr1creditBooks:[],
  Gstr1creditBooksSummary:[],
  Gstr1ExpBooks:[],
  Gstr1expBooksSummary:[],
  Gstr1RetailSalesBooks:[],
  Gstr1RetailSalesBooksSummary:[],
  Gstr1RetailCreditBooks:[],
  Gstr1RetailCreditBooksSummary:[],

};

const slice = createSlice({
  name: 'tax',
  initialState,
  reducers: {
    setGstr1ViewInvoice(state, action) {
      state.gstr1ViewInvoice = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setFilterGstr1ViewInvoice(state, action) {
      state.filterGstr1ViewInvoice = action.payload;
    },
    setGstr1PrepareData(state, action) {
      state.gstr1PrepareData = action.payload;
    },
    setHsnSummary(state, action) {
      state.hsnSummary = action.payload;
    },
    setGst2AData(state, action) {
      state.gst2AData = action.payload;
    },
    setGst2BData(state, action) {
      state.gst2BData = action.payload;
    },
    setTab(state, action) {
      state.tab = action.payload;
    },
    setNotinbook(state, action) {
      state.notInbook = action.payload;
    },
    setNotin2a(state, action) {
      state.notIn2a = action.payload;
    },
    setAsperbook(state, action) {
      state.asPerbook = action.payload;
    },
    setGst2aSummary(state, action) {
      state.gst2aSummary = action.payload;
    },
    setGst2bSummary(state, action) {
      state.gst2bSummary = action.payload;
    },
    setAsperbooksSummary(state, action) {
      state.asperbooksSummary = action.payload;
    },

    setGstr1Tab(state, action) {
      state.Gstr1Tab = action.payload;
    },
    setGstr1B2Bsales(state, action) {
      state.Gstr1B2Bsales = action.payload;
    },
    setGstr1B2BsalesSummary(state, action) {
      state.Gstr1B2BsalesSummary = action.payload;
    },
    setGstr1Exp(state, action) {
      state.Gstr1Exp = action.payload;
    },
    setGstr1expSummary(state, action) {
      state.Gstr1expSummary = action.payload;
    },
    setGstr1credit(state, action) {
      state.Gstr1credit = action.payload;
    },
    setGstr1creditSummary(state, action) {
      state.Gstr1creditSummary = action.payload;
    },
    setGstr1BillofSupply(state, action) {
      state.Gstr1BillofSupply = action.payload;
    },
    setGstr1BillofSupplySummary(state, action) {
      state.Gstr1BillofSupplySummary = action.payload;
    },
    setGstr1RetailCredit(state, action) {
      state.Gstr1RetailCredit = action.payload;
    },
    setGstr1RetailCreditSummary(state, action) {
      state.Gstr1RetailCreditSummary = action.payload;
    },
    setGstr1RetailSales(state, action) {
      state.Gstr1RetailSales = action.payload;
    },
    setGstr1RetailSalesSummary(state, action) {
      state.Gstr1RetailSalesSummary = action.payload;
    },

    setGstr1HeaderTab(state, action) {
      state.Gstr1HeaderTab = action.payload;
    },
    setGstr1BooksTab(state, action) {
      state.Gstr1BooksTab = action.payload;
    },

    setGstr1B2BsalesBooks(state, action) {
      state.Gstr1B2BsalesBooks = action.payload;
    },
    setGstr1B2BsalesBooksSummary(state, action) {
      state.Gstr1B2BsalesBooksSummary = action.payload;
    },
    setGstr1creditBooks(state, action) {
      state.Gstr1creditBooks = action.payload;
    },
    setGstr1creditBooksSummary(state, action) {
      state.Gstr1creditBooksSummary = action.payload;
  },
  setGstr1ExpBooks(state, action) {
    state.Gstr1ExpBooks = action.payload;
},
setGstr1expBooksSummary(state, action) {
  state.Gstr1expBooksSummary = action.payload;
},
setGstr1RetailSalesBooks(state, action) {
  state.Gstr1RetailSalesBooks = action.payload;
},
setGstr1RetailSalesBooksSummary(state, action) {
  state.Gstr1RetailSalesBooksSummary = action.payload;
},
setGstr1RetailCreditBooks(state, action) {
  state.Gstr1RetailCreditBooks = action.payload;
},
setGstr1RetailCreditBooksSummary(state, action) {
  state.Gstr1RetailCreditBooksSummary = action.payload;
},
},
});

export default slice.reducer;

export const {
  setGstr1ViewInvoice,
  setFilterGstr1ViewInvoice,
  setGstr1PrepareData,
  setHsnSummary,
  setGst2AData,
  setTab,
  setNotinbook,
  setNotin2a,
  setAsperbook,
  setGst2BData,
  setGst2aSummary,
  setGst2bSummary,
  setAsperbooksSummary,
  setIsLoading,

  setGstr1Tab,
  setGstr1B2Bsales,
  setGstr1B2BsalesSummary,
  setGstr1Exp,
  setGstr1expSummary,
  setGstr1credit,
  setGstr1creditSummary,
  setGstr1BillofSupply,
  setGstr1BillofSupplySummary,
  setGstr1RetailCredit,
  setGstr1RetailCreditSummary,
  setGstr1RetailSales,
  setGstr1RetailSalesSummary,
  // gstr1 recon as per books

  setGstr1HeaderTab,
  setGstr1BooksTab,
  setGstr1B2BsalesBooks,
  setGstr1B2BsalesBooksSummary,
  setGstr1creditBooks,
  setGstr1creditBooksSummary,
  setGstr1ExpBooks,
  setGstr1expBooksSummary,
  setGstr1RetailSalesBooks,
  setGstr1RetailSalesBooksSummary,
  setGstr1RetailCreditBooks,
  setGstr1RetailCreditBooksSummary,

} = slice.actions;

export const getGst2aSummary = (sd, ed) => async (dispatch) => {
  try {
    await axios
      .get(`/summary/get2A?start_date=${sd}&end_date=${ed}`)
      .then((response) => dispatch(setGst2aSummary(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getGst2bSummary = (sd, ed) => async (dispatch) => {
  try {
    await axios
      .get(`/summary/get2B?start_date=${sd}&end_date=${ed}`)
      .then((response) => dispatch(setGst2bSummary(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getAsperbooksSummary = (sd, ed) => async (dispatch) => {
  try {
    await axios
      .get(`/summary/AsPerBooks?start_date=${sd}&end_date=${ed}`)
      .then((response) => dispatch(setAsperbooksSummary(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getAsperbook = (sd, ed, p, r, s) => async (dispatch) => {
  try {
    axios
      .get(`/dataAsPerBooks?start_date=${sd}&end_date=${ed}&pagination=${p}&records=${r}&search=${s}`)
      .then((response) => dispatch(setAsperbook(response.data)));
    dispatch(setIsLoading(false));
  } catch (error) {
    dispatch(setIsLoading(false));

    return console.error(error.message);
  }
  return true;
};

export const getNotIn2a = (sd, ed, p, r, s) => async (dispatch) => {
  try {
    axios
      .get(`compare?start_date=${sd}&end_date=${ed}&pagination=${p}&records=${r}&search=${s}`)
      .then((response) => dispatch(setNotin2a(response.data.notIn2A)));
    dispatch(setIsLoading(false));
  } catch (error) {
    dispatch(setIsLoading(false));

    return console.error(error.message);
  }
  return true;
};

export const getNotInbooks = (sd, ed, p, r, s) => async (dispatch) => {
  try {
    axios
      .get(`compare?start_date=${sd}&end_date=${ed}&pagination=${p}&records=${r}&search=${s}`)
      .then((response) => dispatch(setNotinbook(response.data.notInBooks)));
    dispatch(setIsLoading(false));
  } catch (error) {
    dispatch(setIsLoading(false));

    return console.error(error.message);
  }
  return true;
};

export const getGstr1ViewInvoice = (date) => async (dispatch) => {
  try {
    axios.get(`/gstr1ViewInvoice?ret_period=${date}`).then((response) => dispatch(setGstr1ViewInvoice(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getFilterGstr1ViewInvoice = (date) => async (dispatch) => {
  try {
    axios
      .get(`/filterGstr1ViewInvoice?ret_period=${date}`)
      .then((response) => dispatch(setFilterGstr1ViewInvoice(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getGstr1PrepareData = (date) => async (dispatch) => {
  try {
    axios.get(`/gstr1PrepareData?ret_period=${date}`).then((response) => dispatch(setGstr1PrepareData(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getHsnSummary = (date) => async (dispatch) => {
  try {
    axios.get(`/hsnSummary?ret_period=${date}`).then((response) => dispatch(setHsnSummary(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getGst2A = (sd, ed, p, r, s) => async (dispatch) => {
  try {
    axios
      .get(`/get2AData?start_date=${sd}&end_date=${ed}&pagination=${p}&records=${r}&search=${s}`)
      .then((response) => dispatch(setGst2AData(response.data)));
    dispatch(setIsLoading(false));
  } catch (error) {
    dispatch(setIsLoading(false));

    return console.error(error.message);
  }
  return true;
};

export const getGst2B = (sd, ed, p, r, s) => async (dispatch) => {
  try {
    axios
      .get(`/get2BData?start_date=${sd}&end_date=${ed}&pagination=${p}&records=${r}&search=${s}`)
      .then((response) => dispatch(setGst2BData(response.data)));
    dispatch(setIsLoading(false));
  } catch (error) {
    dispatch(setIsLoading(false));

    return console.error(error.message);
  }
  return true;
};

export const getGstr1B2Bsales = (ed, sd, s, r, p) => async (dispatch) => {
  try {
    axios
      .get(`/getGstr1B2bData?end=${ed}&start=${sd}&search=${s}&records=${r}&pagination=${p}`)
      .then((response) => dispatch(setGstr1B2Bsales(response.data)));
    dispatch(setIsLoading(false));
  } catch (error) {
    dispatch(setIsLoading(false));
    return console.error(error.message);
  }
  return true;
};

export const getGstr1B2BsalesSummary = (sd, ed) => async (dispatch) => {
  try {
    axios
      .get(`/summary/gstr1b2b?start_date=${sd}&end_date=${ed}`)
      .then((response) => dispatch(setGstr1B2BsalesSummary(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getGstr1Exp = (ed, sd, s, r, p) => async (dispatch) => {
  try {
    axios
      .get(`/getGstr1ExpData?end=${ed}&start=${sd}&search=${s}&records=${r}&pagination=${p}`)
      .then((response) => dispatch(setGstr1Exp(response.data)));
    dispatch(setIsLoading(false));
  } catch (error) {
    dispatch(setIsLoading(false));
    return console.error(error.message);
  }
  return true;
};

export const getGstr1expSummary = (sd, ed) => async (dispatch) => {
  try {
    axios
      .get(`/summary/gstr1exp?start_date=${sd}&end_date=${ed}`)
      .then((response) => dispatch(setGstr1expSummary(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getGstr1credit = (ed, sd, s, r, p) => async (dispatch) => {
  try {
    axios
      .get(`/getGstr1cdnrData?end=${ed}&start=${sd}&search=${s}&records=${r}&pagination=${p}`)
      .then((response) => dispatch(setGstr1credit(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getGstr1creditSummary = (sd, ed) => async (dispatch) => {
  try {
    axios
      .get(`/summary/gstr1cdnr?start_date=${sd}&end_date=${ed}`)
      .then((response) => dispatch(setGstr1creditSummary(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getGstr1BillofSupply = () => async (dispatch) => {
  try {
    axios.get(`/getGstr1NilData`).then((response) => dispatch(setGstr1BillofSupply(response.data)));
    dispatch(setIsLoading(false));
  } catch (error) {
    dispatch(setIsLoading(false));
    return console.error(error.message);
  }
  return true;
};

export const getGstr1BillofSupplySummary = () => async (dispatch) => {
  try {
    axios.get(`/summary/nil`).then((response) => dispatch(setGstr1BillofSupplySummary(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getGstr1RetailCredit = (ed, sd, s, r, p) => async (dispatch) => {
  try {
    axios
      .get(`/getGstr1cdnurData?end=${ed}&start=${sd}&search=${s}&records=${r}&pagination=${p}`)
      .then((response) => dispatch(setGstr1RetailCredit(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getGstr1RetailCreditSummary = (sd, ed) => async (dispatch) => {
  try {
    axios
      .get(`/summary/gstr1cdnur?start_date=${sd}&end_date=${ed}`)
      .then((response) => dispatch(setGstr1RetailCreditSummary(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getGstr1RetailSales = () => async (dispatch) => {
  try {
    axios.get(`/getGstr1B2cData`).then((response) => dispatch(setGstr1RetailSales(response.data)));
    dispatch(setIsLoading(false));
  } catch (error) {
    dispatch(setIsLoading(false));
    return console.error(error.message);
  }
  return true;
};

export const getGstr1RetailSalesSummary = (sd, ed) => async (dispatch) => {
  try {
    axios
      .get(`/summary/gstr1b2c?start_date=${sd}&end_date=${ed}`)
      .then((response) => dispatch(setGstr1RetailSalesSummary(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

// gst R1 Recon  as per books

export const getGstr1B2BsalesBooks = (ed, sd, s, r, p) => async (dispatch) => {
  try {
    axios
      .get(`/b2b_Sale_as_per_book?end=${ed}&start=${sd}&search=${s}&records=${r}&pagination=${p}`)
      .then((response) => dispatch(setGstr1B2BsalesBooks(response.data)));
    dispatch(setIsLoading(false));
  } catch (error) {
    dispatch(setIsLoading(false));
    return console.error(error.message);
  }
  return true;
};

export const getGstr1B2BsalesBooksSummary = (sd, ed) => async (dispatch) => {
  try {
    axios
      .get(`/b2b_Sale_as_per_book_summary?start=${sd}&end=${ed}`)
      .then((response) => dispatch(setGstr1B2BsalesBooksSummary(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getGstr1creditBooks = (ed, sd, s, r, p) => async (dispatch) => {
  try {
    axios
      .get(`/b2b_creditNote_as_per_book?end=${ed}&start=${sd}&search=${s}&records=${r}&pagination=${p}`)
      .then((response) => dispatch(setGstr1creditBooks(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getGstr1creditSummaryBooks = (sd, ed) => async (dispatch) => {
  try {
    axios
      .get(`/b2b_creditnot_as_per_book_summary?start=${sd}&end=${ed}`)
      .then((response) => dispatch(setGstr1creditBooksSummary(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getGstr1ExpBooks = (ed, sd, s, r, p) => async (dispatch) => {
  try {
    axios
      .get(`/export_as_per_book?end=${ed}&start=${sd}&search=${s}&records=${r}&pagination=${p}`)
      .then((response) => dispatch(setGstr1ExpBooks(response.data)));
    dispatch(setIsLoading(false));
  } catch (error) {
    dispatch(setIsLoading(false));
    return console.error(error.message);
  }
  return true;
};

export const getGstr1expBooksSummary = (sd, ed) => async (dispatch) => {
  try {
    axios
      .get(`/summary/gstr1exp?start_date=${sd}&end_date=${ed}`)
      .then((response) => dispatch(setGstr1expBooksSummary(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getGstr1RetailSalesBooks = (sd, ed) => async (dispatch) => {
  try {
    axios.get(`/retail_Sale_as_per_book?start=${sd}&end=${ed}`).then((response) => dispatch(setGstr1RetailSalesBooks(response.data)));
    dispatch(setIsLoading(false));
  } catch (error) {
    dispatch(setIsLoading(false));
    return console.error(error.message);
  }
  return true;
};

export const getGstr1RetailSalesBooksSummary = (sd, ed) => async (dispatch) => {
  try {
    axios
      .get(`/summary/gstr1b2c?start_date=${sd}&end_date=${ed}`)
      .then((response) => dispatch(setGstr1RetailSalesBooksSummary(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getGstr1RetailCreditBooks = (ed, sd, s, r, p) => async (dispatch) => {
  try {
    axios
      .get(`/retail_creditNote_as_per_book?end=${ed}&start=${sd}&search=${s}&records=${r}&pagination=${p}`)
      .then((response) => dispatch(setGstr1RetailCredit(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getGstr1RetailCreditBooksSummary = (sd, ed) => async (dispatch) => {
  try {
    axios
      .get(`/summary/gstr1cdnur?start_date=${sd}&end_date=${ed}`)
      .then((response) => dispatch(setGstr1RetailCreditBooksSummary(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
