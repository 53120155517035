import Joyride from 'react-joyride';
import PropTypes from 'prop-types';
import { useParams, useLocation } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { useState, useEffect } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import { List, Box, ListSubheader } from '@mui/material';
//
import { NavListRoot } from './NavList';
import { getIndustriesSetting } from '../../../redux/slices/organisations';
import { setRun } from '../../../redux/slices/salesprop';
import { useDispatch, useSelector } from '../../../redux/store';
import { getProfileSetting } from '../../../redux/slices/user';

// ----------------------------------------------------------------------

export const ListSubheaderStyle = styled((props) => <ListSubheader disableSticky disableGutters {...props} />)(
  ({ theme }) => ({
    ...theme.typography.overline,
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    color: theme.palette.text.primary,
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.shorter,
    }),
  })
);

// ----------------------------------------------------------------------

NavSectionVertical.propTypes = {
  isCollapse: PropTypes.bool,
  navConfig: PropTypes.array,
};

export default function NavSectionVertical({ navConfig, isCollapse = false, ...other }) {
  const dispatch = useDispatch();
  const run = useSelector((state) => state.salesprop.run);
  const { pathname } = useLocation();
  const isSetting = pathname.includes('/setting');
  const isUserAccount = pathname.includes('/user');

  const [joyrideState, setJoyrideState] = useState({
    run,
    steps: [
      {
        target: '#step-dashboard',
        content: 'Our analytics section provides a real-time dashboard where you can easily track your transactions.',
        disableBeacon: true,
      },
      {
        target: '#step-masterdata',
        content:
          'This section contains three subsections: ledger management, inventory management, and service management.',
        disableBeacon: true,
      },
      {
        target: '#step-transport',
        content:
          'The Transport Industries section enables you to create dockets, generate invoices, and customize both docket and invoice settings.',
        disableBeacon: true,
      },
      {
        target: '#step-transport_2',
        content: 'In this section, you can create a new transport invoice and customize transport settings.',
        disableBeacon: true,
      },
      {
        target: '#step-CourierCargo ',
        content:
          'This section manages courier services like build docket and genrate invoice and customize both settings .',
        disableBeacon: true,
      },
      {
        target: '#step-sales',
        content:
          'In this section, you can create new invoices, generate bills of supply, create export invoices, and download reports in Excel format.',
        disableBeacon: true,
      },
      {
        target: '#step-Ecommerceoronlinesales',
        content: 'This section focuses on eCommerce and online sales.',
        disableBeacon: true,
      },
      { target: '#step-purchase', content: 'In this section, you can create purchase vouchers.', disableBeacon: true },
      { target: '#step-receipt', content: 'This section manages receipt information.', disableBeacon: true },
      {
        target: '#step-paymentorexpense',
        content: 'This section covers new payment and expense with payment details.',
        disableBeacon: true,
      },
      {
        target: '#step-expensejournalorfixedassets',
        content: 'In this section manages expenses journal and fixed Assets entries.',
        disableBeacon: true,
      },
      {
        target: '#step-hrandemployee',
        content: 'This section handles HR and employee management and employee salary details.',
        disableBeacon: true,
      },
      {
        target: '#step-tax',
        content: 'In this section manages tax-related information like GST, TDS, TCS, IncomeTax etc.',
        disableBeacon: true,
      },
      {
        target: '#step-report',
        content:
          'This section provides reporting functionalities like Financial-Statement, Tax-Report, Other-Report, Transaction-History.',
        disableBeacon: true,
      },
      { target: '#step-jobsproject', content: 'In this section create new job and new projects.', disableBeacon: true },
      { target: '#step-team', content: 'This section covers team management details.', disableBeacon: true },
    ],
  });

  useEffect(() => {
    if (isSetting === false && isUserAccount === false) {
      setJoyrideState({
        run,
        steps: [
          {
            target: '#step-dashboard',
            content:
              'Our analytics section provides a real-time dashboard where you can easily track your transactions.',
            disableBeacon: true,
          },
          {
            target: '#step-masterdata',
            content:
              'This section contains three subsections: ledger management, inventory management, and service management.',
            disableBeacon: true,
          },
          {
            target: '#step-transport',
            content:
              'The Transport Industries section enables you to create dockets, generate invoices, and customize both docket and invoice settings.',
            disableBeacon: true,
          },
          {
            target: '#step-transport_2',
            content: 'In this section, you can create a new transport invoice and customize transport settings.',
            disableBeacon: true,
          },
          {
            target: '#step-CourierCargo ',
            content:
              'This section manages courier services like build docket and genrate invoice and customize both settings .',
            disableBeacon: true,
          },
          {
            target: '#step-sales',
            content:
              'In this section, you can create new invoices, generate bills of supply, create export invoices, and download reports in Excel format.',
            disableBeacon: true,
          },
          {
            target: '#step-Ecommerceoronlinesales',
            content: 'This section focuses on eCommerce and online sales.',
            disableBeacon: true,
          },
          {
            target: '#step-purchase',
            content: 'In this section, you can create purchase vouchers.',
            disableBeacon: true,
          },
          { target: '#step-receipt', content: 'This section manages receipt information.', disableBeacon: true },
          {
            target: '#step-paymentorexpense',
            content: 'This section covers new payment and expense with payment details.',
            disableBeacon: true,
          },
          {
            target: '#step-expensejournalorfixedassets',
            content: 'In this section manages expenses journal and fixed Assets entries.',
            disableBeacon: true,
          },
          {
            target: '#step-hrandemployee',
            content: 'This section handles HR and employee management and employee salary details.',
            disableBeacon: true,
          },
          {
            target: '#step-tax',
            content: 'In this section manages tax-related information like GST, TDS, TCS, IncomeTax etc.',
            disableBeacon: true,
          },
          {
            target: '#step-report',
            content:
              'This section provides reporting functionalities like Financial-Statement, Tax-Report, Other-Report, Transaction-History.',
            disableBeacon: true,
          },
          {
            target: '#step-jobsproject',
            content: 'In this section create new job and new projects.',
            disableBeacon: true,
          },
          { target: '#step-team', content: 'This section covers team management details.', disableBeacon: true },
        ],
      });
    }
  }, [run, isSetting, isUserAccount]);

  useEffect(() => {
    dispatch(getIndustriesSetting());
    dispatch(getProfileSetting());
  }, [dispatch]);

  const sectionsEnabled = useSelector((state) => state.organisation?.industriesSetting?.sectionsEnabled);

  const banking = useSelector((state) => state.user?.hideContactNo?.Banking);

  const [nav, setNav] = useState(navConfig);

  useEffect(() => {
    if (sectionsEnabled) {
      let dd = sectionsEnabled.split(',');
      if (banking) {
        dd = [...dd, 'banking'];
      } else {
        dd = [...dd];
      }
      let a = [];
      a = navConfig[0].items;
      const c = ['transport', 'Courier Cargo', 'Courier', 'Ecommerce or online sales', 'banking'];

      if (dd) {
        // dd.forEach((title) => {
        //   a.forEach((e) => {
        //     if (!e.title === title) {
        //       a.push(e);
        //     }
        //     // return a;
        //   });
        // });
        const result = navConfig[0].items.filter((item) => {
          if (c.includes(item.title) && !dd.includes(item.title)) {
            return false;
          }
          return true;
        });

        setNav([{ subheader: 'general', items: result }]);
      }
    }
  }, [sectionsEnabled, banking]);

  const handleCallback = (e) => {
    if (e.action === 'reset') {
      dispatch(setRun(false));
    }
  };

  return (
    <Box {...other}>
      <Joyride
        continuous
        callback={(e) => handleCallback(e)}
        run={joyrideState.run}
        steps={joyrideState.steps}
        hideCloseButton
        scrollToFirstStep
        showSkipButton
        showProgress
        // disableOverlay
        styles={{
          options: {
            zIndex: 2000,
          },

          tooltipContent: {
            textAlign: 'start ',
          },
          'react-joyride__tooltip': {
            marginLeft: '300px',
            left: '300px',
          },
        }}
      />

      {nav.map((group) => (
        <List key={group.subheader} disablePadding sx={{ px: 2 }}>
          <ListSubheaderStyle
            sx={{
              ...(isCollapse && {
                opacity: 0,
              }),
            }}
          >
            {group.subheader}
          </ListSubheaderStyle>

          {group.items.map((list) => (
            <Box id={`step-${list.title?.replaceAll(' ' || '/', '')}`}>
              <NavListRoot key={list.title} list={list} isCollapse={isCollapse} />
            </Box>
          ))}
        </List>
      ))}
    </Box>
  );
}
