import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';

const initialState = {
    flipkartURL:'',
    flipkartFile:'',
    flipkartAll:[],

    amazonURL:'',
    amazonFile:'',
    
    meeshoURL:'',
    meeshoFile:'',
    ecommerceAll:[],

    snapdealURL:'',
    snapdealFile:'',

    isLoading:false,
};


const slice = createSlice({
    name: 'ecommerce',
    initialState,
    reducers: { 
        setFlipkartURL(state, action) {
            state.flipkartURL = action.payload;
        },
        setFlipkartFile(state, action) {
            state.flipkartFile = action.payload;
        },
        setFlipkartAll(state, action) {
            state.flipkartAll = action.payload;
        },

        setAmazonURL(state, action) {
            state.amazonURL = action.payload;
        },
        setAmazonFile(state, action) {
            state.amazonFile = action.payload;
        },

        setMeeshoURL(state, action) {
            state.meeshoURL = action.payload;
        },
        setMeeshoFile(state, action) {
            state.meeshoFile = action.payload;
        },
        setEcommerceAll(state, action) {
            state.ecommerceAll = action.payload;
        },

    
        setSnapdealURL(state, action) {
            state.snapdealURL = action.payload;
        },
        setSnapdealFile(state, action) {
            state.snapdealFile = action.payload;
        },
        setIsLoading(state, action) {
            state.isLoading = action.payload;
        },

    }
});

export default slice.reducer;

export const {
    setFlipkartURL,
    setFlipkartFile,
    setFlipkartAll,


    setAmazonURL,
    setAmazonFile,

    setMeeshoURL,
    setMeeshoFile,
    setEcommerceAll,

    setSnapdealURL,
    setSnapdealFile,

    setIsLoading,
} = slice.actions;


export const getFlipkartAll = (sd, ed, p, r, s) => async (dispatch) => {
    try {
      await axios.get(`/ecommerce/flipkartAll?start_date=${sd}&end_date=${ed}&pagination=${p}&records=${r}&search=${s}`).then((response) => dispatch(setFlipkartAll(response.data)));
    //   dispatch(setIsloading(false));
    } catch (error) {
    //   dispatch(setIsLoading(false));
  
      return console.error(error.message);
    }
    return true;
  };

  export const getEcommerceAll = (sd, ed, p, r, s) => async (dispatch) => {
    try {
        dispatch(setIsLoading(true));
      await axios.get(`/ecommerce/ecommerceAll?start_date=${sd}&end_date=${ed}&pagination=${p}&records=${r}&search=${s}`).then((response) => dispatch(setEcommerceAll(response.data)));
      dispatch(setIsLoading(false));
      
    } catch (error) {
      dispatch(setIsLoading(false));
      return console.error(error.message);
    }
    return true;
  };