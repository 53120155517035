import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';

const initialState = {
  transportDocketData: [],
  transportInvoiceData: null,
  transportDocketSetting: [],
  transportInvoiceSetting: {},
  transportCourierData: [],
  transportCourierInvoiceData: [],
  transportCourierSetting: [],
  transportCourierInvoiceSetting: {},
  master: [],
  masterEdit: [],
  masterEditOpen: false,
  masterEditId: null,
  isView: false,
  isView1: false,
  masterduplicate: false,
  industriesSetting: {},
  invoiceSummary: null,
  tab: 'courier',
  transportSetting: {},
  transportEdit:{},
  transportData:null,
  transportSummary:null,
  isLoading:false,
};

const slice = createSlice({
  name: 'organisations',
  initialState,
  reducers: {
    setTab(state, action){
      state.tab = action.payload;
    },
    setTransportSetting(state, action) {
      state.transportSetting = action.payload;
    },
    setMasterEditOpen(state, action) {
      const masterEditOpen = action.payload;
      state.masterEditOpen = masterEditOpen;
    },
    setDocketdata(state, action) {
      const transportDocketData = action.payload;
      state.transportDocketData = transportDocketData;
    },
    setTransportInvoicedata(state, action) {
      const transportInvoiceData = action.payload;
      state.transportInvoiceData = transportInvoiceData;
    },
    setDocketSetting(state, action) {
      const transportDocketSetting = action.payload;
      state.transportDocketSetting = transportDocketSetting;
    },
    setInvoiceSetting(state, action) {
      state.transportInvoiceSetting = action.payload;
    },
    setCourierdata(state, action) {
      const transportCourierData = action.payload;
      state.transportCourierData = transportCourierData;
    },
    setCourierInvoicedata(state, action) {
      const transportCourierInvoiceData = action.payload;
      state.transportCourierInvoiceData = transportCourierInvoiceData;
    },
    setCourierSetting(state, action) {
      const transportCourierSetting = action.payload;
      state.transportCourierSetting = transportCourierSetting;
    },
    setCourierInvoiceSetting(state, action) {
      state.transportCourierInvoiceSetting = action.payload;
    },
    setMaster(state, action) {
      state.master = action.payload;
    },
    setMasteredit(state, action) {
      state.masterEdit = action.payload;
    },
    setMastereditId(state, action) {
      state.masterEditId = action.payload;
    },
    setIsview(state, action) {
      state.isView = action.payload;
    },
    setIsview1(state, action) {
      state.isView1 = action.payload;
    },
    setMasterDuplicate(state, action) {
      state.masterduplicate = action.payload;
    },
    setIndustriesSetting(state, action) {
      state.industriesSetting = action.payload;
    },
    setInvoiceSummary(state, action) {
      state.invoiceSummary = action.payload;
    },
    setTransportedit(state, action) {
      state.transportEdit = action.payload;
    },
    setTransportSummary(state, action) {
      state.transportSummary = action.payload;
    },
    setTransportData(state, action) {
      state.transportData = action.payload;
    },
  },
});

export default slice.reducer;

export const {
  setMasterDuplicate,
  setMastereditId,
  setMasterEditOpen,
  setMasteredit,
  setDocketdata,
  setTransportInvoicedata,
  setDocketSetting,
  setInvoiceSetting,
  setCourierdata,
  setCourierInvoicedata,
  setCourierSetting,
  setCourierInvoiceSetting,
  setMaster,
  setIsview,
  setIsview1,
  setIndustriesSetting,
  setInvoiceSummary,
  setTransportSetting,
  setTransportedit,
  setTransportSummary,
  setTransportData,
  setTab,
} = slice.actions;





export const getTransportSummary = (sd, ed) => async (dispatch) => {
  try {
    await axios.get(`/organisations/transport2/summary/trns2?start_date=${sd}&end_date=${ed}`).then((response) => dispatch(setTransportSummary(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getTransportAll = (sd, ed, n, r, s) => async (dispatch) => {
  try {
    await axios.get(`/organisations/transport2/invoiceAll?start_date=${sd}&end_date=${ed}&pagination=${n}&records=${r}&search=${s}`).then((response) => dispatch(setTransportData(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getTransportedit = (id) => async (dispatch) => {
  try {
    await axios.get(`/organisations/transport2/invoice?ID=${id}`).then((response) => dispatch(setTransportedit(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getTransportSetting = () => async (dispatch) => {
  try {
    await axios.get('/organisations/transport2/settings').then((response) => dispatch(setTransportSetting(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getIndustriesSetting = () => async (dispatch) => {
  try {
    await axios.get('/organisations/get_setting').then((response) => dispatch(setIndustriesSetting(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getDockets = () => async (dispatch) => {
  try {
    await axios.get('/organisations/transportDocket').then((response) => dispatch(setDocketdata(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getCourier = () => async (dispatch) => {
  try {
    await axios.get('/organisations/courierCargo').then((response) => dispatch(setCourierdata(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getCourierInvoiceSummary = (sd, ed) => async (dispatch) => {
  try {
    await axios
      .get(`/organisations/summary/CUR?start_date=${sd}&end_date=${ed}`)
      .then((response) => dispatch(setInvoiceSummary(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getInvoiceSummary = (sd, ed) => async (dispatch) => {
  try {
    await axios
      .get(`/organisations/summary/TRP?start_date=${sd}&end_date=${ed}`)
      .then((response) => dispatch(setInvoiceSummary(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getInvoiceDockets = (sd, ed, n, r, s) => async (dispatch) => {
  try {
    await axios
      .get(`/organisations/transportInvoice?start_date=${sd}&end_date=${ed}&pagination=${n}&records=${r}&search=${s}`)
      .then((response) => dispatch(setTransportInvoicedata(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getCourierInvoice = (sd, ed, n, r, s) => async (dispatch) => {
  try {
    await axios
      .get(`/organisations/courierInvoice?start_date=${sd}&end_date=${ed}&pagination=${n}&records=${r}&search=${s}`)
      .then((response) => dispatch(setCourierInvoicedata(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getDocketSettings = () => async (dispatch) => {
  try {
    await axios
      .get('/organisations/transportDocket/docketSettings/all')
      .then((response) => dispatch(setDocketSetting(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getCourierSetting = () => async (dispatch) => {
  try {
    await axios
      .get('/organisations/CourierCargo/CourierSettings/all')
      .then((response) => dispatch(setCourierSetting(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getInvoiceSettings = () => async (dispatch) => {
  try {
    await axios
      .get('/organisations/transport_invoice_setting')
      .then((response) => dispatch(setInvoiceSetting(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getCourierInvoiceSetting = () => async (dispatch) => {
  try {
    await axios
      .get('/organisations/courier_invoice_setting')
      .then((response) => dispatch(setCourierInvoiceSetting(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getMaster = () => async (dispatch) => {
  try {
    await axios.get('/organisations/masterAll').then((response) => dispatch(setMaster(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getMasteredit = (id) => async (dispatch) => {
  try {
    dispatch(setMastereditId(id));
    await axios.get(`/organisations/master/${id}`).then((response) => dispatch(setMasteredit(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};