import { useSnackbar } from 'notistack';
import { useEffect, useState, useRef } from 'react';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Dialog, DialogContent, Typography, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FormProvider, RHFTextField } from '../components/hook-form';
import axios from '../utils/axios';
import Iconify from '../components/Iconify';

const PoPMessageForm = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState(false);

  const isCheck = useRef(true);

  const handleClose = () => {
    setOpen(false);
  };

  // useEffect(() => {
  //   window.addEventListener('scroll', () => {
  //     if (isCheck.current) {
  //       setOpen(true);
  //       isCheck.current = false;
  //     }
  //   });

  //   return () => {
  //     window.removeEventListener('scroll');
  //   };
  // }, []);

  let clear = null;
  clear = setTimeout(() => {
    // setOpen(true);
  }, [5000]);

  useEffect(() => {
    if (open) {
      clearTimeout(clear);
    }
  }, [open]);

  const UpdateUserSchema = Yup.object().shape({
    customerName: Yup.string().required('name is required'),
    quoteEmail: Yup.string().email().required('email is required'),
    quoteMobile: Yup.string().required('mobile number is required'),
  });

  const defaultValues = {
    customerName: '',
    quoteEmail: '',
    quoteMobile: '',
  };

  const methods = useForm({
    resolver: yupResolver(UpdateUserSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    try {
      await axios.post(`/postQuote`, data);
      enqueueSnackbar('send success!');
      setOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <Box
        onClick={handleClose}
        sx={{ display: 'flex', justifyContent: 'end', paddingRight: 2, paddingTop: 2, marginBottom: -5 }}
      >
        <Iconify icon={'ph:x'} sx={{ width: 30, height: 30, opacity: 0.1 }} />
      </Box>
      <DialogContent>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Box
            sx={{
              display: 'grid',
              p: 2,
              rowGap: 1,
              gap: 2,
              columnGap: 2,
              justifyContent: 'center',
              alignItems: 'center',
              gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(1, 1fr)' },
            }}
          >
            <Typography variant="h5" sx={{ textAlign: 'center', color: '#05406C' }}>
              Get in Touch With Us
            </Typography>
            <RHFTextField name="customerName" label="Name" size="small" />
            <RHFTextField name="quoteMobile" label="Phone" size="small" />
            <RHFTextField name="quoteEmail" label="Email" size="small" />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <LoadingButton
                type="submit"
                sx={{ background: '#2065D1', width: '120px' }}
                variant="contained"
                loading={isSubmitting}
              >
                submit
              </LoadingButton>
            </Box>
          </Box>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
};

export default PoPMessageForm;
